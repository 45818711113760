"use strict";
//target popup window from discovery/audience

require("pages/targets/targets-common");

var config = require('infra/config'),
    c = require("infra/utils/common"),
    queries = {},
    HOST = config.USER_MGMT_API + '/programs/',
    POSTFIX = "?disable_notification=1";

queries.targetlist = {
  title: 'targets',
  dataArray: [],
  columns: [

          {id: 'name', title: 'Target', ellipsis: true, alwaysShown:true,  template: 'partials/targets-select.partial.html'},
          {id: '_id', title: 'ID', search: true, flex:'0-3', alwaysShown:true, displayValue: "full_id"},
          {id: 'target_type_display', title: 'Type', flex:'0-7', search: true},
          {id: '_user_name', title: 'Created by', search: true, search: true, flex:'0-5'},
          {id: 'date_added', title: 'Date Created', search: true,  displayValue: "date_added_formated"},
          {id: 'updated_by', title: 'Updated by', search: true, flex:'0-5'},
          {id: 'date_updated', title: 'Date Updated', search: true, displayValue: "date_updated_formated" },
          {id: 'activated_to_sorted', title: 'Activated To', displayValue: 'activated_to_display', search: true, flex:'0-7'}
          ],

  crud: true,
  fixedSize: false,
  order: 'name',
  reverse: false,
  excel: false
};

targetsController.$inject = ['$scope', '$http', '$state', 'context', '$filter', 'parent', 'TargetsCommon', 'CHANNEL', 'ModalService', 'notificator', '$timeout', 'close', 'confirmAction', 'mgmtServiceFactory', 'mixpanelSettings'];
function targetsController($scope, $http, $state, context, $filter, parent, TargetsCommon , CHANNEL, ModalService, notificator, $timeout, close , confirmAction, mgmtServiceFactory, mixpanelSettings) {
  
    $scope.queries=queries;
    $scope.editTargetOpened=false;

    $scope.currentTargetName = function(){
      return parent.currentTarget.name ? 
      'Current target: '+parent.currentTarget.name : 'No target selected';
    }

    $scope.isCurrentTarget = function(_id){
      return _id == parent.currentTarget._id;
    }

    $scope.init = function () {

      TargetsCommon.initTargets(parent.targets);
      setTimeout(function(){
        $scope.queries['targetlist'].show(parent.targets);
      });

    };

    $scope.close = function(){
      close(); 
    }


    //events;
    var updateTargetsCBWatch = $scope.$root.$on('updateTargetsCB', function (event, obj) {
        if(obj.action=='create-target'){
          $scope.close();
        }else if(obj.action=='remove-target' ||
          obj.action=='update-target'){
          TargetsCommon.showUpdatedList($scope, obj.targets);
        }
    });

    $scope.$on('$destroy', function () { //exit grid
        updateTargetsCBWatch(); //clears watch
    });

    $scope.switchTarget = function(target){
      
      $scope.$root.$broadcast('updateTargets', {
          action: 'switch-target', 
          target: target});
      $scope.close();
    }

    $scope.edit = function(target){
      TargetsCommon.edit(target, parent.tab, $scope);
    }

    $scope.delete = function(target){
      $scope.deleteTarget = target;
    }

    $scope.deleteConfirm = function(){
      TargetsCommon.deleteConfirm($scope);
    }

    $scope.queries.targetlist.clickRowHandler = $scope.switchTarget;
    $scope.init();
}

module.exports = targetsController;
