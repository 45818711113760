"use strict";
const config = require('infra/config');
const audienceSegmentBuilderHelper = require('./audience-segment-builder-helper');
const common = require("infra/utils/common");

const AUDIENCE_INSIGHTS_URL = config.AUDIENCE_PROFILER_API + '/api/1/ui-related-keywords2';
const AUDIENCE_USER_HISTORY_URL = config.AUDIENCE_PROFILER_API + '/api/1/highlighted-user-history';
const AUDIENCE_SEGMENT_DISTRIBUTION_URL = config.AUDIENCE_PROFILER_API + '/api/v2/audience/demographics';
//AUDIENCE_INSIGHTS_URL = "http://localhost:8099/api/1/ui-related-keywords2"

module.exports = require("angular").module(__filename, [require('data/tv-service').name, require('./audience-skew-service').name])
    .service("audienceInsightsService", ['$q', '$http', 'audienceSkewService', 'keywords', 'errorMgmt', 'util', 'filtersPartition', 'cancellableHttp', 'abiPermissions', 'tvService',
        function($q, $http, audienceSkewService, keywordsService, errorMgmt, util, filtersPartition, cancellableHttp, abiPermissions, tvService) {
            // cache for demographics data / insights data requests
            let lastDemographicsCacheKey, lastReqCacheKey,
                lastDemographicsPromise, lastReqPromise,
                segmentPropertiesReqs = {};

            // demographics data
            this.getFullDemographicsDataWithGenderAgeBySegment = getFullDemographicsDataWithGenderAgeBySegment;
            this.getFullDemographicsDataWithGenderAge = getFullDemographicsDataWithGenderAgeByLogicalStatement;
            this.getDemographicsDataForPreviewBySegment = getDemographicsDataForPreviewBySegment;
            this.getDemographicsDataForPreview = getDemographicsDataForPreviewByLogicalStatement;
            this.getDemographicsDataByKeywordsAndCountries = getDemographicsDataByKeywordsAndCountries;
            // insights data
            this.getData = getData;
            this.getSegmentData = getSegmentData;
            this.getPhrasesIndexInLifestyle = getPhrasesIndexInLifestyle;
            this.getTvInfo = getTvInfo;
            // user history data
            this.userHistory = getUserHistory;
            // utility fns
            this.getSegmentParams = getSegmentParams;
            this.isRequestCancelled = isRequestCancelled;
            // CONSTANTS
            this.QUERY_NAMES = {phrases: "xw", websites: "td", topics: "new-topics"};
            this.levelOfIntentValues = filtersPartition.levelOfIntent.map(i => i.value);

            // demographics: service methods

            function getRawDemographicsDataByLogicalStatement(query, sampleSize = 1500) {
                let filtersCacheKey = JSON.stringify(query);
                if(filtersCacheKey === lastDemographicsCacheKey) return lastDemographicsPromise;

                const promise = cancellableHttp.$http({
                    url: AUDIENCE_SEGMENT_DISTRIBUTION_URL,
                    method: 'POST',
                    cache: true,
                    data: {query, "sample-size": sampleSize}
                })
                    .cancellableThen(res => {
                        if(!res) return false;
                        if(res.status === 200 && res.data.status === 'ok')
                            for(let dataType of ['distribution', 'skew'])
                                res.data.data = fixAgeLegal(res.data.data, dataType);
                        return res.data
                    }, responseFailed(true));

                lastDemographicsCacheKey = filtersCacheKey;
                lastDemographicsPromise = promise;
                return promise;
            }

            function getFullDemographicsDataWithGenderAgeBySegment(segment) {
                const query = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment);
                return getFullDemographicsDataWithGenderAgeByLogicalStatement(query);
            }

            function getFullDemographicsDataWithGenderAgeByLogicalStatement(query) {
                const rawDataPromise = getRawDemographicsDataByLogicalStatement(query);
                const transformedDataPromise = rawDataPromise.then(data => {
                    if(!(data && data.data) || data.status !== 'ok') return data;

                    const dataParts = {
                        audienceSize: transformAudienceSize(data.data),
                        gender: transformGender(data.data),
                        genderAge: transformGenderAge(data.data, query),
                        income: transformIncome(data.data),
                        ethnicity: transformEthnicity(data.data)
                    };
                    return Promise.all(Object.values(dataParts))
                        .then(resultsArr => _.fromPairs(_.zip(Object.keys(dataParts), resultsArr)))
                        .then(transformDataByTypeToDataByDistSkew)
                        .then(transformedData => Object.assign(transformedData, {status: data.status}));
                });

                transformedDataPromise.cancel = rawDataPromise.cancel;
                return transformedDataPromise;
            }

            function getDemographicsDataForPreviewBySegment(segment) {
                const query = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment);
                return getDemographicsDataForPreviewByLogicalStatement(query);
            }

            function getDemographicsDataForPreviewByLogicalStatement(query) {
                const rawDataPromise = getRawDemographicsDataByLogicalStatement(query);
                const transformedDataPromise = rawDataPromise.then(data => {
                    if(!(data && data.data) || data.status !== 'ok') return data;

                    const dataParts = {
                        audienceSize: transformAudienceSize(data.data),
                        gender: transformGender(data.data),
                        age: transformAge(data.data)
                    };
                    return Promise.all(Object.values(dataParts))
                        .then(resultsArr => _.fromPairs(_.zip(Object.keys(dataParts), resultsArr)))
                        .then(transformDataByTypeToDataByDistSkew)
                        .then(transformedData => Object.assign(transformedData, {status: data.status}));
                });

                transformedDataPromise.cancel = rawDataPromise.cancel;
                return transformedDataPromise;
            }

            function getDemographicsDataByKeywordsAndCountries(keywords, countries = []) {
                let query = ['and', {keywords}];
                countries = countries.map(country => country.toLowerCase());
                if(!!countries.length) query.push(['or', ...countries.map(country => ({countries: country}))]);
                return getDemographicsDataForPreviewByLogicalStatement(query);
            }

            function transformDataByTypeToDataByDistSkew(data) {
                let accObj = {distribution: {}, skew: {}};
                for(let prop in data) {
                    const {distribution, skew} = data[prop];
                    if(distribution && skew) {
                        accObj.distribution[prop] = distribution;
                        accObj.skew[prop] = skew;
                    } else {
                        accObj[prop] = data[prop];
                    }
                }
                return accObj
            }

            function getGenderSpecificRawDemographicsDataByQuery(query, gender) {
                const queryForTheSpecificGender = ['and', query, {genders: gender}];
                return getRawDemographicsDataByLogicalStatement(queryForTheSpecificGender);
            }

            // demographics: utility fns

            function roundSegmentRatio(ratio) {
                ratio = +(ratio * 100).toFixed(2);
                return ratio < 0.1 ? Math.max(ratio, 0.01) : parseFloat(ratio.toFixed(1));
            }

            // * data properties transform functions

            const formatDist = value => `${Math.round(value)}%`;
            const formatSkew = value => value.toFixed(1);
            const sortByLabelsNum = ageObject => _.sortBy(ageObject, o => +o.label.split('-')[0]);

            function transformAudienceSize(data) {
                const segmentSize = roundSegmentRatio(data['intenders-ratio-in-geo']),
                    population = data['segment-size-in-geo'] * 1000000;
                const NUM_OF_TICKS = 15, PERCENTS_ACTUALLY_PER_TICK = 100 / NUM_OF_TICKS,
                    PERCENTS_WANTED_PER_TICK = 1,
                    MIN_TICKS = 4, MIN_TICKS_PERCENTS = 0.5,
                    MIN_TICKS_PERCENTS_ADDITION = MIN_TICKS * PERCENTS_ACTUALLY_PER_TICK;
                const segmentGaugeBarPercents = (segmentSize - Math.min(segmentSize, MIN_TICKS_PERCENTS)) * PERCENTS_ACTUALLY_PER_TICK / PERCENTS_WANTED_PER_TICK + MIN_TICKS_PERCENTS_ADDITION;
                return {segmentSize, population, segmentGaugeBarPercents};
            }

            function transformGender(data) {
                const {male: maleDist, female: femaleDist} = data['gender-distribution'];
                const {male: maleSkew, female: femaleSkew} = data['gender-skew'];
                return {
                    distribution: {
                        male: {value: maleDist, displayValue: formatDist(maleDist * 100)},
                        female: {value: femaleDist, displayValue: formatDist(femaleDist * 100)}
                    },
                    skew: {
                        male: {value: +formatSkew(maleSkew), displayValue: formatSkew(maleSkew)},
                        female: {value: +formatSkew(femaleSkew), displayValue: formatSkew(femaleSkew)}
                    }
                };
            }

            function transformGenderAge(data, query) {
                const {male: hasMaleDist, female: hasFemaleDist} = data['gender-distribution'];

                let maleData, femaleData;
                if(hasMaleDist && hasFemaleDist) {
                    [maleData, femaleData] = ['male', 'female'].map(gender =>
                        getGenderSpecificRawDemographicsDataByQuery(query, gender).then(r => r.status === 'ok' ? r.data : data));
                } else { // if only one gender has data (and not both) then this gender is 100% and no need to make another query to server
                    const emptyAgeObj = _.fromPairs(_.map(data['age-distribution'], (v, k) => [k, 0]));
                    const emptyAgeData = Object.assign({}, data, {'age-distribution': emptyAgeObj, 'age-skew': emptyAgeObj});
                    [maleData, femaleData] = hasMaleDist ? [data, emptyAgeData] : [emptyAgeData, data];
                }

                return Promise.all([maleData, femaleData]).then(([maleData, femaleData]) => {
                    const maleDistributionValues = Object.values(maleData['age-distribution']).map(p => p * 100 * data['gender-distribution']['male']);
                    const femaleDistributionValues = Object.values(femaleData['age-distribution']).map(p => p * 100 * data['gender-distribution']['female']);
                    // roundPercents is done to all data though data is still treated as separate for male and female
                    const bothNormalizedDist = common.roundPercents(maleDistributionValues.concat(femaleDistributionValues));
                    const ages = Object.keys(data['age-distribution']);

                    const dist = ages.map((age, i) => {
                        const male = bothNormalizedDist[i], female = bothNormalizedDist[i + ages.length];
                        return {label: age, male: {value: male, displayValue: formatDist(male)}, female: {value: female, displayValue: formatDist(female)}};
                    });
                    const skew = ages.map(age => {
                        const male = formatSkew(maleData['age-skew'][age]), female = formatSkew(femaleData['age-skew'][age]);
                        return {label: age, male: {value: +male, displayValue: male}, female: {value: +female, displayValue: female}};
                    });

                    return {distribution: sortByLabelsNum(dist), skew: sortByLabelsNum(skew)};
                });
            }

            function transformAge(data) {
                const distValues = common.roundPercents(Object.values(data['age-distribution']).map(v => v * 100));
                const dist = Object.keys(data['age-distribution']).map((age, i) => ({
                    label: age, value: distValues[i], displayValue: formatDist(distValues[i])
                }));
                const skew = _.map(data['age-skew'], (value, label) => ({
                    label, value: +formatSkew(value), displayValue: formatSkew(value)
                }));
                return {distribution: sortByLabelsNum(dist), skew: sortByLabelsNum(skew)};
            }

            function transformIncome(data) {
                const allowedIncomes = ['0-25k', '25-50k', '50-75k', '75-100k', '100-150k', '150-200k', '200k+'];
                return {
                    distribution: sortByLabelsNum(_.map(
                        _.pick(data['income-distribution'], allowedIncomes),
                        (value, label) => ({label: label.toUpperCase(), value: value * 100, displayValue: formatDist(value * 100)})
                    )),
                    skew: sortByLabelsNum(_.map(
                        _.pick(data['income-skew'], allowedIncomes),
                        (value, label) => ({label: label.toUpperCase(), value: +formatSkew(value), displayValue: formatSkew(value)})
                    ))
                };
            }

            function transformEthnicity(data) {
                let {"race-distribution": ethnicityDist, "race-skew": ethnicitySkew} = data;

                const raceMapping = {white: "Caucasian", asian: "Asian American", hispanic: "Hispanic", black: "African American"};
                const ethnicities = Object.keys(ethnicityDist).map(key => raceMapping[key]);
                const distValues = common.roundPercents(Object.values(ethnicityDist).map(v => v * 100));
                const skewValues = Object.values(ethnicitySkew).map(formatSkew);

                ethnicityDist = _.zip(ethnicities, distValues).map(([label, value]) => ({label, value: +value, displayValue: formatDist(value)}));
                ethnicitySkew = _.zip(ethnicities, skewValues).map(([label, value]) => ({label, value: +value, displayValue: value}));

                ethnicityDist = _.orderBy(ethnicityDist, 'value', 'desc');
                const ethnicitySkewByLabel = _.keyBy(ethnicitySkew, o => o.label);
                ethnicitySkew = Object.values(raceMapping).map(l => ethnicitySkewByLabel[l]); // order like in +raceMapping+. alphabetically in the future.
                return {distribution: ethnicityDist, skew: ethnicitySkew};
            }

            function fixAgeLegal(data, dataType = 'distribution') {
                const key = `age-${dataType}`;
                if(data && data[key]) data[key] = Object.assign({'13-17': data[key]['12-17']}, _.omit(data[key], ['2-11', '12-17']));
                return data
            }

            // insights: service methods

            function getData(reqData) {
                const reqCacheKey = JSON.stringify(reqData);
                if(reqCacheKey === lastReqCacheKey) return lastReqPromise;
                const segmentPropertiesCacheKey = JSON.stringify(_.omit(reqData, 'search-type'));
                // TODO: handle case of isSport => it will delete the cache! and will be saved as frequent-keyword promise
                if(segmentPropertiesCacheKey !== segmentPropertiesReqs['cache']) segmentPropertiesReqs = {cache: segmentPropertiesCacheKey};
                if(segmentPropertiesReqs[reqData['search-type']]) return segmentPropertiesReqs[reqData['search-type']];

                const reqDefaultsBySearchType = {
                    "wiki": {"wiki-data-entities": 10},
                    "td": {"added-topics-number": 1400, "ensure-topics": 40},
                    "frequent-keywords": {"ensure-topics": 40, "ensure-topics2": 1, "added-topics-number": 1000},
                    "xw": {"ensure-topics": 40, "ensure-topics2": 1, "added-topics-number": 1000},
                    "tv": {"added-topics-number": 1000}
                };
                Object.assign(reqData, reqDefaultsBySearchType[reqData['search-type']]);

                const promise = $http({
                    url: AUDIENCE_INSIGHTS_URL,
                    method: 'POST',
                    cache: true,
                    data: reqData
                })
                    .then(res => {
                        if(!res) return;

                        if(res.data['users-sample']) res.data['users-sample'].forEach(user => { if(user.age === '12-17') user.age = '13-17' });
                        const data = fixAgeLegal(res.data);

                        if(data.status === 'insufficient data' || data.status === 'segment too wide') return data;

                        if(reqData.isSports) data.words = data.brands;
                        data['intenders-ratio-in-geo'] = roundSegmentRatio(data['segment-proportion-heuristic'] || data['intenders-ratio-in-geo']);

                        const convertKIdToPhrase = !['new-topics', 'td', 'tv'].includes(reqData['search-type']) && !reqData['only-phrase-id'];
                        return (convertKIdToPhrase ? getPhrasesByPhrasesIds(data.words, data.brands) : Promise.resolve()).then(() => {
                            data.words.forEach(word => {
                                if(convertKIdToPhrase) {
                                    const wordsArr = (word['phrase'] || '').toLowerCase().split(' ').sort();
                                    word['longest-term-check'] = {words_arr: wordsArr, num_of_words: wordsArr.length};
                                } else {
                                    word['phrase'] = word['phrase-id'];
                                }
                                // transform to percents
                                word['interest-portion'] = word['interest-portion'] * 100;
                                word['segment-portion'] = word['segment-portion'] * 100;
                            });
                            data.words = data.words.filter(word => word['phrase'] !== undefined && word['topic'] !== 'Sensitive Content');

                            if(convertKIdToPhrase) data.words = deleteContainedPhrases(data.words);

                            return data;
                        });
                    }, responseFailed(true));

                lastReqCacheKey = reqCacheKey;
                lastReqPromise = promise;
                if(['xw', 'td', 'frequent-keywords'].includes(reqData['search-type']))
                    segmentPropertiesReqs[reqData['search-type']] = promise;
                return promise;
            }

            function getTvInfo(segment) {
                return getSegmentData(segment, 'tv').then(res => {
                    const shows = res.words;
                    if(_.isEmpty(shows)) return shows;
                    return tvService.getShows(shows.map(show => show['phrase-id'])).then(shows => {
                        res.words.forEach(word => {
                            const show = shows[word['phrase-id']];
                            if(show) Object.assign(word, _.pick(show, ['title', 'image', 'genre', 'summary', 'network']));
                        });
                        return res.words.filter(word => word.title);
                    });
                });
            }

            function getPhrasesIndexInLifestyle(segment, phrases) {
                if(segment && phrases && phrases.length)
                    return getSegmentData(segment, "keywords", {
                        'words-sample-size': audienceSkewService.DEFAULTS['words-sample-size'],
                        'added-topics-number': 0,
                        'only-phrase-id': true,
                        'pre-chosen-words': phrases
                    }).then(audience_data => {
                        return audience_data.words.reduce((phraseToCompIndex, phrase) => {
                            phraseToCompIndex[phrase["phrase-id"] + ""] = phrase["uniqueness-index"]
                            return phraseToCompIndex;
                    }, {})
                    });
                else
                    return Promise.resolve(null);
            }

            function getSegmentData(segment, searchType, additional_params) {
                return getData(getSegmentParams(segment, searchType, additional_params));
            }

            function getSegmentParams(segment, searchType, additional_params) {
                const isTv = searchType === 'tv';
                const DEFAULT_PARAMS = {'users-sample-size': audienceSkewService.DEFAULTS['users-sample-size'], 'words-sample-size': 1000};
                const logicalStatement = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment, isTv);
                const referenceGroup = getReferenceGroup(segment, isTv) && {filter2: getReferenceGroup(segment, isTv)};
                return Object.assign(DEFAULT_PARAMS, {filter1: logicalStatement, 'search-type': searchType}, referenceGroup, additional_params);
            }

            function getSegmentPromise(segmentType, segment) {
                return segmentPropertiesReqs[segmentType] || getSegmentData(segment, segmentType)
            }

            // insights: utility fns

            function getPhrasesByPhrasesIds(phrases, brands) {
                if(_.isEmpty(phrases) && _.isEmpty(brands)) return Promise.resolve();
                return keywordsService.get_kwds_by_ids(_.map(phrases.concat(brands), 'phrase-id'), 1.3)
                    .then(res => { phrases.forEach(word => { word['phrase'] = res[word['phrase-id']] }) })
            }

            function getReferenceGroup(segment, isTv) {
                const demographics = _.filter(segment, segment => segment.type === "demographics")[0];
                const refGroupOK = demographics && demographics.geo && !(segment.length === 1 && _.isEmpty(_.omit(demographics, ['type', 'geo', 'operand'])));
                const demoRefGroup = refGroupOK ? {countries: `${demographics.geo[0].cc.toLowerCase()}:bid_stream`} : null;
                const tvRefGroup = isTv ? {"any-tv": "yes"} : null;
                const refGroup = _.compact([demoRefGroup, tvRefGroup]);
                return _.isEmpty(refGroup) ? null : ["and", ...refGroup];
            }

            function deleteContainedPhrases(phrases) {

                function markIfContain(a1, a2) {
                    let i = 0, j = 0;
                    while(i < a1['words_arr'].length && j < a2['words_arr'].length) {
                        let compare = a1['words_arr'][i].localeCompare(a2['words_arr'][j]);
                        if(compare === 1) return;
                        if(compare === 0) j++;
                        i++;
                    }
                    if(j < a2['words_arr'].length) return;
                    a2['contained'] = true;
                }

                const MIN_DEST_BETWEEN_SIMILAR_TERMS = 20;
                if(phrases.length <= MIN_DEST_BETWEEN_SIMILAR_TERMS) return phrases;
                for(let i = 0; i < phrases.length; i++) {
                    let last_index = Math.min(i + MIN_DEST_BETWEEN_SIMILAR_TERMS, phrases.length);
                    for(let j = i + 1; j < last_index; j++) {
                        let phrase1 = phrases[i]['longest-term-check'];
                        let phrase2 = phrases[j]['longest-term-check'];
                        if(phrase1['num_of_words'] !== phrase2['num_of_words']) continue;
                        phrase1['num_of_words'] > phrase2['num_of_words'] ? markIfContain(phrase1, phrase2) : markIfContain(phrase2, phrase1);
                    }
                }
                return phrases.filter(phrase => !phrase['longest-term-check']['contained']);
            }

            // user history: service method

            function getUserHistory(segment, userId) {
                const segmentPropertiesPromise = getSegmentProperties(segment);
                const logicalStatement = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(segment);
                return segmentPropertiesPromise.then(function(segmentProperties) {
                    return $http({
                        url: AUDIENCE_USER_HISTORY_URL,
                        method: 'POST',
                        cache: true,
                        data: {"highlight-filters": segmentProperties, "filter1": logicalStatement, "user-id": userId, "allow-porn": false}
                    }).then(responseSuccess, responseFailed(true));
                })
            }

            function getSegmentProperties(segment) {
                const searchesPromise = getSegmentPromise('gsw', segment);
                const domainsPromise = getSegmentPromise('td', segment);
                return $q.all([searchesPromise, domainsPromise]).then(([searches, domains]) => ({
                    searches: getSegmentMostCommonKeywords(searches), domains: getSegmentMostCommonKeywords(domains)
                }));
            }

            // user history: utility fns

            function getSegmentMostCommonKeywords(data) {
                if(!data || !data.words) return [];
                const topByCompositionIndex = _.sortBy(data.words, 'uniqueness-index').reverse().slice(0, 30);
                return _.uniq(topByCompositionIndex.concat(topByCompositionIndex).map(word => word['phrase-id']));
            }

            // other service methods and utility fns

            const REQUEST_STATUS_CANCEL = -1;

            function isRequestCancelled(data) {
                return data === REQUEST_STATUS_CANCEL;
            }

            function responseSuccess(res) {
                return res ? res.data : false;
            }

            function responseFailed(showErrorMsg) {
                return function(err) {
                    if(err && err.config && err.config.timeout && err.config.timeout.$$state && err.config.timeout.$$state.value === "AMOBEE_CANCEL_OK")
                        return REQUEST_STATUS_CANCEL;

                    if(showErrorMsg)
                        errorMgmt.widgetServiceError('Audience', err);

                    return false
                }
            }
        }]
    );
