"use strict";
"use strict";

module.exports = angular.module(__filename, []).factory("TopicsMold", function () {
    return function TopicsMold(contex) {
        var self = this;
        self._value = [];
        self.default = [];

        this.replace = function (val) {
            return self._value = val || self.default;
        }.bind(this);
    }
});
