"use strict";
module.exports = {
  geographyConfig: {
    highlightBorderColor: '#fff',
    highlightBorderWidth: 2,
    highlightBorderOpacity: 2,
    borderColor: '#202427',
    borderWidth: 1,
  },
  fills: {
    defaultFill: '#81878c'
  }
}
