"use strict";
module.exports = angular.module(__filename, [])
    .directive('tagsViewer', [function () {
        return {
            restrict: 'E',
            template: require('./tags-viewer.drv.html'),
            scope: {
                "tags": "=",
                "displayKey": "@",
                "onAdd": "&"
            },
            link: function (scope) {
                scope.add = function(tag){
                    scope.onAdd()(tag)
                }
            }
        }
    }]
);
