"use strict";
var SVGMorpheus = require("svg-morpheus");

module.exports = angular.module(__filename, []).directive("svgInline", ["$http", function ($http) {
    return {
        restrict: "E",
        scope: {
          cache: '<'
        },
        link: function ($scope, $elem, $attr) {
            $http.get($attr.src, { cache: !!$scope.cache }).then(function (res) {
                $elem.html(res.data);
                if ($attr.morph) {
                    var morph = new SVGMorpheus($elem.find("svg")[0], {iconId: $attr.morph});
                    $attr.$observe("morph", function (iconId) {
                        morph.to(iconId, {rotation: "none"});
                    });
                }
            });
        }
    }
}]);
