"use strict";
function SentimentController($rootScope) {
}

SentimentController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "sentiment",
    url: "/sentiment",
    template: require("./sentiment.insights.html"),
    display: "Insights",
    data: {
      permissions: ['sentiment']
    },
    controller: SentimentController,
    context: {}
};
