"use strict";
/**
 * Created by pery on 22/07/2015.
 */
module.exports = function postLinkSwitch(scope, element, attr, ctrl, transcludeFn) {

    scope.toggleMenu = toggleOpen;

    function toggleOpen(event) {
        element.toggleClass('open');
        if (element.hasClass('open')) {
            //event.stopPropagation();
            watchForGlobalClosedClick();
        }
    }

    function watchForGlobalClosedClick() {
        setTimeout(function () {
            document.addEventListener('click', closeOptionsList, false);
        });

        function closeOptionsList(event) {
            element.removeClass('open');
            document.removeEventListener('click', closeOptionsList, false);
            event.stopPropagation();
        }
    }
};
