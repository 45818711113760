"use strict";
module.exports = angular.module(__filename, [
    require("angular-modal-service").name,
    require("data/settings/user-mgmt").name
]).
    service('forgotPasswordModal', ['ModalService', 'userMgmt', function (ModalService, userMgmt) {
        this.show = showForgotPasswordModal; // API

        function showForgotPasswordModal() {
            return ModalService.showModal({
                template: require('./forgot-password.html'),
                controller: ['$scope', '$timeout', 'close', ForgotPasswordController]
            });
        }

        function ForgotPasswordController($scope, $timeout, close) {
            $scope.update = validateAndUpdate;
            $scope.close = close;

            // close modal on "back"
            var closeListener = $scope.$root.$on("$stateChangeStart", function () {
                close()
            });
            $scope.$on('$destroy', closeListener);

            function validateAndUpdate(form) {
                $scope.isBusy = true;
                $timeout(function () {
                    ResetPwdRequest(form)
                }, 500);
            }

            function ResetPwdRequest(form) {
                userMgmt.resetPasswordRequest(form.email).then(function (res) {
                    close(true);
                }, function (error) {
                    $scope.isBusy = false;
                    $scope.failed = true;
                    close(false);
                });
            }
        }
    }]
);
