"use strict";
require('ion-range-slider');

module.exports = angular.module(__filename, [])
    .directive('amRangeSlider', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        template: '<div class="am-range-slider"></div>',
        scope: {
            min:'=',
            max:'=',
            type:'@',
            prefix:'@',
            maxPostfix:'@',
            prettify:'@',
            grid:'@',
            gridMargin:'@',
            postfix:'@',
            step:'@',
            minInterval:'@',
            hideMinMax:'@',
            hideFromTo:'@',
            from:'=',
            to:'=',
            disable:'=',
            onChange:'=',
            onFinish:'=',
            values:'=',
            reset:'='
        },
        link: function ($scope, $element) {
            /* defaults */
            if ($scope.hideMinMax === undefined) $scope.hideMinMax = "true";
            if ($scope.hideFromTo === undefined) $scope.hideFromTo = "true";
            if ($scope.grid === undefined) $scope.grid = "true";

            var sliderElement = $($element[0].querySelector('.am-range-slider'));
            sliderElement.ionRangeSlider({
                min: $scope.min,
                max: $scope.max,
                type: $scope.type,
                prefix: $scope.prefix,
                max_postfix: $scope.maxPostfix,
                prettify: $scope.prettify,
                grid: $scope.grid,
                grid_margin: $scope.gridMargin,
                postfix: $scope.postfix,
                step: $scope.step,
                hide_min_max: $scope.hideMinMax,
                hide_from_to: $scope.hideFromTo,
                from: $scope.from,
                to: $scope.to,
                disable: $scope.disable,
                reset: $scope.reset,
                onChange: $scope.onChange,
                onFinish: $scope.onFinish,
                values: $scope.values,
                drag_interval: $scope.dragInterval,
                min_interval: $scope.minInterval !== undefined ? parseInt($scope.minInterval) : undefined
            });

            $scope.$watch('values', function(value) {
               $timeout(function(){ sliderElement.data("ionRangeSlider").update({values: value}); });
            });
            $scope.$watch('min', function(value) {
                $timeout(function(){ sliderElement.data("ionRangeSlider").update({min: value}); });
            });
            $scope.$watch('max', function(value) {
                $timeout(function(){ sliderElement.data("ionRangeSlider").update({max: value}); });
            });
            $scope.$watch('from', function(value) {
                $timeout(function(){ sliderElement.data("ionRangeSlider").update({from: value}); });
            });
            $scope.$watch('to', function(value) {
                $timeout(function(){ sliderElement.data("ionRangeSlider").update({to: value}); });
            });
            $scope.$watch('onFinish', function(value) {
                $timeout(function(){ sliderElement.data("ionRangeSlider").update({onFinish: value}); });
            });
            $scope.$watch('disable', function(value) {
                $timeout(function(){ sliderElement.data("ionRangeSlider").update({disable: value}); });
            });
            $scope.$watch('reset', function(value) {
                $timeout(function(){
                    if (value) {
                        sliderElement.data("ionRangeSlider").update({from: 0});
                        sliderElement.data("ionRangeSlider").update({to: $scope.values.length - 1});
                    }
                 });
            });
        }
    }
}]);
