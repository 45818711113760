"use strict";
module.exports = angular.module(__filename, [])
    .directive('amGaugeChart', ['$timeout', '$window', function ($timeout, $window) {
        return {
            restrict: 'E',
            template: require('./am-gauge-chart.drv.html'),
            scope: {
                "percents": "=",
                "options": "="
            },
            link: function (scope, element, attr, ctrl) {
                var options = scope.options || {};
                options.type = options.type || 'bar';
                scope.low_label = options.low_label || '';
                scope.high_label = options.high_label || '';
                scope.type = options.type;

                var bar_width = 0;
                var indicator_width = 0;

                var placeIndicator = function() {
                    var left = bar_width / 100 * scope.percents - indicator_width / 2;
                    if (left < 0) left = 0;
                    if (left + indicator_width > bar_width) left = bar_width - indicator_width;
                    scope.left = left;
                };

                var drawBar = function() {
                    var width = bar_width / 100 * scope.percents;
                    if (width < 0) width = 0;
                    if (width > bar_width) width = bar_width;
                    scope.width = width;
                };

                var calculateWidths = function() {
                    var current_bar_width = element[0].getElementsByClassName('bar')[0].clientWidth;
                    if (current_bar_width == 0) return;
                    bar_width = current_bar_width;
                    indicator_width = element[0].getElementsByClassName('indicator-bar')[0].clientWidth;
                };

                var redraw = function() {
                    if (scope.percents === undefined) return;
                    if (options.type == 'bar'){
                        drawBar();
                    }
                    else {
                        placeIndicator();
                    }
                }

                scope.$watch('percents', function() {
                    redraw();
                });

                scope.$watch(attr.ngShow, function() {
                    calculateWidths();
                    redraw();
                });

                if (options.resize){
                    angular.element($window).bind('resize', function() {
                        calculateWidths();
                        redraw();
                        scope.$apply();
                    });
                }
                $timeout(function() {
                    calculateWidths();
                    redraw();
                });
            }
        }
    }]
);
