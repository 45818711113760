"use strict";

const dotdotdot = require("jquery.dotdotdot"),
    add_tooltip = require('../tooltip/tooltip.js');
module.exports = require("angular").module(__filename, [
    require("../am-spinner.drv/am-spinner.drv").name
]).directive('selectableBarChart', ["$window", "$timeout", function($window, $timeout) {
        return {
            restrict: 'E',
            template: require('./selectable-bar-chart.drv.html'),
            scope: {
                "data": "=",
                "chartTitle": "=",
                "onSelect": "&",
                "noDataMsg": "=",
                
                //params used in multi-checkbox mode
                "checkboxes": "=",
                "disabledIds": "=",
                "query": "="
            },
            link: function($scope, $element) {
                $scope.interestTypes = [{value: 'websites', label: 'Websites'}, {value: 'phrases', label: 'Phrases'}];
                const onSelect = $scope.onSelect() ? $scope.onSelect() : angular.noop;
                $scope.selectionEnabled = $scope.onSelect();
                const scrollContainer = $element.children().children('.scroll-container');

                $scope.showSpinner = true;
                $scope.selectAll = false;

                $scope.selectElement = function(element) {
                    if(!$scope.selectionEnabled) return;
                    $scope.selectedElementId = element.id;
                    onSelect(element);
                };

                $scope.isSelected = function(element) {
                    return element.id == $scope.selectedElementId;
                };

                let addTooltipCB = function(isActive, element) {
                    if(isActive) {
                        add_tooltip(element.context, 'info', {style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}});
                    } else {
                        element.context.title = "";
                    }
                };

                $scope.$watch('data', function() {
                    $scope.showSpinner = !$scope.data;
                    
                    $scope.queryTitle = ($scope.query||{}).title;

                    if($scope.data && $scope.data.length && $scope.selectionEnabled) $scope.selectElement($scope.data[0]);
                    scrollContainer.scrollTop(0);
                    
                    //reset selection
                    if($scope.checkboxes){
                        if($scope.selectAll){
                            $element.find("#select-all-checkbox").trigger("click");
                        }
                        
                    }

                    $timeout(function() {
                        $element.find('.sbc-title').each(function() {
                            $(this).dotdotdot({callback: addTooltipCB});
                        });
                    }, 0);
                });



                //checkboxes mode

                $scope.toggleSelected = function(bar, $event){
                    //multiple checkboxes
                    $scope.$parent.toggleSelectedBar(bar, $event);
                }

                $scope.toggleSelectAll = function(){

                    let queryTitle = $scope.queryTitle,
                        disabledIds = $scope.disabledIds||{};

                    if($scope.selectAll){
                        $scope.data.forEach((row)=> {
                            row.selected = !disabledIds[row.id+queryTitle];
                        });
                    }else{
                        $scope.data.forEach((row)=> row.selected = false);
                    }

                    $scope.$parent.toggleSelectedAllBar($scope.selectAll, $scope.data.filter((row)=> row.selected));     
                }

                $scope.$on("openContentDrivers", function() {
                    //unselect after adding to target
                    if($scope.checkboxes){
                        var cb = $element.find("#select-all-checkbox");
                        if(cb.hasClass("checked")){
                            cb.trigger("click");
                        }else{
                            $scope.data.forEach((cell)=> cell.selected= false);
                        } 
                        
                    }
                });

            }
        }
    }]
);
