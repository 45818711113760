"use strict";
"use strict";

module.exports = angular.module(__filename, []).factory("GeoMold", ['geoService', function (geoService) {
    return function GeoMold(contex) {
        var me = this;
        me._value = [];
        me.default = [];

        me.replace = function (val) {
            return me._value = val || me.default;
        }.bind(this);
    }
}]);
