"use strict";
var BaseWidget = require("../base_widget"),
    mixpanel = require("infra/mixpanel/mixpanel-insights"),
    c = require("infra/utils/common");

var CHANNEL_SOCIAL = {"label": "Social", "value": 'tweets'};

var MAXIMUM_3_MONTHS_MESSAGE = "Maximum date span is three months. Time frame was adjusted.";
var MINIMUM_1_WEEK_MESSAGE = "Minimum date span is one week. Time frame was adjusted.";
var MINIMUM_1_MONTH_MESSAGE = "Minimum date span is one month. Time frame was adjusted.";

function TimingWidgetController($scope, $timeout, $element, timingService, util, insightsExportService, examplesDataModel, mixpanelInsights) {

    var self = this;
    this.$scope        = $scope;
    this.util          = util;
    this.$timeout      = $timeout;
    this.timingService = timingService;
    this.chartData     = [];
    this.charts        = {};
    this.insightsExportService = insightsExportService;
    this.examplesDataModel = examplesDataModel;

    $scope.compareMode = false;
    $scope.compareModeAllowed = false;
    mixpanelInsights.trackPageView('timing');
    var title_texts = [''].concat(c.getHeatmapHours()).concat('Total');
    var columns = [{width: 15}].concat(Array(25).fill({width: 10}));
    var columns_hourly = [{width: 15}].concat(Array(24).fill({width: 10}));
    var columns_daily = [{width: 15}].concat(Array(9).fill({width: 10}));
    var days = c.getWeekDays();

    this.onResize = function(){
        $timeout(function() {
            var node = d3.select($element[0]).node();
            $scope.chartWidth = node.offsetWidth - 10;
            $scope.chartHeight = node.offsetHeight;
        });
    };

    function formatOutput(value, format){
        return format(value / 100, 'percent');
    }

    function formatRecordOutput(record, format){
        return formatOutput(_.last(record), format);
    }

    function getTermDisplay(term){
        var term_display = term.hasOwnProperty('display') ? term.display : term.text;
        if(c.isString(term_display) && term_display.endsWith("*")){
            term_display = term_display.replace(/\*$/," (refined)");
        }
        return term_display;
    }

    this.getReportFunctionForSeed = function(term){
        return function(format){
            var titles = _.map(title_texts, _.partial(format, _, 'bold'));
            var timing_record = self.timingService.getTimingForSeed(term);
            var formatRecordFunction = _.partial(formatRecordOutput, _, format);
            var getDayLine = function(i){
                var values = _.map(_.filter(timing_record.days_hours, function(rec){return rec[0][0] == i}), formatRecordFunction);
                var average = formatRecordFunction(_.find(timing_record.day_totals, function(rec){return rec[0] == i}), format);
                return [days[i-1]].concat(values).concat([average]);
            };
            var table = [titles].concat(_.map(_.range(1,8),getDayLine))
                                .concat([['Total'].concat(_.map(timing_record.hour_totals, formatRecordFunction)).concat([''])]);
            return {name: getTermDisplay(term), columns: columns, table: table};
        };
    };

    this.getHourlyReport = function(format){
        var titles = _.map([''].concat(c.getHeatmapHours()), _.partial(format, _, 'bold'));
        var table = [titles];
        _.each(self.chartData.seeds, function(record){
            var hour_record = [getTermDisplay(record.term)].concat(_.map(record.hour_totals, _.partial(formatRecordOutput, _, format)));
            table.push(hour_record);
        });
        table.push(['Total'].concat(_.map(self.chartData.average_hours, _.partial(formatOutput, _, format))));
        return {name: 'Hourly', columns: columns_hourly, table: table};
    };

    this.getDailyReport = function(format){
        var titles = _.map([''].concat(days).concat(['Weekdays','Weekend']), _.partial(format, _, 'bold'));
        var table = [titles];
        var formatOutputFunction = _.partial(formatOutput, _, format);
        _.each(self.chartData.seeds, function(record){
            var day_record = [getTermDisplay(record.term)].concat(_.map(record.day_totals, _.partial(formatRecordOutput, _, format)))
                                                          .concat([formatOutputFunction(record.weekday_total), formatOutputFunction(record.weekend_total)]);
            table.push(day_record);
        });
        var totals = self.chartData.average_days.concat([self.chartData.average_weekday, self.chartData.average_weekend]);
        table.push(['Total'].concat(_.map(totals, formatOutputFunction)));
        return {name: 'Daily', columns: columns_daily, table: table};
    };

    this.adjustTimeframe = function(changedTimeframe, oldTimeframe, timeAmountUnits) {
        const format = "DD_MM_YY_HH_mm";
        let adjustedTimeframe = angular.copy(oldTimeframe);

        if ((changedTimeframe === undefined || changedTimeframe[1] === 'month' || oldTimeframe[1].substring(0, 8) === changedTimeframe[1].substring(0, 8))
            && moment(oldTimeframe[0], format).add(timeAmountUnits) <= moment()) {
            adjustedTimeframe[1] = moment(oldTimeframe[0], format).add(timeAmountUnits).format(format);
        } else {
            adjustedTimeframe[0] = moment(oldTimeframe[1], format).subtract(timeAmountUnits).format(format);
        }

        return adjustedTimeframe;
    };

    this.validateTimeframe = function (changedVals, channel) {
        let timeframe = $scope.timeframe;
        let notification_message = null;

        if ((timeframe[1] === 'month' && parseInt(timeframe[0]) > 3) || (timeframe[1] === 'year')) {
            $scope.timeframe = [3, 'month'];
            notification_message = MAXIMUM_3_MONTHS_MESSAGE;
        } else if (channel === 'articles' && (timeframe[1] === 'day')) {
            $scope.timeframe = [1, 'month'];
            notification_message = MINIMUM_1_MONTH_MESSAGE;
        } else if (timeframe[1] != 'month' && timeframe[1] != 'day') {
            const date_format = "DD_MM_YY";

            if (moment(timeframe[0], date_format) < moment(timeframe[1], date_format).subtract({'month': 3, 'day': c.calcOffsetDays(timeframe, 3)})){
                $scope.timeframe = this.adjustTimeframe(changedVals.timeframe, timeframe, {'month': 3});
                notification_message = MAXIMUM_3_MONTHS_MESSAGE;
            } else if (channel === 'articles' && moment(timeframe[0], date_format).add({'month': 1}) > moment(timeframe[1], date_format)) {
                $scope.timeframe = this.adjustTimeframe(changedVals.timeframe, timeframe, {'month': 1});
                notification_message = MINIMUM_1_MONTH_MESSAGE;
            } else if (moment(timeframe[0], date_format).add({'day': 6}) > moment(timeframe[1], date_format)){
                $scope.timeframe = this.adjustTimeframe(changedVals.timeframe, timeframe, {'day': 6});
                notification_message = MINIMUM_1_WEEK_MESSAGE;
            }
        }
        if(notification_message) {
            this.notificator.notify({body: notification_message});
        }
    };

    this.validateContextChannel = function() {
        if (!$scope.insightsChannel.hasOwnProperty('value')
            || ($scope.$root.insightsTimingChannelsFilter.length > 1
                && _.map($scope.$root.insightsTimingChannelsFilter, 'value').indexOf($scope.insightsChannel.value) == -1)) {
            $scope.$root.context.current.insightsChannels = $scope.insightsChannel = CHANNEL_SOCIAL;
        }
    };

    this.getChannelForService = function() {
        return $scope.$root.insightsTimingChannelsFilter.length == 1 ? CHANNEL_SOCIAL : _.clone($scope.insightsChannel);
    };

    this.insightsExportService.setTitle('Timing');
}

TimingWidgetController.prototype._doUpdate = function(values, changedVals){
    var self = this;
    var $scope = this.$scope;
    self.examplesDataModel.visible = false;
    $scope.inProcess = true;
    $scope.noData = true;
    var scope_vars = _.cloneDeep($scope);
    scope_vars.insightsChannel = this.getChannelForService();
    this.validateContextChannel();
    this.validateTimeframe(changedVals, scope_vars.insightsChannel.value);
    this.parameters = this.util.buildInsightsParameters(scope_vars);
    return self.timingService.get(this.parameters).then(function(data){
        $scope.inProcess = false;
        $scope.noData = false;
        var ca = [];
        self.chartData = data;
        self.charts = {};
        self.insightsExportService.setParams(self.parameters);
        self.insightsExportService.setReportFunctions([]);
        if (c.isArray(self.chartData.seeds)) {
            _.each($scope.terms, function (t, i) {
                _.each(self.chartData.seeds, function (d, j) {
                    if (c.is(d.term) && t.id == d.term.id) {
                        var key = c.getKey(d.term.id, d.term.text);
                        if (self.charts[key] == null) {
                            self.charts[key] = {
                                key: key,
                                label: d.term.text,
                                display: self.util.getTermDisplay(d.term),
                                data: d.days_hours,
                                day_totals: d.day_totals,
                                hour_totals: d.hour_totals,
                                colors: d.days_hours_colors,
                                day_totals_colors: d.day_totals_colors,
                                hour_totals_colors: d.hour_totals_colors
                            };
                            ca.push(self.charts[key]);
                            self.insightsExportService.addReportFunction(self.getReportFunctionForSeed(d.term));
                        }
                    }
                });
            });
        }
        $scope.chartArray = ca;
        if($scope.chartArray.length <= 1){
            $scope.compareModeAllowed = false;
            $scope.compareMode = false;
        } else {
            $scope.hourlyData = _.pick(self.chartData, 'seeds', 'average_hours', 'average_hours_colors');
            $scope.dailyData = _.pick(self.chartData, 'seeds', 'average_days', 'average_days_colors',
                                                               'average_weekday', 'average_weekday_color',
                                                               'average_weekend', 'average_weekend_color');
            var labels = _.map(self.chartData.seeds, function(seed){return self.util.getTermDisplay(seed.term)});
            $scope.hourlyData.labels = labels;
            $scope.dailyData.labels = labels;
            self.insightsExportService.addReportFunction(self.getHourlyReport);
            self.insightsExportService.addReportFunction(self.getDailyReport);
            $scope.compareModeAllowed = true;
        }
        self.onResize();
    }, function (error) {
        $scope.$emit('insightsError', error);
        return error;
    });
};

TimingWidgetController.$inject = ["$scope", "$timeout", "$element", "timingService", "util", "insightsExportService", "examplesDataModel", "mixpanelInsights"];

module.exports = require("angular").module(__filename, [
    require('data/insights/timing-service').name,
    require('../../common/am-heatmap-chart/heatmap-chart').name,
    require('../../common/am-heatmap-chart/seed-heatmap-chart').name,
    require('../../common/am-heatmap-chart/daily-heatmap-chart').name,
    require('../../common/am-heatmap-chart/hourly-heatmap-chart').name
])
.directive("timingWidget", [function() {
    return BaseWidget({
        restrict: "E",
        template: require('./timing-widget.html'),
        scope: {
            timeframe: "=",
            terms: "=",
            geo: "=",
            topics: "=",
            insightsChannel: '='
        },
        controller: TimingWidgetController
    });
}]);
