"use strict";
function GeoController($rootScope) {
}

GeoController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "geo",
    url: "/geo",
    template: require("./geo.insights.html"),
    display: "Insights",
    data: {
    },
    controller: GeoController,
    context: {}
};
