"use strict";
module.exports.stateConfig = {
  name: "help-center",
  url: "http://help.intelligence.amobee.com",
  external: true,
  display: "Help Center",
  data: {
    permissions: ["help center"]
  }
};
