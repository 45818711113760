"use strict";
const config = require("infra/config");
const CONCEPT_KEY = 'phrases';
const CONCEPTS_BL_KEY = 'phrases_bl';
const date = require("infra/utils/date");

module.exports = require("angular").module(__filename, []).service('GridDataLayer', ['topicsTree', 'geoService', 'CHANNEL', '$state', 'context', 'abiPermissions',
    function (topicsTree, geoService, CHANNEL , $state, context, permissions) {

        function getTimeframeDates(timeframe) {
            let parsed_tf = date.getTimeframe(timeframe, true);
            parsed_tf.start = parsed_tf.start.startOf('day').format();
            let endDate = parsed_tf.end.startOf('day').format();
            parsed_tf.end = endDate != parsed_tf.start ? endDate : parsed_tf.end.endOf('day').format();
            return parsed_tf;
        }

        function getTimeframe(timeframe) {
            let [quantity, unit] = timeframe;
            switch (unit) {
                case "day":
                    switch (quantity) {
                        case 1:
                            return "yesterday";
                        case 3:
                            return "half_week";
                        case 7:
                            return "weekly";
                    }
                    break;
                case "week":
                    switch (quantity) {
                        case 1:
                            return "weekly";
                    }
                    break;
                case "month":
                    switch (quantity) {
                        case 1:
                            return "monthly";
                        case 3:
                            return "quarterly";
                    }
                    break;
            }
            return "custom";
        }

        function replaceFieldName(item, oldName, newName) {
            if (item[oldName] != null && item[oldName] != undefined) {
                item[newName] = item[oldName];
                delete item[oldName];
            }
        }

        function extractDataAndTopics(termsResolved, metric) {
            let res = {}, topics = {};
            for (let keyName in termsResolved) {
                res[keyName] = metric ? termsResolved[keyName]['data'][metric] : termsResolved[keyName]['data']['data'];
                let term_topics = termsResolved[keyName]['data']['topics_distribution'] || {};
                Object.keys(term_topics).forEach(function (t) {
                    topics[t] = (topics[t] || 0) + term_topics[t]
                })
            }
            if (!_.isEmpty(topics)) {
                let total_topics = _.values(topics).reduce((a, b) => a + b);
                for (let topic in topics) {
                    topics[topic] = Number((topics[topic] * 100 / total_topics).toFixed(1));
                }
            }
            return {data: res, topics: topics};
        }

        function filterConfigTerms(terms) {
            return _.filter(terms, (t) => !(t.type == 'hashTag' || t.type == 'mention' || t.type == 'post'));
        }

        function tweakTelcoData(config, timeframe) {
            let c = config.channels.value || '';
            if (c.includes("telco")) {
              timeframe = date.getTimeframe(config.timeframe, false, CHANNEL.daysShift(c));
              if ((c == "au_telco") || ((c == "sg_telco") && (!permissions.hasPermission('sg telco audience filters')))) {
                  config.audience = [];
              }
            }
            return config, timeframe;
        }

        const webQuery = {
            endpoint: '/concepts/web',
            host: config.DISCOVERY_API,
            method: 'POST',
            paramsDefaults: function (config) {
                /** remove hash and mentions **/
                config.terms = filterConfigTerms(config.terms);

                const topics = (config.topics.length == 0) ? topicsTree.topicsFlatten : config.topics;
                let timeframe = getTimeframeDates(config.timeframe);
                config, timeframe = tweakTelcoData(config, timeframe);

                return {
                    audience: _.flatMap(config.audience, 'value'),
                    timeframe_start: timeframe.start,
                    timeframe_end: timeframe.end,
                    geos: _.map(geoService.serverValue(config.geos), 'id'),
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    topics: topicsTree.getIds(topics),
                    sensitive_content: topicsTree.isSensitive,
                    examples: config.examples,
                    information: config.information,
                    unique_examples: false,
                    key_trends: config.isKeyTrends ? 1 : 0,
                    clustering: config.clustering,
                    channels: config.channels.value,
                    language: context.current._language_mold.getLanguage($state, context).value
                };
            },
            concepts: function (params, concept) {
                params[CONCEPT_KEY] = [concept];
                params[CONCEPTS_BL_KEY] = [];
            },
            blConcepts: function (params, concept) {
                params[CONCEPT_KEY] = [];
                params[CONCEPTS_BL_KEY] = [concept];
            },
            resolveData: function (termsResolved, metric) {
                Object.keys(termsResolved).forEach(function (term) {
                    termsResolved[term] = {
                        data: {
                            topics_distribution: termsResolved[term]['data']['topics'],
                            data: termsResolved[term]['data']['data'][term]
                        }
                    }
                });
                return extractDataAndTopics(termsResolved);
            },
            addQueryData: function (item) {}
        };

        const facebookPagesQuery = {
            endpoint: '/pages/facebook',
            host: config.SEARCH_API,
            method: 'POST',
            paramsDefaults: function (config) {
                const topics = (config.topics.length == 0) ? topicsTree.topicsFlatten : config.topics;
                const timeframe = getTimeframeDates(config.timeframe);
                return {
                    audience: _.flatMap(config.audience, 'value'),
                    timeframe_start: timeframe.start,
                    timeframe_end: timeframe.end,
                    geos: _.map(geoService.serverValue(config.geos), 'id'),
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    topics: topicsTree.getIds(topics),
                    sensitive_content: topicsTree.isSensitive,
                    examples: config.examples,
                    information: config.information,
                    language: context.current._language_mold.getLanguage($state, context).value,
                    size: 200
                };
            },
            concepts: function (params, concept) {
                params[CONCEPT_KEY] = [concept];
                params[CONCEPTS_BL_KEY] = [];
            },
            blConcepts: function (params, concept) {
                params[CONCEPT_KEY] = [];
                params[CONCEPTS_BL_KEY] = [concept];
            },
            resolveData: function (termsResolved, metric) {
                Object.keys(termsResolved).forEach(function (term) {
                    termsResolved[term] = {
                        data: {
                            topics_distribution: termsResolved[term]['data'][term].topics,
                            data: termsResolved[term]['data'][term]['data']
                        }
                    }
                });
                return extractDataAndTopics(termsResolved);
            },
            addQueryData: function (item) {}
        };

        const facebookInterestsQuery = {
            endpoint: '/interests/facebook',
            host: config.DISCOVERY_API,
            paramsDefaults: function (config) {
                const timeframe = getTimeframeDates(config.timeframe);
                return {
                    'audience[]': _.flatMap(config.audience, 'value'),
                    timeframe_start: timeframe.start,
                    timeframe_end: timeframe.end,
                    'geos[]': _.map(geoService.serverValue(config.geos), 'id'),
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    'topics[]': topicsTree.getIds(config.topics),
                    examples: config.examples,
                    information: config.information,
                    channels: config.channels.value,
                    language: context.current._language_mold.getLanguage($state, context).value
                };
            },
            concepts: function (params, concept) {
                params['phrases[]'] = [concept];
                params['phrases_bl[]'] = [];
            },
            blConcepts: function (params, concept) {
                params['phrases[]'] = [];
                params['phrases_bl[]'] = [concept];
            },
            resolveData: function (termsResolved, metric) {
                Object.keys(termsResolved).forEach(function (term) {
                    termsResolved[term] = {
                        data: {
                            topics_distribution: termsResolved[term].data.topics,
                            data: termsResolved[term].data.data[term]
                        }
                    }
                });
                return extractDataAndTopics(termsResolved);
            },
            addQueryData: function (item) {}
        };

        const socialQuery = {
            host: config.TWITTER_API,
            paramsDefaults: function (config) {
                const timeframe = getTimeframeDates(config.timeframe);
                const geos = _.map(geoService.serverValue(config.geos), 'id');

                let params = {
                    start_date: timeframe.start,
                    end_date: timeframe.end,
                    timeframe: getTimeframe(config.timeframe),
                    summary: config.examples,
                    filter_kw_by_topics: 1,
                    app: 'abi',
                    topic_level: 'super',
                    topics_distribution: 1,
                    sensitive_content: topicsTree.isSensitive,
                    sub_geos: geoService.createRequestSubGeoParam(config.sub_geos),
                    language: context.current._language_mold.getLanguage($state, context).value
                };

                if (config.topics.length > 0 && config.topics.length < topicsTree.topicsFlatten.length) {
                    params['topics'] = topicsTree.getIds(config.topics).join();
                }

                if (geos.length < geoService.geos.length) {
                    params['geos[]'] = geos;
                }

                if (config.information == 'audience') {
                    params['doc_num'] = 5000;
                    params['min_rt_count'] = 1;
                }

                return params;
            },
            concepts: function (params, concept) {
                params['concepts[]'] = [concept];
            },
            blConcepts: function (params, concept) {
                if (concept.required && concept.required.length > 0)
                    params['concepts[]'] = concept.required;
                if (concept.included && concept.included.length > 0)
                    params['or_concepts[]'] = concept.included;
                if (concept.excluded && concept.excluded.length > 0)
                    params['rejected_kws[]'] = concept.excluded;
            },
            resolveData: function (termsResolved, metric) {
                return extractDataAndTopics(termsResolved, metric);
            },
            addSocialQueryData: function (item) {
                replaceFieldName(item, 'net_sentiment', 'net_sent');
                if (item['sent_count']) {
                    item['pos'] = item['sent_count']['pos'];
                    item['neu'] = item['sent_count']['neu'];
                    item['neg'] = item['sent_count']['neg'];
                    delete item['sent_count'];
                }
            }
        };

        return {
            version: '1.0.0', //when adding new params, changing the version will reset for all users
            queries: {
                facebook: {
                    interests: $.extend(true, {}, facebookInterestsQuery, {
                        metric: 'interests',
                        gridAdvanced: {}
                    }),
                    pages: $.extend(true, {}, facebookPagesQuery, {
                        metric: 'pages',
                        gridAdvanced: {}
                    })
                },
                articles: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {}
                    })
                },
                sg_telco: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                },
                au_telco: {
                    phrases: $.extend(true, {}, webQuery, {
                        metric: 'phrases',
                        gridAdvanced: {},
                        supportSubGeos: true
                    })
                },
                tweets: {
                    phrases: $.extend(true, {}, socialQuery, {
                        metric: 'phrases',
                        endpoint: '/targeting/api/VERSION/phrases',
                        gridAdvanced: {
                            targeting_mode: 50, targeting_mode_def: 50,
                            min_rt_count: 0, min_rt_count_def: 0
                        },
                        hiddenColumns: ["Retweets"],
                        addQueryData: function (item) {
                            replaceFieldName(item, 'w', 'phrase');
                            replaceFieldName(item, 'kid', 'id');
                            replaceFieldName(item, 'kl', 'specificity');
                            replaceFieldName(item, 'consumption', 'mentions_per_day');
                            replaceFieldName(item, 'c', 'mentions');
                            replaceFieldName(item, 'v', 'velocity');

                            socialQuery.addSocialQueryData(item);
                        }
                    }),
                    hashtags: $.extend(true, {}, socialQuery, {
                        metric: 'hashtags',
                        endpoint: '/targeting/api/VERSION/hashtags',
                        gridAdvanced: {
                            targeting_mode: 50, targeting_mode_def: 50,
                            min_rt_count: 0, min_rt_count_def: 0
                        },
                        addQueryData: function (item) {
                            replaceFieldName(item, 'ht', 'hashtag');
                            replaceFieldName(item, 'v', 'velocity');
                            replaceFieldName(item, 'c', 'mentions');

                            socialQuery.addSocialQueryData(item);
                        }
                    }),
                    influencers: $.extend(true, {}, socialQuery, {
                        metric: 'handles',
                        endpoint: '/targeting/api/VERSION/influencers',
                        gridAdvanced: {
                            targeting_mode: 50, targeting_mode_def: 50,
                            min_rt_count: 2, min_rt_count_def: 2,
                            min_followers: 100, min_followers_def: 100
                        },
                        addQueryData: function (item) {
                            replaceFieldName(item, 'uh', 'handle');
                            replaceFieldName(item, 'rt_count', 'retweets');
                            replaceFieldName(item, 'verf', 'verified');
                            replaceFieldName(item, 'c', 'mentions');

                            socialQuery.addSocialQueryData(item);
                        }
                    }),
                    audience: $.extend(true, {}, socialQuery, {
                        metric: 'handles',
                        endpoint: '/targeting/api/VERSION/audience',
                        gridAdvanced: {
                            targeting_mode: 50, targeting_mode_def: 50,
                            min_rt_count: 1, min_rt_count_def: 1,
                            min_followers: 10, min_followers_def: 10
                        },
                        addQueryData: function (item) {
                            replaceFieldName(item, 'uh', 'handle');
                            replaceFieldName(item, 'rt_count', 'retweets');
                            replaceFieldName(item, 'verf', 'verified');
                            replaceFieldName(item, 'c', 'mentions');

                            socialQuery.addSocialQueryData(item);
                        }
                    }),
                    emojis: $.extend(true, {}, socialQuery, {
                        metric: 'emojis',
                        endpoint: '/targeting/api/VERSION/emojis',
                        gridAdvanced: {
                            targeting_mode: 50, targeting_mode_def: 50,
                            min_rt_count: 0, min_rt_count_def: 0
                        },

                        addQueryData: function (item) {
                            replaceFieldName(item, 'rt_count', 'retweets');
                            replaceFieldName(item, 'c', 'mentions');

                            socialQuery.addSocialQueryData(item);
                        }
                    })
                }
            },
            hiddenColumns: ["Klout score", "Specificity Score"]
        };
    }
]);
