"use strict";
module.exports = angular.module(__filename, [
    require("angular-modal-service").name,
    require("data/settings/user-mgmt").name
]).service('resetPasswordModal', ['ModalService', 'userMgmt', 'notificator', function (ModalService, userMgmt, notificator) {
    this.show = showResetPasswordModal;

    //////////////////////////////

    function showResetPasswordModal(id, showCancel) {
        return ModalService.showModal({
            inputs: {
                userId: id,
                showCancel: showCancel
            },
            template: require('./reset-password.html'),
            controller: ['$scope', 'userId', 'showCancel', 'close', ResetPasswordController]
        });
    }

    function ResetPasswordController($scope, userId, showCancel, close) {
        $scope.pwdConformationError = false;
        $scope.update = validateAndUpdate;
        $scope.close = close;
        $scope.showCancel = showCancel;
        $scope.isBusy = false;

        function validateAndUpdate(form) {
            if (form.password == form.passwordConfirm) {
                updatePwd(form);
            } else {
                $scope.pwdConformationError = true;
            }
        }

        function updatePwd(form) {
            $scope.isBusy = true;
            userMgmt.resetPassword(userId, form).then(function (res) {
                notificator.success({body: "Password successfully updated"});
                close(true);
            }, function (error) {
                notificator.error({body: "Password update failed!"});
                close(false);
            });
        }
    }
}]);
