"use strict";
function AssociationController($rootScope) {
}

AssociationController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "association",
    url: "/association",
    template: require("./association.insights.html"),
    display: "Insights",
    data: {
      permissions: ['associations']
    },
    controller: AssociationController,
    context: {}
};
