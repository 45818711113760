"use strict";
var config = require("infra/config"),
    c = require("infra/utils/common");

var TREND_API = config.SEARCH_API + '/alert_consumption?disable_notification=1';

module.exports = angular.module(__filename, [])
    .service("alertsInsightsService", ['$http', 'topicsTree', 'geoService', function ($http, topicsTree, geoService) {

        this.getInsights = getInsights;

        function getInsights(params) {
            var termsMap = {};
            var queryParams = buildQueryParameters(termsMap, params);
            return $http({
                method: 'GET',
                cache: true,
                url: TREND_API,
                params: queryParams
            }).then(function (response) {
                var data = {};
                data.chart = [];
                data.averages = [];
                data.range = [];

                _.each(termsMap, function (termObject, termName) {
                    var avgs = [], thresholds = [], values = [], termData = response.data[termName];

                    _.each(termData, function (dayData) {
                        var date = c.parseDate(dayData.date);
                        data.range.push(date);
                        avgs.push({date: date, value: dayData.avg});
                        thresholds.push({date: date, value: dayData.threshold});
                        values.push({date: date, value: dayData.value});
                    });

                    data.chart.push({
                        term: _.extend(termObject, {class: 'term-A'}),
                        series: values
                    });

                    data.chart.push({
                        term: {
                            class: "gray-line",
                            text: "AVG",
                            type: "term"
                        },
                        series: avgs
                    });

                    data.chart.push({
                        term: {
                            class: "dashed gray-line",
                            text: "STDev",
                            type: "term"
                        },
                        series: thresholds
                    });
                });

                var examples = _.map(response.data.abi_content_examples, (example) => _.extend(example, {class: 'term-A'}));
                examples = _.reject(examples, (ex) => ex.keyword && ex.letter != 'A');
                var partition = _.partition(examples, (example) => example.url);

                return {graphData: data, visible_examples: partition[0], examples: partition[1], activation: response.data.activation_threshold};
            });
        }

        function buildQueryParameters(termsMap, parameters) {
            var all_terms = parameters.boolean_logics ? parameters.boolean_logics.concat(parameters.terms) : parameters.terms;
            _.each(all_terms, function (entry) {
                termsMap[entry.text] = entry;
            });

            var t = c.getTimeframe(parameters.timeframe);
            var channel = parameters.channel ? parameters.channel : 'articles';
            return {
                channel: channel,
                alert_sentiment: parameters.sentiment,
                alert_change: parameters.change,
                type: parameters.type,
                normalize: parameters.to_normalize,
                'phrases[]': _.map(parameters.terms, 'text'),
                'boolean_logics[]': parameters.boolean_logics,
                kwd_ids: parameters.kwd_ids,
                'topics[]': topicsTree.getIds(parameters.topics),
                'origins[]': geoService.objToIdArray(parameters.geo),
                with_examples: true,
                timeframe: t.type,
                timeframe_start: t.start,
                timeframe_end: t.end,
                sensitive_content: parameters.sensitive_content,
                triggers: parameters.triggers
            };
        }
    }]
);
