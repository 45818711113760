"use strict";
var d3 = require("d3"),
    c = require("infra/utils/common"),
    $ = require("jquery"),
    _ = require("lodash");

var PAD = {
    left: 50,
    right: 20,
    top: 20,
    bottom: 30
};
var HOVER_PAD = 0;
var HOVER_OVERLAY = 6;
var HOVER_HEIGHT = 26;
var HOVER_WIDTH = 50;
var HOVER_WIDE_WIDTH = 76;
var HOVER_TEXT_PAD = 13;
var HOVER_WIDE_TEXT_PAD = 20;
var HOVER_POINT_RADIUS = 6;
var DEFAULT_POINT_RADIUS = 3;
var DEBUG_MARKER = false;
var DEFAULT_TREND_CLASS = 'trending-default';
var DEFAULT_INTERPOLATION = 'monotone'; // 'cardinal'
var MIN_X_TICK_SPACE = 80;
var MAX_X_TICK_SPACE = 130;
var AXIS_COLOR = '#81878C';
var Y_AXIS_DEFAULT_MAX = 103;
var DEFAULT_Y_AXIS = false;
var TERM_CLASSES = ["term-A", "term-B", "term-C", "term-D", "term-E", "term-F", "term-G"];

var getIdString = function (id) {
    return '#' + id.replace('*', "\\*");
};

// Proper solution for the graph boundaries mouse listener
// http://stackoverflow.com/questions/24189217/d3-mouse-move-cross-hair-boundary
// http://stackoverflow.com/questions/16918194/d3-js-mouseover-event-not-working-properly-on-svg-group
// http://jsfiddle.net/BWW66/
// Fixed: http://jsfiddle.net/BWW66/5/
function TrendChart(containing_object, id, configuration) {
    var self = this;

    this.id = id;
    this.containing_object = containing_object || {};
    this.configuration = c.is(configuration) ? configuration : {};
    this.shouldDetectTimeOrDate = this.configuration.detectTimeOrDate === true;
    this.minutes = 'minutes' === this.configuration.type;
    this.days = {isDays: true};
    this.range = [];
    this.wide = true;
    this.letterClicked = '';
    this.Y_AXIS_LINES = 10;
    this.isTimeModeDetected = false;

    this.clean = function() {
        this.infoMap = {};
        this.currentInfo = null;
        this.delta = 0;
        this.move = null;
        this.range = [];
        if (c.is(this.svg)) {
            this.svg.selectAll(".line, g.y-axis, circle, text[letter], .chart-index-rect, .chart-index-text").remove();
        }
        if (c.is(this.info)) {
            this.info.selectAll("*").remove();
        }
    };

    this.init = function() {
        var self = this;
        this.clean();
        setDefaultTemplates();

        var root = $(document.getElementById(this.id));
        root.mouseleave(function() {
            if (c.is(self.currentInfo))
                self.currentInfo.style("opacity", 1e-6);
        });
        this.container = d3.select(getIdString(this.id));
        this.envelop = this.container.append("svg")
            .attr("height", "100%");
        this.container.select("svg")
            .attr("style","position:absolute;");
        this.svg = this.envelop.append("g")
            .attr("id", "canvas")
            .attr("height", "100%")
            .attr("width", this.container.style("width"));
        this.svg.append("g")
            .attr("class", "x-axis axis");
        this.domain = this.svg.append("g")
            .attr("stroke", "none")
            .attr("class", "y-axis axis");
        var gy = this.svg.select("g.y-axis.axis");
        gy.select("path.domain").attr("id", "domain");
        this.info = this.svg.append("g")
            .attr("id", "info");
        if (DEBUG_MARKER) {
            this.marker = this.svg.append("line")
                .attr("id", "marker-line")
                .attr("stroke", "red")
                .attr("stroke-width", "1px")
                .attr("x1", 0)
                .attr("x2", 0)
                .attr("y1", 0);
        }
    };

    function setDefaultTemplates() {
      self.configuration.templates = self.configuration.templates || {};
    };

    this.draw = function(data, max, titleTemplateName, wide, with_avg) {
        var self = this;
        this.clean();
        this.wide = wide;
        if (c.is(data) && c.isArray(data.range)) {
            this.range = data.range;
            if(this.shouldDetectTimeOrDate){
                detectTimeOrDate(data.range);
            }
            this.updateLayout(data.range, max, data.midnight);
            this.addLines(data);
            this.addInfo(data, titleTemplateName, with_avg);
            setTimeout( function() {self.addInfoTooltips()}, 0 );
        }
    };

    function detectTimeOrDate (dateRange){
        self.isTimeModeDetected = dateRange.some(function(date,i) {
            return date.getHours() > 0;
        });
        if(self.isTimeModeDetected){
            self.minutes = true;
            self.days = {isDays: false};
        }
        else {
            self.minutes = false;
            self.days = {isDays: true};
        }
    }

    this.updateLayout = function(range, max, minutes) {
        var root = $(document.getElementById(this.id));
        var self = this;
        // left: 50, right: 20, top: 20, bottom: 30
        this.width = root.width() - PAD.left - PAD.right - (this.wide ? 17 : 5);
        this.height = root.height() - PAD.top - PAD.bottom;
        if (DEBUG_MARKER) {
            this.marker.attr("y2", this.height);
        }
        this.svg.attr("transform", "translate(" + PAD.left + "," + PAD.top + ")");
        this.xScale = d3.time.scale()
            .range([0, this.width]);
        this.yScale = d3.scale.linear()
            .range([this.height, HOVER_PAD]);
        this.svg.select("g.x-axis")
            .attr("transform", "translate(0," + this.height + ")");
        this.svg.append("g").attr("class", "y-axis axis");

        var uniqueDateRange = (range.map(function (date) { return date.getTime() })
                                   .filter(function (date, i, array) { return array.indexOf(date) === i;}));

        var rangeLength = uniqueDateRange.length;

        var ticks = self.wide ? ((this.width / MAX_X_TICK_SPACE) | 0) : ((this.width / MIN_X_TICK_SPACE) | 0);
        ticks = (self.days.isDays && self.days.number < ticks) ? self.days.number : ticks;
        ticks = (ticks > rangeLength) ? rangeLength : ticks;

        var usedDates = [];
        var prev_date = null;
        var xAxis = d3.svg.axis()
            .scale(this.xScale)
            .orient("bottom")
            .ticks(ticks)
            .tickSize(0)
            .tickPadding(10)
            .tickFormat(function (d, index) {
                if (c.isString(self.configuration.type) && self.minutes && !self.days.isDays) {
                    if (self.wide) {
                        return (d.getMonth() + 1) + '/' + d.getDate() + ' ' + c.getHoursLabel(d, /*minutes*/true);
                    }
                    return c.getHoursLabel(d);
                }
                else if(self.isTimeModeDetected && self.minutes){
                    if(!prev_date || prev_date.getUTCDate() < d.getUTCDate()) {
                        prev_date = d;
                        return c.getUTCHoursLabel(d, false, true);
                    }
                    else {
                        return c.getUTCHoursLabel(d);
                    }
                }
                else {
                    var formatted = (d.getMonth() + 1) + '/' + d.getDate();
                    if(usedDates.indexOf(formatted) == -1){
                        usedDates.push(formatted);
                        return formatted;
                    }
                }
                return '';
            });

        this.xScale.domain(d3.extent(uniqueDateRange));
        var g = this.svg.selectAll("g.x-axis")
            .call(xAxis);
        var m = Y_AXIS_DEFAULT_MAX;
        if (c.isNumber(max) && max > 0) {
            if (self.configuration.force_max || max <= 100) {
                m = max + (max/100)*3;
            }
        }
        this.yScale.domain([this.configuration.minValue || HOVER_PAD, m]);
        var yAxis = d3.svg.axis()
            .scale(this.yScale)
            .orient("left")
            .ticks(this.Y_AXIS_LINES)
            .tickFormat((d) => {
                if (d >= 10000) {
                  if (d >= 1000000){
                    return d/1000000 + "M"
                  }
                    return d/1000 + 'K';
              }
              return (d == 0 && !this.configuration.minValue) ? '' : d;
            })
            .tickSize(-this.width);
        this.svg.selectAll("g.y-axis")
            .call(yAxis);
        if (!DEFAULT_Y_AXIS) {
            this.svg.selectAll("g.y-axis text")
                .attr("y", -10)
                .attr("x", -11);
            this.svg.selectAll("g.y-axis .tick line")
                .attr("x1", -10)
                .attr("y1", 0);
            this.svg.selectAll("g.y-axis .tick").append("svg:line")
                .attr("x1", (d) => (d == 0 && !this.configuration.minValue) ? 0 : -2)
                .attr("y1", 0)
                .attr("x2", (d) => (d == 0 && !this.configuration.minValue) ? 0 : -30)
                .attr("y2", 0)
                .style("stroke", AXIS_COLOR)
                .attr("class", "small-ticks");
        }
    };

    function getTermClassKey(termClass) {
        var key;
        if (c.isString(termClass) && TERM_CLASSES.indexOf(termClass) > -1) {
            key = termClass;
        } else {
            key = (new Date()).getTime().toString();
        }
        return key;
    }

    this.addLines = function(data) {
        var self = this;
        var line = d3.svg.line()
            .interpolate(DEFAULT_INTERPOLATION)
            .x(function(d) {
                return self.xScale(d.date);
            })
            .y(function(d) {
                return self.yScale(d.value);
            });

        var estimationPoint =  getEstimationPoint(data);

        if(estimationPoint) {
            var splitData = splitDataByPoint(estimationPoint, data);
            addLineBase(splitData[0], line, "Continuousline");
            addLineBase(splitData[1], line, "DashLine").style("stroke-dasharray", "5,5");
        }
        else {
            addLineBase(data.chart, line, "Continuousline");
        }
    };

    function getEstimationPoint(data) {
        if(_.isEmpty(data.in_process_time_mark)){
            return null;
        }
        return Date.parse(data.in_process_time_mark);
    };

    function splitDataByPoint(point, data) {
        var beforePointData = $.extend(true, [], data.chart);
        var afterPointData = $.extend(true, [], data.chart);

        _.each(data.chart, function(entry, i)
        {
            afterPointData[i]["series"] = data.chart[i]["series"].filter(function (d) {
                return d["date"] >= point;
            });

            beforePointData[i]["series"] = data.chart[i]["series"].filter(function (d) {
                return d["date"] <= point;
            });
        });

        return new Array(beforePointData, afterPointData);
    };

    function getDateKeys(date) {
        var pointDate = c.getDateKey(date, self.minutes, '/', true);
        var dateKey = c.getDateKey(date, self.isTimeModeDetected, '-', false);
        var datePkey = c.getDateKey(date, self.isTimeModeDetected, '/', true);
        return {"pointDate": pointDate, "dateKey": dateKey, "datePkey": datePkey};
    };

    function addLineBase(chart, line, name) {
        var path = self.svg.selectAll("path.line" + name).data(chart);
        var pathObject = path.enter().append("path")
            .attr("class", function(d){
                if (c.is(d.term) && c.isString(d.term.class)) {
                    return "line " + d.term.class;
                }
                return "line " + DEFAULT_TREND_CLASS;
            })
            .attr("id", function(d){
                return getTermClassKey(d.term.class);
            })
            .attr("d", function(d){
                return line(d.series);
            })
            .attr("tkey", function(d){
                return getTermClassKey(d.term.class);
            })
            .on('mouseover', function(evt, node, i) {
                var oc = this.getAttribute('class');
                var cc = 'hover-chart-line ' + oc; // light
                this.setAttribute('class', cc);
                this.setAttribute('class', cc);
                this.setAttribute('oc', oc);
                if(self.configuration.agg_examples_mode) return;
                if (c.is(self.currentInfo)) {
                    var key = this.getAttribute('tkey');
                    self.currentInfo.selectAll("circle").attr("r", function(d) {
                        var p = this.getAttribute('pkey');
                        if (key == p) {
                            return HOVER_POINT_RADIUS;
                        }
                        return DEFAULT_POINT_RADIUS;
                    });
                }
            })
            .on('mouseout', function() {
                var cc = this.getAttribute('oc');
                this.setAttribute('class', cc);
                if(self.configuration.agg_examples_mode) return;
                if (c.is(self.currentInfo)) {
                    self.currentInfo.selectAll("circle").attr("r", DEFAULT_POINT_RADIUS);
                }
            })
            .on("click", function() {
                if(self.configuration.agg_examples_mode) return;
                var element = null;
                var value = 0;
                var date = null;
                if (c.is(self.currentInfo)) {
                    var key = this.getAttribute('tkey');
                    if (c.isString(key)) {
                        self.currentInfo.selectAll("circle").attr("pkey", function(d) {
                            var p = this.getAttribute('pkey');
                            if (key == p) {
                                var id = this.getAttribute('id');
                                value = this.getAttribute('consumption');
                                date = this.getAttribute('pdate');
                                if (c.isString(id)) {
                                    element  = $(document.getElementById(id));
                                }
                            }
                            return p;
                        });
                    }
                }
                if (value > 0) {
                    if (!c.is(element))
                        element = $('#canvas');
                    self.containing_object.showPopup(element, c.getKey(element.attr("term_id"), element.attr("term_text")), value, date);
                }
            });
        if(!self.configuration.agg_examples_mode){
            pathObject.style("cursor","pointer")
        }
        return pathObject;
    };

    function calcHoverXLocation(caption, cx ,maxCx) {
        var hoverXLocation = Math.ceil(caption.length * 7 / 2);
        if (cx < hoverXLocation) {
            if(caption.length > 6){
                hoverXLocation = 40;
            }
            else{
                hoverXLocation = 25;
            }
        } else if (parseFloat(cx + hoverXLocation) > maxCx) {
            hoverXLocation = -(maxCx - cx - caption.length * 7 - 5);
        }
        return hoverXLocation;
    }

    this.addInfo = function(data, titleTemplateName, with_avg) {
        var px = 0, ix = 0, self = this;
        _.each(data.chart, function(entry, i) {
            var key = getTermClassKey(entry.term.class);
            var avgData = _.find(data.averages, ['class', key]) || {};
            _.each(entry.series, function(tick, j) {
                if (c.isDate(tick.date)) {
                    var infoGroup = c.getMapItemByDate(self.infoMap, tick.date, self.minutes, false);
                    if (!c.is(infoGroup)) {
                        infoGroup = self.info.append("g")
                            .attr("id", 'group-' + c.getDateKey(tick.date, self.minutes, '-', false))
                            .style("opacity", 1e-6);
                        //TODO Confirm the item is not empty; infoGroup
                        c.addMapItemByDate(self.infoMap, infoGroup, tick.date, self.minutes);
                    }
                    if (i == 0) {
                        ix++;
                        var cx = self.xScale(tick.date);
                        self.delta = px > 0 ? (self.minutes ? (cx - px) : (px - cx)) + self.delta : self.delta;
                        px = cx;

                        var date;
                        var widthAppendex = 0
                        if(self.days.isDays){
                            date = (1 + tick.date.getMonth()) + '/' + tick.date.getDate();
                        }
                        else if(self.isTimeModeDetected){
                            date = c.getUTCHoursLabel(tick.date,false,true);
                            widthAppendex = 10
                        }
                        else{
                            date = c.getHoursLabel(tick.date, false, self.wide);
                        }

                        var maxCx = parseInt(self.xScale(_.max(_.map(entry.series, "date")))) + 20 - date.length * 7;
                        var tooltipX = cx - (self.wide ? HOVER_WIDE_WIDTH + widthAppendex * 2 : HOVER_WIDTH + widthAppendex * 2)/2;
                        if(tooltipX >= maxCx) tooltipX = maxCx;

                        var ls = (self.minutes && !self.days.isDays ? (((date.length - 1)*3)/2) : 0) + widthAppendex;
                        var textX = cx - (self.wide ? (HOVER_WIDE_WIDTH/2 - HOVER_WIDE_TEXT_PAD) : (HOVER_WIDTH/2 - HOVER_TEXT_PAD)) - ls;
                        if(textX >= maxCx) textX = maxCx;

                        var line = infoGroup.append("line")
                            .attr("stroke", "#cacaca")
                            .attr("x1", cx)
                            .attr("x2", cx)
                            .attr("stroke-width", 1)
                            .attr("y1", 0)
                            .attr("y2", self.height);
                        var tooltip = infoGroup.append("rect")
                            .attr("id", "#date-label-background" + date)
                            .attr("x", tooltipX)
                            .attr("rx", 4)
                            .attr("y", HOVER_OVERLAY - HOVER_HEIGHT)
                            .attr("ry", 4)
                            .attr("width", (self.wide ? HOVER_WIDE_WIDTH + widthAppendex : HOVER_WIDTH + widthAppendex))
                            .attr("height", HOVER_HEIGHT)
                            .attr("class", "hover-date");


                        var text = infoGroup.append("text")
                            .attr("id", "#date-label-" + date)
                            .attr("x", textX)
                            .attr("y", -2)
                            .attr("class", "hover-text")
                            .text(date);
                    }

                    if (c.isNumber(tick.value)) {
                        var {pointDate, dateKey} = getDateKeys(tick.date);
                        var point = infoGroup.append("circle")
                            .attr("class", entry.term.class)
                            .attr("id", function(d) {
                                return self.id + '-point-' + key + '-' + c.getDateKey(tick.date, self.minutes, '-', false);
                            })
                            .attr("pkey", key)
                            .attr("consumption", tick.value)
                            .attr("term_id", entry.term.id)
                            .attr("term_text", entry.term.text)
                            .attr("pdate", pointDate)
                            .attr("r", DEFAULT_POINT_RADIUS)
                            .attr("cx", function(d) {
                                return self.xScale(tick.date);
                            })
                            .attr("cy", function(d) {
                                return self.yScale(tick.value);
                            })
                            .on("mouseover", function() {
                                d3.select(document.getElementById("date-label-" + date)).style("display", "none");
                                d3.select(document.getElementById("date-label-background" + date)).style("display", "none");
                                if(self.configuration.agg_examples_mode) {
                                    d3.select("#chart-index-rect-" + entry.term.class + "-" + dateKey).style("display", "block");
                                    d3.select("#chart-index-text-" + entry.term.class + "-" + dateKey).style("display", "block");
                                }
                                point.attr("r", HOVER_POINT_RADIUS);
                            })
                            .on("mousemove", function() {
                            })
                            .on("mouseout", function() {
                                if(self.configuration.agg_examples_mode) {
                                    d3.select("#chart-index-rect-" + entry.term.class + "-" + dateKey).style("display", "none");
                                    d3.select("#chart-index-text-" + entry.term.class + "-" + dateKey).style("display", "none");
                                }
                                point.attr("r", DEFAULT_POINT_RADIUS);
                            })
                            .on("click", function(tick) {
                                if(self.configuration.agg_examples_mode) return;

                                var element  = $(document.getElementById(this.id));
                                if (!c.is(element)) {
                                    element = $('#canvas');
                                }
                                var date = this.getAttribute('pdate');
                                var value = this.getAttribute('consumption');
                                if (value > 0) {
                                    self.containing_object.showPopup(element, c.getKey(entry.term.id, entry.term.text), value, date);
                                }
                            });
                        var consumption = point.attr('consumption');
                        var circlex = parseInt(point.attr('cx'));

                        if(!self.configuration.agg_examples_mode){
                            point.style("cursor","pointer")
                        }

                        var consumptionAvg = Math.round((consumption / (avgData.original || avgData.value || 1)) * 100 - 100);
                        if (with_avg != false) {
                          var titleTemplate = self.configuration.templates[titleTemplateName];
                          var title = titleTemplate({consumption: parseFloat(consumption), consumptionAvg: parseInt(consumptionAvg)});
                        } else {
                          var title = '';
                        }
                        var maxCx = parseInt(self.xScale(_.max(_.map(entry.series, "date")))) + 20;
                        var hoverXLocation = calcHoverXLocation(title, circlex, maxCx);

                        self.svg.append("rect")
                            .attr("id", "chart-index-rect-" + entry.term.class + "-" + dateKey)
                            .attr("class", "chart-index-rect")
                            .attr("x", circlex - hoverXLocation)
                            .attr("y", -20)
                            .attr("rx", 4)
                            .attr("ry", 4)
                            .attr("width", title.length * 7)
                            .attr("height", 26)
                            .style("display", "none");

                        self.svg.append("text")
                            .attr("id", "chart-index-text-" + entry.term.class + "-" + dateKey)
                            .attr("class", "chart-index-text")
                            .attr("x", circlex - hoverXLocation + 5)
                            .attr("y", -2)
                            .text(title)
                            .style("display", "none");
                    }
                } else {
                    console.log('Invalid date: ' + JSON.stringify(tick));
                }
            });
            if (DEBUG_MARKER) {
                console.log('[Tooltip points] map: ' + JSON.stringify(self.infoMap, function(key, value) {
                    if (c.isArray(value) || c.isString(value) || c.isNumber(value))
                        return value;
                    else if (key == 'undefined' || key == '' || key == null)
                        return value;
                    else if (!isNaN(parseInt(key))) {
                        if (key > 2012 && key < 2016) {
                            return value;
                        } else if (key > 0 && key < 13) {
                            var valid = true;
                            _.each(value, function(entry, index) {
                                valid = value && (c.isArray(entry) && !isNaN(parseInt(index)) && key > 0 && key < 32);
                            });
                            if (valid) return value;
                        }
                    }
                    return '...';
                }));
            }
        });
        this.delta = (ix > 0) ? (this.delta/ix)/2 : this.delta;
    };

    this.findDateItem = function(date, later) {
        var item = c.getMapItemByDate(this.infoMap, date, this.minutes, false);
        if (!c.is(item)) {
            var p, n;
            _.each (this.range, function(entry, index) {
                if (date > entry) {
                    p = (p && p > entry) ? p : entry;
                } else {
                    n = (n && n < entry) ? n : entry;
                }
            });
            p = c.is(p) ? p : n;
            n = c.is(n) ? n : p;
            if (c.is(n) && c.is(p)) {
                var d = (((date.getTime() - p.getTime()) > (n.getTime() - date.getTime())) ? n : p);
                item = c.getMapItemByDate(this.infoMap, d, this.minutes, false);
            }
        }
        return item;
    };

    this.updateTooltip = function(infoGroup) {
        if (c.is(infoGroup)) {
            if (infoGroup != this.currentInfo) {
                if (c.is(this.currentInfo))
                    this.currentInfo.style("opacity", 1e-6);
                infoGroup.style("opacity", 1);
                this.currentInfo = infoGroup;
            } else infoGroup.style("opacity", 1);
            return true;
        }
        return false;
    };

    this.addInfoTooltips = function() {
        var self = this;
        this.container.on("mousemove", function(e) {
            try {
                var fx = d3.mouse(this)[0] - PAD.left;
                if (DEBUG_MARKER) {
                    self.marker.attr("x1", d3.mouse(this)[0] - PAD.left).attr("x2", fx);
                }
                var date = self.xScale.invert(fx + self.delta/2);
                self.updateTooltip(self.findDateItem(date, (fx - self.move) > 0));
                self.move = fx;
            } catch (e) {}
        });
    };

    this.addCircles = function(data, circle_text){
        var self = this;
        var previous_letter = '';
        _.each(data,function(entry,k){
            if(entry.letter == previous_letter){
                return;
            }
            previous_letter = entry.letter;
            var entryDate = new Date(entry.date);
            if(!self.isTimeModeDetected){
                entryDate.setTime(entryDate.getTime() + (entryDate.getTimezoneOffset() + 60) * 60000);
            }
            var {dateKey,datePkey} = getDateKeys(entryDate);
            var currentCircle = d3.select("circle[pdate=\"" + datePkey + "\"][pkey=\"" + entry.class + "\"]");
            if (!currentCircle.empty()) {
                currentCircle.attr("display", "none");
                var circlex = parseFloat(currentCircle.attr("cx"));
                var circley = parseFloat(currentCircle.attr("cy"));
                var circleClass = currentCircle.attr("class");
                var consumption = currentCircle.attr("consumption");
                self.svg.append("circle")
                    .attr("id", "trend-chart-circle-" + entry.class + "-" + dateKey)
                    .attr("class", circleClass + " trend-chart-circle")
                    .attr("stroke-width", 2)
                    .attr("cx", circlex)
                    .attr("cy", circley)
                    .attr("letter", entry.letter)
                    .attr("consumption", consumption)
                    .on("mouseover", function () {
                        var element = d3.select(this);
                        self.highlightSwitchIdValues(element, true, "circle", "text");
                        var currentLetter = element.attr("letter");
                        d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "block");
                        d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "block");
                        self.emitEvent("chartCircleMouseover",currentLetter);
                        self.moveCircleToFront(currentLetter);
                    }).on("mouseout", function () {
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        if (currentLetter != self.letterClicked) {
                            self.highlightSwitchIdValues(element, false, "circle", "text");
                        }
                        d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "none");
                        d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "none");
                        self.emitEvent("chartCircleMouseout",currentLetter);
                    }).on("click", function () {
                        self.highlightCircleFromLetter(self.letterClicked, false);
                        var element = d3.select(this);
                        var currentLetter = element.attr("letter");
                        self.letterClicked = currentLetter;
                        self.highlightSwitchIdValues(element, true, "circle", "text");
                        self.moveCircleToFront(currentLetter);
                        self.emitEvent("chartCircleClick",{letter: currentLetter, class: entry.class});
                        d3.event.stopPropagation();
                    });

                if (circle_text != false) {
                    self.svg.append("text")
                        .attr("id", "trend-chart-text-" + entry.class + "-" + dateKey)
                        .attr("class", circleClass + " trend-chart-text")
                        .attr("x", circlex)
                        .attr("y", circley)
                        .text(entry.letter)
                        .attr("letter", entry.letter)
                        .attr("consumption", consumption)
                        .on("mouseover", function () {
                            var element = d3.select(this);
                            var currentLetter = element.attr("letter");
                            self.highlightSwitchIdValues(element, true, "text", "circle");
                            d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "block");
                            d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "block");
                            self.emitEvent("chartCircleMouseover",currentLetter);
                            self.moveCircleToFront(currentLetter);
                        }).on("mouseout", function () {
                            var element = d3.select(this);
                            var currentLetter = element.attr("letter");
                            if (currentLetter != self.letterClicked) {
                                self.highlightSwitchIdValues(element, false, "text", "circle");
                            }
                            d3.select("#chart-index-rect-" + entry.class + "-" + dateKey).style("display", "none");
                            d3.select("#chart-index-text-" + entry.class + "-" + dateKey).style("display", "none");
                            self.emitEvent("chartCircleMouseout",currentLetter);
                        }).on("click", function () {
                            self.highlightCircleFromLetter(self.letterClicked, false);
                            var element = d3.select(this);
                            var currentLetter = element.attr("letter");
                            self.letterClicked = currentLetter;
                            self.highlightSwitchIdValues(element, true, "text", "circle");
                            self.moveCircleToFront(currentLetter);
                            self.emitEvent("chartCircleClick",{letter: currentLetter, class: entry.class});
                            d3.event.stopPropagation();
                        });
                }

                if (self.letterClicked) {
                    self.highlightCircleFromLetter(self.letterClicked, true);
                }
            }
        });
    };

    this.resetHighlightedCircle = function() {
      this.highlightCircleFromLetter(this.letterClicked, false);
      this.letterClicked = '';
    };

    this.highlightSwitchIdValues = function(element, highlightOn, searchValue, replaceValue){
        element.classed("highlighted", highlightOn);
        var elementId = element.attr("id").replace(searchValue, replaceValue);
        d3.select("#" + elementId).classed("highlighted", highlightOn);
    };

    this.highlightCircleFromLetter = function(letter, highlightOn){
        if(letter) {
            d3.selectAll(".trend-chart-circle[letter=" + letter + "], .trend-chart-text[letter=" + letter + "]").classed("highlighted", highlightOn);
        }
    };

    this.moveCircleToFront = function(letter){
        d3.select(".trend-chart-circle[letter=" + letter + "]").moveToFront();
        d3.select(".trend-chart-text[letter=" + letter + "]").moveToFront();
    };

    this.setDays = function(unit) {
        this.days = {isDays: unit >= 7, number: unit};
    };

    this.emitEvent = function(eventType,data){
        if(self.containing_object.hasOwnProperty('emitEventForChartCircle') && typeof self.containing_object.emitEventForChartCircle == 'function'){
            self.containing_object.emitEventForChartCircle(eventType,data);
        }
    };

    this.init();
}

module.exports = TrendChart;
