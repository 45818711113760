"use strict";
module.exports = angular.module(__filename, [
    require('../components/dropdown/dropdown').name
]).component("alertFilters", {
    bindings: {
        alert: '<',
        channels: '<',
        type: '<',
        sentimentDetection: '<',
        alertChangeDirection: '<',
        onChannelChanged: '&'
    },
    template: '<ng-include src="$ctrl.getTemplateUrl()"/>',
    controller: ['$scope', '$rootScope', function ($scope, $rootScope) {
        this.$onInit = function () {
            this.geos = $rootScope.Geos.geos;
        };

        this.onGeoSelected = function (geo) {
            this.alert.geo = geo;
        };

        this.onSentimentSelected = function (sentimentDetection) {
            this.alert.sentiment = sentimentDetection;
        };

        this.onSentimentEventSelected = function (change) {
            this.alert.change = change;
        };

        this.getTemplateUrl = function () {
            return `partials/${this.type}-filter.partial.html`;
        };

        this.getCss = function (option, currentVal) {
            var res = "";

            if (option.first) {
                res += "first ";
            }
            if ($scope.$eval(option.last)) {
                res += "last ";
            }
            if (option.value === currentVal) {
                res += "chosenTime ";
                if (option.first) {
                    res += "chosenFirst ";
                }
            }
            return res;
        };

        this.changeChannel = function (channel) {
            this.alert.channel = channel;
            if (this.alert.channel == 'tweets') {
                this.alert.benchmark_timeframe = 'last_month';
            }

            this.onChannelChanged({$event: channel})
        };
    }]
});
