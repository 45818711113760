"use strict";
module.exports = angular.module(__filename, [
  require("./am-nav-button").name,
]).component('amNavBar', {
  template: "<ng-transclude></ng-transclude>",
  transclude: true,
  bindings: {
    chosen: '<'
  },
  controller: ["$rootScope", "$scope", function($rootScope, $scope) {
    var self = this;

    self.$onInit = function() {
      self.buttons = {};
      self.selected = null;
      self.unregisterStateChange = $rootScope.$on("$stateChangeStart", function(evnt, toState, toParams, fromParams, options) {
        $scope.$broadcast("am-nav-bar:link:set", toState.name);
      });
    };

    self.register = function(button) {
      self.buttons[button.$id] = button;
    };

    self.setSelectedButtonById = function(id) {
      self.selected = id;
      _.each(self.buttons, function(button) {
        button.selected = (id === button.$id);
      });
    };

    self.$onDestroy = function() {
      self.unregisterStateChange();
    };
  }]
});
