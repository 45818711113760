"use strict";
'use strict';
module.exports = angular.module(__filename, [])
    .service('userHistoryExportService', ['Excel', 'segmentInterestExportService',
            function(Excel, segmentInterestExportService) {
                function createProfileWorksheet(workbook, formatter, userId, userProfile, demographicsData, segment) {
                    const profileWorksheet = workbook.createWorksheet({name: 'Profile'});
                    const summary = segmentInterestExportService.getSegmentSummaryData(segment, demographicsData, formatter);

                    const userExample = [
                        [formatter('Example Info', 'bold')],
                        ['Example Id:', userId],
                        ['Gender:', userProfile['gender'] || "N/A"],
                        ['Age:', userProfile['age'] || "N/A"]
                    ];

                    profileWorksheet.setData(Excel.insertDataAtColumn(summary, userExample, 10));
                    profileWorksheet.setColumns([...Array(12)].map(() => ({width: segmentInterestExportService.COMMON_COLUMN_WIDTH})));
                    workbook.addWorksheet(profileWorksheet);
                }

                function createMomentsSummaryWorksheet(workbook, formatter, momentsSummary) {
                    const momentsSummaryWorksheet = workbook.createWorksheet({name: "Moments Summary"});

                    const data = [
                        [formatter('Moment Type', 'bold'), formatter('Moment Portion', 'bold')]
                    ].concat(momentsSummary.map(moment => [moment.activity, formatter(parseFloat(moment.value) / 100, 'percent')]));

                    momentsSummaryWorksheet.setData(data);
                    momentsSummaryWorksheet.setColumns([{width: 18}, {width: 36}]);
                    workbook.addWorksheet(momentsSummaryWorksheet);
                }

                function createTimelineWorksheet(workbook, formatter, userHistory, sheetName) {
                    const timelineWorksheet = workbook.createWorksheet({name: sheetName});
                    const titleLine = [
                        formatter('Time', 'bold'),
                        formatter('Matching Moments', 'bold'),
                        formatter('Moment Type', 'bold'),
                        formatter('Website', 'bold'),
                        formatter('URL', 'bold'),
                        formatter('Moment Headline', 'bold')
                    ];

                    const data = userHistory.map(user => [
                        formatter(moment(user['ts']).format('YYYY-MM-DD HH:mm:ss') + '.000Z', 'fullDate'),
                        user['highlighted'] ? "Yes" : "No",
                        user['activityText'],
                        user['domain'],
                        user['url'],
                        user['title'] || user['category'] || user['term']
                    ]);
                    data.unshift(titleLine);
                    timelineWorksheet.setData(data);
                    timelineWorksheet.setColumns([{width: 20}, {width: 18}, {width: 15}, {width: 20}, {width: 80}]);
                    workbook.addWorksheet(timelineWorksheet);
                }

                const fileNamer = segmentInterestExportService.momentBasedFileNamerGen('User History');

                function exportToExcel(demographicsData, segment, filteredUserHistory, userProfile, userId, momentsSummary) {
                    const workbook = Excel.builder.createWorkbook();
                    const formatter = Excel.formater(workbook);

                    createProfileWorksheet(workbook, formatter, userId, userProfile, demographicsData, segment);
                    createMomentsSummaryWorksheet(workbook, formatter, momentsSummary);
                    createTimelineWorksheet(workbook, formatter, filteredUserHistory, 'Timeline');

                    workbook.fileName = fileNamer();
                    return workbook;
                }

                function downloadExcel(excel) {
                    return segmentInterestExportService.excelDownloader(excel, fileNamer);
                }

                return {exportToExcel, downloadExcel}
            }
        ]
    );
