"use strict";
module.exports = angular.module(__filename, []).controller('connectSourceController', ['$scope', 'close', 'source', 'program_id', 'programSourcesService', 'notificator',
    function ($scope, close, programSource, p_id, service, notificator) {
        $scope.close = close;
        $scope.isNumber = _.isNumber;
        $scope.save = save;
        $scope.sourceIcon = sourceIcon;
        $scope.selectType = selectType;
        $scope.add = addSource;
        $scope.remove = removeSource;
        $scope.isInputValid = isInputValid;
        $scope.isDisabled = isDisabled;

        var icons = {
            facebook: 'icon-sources-facebook-tiny',
            twitter: 'icon-sources-twitter-tiny',
            pinterest: 'icon-sources-pinterest-tiny',
            youtube: 'icon-sources-youtube-tiny',
            rss: 'icon-sources-rss-tiny'
        };

        $scope.types = [
            {
                value: 'facebook',
                channel: 'facebook',
                icon: 'icon-sources-facebook',
                placeholder: 'www.facebook.com/name OR name',
                validateInput: function () {
                    var urlRegex = /^(https?:\/\/)?www\.facebook\.com\/(?=([a-zA-Z0-9\-\.]{3,}))\/?/;
                    var userRegex = /^(?!(https?:\/\/)?www\.facebook\.com)([a-zA-Z0-9\-\.]{3,})$/;

                    var valid = urlRegex.test($scope.url);
                    if (valid) {
                        var extractRegex = /^((https?:\/\/)?www\.facebook\.com\/)?([a-zA-Z0-9\-\.]{3,})/;
                        this.username = _.last($scope.url.match(extractRegex));
                    } else {
                        valid = userRegex.test($scope.url);
                        this.username = valid ? $scope.url : null;
                    }

                    $scope.editSourceForm.url_input.$valid = valid;
                },
                extract: function () {
                    return 'www.facebook.com/' + this.username;
                }
            },
            {
                value: 'youtube',
                channel: 'videos',
                icon: 'icon-sources-youtube',
                placeholder: 'www.youtube.com/channel/name OR name',
                validateInput: function () {
                    var urlRegex = /^((https?:\/\/)?www\.youtube\.com\/((?:user\/)?[a-zA-Z0-9_]{1,20}|channel\/.{24}))$/;
                    var userRegex = /^[a-zA-Z0-9_]{1,20}$/;

                    var valid = urlRegex.test($scope.url);
                    if (valid) {
                        this.username = _.last($scope.url.match(urlRegex));
                    } else {
                        valid = userRegex.test($scope.url);
                        this.username = valid ? $scope.url : null;
                    }

                    $scope.editSourceForm.url_input.$valid = valid;
                },
                extract: function () {
                    return 'www.youtube.com/' + this.username;
                }
            },
            {
                value: 'twitter',
                channel: 'tweets',
                icon: 'icon-sources-twitter',
                placeholder: 'www.twitter.com/username OR @username',
                validateInput: function () {
                    var urlRegex = /^(https?:\/\/)?(www\.)?twitter\.com\/(#!\/)?([a-zA-Z0-9_]{1,15})$/;
                    var userRegex = /^@([a-zA-Z0-9_]{1,15})$/;

                    var valid = urlRegex.test($scope.url);
                    if (valid) {
                        this.username = _.last($scope.url.match(urlRegex));
                    } else {
                        valid = userRegex.test($scope.url);
                        this.username = valid ? _.last($scope.url.match(userRegex)) : null;
                    }

                    $scope.editSourceForm.url_input.$valid = valid;
                },
                extract: function () {
                    return 'www.twitter.com/' + this.username;
                }
            },
            {
                value: 'pinterest',
                channel: 'pinterest',
                icon: 'icon-sources-pinterest',
                placeholder: 'www.pinterest.com/username OR username',
                validateInput: function () {
                    var urlRegex = /^(https?:\/\/)?www\.pinterest\.com\/([a-zA-Z0-9]{3,15})\/?$/;
                    var userRegex = /^([a-zA-Z0-9]{3,15})$/;

                    var valid = urlRegex.test($scope.url);
                    if (valid) {
                        this.username = _.last($scope.url.match(urlRegex));
                    } else {
                        valid = userRegex.test($scope.url);
                        this.username = valid ? _.last($scope.url.match(userRegex)) : null;
                    }

                    $scope.editSourceForm.url_input.$valid = valid;
                },
                extract: function () {
                    return 'www.pinterest.com/' + this.username;
                }
            },
            {value: 'rss', icon: 'icon-sources-rss', disabled: true}
        ];

        $scope.title = programSource ? 'Edit Source' : 'Add a New Source';
        $scope.programSource = programSource || {sources: []};

        if (programSource) {
            selectType(_.find($scope.types, {value: _.first(programSource.sources).type}));
        }

        function sourceIcon(type) {
            return icons[type];
        }

        function isDisabled(type) {
            return type.disabled || $scope.programSource.sources.length > 0;
        }

        function clearSelected() {
            $scope.url = null;
            $scope.placeholder = null;
            $scope.selectedType = null;
            _.each($scope.types, function (t) {
                t.selected = false;
            });
        }

        function selectType(type) {
            clearSelected();

            type.selected = true;
            $scope.selectedType = type;
            $scope.placeholder = type.placeholder;
        }

        function isInputValid() {
            return $scope.selectedType && $scope.url && $scope.editSourceForm.url_input.$valid;
        }

        function addSource() {
            var url = $scope.selectedType.extract();
            if (_.find($scope.programSource.sources, {url: url})) {
                notificator.notify({body: 'URL already exist'});
            } else if ($scope.programSource.sources.length >= 100) {
                notificator.notify({body: 'Max 100 URLs for source'});
            } else {
                var source = {type: $scope.selectedType.value, url: url, channel: $scope.selectedType.channel};
                $scope.programSource.sources.push(source);
                $scope.url = null;
            }
        }

        function removeSource(index) {
            $scope.programSource.sources.splice(index, 1);
        }

        function save() {
            service.save(p_id, $scope.programSource).then(function () {
                var msg = 'Program source saved successfully' + (programSource ? '' : '. Content from the source will be available within 15 minutes');
                notificator.success({body: msg});
                close({reload: true});
            });
        }
    }
]);
