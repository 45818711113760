"use strict";
"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
]).factory("LevelOfIntentMold", ['filtersPartition', 'filterMoldService', function (filtersPartition, filterMoldService) {
    return function TestSegmentMold() {
        var me = this;
        me._value = [];
        me.default = [];
        filterMoldService.getDefaultFilterMoldFunction.call(this, filtersPartition.levelOfIntent);
    }
}]);
