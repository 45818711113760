"use strict";
module.exports = angular.module(__filename, [
    require('data/settings/program-service').name,
    require("angular-modal-service").name

]).controller('cloneProgramController',
    ['$scope', '$rootScope', 'program', 'close', 'programService', 'notificator', '$q',
        function ($scope, root, program, close, programService, notificator, $q) {
            $scope.close = close;
            $scope.program = program;
            $scope.newName = '';

            // close modal on navigate back
            var closeListener = $scope.$root.$on("$stateChangeStart", function () {
                close({reload: false})
            });
            $scope.$on('$destroy', closeListener);

            $scope.save = function () {
                programService.cloneProgram($scope.program.id, $scope.newName).then(
                    function (data) {
                        root.user.programs.push(programService.buildProgram(data));
                        notificator.success({body: 'Program cloned successfully'});
                        close({reload: true});
                    }
                );
            }
        }
    ]
);
