"use strict";
module.exports = angular.module(__filename, []).directive('amEmptyScreenMessage', [function () {
    return {
        restrict: 'E',
        template: require('./emptyScreenMessage.html'),
        scope: {
            imgSrc: '@',
            msgTitle: '@',
            msgText: '@'
        }
    }
}]);
