"use strict";
var validate  = require('./validate');

// Language types validators from validate.js
var is = validate.is;
var isNumber = validate.isNumber;
var isArray = validate.isArray;
var isString = validate.isString;
var isDate = validate.isDate;

// Constants
var MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
var M = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

// Functions
var getHoursLabel = function (date, exclude, include) {
    var r = '', h = date.getHours(), m = date.getMinutes();
    if (include) {
        r = ((1 + date.getMonth()) + '/' + date.getDate()) + ' ';
    }
    if (exclude && h == 0 && m == 0) {
        return r;
    }
    return r + ((h > 9 ? h : ('0' + h)) + ':' + (m > 9 ? m : ('0' + m)));
};

var getUTCHoursLabel = function (date, exclude, include) {
    var r = '', h = date.getUTCHours(), m = date.getUTCMinutes();
    if (include) {
        r = ((1 + date.getUTCMonth()) + '/' + date.getUTCDate()) + ' ';
    }
    if (exclude && h == 0 && m == 0) {
        return r;
    }
    return r + ((h > 9 ? h : ('0' + h)) + ':' + (m > 9 ? m : ('0' + m)));
};

var parseDateHourMinute = function (formatted, f) {
    return moment(formatted, isString(f) ? f : 'YYYY-MM-DDTHH:mm');
};

var parseDate = function (formatted) {
    var d = formatted.replace(/-/g, '/');
    return new Date(d);
};

var setDatedValueByIdAndKey = function (map, date, minutes, value, id, key, df) {
    if (isDate(date)) {
        var format = isString(df) ? df : (minutes ? 'YYYY-MM-DDTHH:mm:SS' : 'M/D/YYYY');
        var d = moment(date).format(format);
        var entry = map[d];
        if (!is(entry)) {
            entry = {};
            map[d] = entry;
        }
        if (isString(key)) {
            var field = entry[id];
            if (!is(field)) {
                field = {};
                entry[id] = field;
            }
            entry[id][key] = value;
        } else {
            entry[id] = value;
        }
    }
};

var isCustomTimeframe = function (timeframe) { return isArray(timeframe) && !isNumber(timeframe[0]) };

var getTimeframe = function (timeframe, dontFormat, subtractDay) {
    if (isArray(timeframe)) {
        var type = 'last_month';
        var measure = timeframe[1];
        var count = isNumber(timeframe[0]) ? timeframe[0] : 1;
        var r;
        var start, end;

        if (isNumber(timeframe[0])) {
            if ('year' == timeframe[1]) {
                type = 'last_year';
                measure = 'year';
            } else if ('month' == timeframe[1]) {
                if (count == 3) {
                    type = 'last_quarter';
                } else if (count == 6) {
                    type = 'last_6_months';
                } else if (count == 13) {
                    type = 'last_year';
                }
            }

            if(subtractDay){
                var subtractUnits = {};
                subtractUnits[measure] = count;
                
                if(measure == 'month' || measure == 'year'){
                    //subtractUnits.day = 1;
                }
                
                start = moment().subtract(subtractUnits).subtract(subtractDay, 'days');    
                end = moment().subtract(subtractDay, 'days');
            } else {
                start = moment().subtract(count, measure);
                end = moment();
            }
            if (!dontFormat && measure !== 'hour') start = start.startOf('day').format(MOMENT_DATE_FORMAT);
            if (!dontFormat) end = end.format(MOMENT_DATE_FORMAT);
            r = {
                type: type,
                end: end,
                start: start
            };
        } else { // Custom date
            start = moment(timeframe[0], "DD/MM/YY");
            end = moment(timeframe[1], "DD/MM/YY");
            if (!dontFormat) {
                start = start.format(MOMENT_DATE_FORMAT);
                end = end.format(MOMENT_DATE_FORMAT);
            }
            r = {
                type: 'na',
                start: start,
                end: end
            };
        }

        return r;
    }

    return { // Default
        type: 'last_month',
        start: moment().subtract(1, "month").startOf('day').format(MOMENT_DATE_FORMAT),
        end: moment().format(MOMENT_DATE_FORMAT)
    };
};

var getMonthShortLabel = function (m) { return M[m + 1]; };

var getDateKey = function (date, minutes, d, pretty) {
    var k1 = date.getFullYear().toString(),
        k2 = (1 + date.getMonth()).toString(),
        k3 = date.getDate().toString(),
        k4 = minutes ? ('T' + date.getHours() + '_' + date.getMinutes() + '_' + date.getSeconds()) : '';

    return pretty ? (k3 + d + k2 + d + k1 + k4) : (k1 + d + k2 + d + k3 + k4);
};

var addMapItemByDate = function (map, item, date, minutes) {
    if (is(map) && isDate(date)) try {
        var k1 = date.getFullYear().toString(),
            k2 = (1 + date.getMonth()).toString(),
            k3 = date.getDate().toString(),
            k4 = date.getHours().toString(),
            k5 = date.getMinutes().toString();

        if (!is(map[k1])) map[k1] = {};
        if (!is(map[k1][k2])) map[k1][k2] = {};
        if (minutes) {
            if (!is(map[k1][k2][k3])) map[k1][k2][k3] = {};
            var m = map[k1][k2][k3];
            if (!is(m[k4])) m[k4] = {};
            m[k4][k5] = item;
        } else {
            map[k1][k2][k3] = item;
        }
    } catch (e) {
        console.log('Failed to add the element by date', e);
    }
};

var getMapEntry = function (map, key, roughly) {
    var r = map[key];
    if (!is(r) && roughly) {
        var p, n, d = parseInt(key);
        _.each(map, function (i, j) {
            var c = parseInt(j);
            p = ((c < d) && (!isNumber(p) || ((d - p) > (d - c)))) ? c : p;
            n = ((c > d) && (!isNumber(n) || ((n - d) > (c - d)))) ? c : n;
        });
        p = isNumber(p) ? p : n; //TODO Take real previous
        n = isNumber(n) ? n : p; //TODO Take real next
        if (p > 0 && n > 0) {
            return (d - p) > (n - d) ? map[n.toString()] : map[p.toString()];
        }
    }
    return r;
};

var getMapItemByDate = function (map, date, minutes, roughly) {
    var r = null;
    if (is(map) && isDate(date)) try {
        var i = getMapEntry(map, date.getFullYear().toString(), roughly);
        if (is(i)) {
            i = getMapEntry(i, (1 + date.getMonth()).toString(), roughly);
            if (is(i)) {
                i = getMapEntry(i, date.getDate().toString(), roughly);
                if (is(i)) {
                    if (minutes) {
                        i = getMapEntry(i, date.getHours().toString(), roughly);
                        if (is(i)) {
                            r = getMapEntry(i, date.getMinutes().toString(), roughly);
                        }
                    } else r = i;
                }
            }
        }
    } catch (e) {}
    return r;
};

var getTimeframeLabel = function (timeframe, format) {
    if (isArray(timeframe)) {
        if (isNumber(timeframe[0]) && isString(timeframe[1])) {
            return timeframe[0] + ' ' + timeframe[1];
        } else if (isString(timeframe[0]) && isString(timeframe[1])) {
            var start, end;
            if (format) {
                start = moment(timeframe[0], "DD_MM_YY_HH_mm").format(format);
                end = moment(timeframe[1], "DD_MM_YY_HH_mm").format(format);
            } else {
                start = timeframe[0].replace("_", "-");
                end = timeframe[1].replace("_", "-");
            }

            return start + ' - ' + end + ' (Custom timeframe)';
        }
    }
    return 'Unknown';
};

var timeframeDiffFromTodayInDays = function (timeframe) {
    if (!timeframe) return 0;
    var days;

    if (timeframe[1] == 'month') {
        days = timeframe[0] * 30;
    } else {
        var start = getTimeframe(timeframe).start;
        days = moment.duration(moment().diff(start)).asDays();
    }

    return days;
};

var timeframeDiffInDays = function (timeframe) {
    if (!timeframe) return 0;
    var days;

    if (timeframe[1] == 'month') {
        days = timeframe[0] * 30;
    } else {
        var end = moment(timeframe[1], "DD_MM_YY_HH_mm");
        var start = moment(timeframe[0], "DD_MM_YY_HH_mm");
        days = moment.duration(end.diff(start)).asDays();
    }

    return days;
};

var isMoreThanMonth = function (timeframe) {
    return timeframeDiffInDays(timeframe) > 31;
};

var hasDatesOlderThanOneMonth = function (timeframe) {
    return Math.floor(timeframeDiffFromTodayInDays(timeframe)) > 31;
};

var getDateRange = function (start, end, returnFormat, units) {
    if (!units) units = 'day';
    if (!returnFormat) returnFormat = 'YYYY-MM-DD';

    var momentStart = moment(start);
    var momentEnd = moment(end);
    var currentDate = momentStart;
    var range = [];
    while (currentDate.isBefore(momentEnd) || currentDate.isSame(momentEnd)) {
        range.push(currentDate.format(returnFormat));
        currentDate.add(1, units)
    }

    return range;
};

//Special case where this day of the month didn't occur in the months before
var calcOffsetDays = function (timeframe, num_months) {
    var format = "DD_MM_YY_HH_mm";
    var days = 1;
    while (moment(timeframe[1], format).subtract({'month': num_months}) <= moment(timeframe[1], format).subtract({'month': num_months, 'day': days})) {
        days++;
    }
    return days - 1;
};

var methods = {
    getTimeframeLabel: getTimeframeLabel,
    getMapItemByDate: getMapItemByDate,
    addMapItemByDate: addMapItemByDate,
    getDateKey: getDateKey,
    setDatedValueByIdAndKey: setDatedValueByIdAndKey,
    getTimeframe: getTimeframe,
    getHoursLabel: getHoursLabel,
    getUTCHoursLabel:getUTCHoursLabel,
    parseDateHourMinute: parseDateHourMinute,
    parseDate: parseDate,
    getMonthShortLabel: getMonthShortLabel,
    isMoreThanMonth: isMoreThanMonth,
    hasDatesOlderThanOneMonth: hasDatesOlderThanOneMonth,
    isCustomTimeframe: isCustomTimeframe,
    getDateRange: getDateRange,
    calcOffsetDays: calcOffsetDays
};

module.exports = methods;
