"use strict";
'use strict';
module.exports = angular.module(__filename, [])
    .service('usersExportService', ['Excel',
        function (Excel) {
            var objectURL;
            var options = {
                base64: false,
                type: 'blob',
                mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            };

            return {
                exportUsersToExcel: function (users, options, geos) {
                    var workbook = Excel.builder.createWorkbook();
                    var formatter = Excel.formatter(workbook);
                    var workSheet = workbook.createWorksheet({name: 'Users'});
                    var boldCenterAligned = workbook.getStyleSheet().createFormat({
                      alignment: {
                        horizontal: "center"
                      },
                      font: {bold: true}
                    });
                    var titleLine = [
                        formatter('User Name', 'bold'),
                        formatter('E-mail', 'bold'),
                        formatter('Organization', 'bold'),
                        formatter('User Type', 'bold'),
                        formatter('Created', 'bold'),
                        formatter('Last Update', 'bold')
                    ];

                    var basic_info_size = titleLine.length;

                    var available_permissions = _(options.permissions).filter("display").orderBy(['group', 'name'], ['asc', 'asc']).map('name').value();
                    var available_groups_and_sizes = _(_(options.permissions).filter("display").groupBy("group")
                                                       .reduce( function(result, value, key) {
                                                         result[key] = value.length;
                                                         return result}, {})
                                                       ).toPairs().orderBy()
                                                       .value();
                    var group_sizes = _(options.permissions).filter("display").groupBy("group");

                    var titleLine1 = _.concat(_.fill(Array(basic_info_size), ""), {value: "Permissions", metadata: {style: boldCenterAligned.id}});
                    var geo_size = geos[Object.keys(geos)[0]].length;
                    var permissions_size = _.sum(_.map(available_groups_and_sizes, function(x) { return x[1] })) + geo_size;
                    var titleLine1Location = [Excel.columnName(basic_info_size+1)+"1", Excel.columnName(basic_info_size + permissions_size) + "1"];

                    var titleLine2 = _.flatten(
                                        _.concat(
                                          _.fill(Array(basic_info_size), ""),
                                          _.map(available_groups_and_sizes, function(p) {
                                            return _.concat(
                                                            {value: _.startCase(p[0]), metadata: {style: boldCenterAligned.id}},
                                                            _.fill(Array(p[1]-1), ""));
                                          }),
                                        {value: "Geos", metadata: {style: boldCenterAligned.id}}));

                    var titleLine3 = _.concat(titleLine, _.map(available_permissions, function(p) {
                                        return formatter(_.startCase(p), 'bold');
                                     }),
                                     _.map(geos[Object.keys(geos)[0]], function(g) {
                                       return formatter(_.startCase(g.label), 'bold');
                                     })
                                   );

                    var data = _.map(users, function (u) {
                      var base = [
                        u.username, u.email, u.account.name, u.user_type.name,
                        moment(u.created_at).format('MMM D, YYYY h:mm:ss A'),
                        moment(u.updated_at).format('MMM D, YYYY h:mm:ss A')
                      ];
                      var permissions = available_permissions.map(function (p) {
                        return _.find(u.permissions, function(up) { return up.name == p; }) ? 'y' : '-';
                      });
                      var geosStr = geos["user"+u.id].map(function (g){
                        return g.supported == true ? 'y' : '-';
                      });
                      return _.concat(base, permissions,geosStr);
                    });

                    data.unshift(titleLine1, titleLine2, titleLine3);
                    workSheet.mergeCells(titleLine1Location[0], titleLine1Location[1]);
                    var start = basic_info_size + 1;
                    _.each(available_groups_and_sizes, function(value) {
                      workSheet.mergeCells(Excel.columnName(start)+"2", Excel.columnName(start+value[1]-1)+"2");
                      start += value[1];
                    });
                    workSheet.mergeCells(Excel.columnName(start)+"2", Excel.columnName(start+geo_size-1)+"2");
                    workSheet.setData(data);
                    workSheet.setColumns([{width: 20}, {width: 32}, {width: 14}, {width: 12}, {width: 26}, {width: 26}]);
                    workbook.addWorksheet(workSheet);
                    workbook.fileName = 'Users.xlsx';
                    return workbook;
                },
                downloadExcel: function (excel) {
                    URL.revokeObjectURL(objectURL);
                    var blob = Excel.builder.createFile(excel, options);
                    objectURL = URL.createObjectURL(blob);
                    var linkEl = document.createElement('a');
                    linkEl.href = objectURL;
                    linkEl.setAttribute('download', excel.fileName || 'Users.xlsx');
                    linkEl.click();
                }
            }
        }
    ]
);
