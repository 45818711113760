"use strict";
var DEFAULT_OPTIONS = {num_of_ticks: 15};

module.exports = angular.module(__filename, [])
    .directive('amGaugeBar', [function () {
        return {
            restrict: 'E',
            template: require('./am-gauge-bar.drv.html'),
            scope: {
                "percents": "=",
                "options": "="
            },
            link: function ($scope) {
                var options = _.merge({}, DEFAULT_OPTIONS, $scope.options)

                $scope.getTicks = () => new Array(options.num_of_ticks);     
                $scope.lastHighlightedTick = () => Math.ceil(($scope.percents || 0) / 100.0 * options.num_of_ticks) - 1;
            }
        }
    }]
);
