"use strict";
function LandscapeController($rootScope) {
}

LandscapeController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "landscape",
    url: "/landscape",
    template: require("./landscape.insights.html"),
    display: "Insights",
    data: {
        permissions: ['landscape']
    },
    controller: LandscapeController,
    context: {}
};