"use strict";
const BORDER_WIDTH = 2 * 2;
const MIN_SPACE_BETWEEN_ELEMENTS = 10 + BORDER_WIDTH;

module.exports = require("angular").module(__filename, [
    require("../../common/am-gauge-chart.drv/am-gauge-chart.drv").name
]).directive('elementSelector', ["$window", "$timeout", function($window, $timeout) {
        return {
            restrict: 'E',
            template: require('./element-selector.drv.html'),
            scope: {
                "data": "=",
                "onSelect": "&",
                "withPaging": "="
            },
            link: function($scope, $element) {
                const onSelect = $scope.onSelect() ? $scope.onSelect() : angular.noop;
                const containerElement = $element.children().children('.elements-container');
                $scope.slyOptions = {pageBuilder: function() { return '<i></i>'}, activateOn: null};
                $scope.slyCallbacks = {
                    moveEnd: () => $scope.$apply()
                };

                $scope.options = {resize: true};
                const isNoElements = () => !$scope.data || !$scope.data.length;

                let prevWidth = 0;
                let prevElementWidth = 0;
                let ELEMENT_WIDTH = 0;

                function resize() {
                    // 145 = thin element, 205 = thick element
                    // element width + 3px border * 2
                    ELEMENT_WIDTH = (window.innerHeight <= 900 ? 145 : 205) + (2 * 2);
                    
                    if(isNoElements()) return false;

                    let width = containerElement.width();

                    if((!width || width == prevWidth) &&  ELEMENT_WIDTH == prevElementWidth) return false;

                    prevWidth = width;
                    prevElementWidth = ELEMENT_WIDTH;
                    width = width + 6;
                    $scope.numOfElements = Math.floor((width + MIN_SPACE_BETWEEN_ELEMENTS) / (ELEMENT_WIDTH + MIN_SPACE_BETWEEN_ELEMENTS));
                    $scope.space = Math.floor((width - ELEMENT_WIDTH * $scope.numOfElements) / ($scope.numOfElements - 1) + BORDER_WIDTH);

                    if(width < 2000) $scope.space -= 1;
                }

                angular.element($window).bind('resize', function() {
                    if(isNoElements()) return;
                    if(resize() == false) return;
                    $scope.$apply();
                });

                $scope.$watch('data', function() {
                    if(!isNoElements() && !$scope.selectedElementId) {
                        $scope.selectElement($scope.data[0])
                    }

                    $timeout(function() {
                        $scope.sly.toCenter($('#' + $scope.getElementId($scope.selectedElementId)), true)
                    }, 100);
                    $scope.sly.reload();

                    resize();
                });

                $scope.selectElement = function(element) {
                    $scope.selectedElementId = element.id;
                    onSelect(element);
                };

                $scope.isSelected = function(element) {
                    return element.id === $scope.selectedElementId;
                };

                $scope.tooltipDirection = (id) => {
                    const element = $('#' + $scope.getElementId(id));
                    const parentWidth = element.parent().parent().width();
                    const elementPostion = element.position();
                    if(!elementPostion) return;
                    if(elementPostion.left < 100) return 'left';
                    if(parentWidth - (elementPostion.left + ELEMENT_WIDTH) < 200) return 'right';
                    return 'center'
                };

                $scope.getElementId = (id) => _.isUndefined(id) ? '' : 'element-id-' + id.replace(/\s/g, '');
            }
        }
    }]
);
