"use strict";
var common = require("infra/utils/common");

module.exports = angular.module(__filename, []).directive("radiogroupSlider", [function () {
    return {
        restrict: 'E',
        scope: {
            values: '=',
            value: '='
        },
        template: require('./radiogroup-slider.html'),
        link: function ($scope, $element, attr) {
            var element_width = $element.children().children('.values-container').width();
            var font = $element.css('font');
            var DOT_WIDTH = 10;

            var calcElementsPositions = function (labels) {
                element_width = $element.children().children('.values-container').width();
                var num_of_labels = labels.length;
                var dots_positions = new Array(num_of_labels);
                var labels_positions = new Array(num_of_labels);
                for (var i = 0; i < num_of_labels; i++) {
                    var label_width = common.getTextWidth(labels[i], font);
                    var dot_position = calcDotPosition(num_of_labels, i);
                    dots_positions[i] = dot_position - DOT_WIDTH / 2;
                    labels_positions[i] = dot_position - label_width / 2;
                }
                return {dots: dots_positions, labels: labels_positions};
            };

            var calcDotPosition = function (num_of_labels, index) {
                if (num_of_labels < 2) return 0;
                var space_between_dots = element_width / (num_of_labels - 1);
                return space_between_dots * index;
            };

            $scope.$watch('values', function(){
                font = font || $element.css('font');
                $scope.elements_positions = calcElementsPositions(_.map($scope.values, 'label')); 
            })  

            $scope.$watch('value', function(){
                var empty_value = !$scope.value || Array.isArray($scope.value);
                if (empty_value && $scope.values.length) $scope.value = $scope.values[0];
            })  

            $scope.isSelected = function(value){
                return value.value == $scope.value.value;
            } 

            $scope.select = function(value){
                $scope.value = value;    
            }                  
        }
    }
}]);
