"use strict";
var config = require('infra/config');
module.exports = angular.module(__filename, [
    require("satellizer").name,
    require("./authentication").name
]).config(["$authProvider", function ($authProvider) {
    $authProvider.loginUrl = config.AUTHENTICATION_API + '/login';
    $authProvider.signupUrl = config.AUTHENTICATION_API + '/signup';
    $authProvider.tokenPrefix = 'access';
    $authProvider.tokenType = '';
}]).constant("authenticationConfig", {
    doAfterAuthentication: []
}).service("reauthenticateInterceptor", ["$injector", function ($injector) {
    var isModalOpen = false;
    return {
        'response': function (response) {
          return $injector.invoke(['$q', '$window', '$state', 'ModalService', 'notificator', function ($q, $window, $state, ModalService, notificator) {
            var returnValue = response;
            if (response.status === 205 && !isModalOpen) {
              isModalOpen = true;
              ModalService.showModal({
                template: require('./new-version.html'),
                controller: ['$scope', function ($scope) {
                  $scope.reload = function () {
                    $window.location.reload();
                  }
                }]
              });

              returnValue = $q.reject(response);
            }

            return returnValue;
          }]);
        },
        'responseError': function (response) {
            var isRequestCancelled = function isRequestCancelled(error){
                return error.config.timeout &&
                        error.config.timeout.$$state &&
                        error.config.timeout.$$state.value == "AMOBEE_CANCEL_OK";
            }
            return $injector.invoke(["$q", '$state', "$http", "authentication", "$auth", function ($q, $state, $http, authentication, $auth) {
                if (!isRequestCancelled(response)) {
                  console.log('Response Error. status ' + response.status + ' for url: ' + response.config.url, response);
                }

                // Do not try to login if:
                // 1. response is not 401
                // 2. request sent without authentication header
                // 3. current state is login
                // 4. request is to reAuthentication (/login)
                if (response.status !== 401 || response.config.skipAuthorization || $state.is('login')) return $q.reject(response);
                if (response.config.url.split('/').reverse()[0] === 'login') return $q.reject(response);

                return authentication.silentReAuthentication(response.config.url).then(function (data) {
                    return $http(response.config);
                }).catch(function (err) {
                    // Couldn't re-auth
                    console.log(err);
                    $state.go('login', {next: $state.current.name});
                    return $q.reject(response);
                });
            }]);
        }
    };
}]).config(["$httpProvider", function ($httpProvider) {
    $httpProvider.interceptors.push('reauthenticateInterceptor');
}]);
