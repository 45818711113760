"use strict";
module.exports = angular.module(__filename, [
]).component('amSubNavButton', {
  template: require('./am-sub-nav-button.html'),
  require: {
    subBar: '^amSubNav'
  },
  bindings: {
    icon: '@',
    label: '@',
    permission: '@',
    link: '@',
    linkState: '<',
    highlight: '@',
    noHighlight: '@'
  },
  controller: ['$scope', '$element', '$transclude', 'abiPermissions', 'contactUs', function($scope, $element, $transclude, abiPermissions, contactUs) {
    var self = this;
    self.$id = $scope.$id;

    self.hasPermission = abiPermissions.hasPermission;

    self.$onInit = function() {
      self.selected = false;
      self.routerLink = self.getLink();
      self.highlightLinkRe = self.getHighlightLinkRe();
      self.disabled = self.disable(!self.hasPermission(self.permission));
      if (self.disabled && !self.hasPermission(['show blocked applications in UI'])) {
        $element.remove();
      } else {
        self.subBar.register(self);
      }
      self.unregisterNoHighlight = $scope.$on('am-nav-button:no-highlight', function(event, value) {
        self.noHighlight = value;
      });
      self.unregisterSetLink = $scope.$on('am-nav-bar:link:set', function(event, value) {
        if (self.highlightLinkRe.test(value) && (!self.noHighlight)) {
          event.preventDefault();
          self.setSelected(true);
        };
      });
    };

    self.getHighlightLinkRe = function() {
      var link = self.highlight || self.getLink();
      return new RegExp("^"+_.escapeRegExp(link) + "(\\.|$)");
    };

    self.getLink = function() {
      return self.link || self.linkState || '.';
    };

    self.disable = function(value) {
      if (!arguments.length) return self.disabled;
      self.disabled = value;
      self.routerLink = value ? '.' : self.getLink();
      self.highlightLinkRe = self.getHighlightLinkRe();
      return value;
    };

    self.setSelected = function(value) {
      self.selected = value;
      if (value) {
        self.subBar.setSelectedButtonById(self.$id);
      }
    }

    self.onClick = function($event) {
      if (self.disabled) {
        $event.stopPropagation();
        contactUs.show({showTo: false, subject: "Brand Intelligence Upgrade Request", msgPlaceholder: "Anything you'd like us to know?", showAttachments: false});
      }
    };

    self.$onDestroy = function() {
      self.unregisterNoHighlight();
      self.unregisterSetLink();
    };
  }]
});

