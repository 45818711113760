"use strict";
module.exports = angular.module(__filename, []).directive("amToggleButton", ["$timeout", ($timeout) => ({
    restrict: 'E',
    scope: {
        values: '=',
        selected: '=?',
        onSelect: '&',
        disabled: '='
    },
    template: require('./am-toggle-button.html'),
    link: function($scope, $element, $attr) {
        const onSelect = $scope.onSelect() || angular.noop;

        $scope.select = value => {
            if(!$scope.isSelected(value)) {
                if($scope.debounce)
                    setTimeout(() => { $scope.selectTimed(value) }, $scope.debounce * 1);
                else
                    $scope.selectTimed(value);
            }
        };

        $scope.selectTimed = value => {
            $scope.selected = value.value;
            $timeout(() => { onSelect(value.value) });
        };

        $scope.isSelected = value => $scope.selected === value.value;

        $scope.$watch('values', () => {
            if($scope.values && !$scope.selected) $scope.select($scope.values[0]);
        });
    }
})]);
