"use strict";
module.exports = {
  supportedChannels: [
    'articles', 'sg_telco', 'au_telco'
  ],
  scalingOptionsByChannel: {
    'articles': [
      {
        id: 1,
        label: 'State',
        value: 'state'
      },
      {
        id: 2,
        label: 'DMA',
        value: 'dma'
      }
    ],
    'sg_telco': [
      {
        id: 1,
        label: 'Regions',
        value: 'regions'
      }, {
        id: 2,
        label: 'Planning areas',
        value: 'planning'
      }, {
        id: 3,
        label: 'Subzones',
        value: 'subzone'
      }, {
        id: 4,
        label: 'Electoral',
        value: 'electoral',
        permission: 'SG Telco Electoral Filter'
      }
    ],
    'au_telco': [
      {
        id: 1,
        label: 'State',
        value: 'state'
      }, {
        id: 2,
        label: 'SA4',
        value: 'sa4'
      }, {
        id: 3,
        label: 'SA3',
        value: 'sa3'
      }
    ]
  },
  measureOptions: [
    {
      label: "Home",
      value: "home"
    }, {
      label: "Work",
      value: "work"
    }
  ],
  visualizationModesOptions: [
    {
      label: "Map",
      value: "map",
      icon: 'world'
    }, {
      label: "Chart",
      value: 'chart',
      icon: 'bar-chart'
    }
  ],
  formulaOptions: [
    {
      label: "Consumption",
      value: "consumption",
      tooltip: 'Geo consumption score represents how often a seed interest consumed in relation to a certain region, compared to the other displayed regions. The displayed region with the highest consumption is given a score of 100.'
    }, {
      label: "Skew",
      value: "skew",
      tooltip: 'The extent to which the seed interest is over-indexed within a certain region compared to the entire country.',
      tooltipSubGeos: 'The extent to which the seed interest is over-indexed within a certain region compared to all of the selected regions'
    }, {
      label: "Distribution",
      value: "distribution",
      tooltip: 'The % of a region out of the total interest consumption',
      tooltipSubGeos: 'The % of a region out of the total interest consumption in all of the selected regions'
    }
  ],
  topChartConfiguration: {
    skew: {
      groupYLocation: 10,
      topBarGroup: {
        examples: {},
        topBar: {
          precision: 2
        }
      }
    },
    distribution: {
      groupYLocation: 10,
      topBarGroup: {
        examples: {},
        topBar: {
          precision: 2,
          labelSuffix: '%'
        }
      }
    },
    consumption: {
      groupYLocation: 10,
      topBarGroup: {
        examples: {},
        topBar: {
          precision: 2
        }
      }
    }
  }
}
