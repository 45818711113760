"use strict";
module.exports = {
    convertAudienceSegmentToLogicStatement: convertAudienceSegmentToLogicStatement
};

var KEY_NAME_MAP = {"geo": "countries", "age": "ages", "gender": "genders", "children": "children", "income": "new-incomes", "ethnicity": "new-races"};
var DEMOGRAPHICS_FIELDS = ['age', 'geo', 'income', 'ethnicity', 'gender', 'children'];

function getStatementOperand(statement) {
    if (!Array.isArray(statement)) return 'none';
    return statement[0];
}

function isAndStatement(statement) {
    return getStatementOperand(statement) == 'and';
}

function isNotStatement(statement) {
    return getStatementOperand(statement) == 'not';
}

function isNoneStatement(statement) {
    return getStatementOperand(statement) == 'none';
}

function optimizeStatement(statement) {
    // [and, filter1, [and, filter2, filter3]] => [and, filter1, filter2, filter3]
    if (isNoneStatement(statement)) return statement;

    if (isAndStatement(statement)) {
        if (statement.length == 2 && !isNoneStatement(statement[1])) return statement[1];
        var optimizedLogicStatement = ["and"]
        statement.slice(1).forEach(function (s) {
            if (isAndStatement(s)) {
                optimizedLogicStatement = optimizedLogicStatement.concat(s.slice(1))
            }
            else {
                optimizedLogicStatement.push(optimizeStatement(s));
            }
        });

        return optimizedLogicStatement;
    }

    for (var i = 0; i < statement.length; i++) {
        statement[i] = optimizeStatement(statement[i])
    }

    return statement;
}

function convertInterestsAndWebsitesSegmentToLogicStatement(interests) {
    var filterType = interests.type == "interests" ? interests.levelOfIntent.value : "domains";
    var filters = _.pick(interests, ['required', 'included', 'excluded']);
    filters = _.mapValues(filters, function (filterVal, filterType) {
        return _.map(filterVal, 'id')
    });
    filters = _.mapValues(filters, function (filterVal) {
        return _.map(filterVal, val => ({[filterType]: (filterType == 'domains') ? val.toLowerCase() : val}))
    });
    var logicStatement = [];
    if (filters["required"]) logicStatement = logicStatement.concat(filters["required"]);
    if (filters["included"]) logicStatement.push(["or"].concat(filters["included"]));
    if (filters["excluded"]) logicStatement.push(["not"].concat(filters["excluded"]));

    return logicStatement.length == 1 ? logicStatement[0] : ["and"].concat(logicStatement);
}

function convertInterestsToLogicStatement(interests) {
    var searchedForInterests = convertInterestsAndWebsitesSegmentToLogicStatement(_.extend({}, interests, {levelOfIntent: {value: "iw"}}));
    return interests.levelOfIntent.value == 'searches' ? searchedForInterests : ["or", convertInterestsAndWebsitesSegmentToLogicStatement(interests), searchedForInterests]
}

function replaceValues(values, oldValue, newValues) {
    if (!_.includes(values, oldValue)) return;
    values.splice(values.indexOf(oldValue), 1);
    _.each(newValues, function (newVal) {
        values.push(newVal);
    });
}

function rebuildIncome(income) {
    let newIncome = [];

    _.each(income, (val) => {
        _.isArray(val) ? _.each(val, vv => newIncome.push(vv)) : newIncome.push(val)
    })
    return newIncome
}

function convertDemographicsSegmentToLogicStatement(demographics) {
    var filters = _.pick(demographics, DEMOGRAPHICS_FIELDS);

    filters = _.mapValues(filters, function (filterVal, filterType) {
        if (_.includes(['age', 'gender'], filterType)) return _.map(filterVal, 'summary').map(f => f == "13-17" ? "12-17" : f);
        if (_.includes(['children'], filterType)) return _.map(filterVal, function (children) {return children.label.toLowerCase();})
        if (_.includes(['ethnicity', 'income'], filterType)) return _.map(filterVal, 'value')
        if (_.includes(['geo'], filterType)) return _.map(filterVal, function (geo) {return geo.cc.toLowerCase();})
    });
    replaceValues(filters.income, "0-25k", ["0-15k", "15-25k"]);
    replaceValues(filters.income, "25-50k", ["25-35k", "35-50k"]);
    if (filters.income) {
        // Flatten income params - to accommodate server api
        filters.income = rebuildIncome(filters.income)
    }

    filters = _.mapKeys(filters, (val, key) => KEY_NAME_MAP[key] || key);
    filters = _.map(filters, function (filterVal, filterType) {
        var values = _.map(filterVal, function (v) {
            return {[filterType]: v}
        });
        return values.length == 1 ? values[0] : ["or"].concat(values);
    });
    return filters.length == 1 ? filters[0] : ["and"].concat(filters);
}

function convertSegmentToLogicStatement(segment) {
    if (segment.type == "demographics") return convertDemographicsSegmentToLogicStatement(segment);
    if (segment.type == "interests") return convertInterestsToLogicStatement(segment);
    if (segment.type == "websites") return convertInterestsAndWebsitesSegmentToLogicStatement(segment);
    if (segment.type == "lifestyle") return {intentions: segment.value};
}

function convertAudienceSegmentToLogicStatement(audienceSegment, isTv) {
    audienceSegment = _.cloneDeep(audienceSegment);
    _.each(audienceSegment, function (segment) {
        segment.operand = segment.operand || {value: "and"}
    });

    // When user "exclude" demographics with country, delete all geos from all segments and add an "require" segment with this geo
    var geo = _.get(_.filter(audienceSegment, function (segment) {
        return segment.type == "demographics";
    })[0], 'geo');

    if (geo) {
        geo.forEach(g => g.cc = `${g.cc}:bid_stream`);
        _.each(audienceSegment, function (segment) {delete segment.geo})
        _.remove(audienceSegment, function (segment) {return segment.type == "demographics" && _.isEmpty(_.pick(segment, DEMOGRAPHICS_FIELDS))})
        audienceSegment.push({geo: geo, type: "demographics", operand: {value: "and"}})
    }

    var segmentByOperand = _.groupBy(audienceSegment, 'operand.value');

    var andStatements = _.map(segmentByOperand["and"], function (segment) {
        return convertSegmentToLogicStatement(segment)
    });
    var orStatements = _.map(segmentByOperand["or"], function (segment) {
        return convertSegmentToLogicStatement(segment)
    });
    var notStatements = _.map(segmentByOperand["not"], function (segment) {
        return convertSegmentToLogicStatement(segment)
    });

    if (orStatements.length) orStatements = [["or"].concat(orStatements)];
    if (notStatements.length) notStatements = [["not"].concat(notStatements.length == 1 ? notStatements : [["and"].concat(notStatements)])];

    var logicalStatement = ["and"].concat(andStatements).concat(orStatements).concat(notStatements)
    if (isTv) logicalStatement.push({"any-tv": "yes"})

    return optimizeStatement(logicalStatement);
}
