"use strict";
var c = require("infra/utils/common");
module.exports = angular.module(__filename, []).controller('customAxisDialogController', ['$scope', '$state', 'close', 'customAxis',
    'currentAxesNames', 'metricOptions', '$timeout', 'notificator', 'util', 'geoService',
    function ($scope, $state, close, customAxis, currentAxesNames, metricOptions, $timeout, notificator, util, geoService) {
        var self = this;

        $scope.setGeos = function() {
            var selectedChannel = (($scope.customAxis || {}).channel || '').toLowerCase();
            if (selectedChannel == 'sg_telco') {
                $scope.geos = [{label: 'Singapore', id: '702', cc: 'SG'}];
            } else if (selectedChannel == 'au_telco') {
                $scope.geos = [{label: 'Australia', id: '036', cc: 'AU'}];
            } else {
                $scope.geos = angular.copy($scope.$root.Geos.geos);
            }
            showSubGeosByChannel(selectedChannel);
        };

        function showSubGeosByChannel(channel) {
            $scope.showSubGeos = $scope.$root.Geos.showSubGeos([channel]);
            if ($scope.showSubGeos) {
                $scope.$root.Geos.setSubGeos($scope, () => null, [channel]);
                $scope.dataTrees.subGeos.checkedArray = self.subGeosTreeHelperInstance.contextSubGeosHelper($scope.customAxis.sub_geos).getAllCheckedSubGeos();
                $scope.dataTrees.subGeos.show();
            } else {
                $scope.customAxis.sub_geos = [];
            }
        }

        $scope.toggleOpenTopicTree = function() {
            $scope.topicTreeOpen = !$scope.topicTreeOpen;
        };

        $scope.closeTopicTree = function() {
            $scope.topicTreeOpen = false;
        };

        $scope.toggleOpenSubGeosTree = function() {
            $scope.subGeosTreeOpen = !$scope.subGeosTreeOpen;
        };

        $scope.closeSubGeosTree = function() {
            $scope.subGeosTreeOpen = false;
        };

        $scope.validateAssociationByChannel = function() {
            if (!_.isEmpty($scope.customAxis.channel)
                && ((!_.isEmpty($scope.customAxis.associationInterest) && c.validateNonPhrases($scope.customAxis.associationInterest, $scope.customAxis.channel, notificator))
                || $scope.customAxis.channel === 'videos')) {
                $scope.customAxis.associationInterest = [];
            }
        };

        $scope.setMetrics = function() {
            $scope.metrics = (!$scope.customAxis.channel || $scope.customAxis.channel === 'tweets') ? metricOptions
                : _.filter(metricOptions, (option) => !(option.metric || '').endsWith('_sentiment'));
        };

        $scope.hideStrength = function() {
            var hideStrength = ($scope.customAxis || {}).metric && $scope.customAxis.metric != 'consumption' || _.isEmpty($scope.customAxis.associationInterest);
            if (hideStrength) $scope.customAxis.measure = 'index';
            return hideStrength;
        };

        function validateForm() {
            var axisName = !_.isEmpty($scope.customAxis.label);
            $scope.customAxisForm.label.$setValidity('required', axisName);
            var axisNameUnique = $scope.customAxis && !currentAxesNames.has(($scope.customAxis.label || '').toLowerCase());
            $scope.customAxisForm.label.$setValidity('axisNameNotUnique', axisNameUnique);
            var metricSelected =  !_.isEmpty($scope.customAxis.metric);
            $scope.customAxisForm.metricDropdown.$setValidity('noMetricSelected', metricSelected);
            var channelSelected =  !_.isEmpty($scope.customAxis.channel);
            $scope.customAxisForm.channelDropdown.$setValidity('noChannelSelected', channelSelected);
        }

        function formatChannels() {
            return _.map($scope.$root.insightsChannelsFilter, function(channel) {
                return _.extend({}, channel, {id: channel.value});
            });
        }
        $scope.deleteCustomAxis = function() {
            $scope.customAxis.delete = true;
            close($scope.customAxis);
        };

        $scope.cloneCustomAxis = function() {
            currentAxesNames.add($scope.customAxis.label);
            $scope.customAxis = angular.copy($scope.customAxis);
            $scope.title = `Edit '${$scope.customAxis.label}' (cloned)`;
            $scope.customAxis.label = '';
            $scope.customAxis.id = c.generateUUID();
        };

        $scope.saveCustomAxis = function() {
            validateForm();
            if ($scope.customAxisForm.$invalid) return;
            $scope.customAxis.geo = _.isEmpty($scope.customAxis.geoModel) ? angular.copy($scope.geos)
                : _.map($scope.customAxis.geoModel, (geoCode) => _.filter($scope.geos, (geo) => geo.id == geoCode)[0]);
            if (_.isEmpty($scope.customAxis.sub_geos)) {
                delete $scope.customAxis.sub_geos;
            }
            close(_.omit($scope.customAxis, ['geoModel']));
        };

        function initCustomAxis() {
            if (customAxis == undefined || customAxis.label == undefined) {
                $scope.action = 'create';
                customAxis = {};
                customAxis.id = c.generateUUID();
                customAxis.measure = 'index';
                customAxis.custom = true;
                $scope.title = 'Create a custom axis';
                $scope.dataTrees.topicDataTree.checkedArray = [];
            } else {
                $scope.action = 'update';
                $scope.title = "Edit '" + customAxis.label + "'";
                $scope.dataTrees.topicDataTree.checkedArray = customAxis.topics;
            }

            $scope.customAxis = angular.copy(customAxis);
            if (_.first($scope.customAxis.associationInterest) && !($scope.customAxis.associationInterest[0] || {}).hasOwnProperty('display')) {
                $scope.customAxis.associationInterest[0].display = util.getTermDisplay($scope.customAxis.associationInterest[0]);
            }
            $scope.dataTrees.topicDataTree.saveChecked = function (checkedArray) {
                $scope.customAxis.topics = _.map(_.filter(checkedArray, (t) => _.isNumber(t.id)),
                    (topic) => _.pick(topic, ['id', 'name']));
            };
            //override saveChecked so it won't change context.current.sub_geos
            self.subGeosTreeHelperInstance = geoService.indexedSubGeosTreeHelper($scope.$root.subGeosTree);
            $scope.dataTrees.subGeos.saveChecked = function (checkedArray) {
                $scope.customAxis.sub_geos = self.subGeosTreeHelperInstance.checkedArrayToContextSubGeos(checkedArray.filter((c) => c.type));
            };
            $scope.setGeos();
            $scope.setMetrics();
            $scope.channels = formatChannels();
            $scope.customAxis.geoModel = _.map(customAxis.geo, 'id');
        }

        function init() {
            $scope.close = close;
            initCustomAxis();
            $scope.dataTrees.topicDataTree.show();
        }

        init();
    }
]);
