"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [])
    .directive("amTimeframeFilter", ['$rootScope', '$state', '$window','abiPermissions', 'filtersPartition', 'context', 'notificator', 'CHANNEL', function ($rootScope, $state, $window,abiPermissions, filtersPartition, context, notificator, CHANNEL) {
        return {
            restrict: "E",
            template: require("./timeframe.html"),
            scope: {
                context: "=context",
                showDatePicker: '&',
                showTimePicker: '='
            },
            link: function (scope, elem, attr) {
                angular.element($window).bind('resize', () => scope.narrowMode = $window.innerWidth < 1279);
                scope.narrowMode = $window.innerWidth < 1279;
                scope.times = [
                    {
                        text: "1H",
                        amount: 1,
                        unit: "hour",
                        displayText: '1 hour',
                        relevantPages: ["discovery", "real time"],
                        first: ["discovery", "real time"],
                        excluded: ["custom sources"]
                    },
                    {text: "3H", amount: 3, unit: "hour", displayText: '3 hours', relevantPages: ["real time"]},
                    {text: "6H", amount: 6, unit: "hour", displayText: '6 hours', relevantPages: ["real time"]},
                    {text: "12H", amount: 12, unit: "hour", displayText: '12 hours', relevantPages: ["real time"], last: ["real time"]},
                    {text: "1D", amount: 1, unit: "day", displayText: '1 day', relevantPages: ["discovery"], excluded: ["custom sources"]},
                    {
                        text: "3D",
                        amount: 3,
                        unit: "day",
                        displayText: '3 days',
                        relevantPages: ["discovery"],
                        excluded: ["custom sources"],
                    },
                    {
                        text: "1W",
                        amount: 7,
                        unit: "day",
                        displayText: '1 week',
                        relevantPages: ["insights.consumption","discovery", "insights.timing"],
                        first: ["insights.timing", "custom sources"],
                        excludedTabsArticles: ["insights.timing"],
                        permissions: [{ page: "insights.consumption", key:"hourly consumption trends"}]
                    },
                    {
                        text: "1M",
                        amount: 1,
                        unit: "month",
                        displayText: '1 month',
                        relevantPages: ["discovery", "insights"],
                        first: ["insights"],
                        notFirstInTabChannel: [{tab: "insights.timing", channel: "tweets"}]
                    },
                    {
                        text: "3M",
                        amount: 3,
                        unit: "month",
                        displayText: '3 months',
                        relevantPages: ["discovery", "insights"],
                        last: ["discovery", "insights.timing"],
                        excludedTabsArticles: ["insights.geo"]
                    },
                    {
                        text: "6M",
                        amount: 6,
                        unit: "month",
                        displayText: '6 months',
                        relevantPages: ["insights"],
                        excludedTabs: ["insights.timing"],
                        excludedTabsSocial: ["insights.association"],
                        excludedTabsArticles: ["insights.geo"]

                    },
                    // when dealing with a year, the request is to compare current month to last year month, so amount = 13.
                    {
                        text: "1Y",
                        amount: 13,
                        unit: "month",
                        displayText: '1 year',
                        relevantPages: ["insights"],
                        last: ["insights"],
                        excludedTabs: ["insights.timing"],
                        excludedTabsSocial: ["insights.association"],
                        excludedTabsArticles: ["insights.geo"]

                    }
                ];
                var format = scope.getFormat = "DD_MM_YY_HH_mm";
                var channel_start_dates = {
                    'sg_telco': '01_09_16_00_00',
                    'au_telco': '25_03_17_00_00',
                    'articles/insights/geo': '01_09_17_00_00',
                    'sg_telco/insights/channels/source': '18_12_17_00_00',
                    'au_telco/insights/channels/source': '03_04_17_00_00'
                };

                var incomeValues = _.flatMap(filtersPartition.newIncome, 'value');
                var raceValues = ['white', 'asian'];

                scope.state = $state;

                var getMold = function () {
                    var page = $state.current.name.split('.')[0];
                    switch (page) {
                        case "discovery":
                            return context.current._discovery_timeframe_mold;
                        case "warroom":
                            return context.current._warroom_timeframe_mold;
                        default:
                            return context.current._insights_timeframe_mold;
                    }
                };

                scope.getTelcoChannel = function () {
                    return scope.isAUTelco() ? 'au_telco' :
                        scope.isSGTelco() ? 'sg_telco' :
                            '';
                };

                scope.isNoChannels = function () {
                    return c.getChannels($state, context).length == 0;
                };

                function isTelco(name) {
                    return c.getChannels($state, context).includes(name) &&
                        location.hash.indexOf("insights/timing") === -1 &&
                        location.hash.indexOf("insights/landscape") === -1;
                }

                scope.isAUTelco = function () {
                    return isTelco("au_telco");
                };

                scope.isSGTelco = function () {
                    return isTelco("sg_telco");
                };

                function setMinDatesForTelco() {
                    var sg = scope.isSGTelco(),
                        au = scope.isAUTelco(),
                        channel = scope.getTelcoChannel(),
                        channelStartDatesKey = isInsightsChannelsSourceTelco() ? `${channel}/insights/channels/source` : channel;

                    if (sg || au) {
                        scope.toMaxDate = moment().subtract(CHANNEL.daysShift(channel), 'days');
                        var start_date = channel_start_dates[channelStartDatesKey];
                        scope.fromMinDate = moment(start_date, format);
                        if (scope.toMinDate < scope.fromMinDate) {
                            scope.toMinDate = scope.fromMinDate;
                        }
                    }
                }

                scope.fromMinDate = moment().subtract(18, 'months');
                scope.toMaxDate = moment();
                if (location.hash.indexOf('insights/timing') !== -1) {
                    scope.toMaxDate = moment().subtract(1, 'days');
                }
                setMinDatesForTelco();
                scope.toMinDate = scope.fromMinDate;
                scope.fromMaxDate = scope.toMaxDate;

                var onChange = function (newVal, oldVal) {
                    var sg = scope.isSGTelco(),
                        au = scope.isAUTelco(),
                        channel = scope.getTelcoChannel();

                    scope.noTimeframe = _.isEmpty(scope.getTimes());
                    scope.toMaxDate = moment().subtract(CHANNEL.daysShift(channel), 'days');

                    if (isBubblesCustomSources()) {
                        scope.fromMinDate = moment().subtract(45, 'days');
                    } else if ($state.current.name.split('.')[0] === 'discovery') {
                        scope.fromMinDate = moment().subtract(3, 'months');
                    } else if (location.hash.indexOf('insights/geo') !== -1 && (context.current._insightsChannels_mold._value || {}).value == 'articles') {
                        scope.fromMinDate = moment(channel_start_dates['articles/insights/geo'], format);
                        if (scope.toMinDate < scope.fromMinDate) {
                          notificator.notify({body: 'Geos view data is available only from September 2017 onwards.'});
                        }
                    } else {
                        scope.fromMinDate = moment().subtract(18, 'months');
                        setMinDatesForTelco();
                    }

                    var mold = getMold();
                    if (!mold) {
                        return;
                    }

                    mold = mold._value;
                    var oldFromDate = scope.fromDate;

                    if (c.isNumber(mold[0])) {
                        const index = _.findIndex(scope.times, {amount: mold[0], unit: mold[1]});
                        scope.currentTime = scope.times[index];

                        scope.toDate = moment();
                        var unitAmount = {};
                        unitAmount[mold[1]] = mold[0];

                        scope.fromDate = moment().subtract(unitAmount);

                        if (location.hash.indexOf('insights/timing') !== -1) {
                            if (mold[1] == 'month') {
                                scope.toDate = moment().subtract(1, 'days');
                                scope.fromDate = moment(scope.fromDate).subtract(1, 'days');
                            } else if (mold[0] == 7) {
                                scope.toDate = moment().subtract(1, 'days');
                                scope.fromDate = moment().subtract(8, 'days');
                            }
                        }

                        if (!c.isNumber(mold[1]) && !IsCurrentSelectionAllowed(mold[1])){
                            setCustomTimeframe();
                        }

                        if (sg || au) {
                            scope.toDate = moment().subtract(CHANNEL.daysShift(channel), 'days');
                            scope.fromDate = moment().subtract(CHANNEL.daysShift(channel), 'days').subtract(unitAmount);
                            let channelStartDatesKey = isInsightsChannelsSourceTelco() ? `${channel}/insights/channels/source` : channel;
                            if(scope.fromDate < scope.fromMinDate){
                                if((sg && scope.fromDate < moment(channel_start_dates[channelStartDatesKey], format)) || (au && scope.fromDate < moment(channel_start_dates[channelStartDatesKey], format))) {
                                    scope.fromDate = scope.fromMinDate;
                                    getMold().replace([moment(scope.fromDate).format(format), moment(scope.toDate).format(format)]);
                                    notificator.notify({body: "No data exists for the selected timeframe. The timeframe's start date has been updated."});
                                } else {
                                    scope.fromMinDate = scope.fromDate;
                                }
                            }
                        }
                    } else {
                        scope.currentTime = scope.getTimes()[0];
                        if ((sg || au) && scope.fromDate && scope.fromDate < scope.fromMinDate) {
                            var diffMonths = scope.toDate.diff(scope.fromDate, 'months');
                            var diffDays = scope.toDate.diff(scope.fromDate.add(diffMonths, 'month'), 'days');
                            var notificationMessage = "No data exists for the selected timeframe. The timeframe's start date has been updated.";
                            scope.fromDate = scope.fromMinDate.clone();
                            if (scope.toDate < scope.fromDate) {
                                scope.toDate = scope.fromDate.clone().add({'month': diffMonths, 'day': diffDays});
                                if (scope.toDate > moment().subtract(CHANNEL.daysShift('sg_telco'), 'days')) {
                                    scope.toDate = moment().subtract(CHANNEL.daysShift('sg_telco'), 'days');
                                    scope.toMinDate = scope.toDate.clone();
                                }
                                notificationMessage = "No data exists for the selected timeframe. The timeframe's start date and end date have been updated.";
                            }
                            notificator.notify({body: notificationMessage});
                        } else {
                            scope.fromDate = scope.roundDownHalfHour(mold[0]);
                            if (scope.fromDate < scope.fromMinDate) {
                                scope.fromDate = scope.fromMinDate;
                            }
                            scope.toDate = scope.roundDownHalfHour(mold[1]);
                            if (scope.toDate < scope.toMinDate) {
                                scope.toDate = scope.toMinDate;
                            }
                        }
                        if (moment(scope.toDate) > scope.toMaxDate) {
                            notificator.notify({body: "Channel displays only data older than two days. Timeframe's end time has been updated"});
                            setCustomTimeframe();
                        } else {
                            if (moment(scope.toDate) > moment(scope.fromDate).add({'month': 13}) &&
                                location.hash.indexOf("insights/timing") === -1 && //Timing and association social have their own timeframe validation
                                (location.hash.indexOf("insights/geo") === -1 || scope.context.current.insightsChannels.value !== "articles") &&
                                (location.hash.indexOf("insights/association") === -1 || scope.context.current.insightsChannels.value !== "tweets")) {
                                scope.toDate = moment(scope.fromDate).add({'month': 13});
                                notificator.notify({body: "Maximum date span is 13 months. Timeframe was adjusted."});
                            }
                            getMold().replace([moment(scope.fromDate).format(format), moment(scope.toDate).format(format)]);
                        }
                    }

                    scope.fromMaxDate = scope.toDate;
                    scope.toMinDate = scope.fromDate;

                    if (audienceAllowed() && moment(scope.fromDate) < moment('2017-05-21')) {
                        var notifyIncome = false,
                            notifyRace = false,
                            audienceValues = _.map(context.current.audience, 'value');

                        if (moment(oldFromDate) > moment('2017-05-21')) {
                            if (isIncomeSelected(audienceValues)) notifyIncome = true;
                            if (isRaceSelected(audienceValues)) notifyRace = true;
                        }

                        if (oldVal && oldVal.audience && newVal && newVal.audience) {
                            var oldAudience = _.map(oldVal.audience, 'value'),
                                newAudience = _.map(newVal.audience, 'value');

                            if (!isIncomeSelected(oldAudience) && isIncomeSelected(newAudience)) notifyIncome = true;
                            if (!isRaceSelected(oldAudience) && isRaceSelected(newAudience)) notifyRace = true;
                        } else {
                            if (isIncomeSelected(audienceValues)) notifyIncome = true;
                            if (isRaceSelected(audienceValues)) notifyRace = true;
                        }

                        if (notifyIncome) notificator.notify({body: "Sorry, filtering by Audience Income is available only on data from 05/21. Please refine your settings accordingly"});
                        if (notifyRace) notificator.notify({body: "Sorry, filtering by Asian American or Caucasian is available only on data from 05/21. Please refine your settings accordingly"});
                    }

                    $rootScope.$broadcast('timeframe-updated');
                };

                function audienceAllowed() {
                    return $state.includes('discovery') || (context.current.insightsChannels.value == 'articles'
                        && ($state.includes('*.consumption')
                        || $state.includes('*.channels')
                        || $state.includes('*.association')
                        || $state.includes('*.topic')));
                }

                function isIncomeSelected(audience) {
                    return _.intersection(incomeValues, _.flatten(audience)).length > 0;
                }

                function isRaceSelected(audience) {
                    return _.intersection(raceValues, audience).length > 0;
                }

                function containsLocation(arr) {
                    var page = $state.current.display.toLowerCase();
                    var tab = $state.current.name.toLowerCase();
                    return c.isArray(arr) &&
                        _.reduce(arr, function (result, loc) {
                            return result || [page, tab].indexOf(loc) !== -1
                        }, false);
                }

                scope.roundDownHalfHour = function (timeStr) {
                    var time = moment(timeStr, format);
                    var minutes = time.get('minute');
                    if (minutes >= 30) {
                        minutes -= 30;
                    }

                    time = time.subtract({'minutes': minutes});
                    return moment(time);
                };

                function setCustomTimeframe(){
                    getMold().replace([moment(scope.fromDate).format(format), moment(scope.toMaxDate).format(format)]);
                }

                function isStreamsCustomSources() {
                    return location.hash.indexOf("discovery/streams") > -1 && context.current.showCustomSources;
                }

                function isBubblesCustomSources() {
                    return location.hash.indexOf("discovery/bubbles") > -1 && context.current.bubblesShowCustomSources;
                }

                function includedInChannelDates(time, channel) {
                    return moment().subtract(time.amount, time.unit) > moment(channel_start_dates[channel], format);
                }

                function isInsightsChannelsSourceTelco() {
                    return location.hash.indexOf('insights/channels') !== -1 && context.current.isReferralsSourceTelco;
                }

                function IsCurrentSelectionAllowed(unit){
                    return _.find(scope.getTimes(), (time) => time.unit === unit);
                }

                scope.filterTime = function (time) {
                    var page = $state.current.display.toLowerCase();
                    var tab = $state.current.name.toLowerCase();
                    var relevantForPage = time.relevantPages.indexOf(page) !== -1 || time.relevantPages.indexOf(tab) !== -1;
                    var excluded = time.hasOwnProperty('excludedTabs') && time.excludedTabs.indexOf(tab) !== -1;
                    var excludedInTabForSocial = time.hasOwnProperty('excludedTabsSocial') && time.excludedTabsSocial.indexOf(tab) !== -1;
                    var excludedInTabForArticles = time.hasOwnProperty('excludedTabsArticles') && time.excludedTabsArticles.indexOf(tab) !== -1;
                    var isSocial = (context.current._insightsChannels_mold._value || {}).value == 'tweets';
                    var isArticles = (context.current._insightsChannels_mold._value || {}).value == 'articles';
                    var permission = _.find(time.permissions, function(p){
                        return p.page == page || p.page == tab;
                    });
                    var hasPermission = !permission || abiPermissions.hasPermission(permission.key);
                    return relevantForPage && !excluded && !(excludedInTabForSocial && isSocial) && !(excludedInTabForArticles && isArticles) && hasPermission;
                };

                scope.hasTimes = function () {
                    var has_times = false;
                    _.each(scope.times, function (time) {
                        has_times = has_times || scope.filterTime(time);
                    });
                    return has_times;
                };

                scope.hideTimeframes = function (time) {
                    var context = scope.context.current;
                    var discovery_timeframe = context._discovery_timeframe_mold._value;
                    var sg = scope.isSGTelco(), au = scope.isAUTelco();
                    if (time.text === '3M' && (scope.isFacebook() ||
                        (location.hash.indexOf("discovery/bubbles") > -1 && context.bubblesChannels && (context.bubblesChannels.value === 'tweets' || context.bubblesShowCustomSources)))) {
                        // change timeframe if 3 month was selected
                        if (discovery_timeframe && discovery_timeframe[0] == 3 && discovery_timeframe[1] == "month") {
                            scope.context.current._discovery_timeframe_mold.replace([1, "month"]);
                        }
                        return true;
                    }

                    if (time.text === '1H') {
                        if (scope.isNoChannels()) {
                            if (discovery_timeframe && discovery_timeframe[0] == 1 && discovery_timeframe[1] == "hour") {
                                scope.context.current._discovery_timeframe_mold.replace([1, "day"]);
                            }
                            return true;
                        } else if (au) {
                            if (discovery_timeframe && discovery_timeframe[0] == 1 && discovery_timeframe[1] == "hour") {
                                scope.context.current._discovery_timeframe_mold.replace([CHANNEL.daysShift('au_telco'), "day"]);
                            }
                            return true;
                        } else if (location.hash.indexOf("discovery/grid") > -1 && context.bubblesChannels && context.bubblesChannels.value === 'tweets') {
                            if (discovery_timeframe && discovery_timeframe[0] == 1 && discovery_timeframe[1] == "hour") {
                                scope.context.current._discovery_timeframe_mold.replace([1, "day"]);
                            }
                            return true;
                        }
                    }

                    if (isStreamsCustomSources() && (time.text === '1H' || time.text === '1D' || time.text === '3D')) {
                        var tf_str = discovery_timeframe.join(' ');
                        if (tf_str == '1 hour' || tf_str == '1 day' || tf_str == '3 day') {
                            scope.context.current._discovery_timeframe_mold.replace([7, "day"]);
                        }
                        return true;
                    }

                    if (sg && (time.text === '1H')) {
                        if ((discovery_timeframe && discovery_timeframe[0] == 1 && discovery_timeframe[1] == "hour")) {
                            scope.context.current._discovery_timeframe_mold.replace([3, "day"]);
                        }
                        return true;
                    }

                    if ((time.text === '1Y') && ((au && !includedInChannelDates(time, 'au_telco')) || (sg && !includedInChannelDates(time, 'sg_telco')))) {
                        return true;
                    }

                    return (isInsightsChannelsSourceTelco()
                        && ((au && !includedInChannelDates(time, 'au_telco/insights/channels/source'))
                        || (sg && !includedInChannelDates(time, 'sg_telco/insights/channels/source'))));
                };

                scope.isFacebook = function () {
                    return (location.hash.indexOf("discovery/bubbles") > -1 || location.hash.indexOf("discovery/grid") > -1)
                        && scope.context.current.bubblesChannels && scope.context.current.bubblesChannels.value === 'facebook';
                };

                scope.setTime = function (chosenTime) {
                    if (!chosenTime) return;
                    getMold().replace([chosenTime.amount, chosenTime.unit]);
                    scope.fromMinDate = moment().subtract(18, 'months');
                    scope.toMaxDate = moment();
                    if (location.hash.indexOf('insights/timing') !== -1) {
                        scope.toMaxDate = moment().subtract(1, 'days');
                    }

                    if (location.hash.indexOf('insights/geo') !== -1 && (context.current._insightsChannels_mold._value || {}).value == 'articles') {
                        scope.fromMinDate = moment(channel_start_dates['articles/insights/geo'], format)
                    }
                    setMinDatesForTelco();
                };

                scope.getCss = function (time) {
                    if (!time) return;
                    var fromContext;
                    var res = "";
                    var locationStr = "";
                    if (location.hash.indexOf("discovery") > -1) {
                        fromContext = scope.context.current._discovery_timeframe_mold;
                        locationStr = "discovery";
                    } else if (location.hash.indexOf("warroom") > -1) {
                        fromContext = scope.context.current._warroom_timeframe_mold;
                        locationStr = "warroom";
                    } else {
                        fromContext = scope.context.current._insights_timeframe_mold;
                        locationStr = "insights";
                    }

                    var customSourcesTime = isStreamsCustomSources() && _.includes(time.first, 'custom sources');
                    var firstInTabChannel = !_.some(time.notFirstInTabChannel, { 'tab': $state.current.name.toLowerCase(), 'channel': (context.current._insightsChannels_mold._value || {}).value });

                    if (containsLocation(time.first) && firstInTabChannel || customSourcesTime) {
                        res += "first ";
                    }

                    if (containsLocation(time.last) && !containsLocation(time.notLast)) {
                        res += "last ";
                    }

                    if (fromContext && fromContext._value[0] === time.amount && fromContext._value[1] === time.unit) {
                        res += "chosenTime ";
                        if (time.first === locationStr) {
                            res += "chosenFirst ";
                        }
                    }
                    return res;
                };

                scope.setFromDate = function ($date, $value, fromString) {
                    scope.changedFromDate = true;
                    getMold().replace([scope.fromDate.format(format), scope.toDate.format(format)]);
                };
                scope.setToDate = function ($date, $value, fromString) {
                    scope.changedFromDate = false;
                    getMold().replace([scope.fromDate.format(format), scope.toDate.format(format)]);
                };

                scope.getPickerCss = function () {
                    var fromContext = scope.context.current._insights_timeframe_mold;
                    if (location.hash.indexOf("discovery") > -1) fromContext = scope.context.current._discovery_timeframe_mold;
                    if (fromContext && !c.isNumber(fromContext._value[0])) {
                        return "selected";
                    }
                };

                scope.getTimes = () => scope.times.filter(scope.filterTime).filter(time => !scope.hideTimeframes(time));

                onChange(null, null);
                scope.context.onChange(onChange);
                $rootScope.$on("$stateChangeSuccess", onChange);
                scope.narrowModeOpen = false;
                scope.narrowModeChanged = () => scope.narrowModeOpen = !scope.narrowModeOpen;
                scope.closeNarrowModeOption = () => scope.narrowModeOpen = false;
            }
        }
    }]
);
