"use strict";
module.exports = angular.module(__filename, [
  require("./am-sub-nav").name
]).component('amNavButton', {
  template: require('./am-nav-button.html'),
  require: {
    bar: '^amNavBar'
  },
  bindings: {
    icon: '@',
    label: '@',
    permission: '@',
    link: '@',
    linkState: '<',
    highlight: '@',
    noHighlight: '@'
  },
  transclude: {
    'slot' : '?amSubNav'
  },
  controller: ['$rootScope', '$scope', '$element', '$transclude', 'abiPermissions', 'contactUs', function($rootScope, $scope, $element, $transclude, abiPermissions, contactUs) {
    var self = this;
    self.$id = $scope.$id;

    self.hasPermission = abiPermissions.hasPermission;

    self.$onInit = function() {
      self.selected = false;
      self.routerLink = self.getLink();
      self.highlightLinkRe = self.getHighlightLinkRe();
      self.disabled = self.disable(!self.hasPermission(self.permission));
      self.subAppsAmount = 0;
      self.hasSubApps = $transclude.isSlotFilled('slot');
      self.qtipApi = null;
      if (self.disabled && !self.hasPermission(['show blocked applications in UI'])) {
        $element.remove();
      } else {
        self.bar.register(self);
      }

      if (self.noHighlight !== undefined) {
        $scope.$broadcast("am-nav-button:no-highlight", self.noHighlight);
      }

      if (self.bar.chosen == self.label) {
        self.setSelected(true);
      }

      self.unregisterSetLink = $scope.$on('am-nav-bar:link:set', function(event, value) {
        if (self.highlightLinkRe.test(value) && (!self.noHighlight)) {
          event.preventDefault();
          self.setSelected(true);
        };
      });
      self.unregisterSubNavAmount = $scope.$on('am-sub-nav:link:amount', function(event, value) {
        self.subAppsAmount = value;
      });
      self.unregisterSubNavLink = $scope.$on('am-sub-nav:link:set', function(event, value) {
        self.subNavLink = value;
        self.routerLink = self.disabled ? '.' : self.getLink();
        self.highlightLinkRe = self.getHighlightLinkRe();
      });
      self.unwatchMenuOpen = $rootScope.$watch('menuOpen', function(newValue, oldValue) {
        if (newValue == oldValue || !self.qtipApi) return;
        self.qtipApi.apiPromise().then(function(api) {
          var my = newValue ? "bottom center" : "left center";
          var at = newValue ? "top center" : "right center";
          var x  = newValue ? 0 : -10;
          var y  = newValue ? 10 :  0;
          api.set({
            "position.my" : my,
            "position.at" : at,
            "position.adjust.x" : x,
            "position.adjust.y" : y
          });
        });
      });
    };

    self.isOpen = function() {
      return $rootScope.menuOpen;
    };

    self.getHighlightLinkRe = function() {
      var link = self.highlight || self.getLink(false);
      return new RegExp("^"+_.escapeRegExp(link) + "(\\.|$)");
    };

    self.getLink = function(withSubNavLink = true) {
      var link = self.link || self.linkState;
      if (withSubNavLink) {
        link = link || self.subNavLink;
      }
      return link || ".";
    };

    self.disable = function(value) {
      if (!arguments.length) return self.disabled;
      self.disabled = value;
      self.routerLink = value ? '.' : self.getLink();
      return value;
    };

    self.setSelected = function(value) {
      self.selected = value;
      if (value) {
        self.bar.setSelectedButtonById(self.$id);
      }
    }

    self.onClick = function($event) {
      if (self.disabled) {
        $event.stopPropagation();
        contactUs.show({showTo: false, subject: "Brand Intelligence Upgrade Request", msgPlaceholder: "Anything you'd like us to know?", showAttachments: false});
      }
    };

    self.onMouseEnter = function($event) {
      $scope.$broadcast("am-nav-button:mouseenter");
    };

    self.onMouseLeave = function($event) {
      $scope.$broadcast("am-nav-button:mouseleave");
    };

    self.$onDestroy = function() {
      self.unregisterSubNavLink();
      self.unregisterSubNavAmount();
      self.unwatchMenuOpen();
      self.unregisterSetLink();
    };
  }]
});
