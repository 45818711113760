"use strict";
require('qtip2');

var DEFAULT_SETTINGS = {
    position: {
        viewport: $(window),
        my: 'bottom center',
        at: 'center',
        adjust: {screen: true}
    },
    show: 'click',
    overwrite: true,
    hide: 'unfocus'
};

module.exports = angular.module(__filename, [])
    .service("popupService", ["$compile", "$rootScope", "$timeout", function ($compile, $rootScope, $timeout) {
        return {
            pop: function (elem, data, settings_override, template, bindings) {
                var newScope = $rootScope.$new(true);
                $rootScope.$applyAsync(function () {
                    angular.extend(newScope, data);
                    var popup_html = $compile(template)(newScope);

                    $timeout(function () {
                        var settings = $.extend(true, {content: popup_html}, DEFAULT_SETTINGS, settings_override);
                        elem.qtip(settings);

                        if (bindings) {
                            for (var objId in bindings) {
                                popup_html.find("#" + objId).click(bindings[objId]);
                            }
                        }
                    }, 0)
                })
            }
        }
    }]
);
