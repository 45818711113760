"use strict";
var d3 = require("d3"),
    c = require("infra/utils/common"),
    TopBar = require("./top-bar"),
    add_tooltip = require('../tooltip/tooltip.js');

var BARS_HORIZONTAL_MARGIN = 45;
var BARS_VERTICAL_MARGIN = 8;
var CONTENT_VERTICAL_MARGIN = BARS_VERTICAL_MARGIN * 3;
var REMOVE_BUTTON_WIDTH = 9;
var REMOVE_BUTTON_LEFT_MARGIN = 5;
var MAX_LABEL_LENGTH = 25;
var ICON_WIDTH = 21;
var ICON_HEIGHT = 18;
var ICON_MARGIN = 2;
var SMALL_ICON_WIDTH = 12;
var SMALL_ICON_HEIGHT = 11;
var DEFAULT_FILL = "#81878c";
var HOVER_FILL = "#ffffff";
var NO_FILL = "#151719";
var TRIANGLE_RIGHT_ICON = "/images/icons/Triangle_Right.svg";
var TRIANGLE_DOWN_ICON = "/images/icons/Triangle_Down.svg";
TopBarGroup.LABEL_WIDTH = 250;
TopBarGroup.LABEL_LEFT_MARGIN = REMOVE_BUTTON_LEFT_MARGIN + REMOVE_BUTTON_WIDTH + 15;
TopBarGroup.BARS_LEFT_POS = TopBarGroup.LABEL_WIDTH + BARS_HORIZONTAL_MARGIN;
TopBarGroup.DEFAULT_CLASS = 'trending-default';
TopBarGroup.SPLIT_BAR_DIVIDER_OFFSET = 10;

var BUTTON_ATTRS = {
    "graph" : {
        "path" : [
            { 'd' : "M1.102 12.695h3.305v3.305h-3.305v-3.305z" },
            { 'd' : "M5.508 10.492h3.305v5.508h-3.305v-5.508z" },
            { 'd' : "M9.914 8.289h3.305v7.711h-3.305v-7.711z" },
            { 'd' : "M14.32 6.086h3.305v9.914h-3.305v-9.914z" },
            { 'd' : "M15.194 2.431h-3.259l-4.282 3.211-2.22-1.11-5.433 4.526v1.434l5.583-4.654 2.187 1.093 4.531-3.398h3.35l1.975-1.975v-1.558z" }
        ]
    },
    "content_drivers" : {
        "rect" : [
            {'x' : 10, 'y' : 3, 'width' : 11, 'height' : 3},
            {'x' : 10, 'y' : 8, 'width' : 11, 'height' : 3},
            {'x' : 10, 'y' : 13, 'width' : 11, 'height' : 3},
            {'x' : 5, 'y' : 3, 'width' : 3, 'height' : 3},
            {'x' : 5, 'y' : 8, 'width' : 3, 'height' : 3},
            {'x' : 5, 'y' : 13, 'width' : 3, 'height' : 3}
        ]
   }
};


function paintButton(button,type,no_background_rect){
    if(!BUTTON_ATTRS.hasOwnProperty(type)) return;
    if(!no_background_rect){
        var rect = button.append('rect')
                         .attr('x',0)
                         .attr('y',0)
                         .attr('width',ICON_WIDTH)
                         .attr('height',ICON_HEIGHT)
                         .attr('fill',NO_FILL)
                         .classed('graph-button-rect',true)
    }
    $.each(BUTTON_ATTRS[type], function(elem_type,attr_arr){
        $.each(attr_arr,function(index,attrs){
            var element = button.append(elem_type).attr("fill",DEFAULT_FILL);
            $.each(attrs,function(key,value){
               element.attr(key,value);
            });
        });
        button.on('mouseover',function(){
            d3.select(this).selectAll(elem_type + ':not(.graph-button-rect)').attr("fill",HOVER_FILL);
        });
        button.on('mouseout',function(){
            d3.select(this).selectAll(elem_type + ':not(.graph-button-rect)').attr("fill",DEFAULT_FILL);
        });
    });
}

function enrichBarDisplayData(data, barWidth){
  var num_terms = data.length;
  var current_start_position = 0;
  _.each(data, function (record,index){
    record.width = index == num_terms - 1 ? barWidth - current_start_position : Math.round((barWidth + 1 - num_terms) * record.share) ; //Last item gets remainder of bar width
    var name = record.display ? record.display : record.id;
    record.title = name ? name + " " + (record.share * 100).toFixed(1) + "%" : "";
    record.start = current_start_position;
    current_start_position += record.width + (record.width > 0 ? 2 : 0);
  });
  return data;
}


function TopBarGroup(g, containing_object) {
  this.configuration = containing_object.configuration.topBarGroup || {};
  this.g = g;
  this.x = containing_object.x;
  this.barWidth = containing_object.barWidth;
  this.split_bars = {};
  this.share_bar_tooltips = {};
  this.element = d3.select(this.g);
  this.remove = containing_object.remove;
  this.type = containing_object.type;
  this.expandFunction = containing_object.onBarGroupExpand;
  this.startPosition = TopBar.DEFAULT_VIEW_TYPE != this.type ? TopBarGroup.BARS_LEFT_POS + this.barWidth - TopBar.DIVIDER_OFFSET - TopBarGroup.SPLIT_BAR_DIVIDER_OFFSET : TopBarGroup.BARS_LEFT_POS;
  this.width = TopBarGroup.BARS_LEFT_POS + (this.x.range()[1] - this.x.range()[0]) + BARS_HORIZONTAL_MARGIN;
  this.disabled = false;
  var self = this;

  if (this.configuration.graph || this.configuration.examples) {
        this.background = this.element.append("rect")
            .classed("selected-row", function(d) { return d.selected; })
            .on('click',function(d){
                if(d3.select(this).classed("selected-row")) {
                    d3.event.stopPropagation();
                }
            });
        this.element
            .on("mouseover", function() {
                self.background.classed("hover", true);
            }).on("mouseout", function() {
                self.background.classed("hover", false);
            });
    }

    if (this.configuration.graph) {
        this.graphButton = this.element
            .append("svg")
            .classed("graph-button", true)
            .attr('width', ICON_WIDTH).attr('height', ICON_HEIGHT)
            .on('click', function (d) {
                d3.selectAll(".selected-row").classed("selected-row", false).each(function(d) { d.selected = false; });
                self.configuration.graph.showGraph(d);
                self.background.classed("selected-row", d.selected = true);
                d3.event.stopPropagation();
            });

        paintButton(this.graphButton, 'graph');
    }

    if (this.configuration.examples) {
      this.contentDriversButton = this.element
        .append("svg")
        .classed("graph-button", true)
        .attr('width', ICON_WIDTH).attr('height', ICON_HEIGHT)
        .on('click', function(d) {
          d3.selectAll(".selected-row").classed("selected-row", false).each(function(d) { d.selected = false; });
          self.configuration.examples.showExamples(d);
          self.background.classed("selected-row", d.selected = true);
          d3.event.stopPropagation();
        });

      paintButton(this.contentDriversButton,'content_drivers');
    }

    if (this.configuration.clickableLabel) {
        this.labelLinkElement = this.element
            .append("a")
            .attr("xlink:href", (d) => d.url)
            .attr("target", "_blank");
    }

    this.label = (this.labelLinkElement || this.element)
        .append("text")
        .attr("text-anchor", "start")
        .classed("cluster-label", true)
        .on('click',function(d){
            if (self.background && self.background.classed("selected-row")) {
                d3.event.stopPropagation();
            }
        });

    if (this.remove) {
      this.remove_button = this.element
          .append("text")
          .html("&times;")
          .attr("text-anchor", "start")
          .classed("remove-button", true)
          .on('click', function(d) {
            if (self.disabled) return;
            self.disabled = true;
            if(self.background) {
              self.background.classed("selected-row", d.selected = false);
            }
            self.remove(d);
          })
    }

    if (this.configuration.iconLabels) {
      this.icon_label = this.element
        .append("svg:image")
        .attr('width', ICON_WIDTH).attr('height', ICON_HEIGHT)
        .attr("xlink:href",function(d){
          return self.configuration.iconLabels[d.label];
        })
    }

    this.content = this.element.append("g")
      .classed("cluster-content", true)
      .attr("transform", "translate(0," + CONTENT_VERTICAL_MARGIN + ")");

    this.bars_group = this.content.append("g")
      .attr("transform", function(d, i) {
        return "translate(" + TopBarGroup.BARS_LEFT_POS + ",0)";
      });
}

TopBarGroup.prototype.setData = function(data, type, measure) {

  this.data = data.values;
  this.children = data.children;
  this.open = data.open;
  var self = this;

  this.label.html(c.cutText(data.label, MAX_LABEL_LENGTH, '...'));
  if(data.label.length > MAX_LABEL_LENGTH) {
    this.label.attr("title", data.label);
    add_tooltip(this.label, 'info', {style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}}});
  }

  if(measure === 'sov'){
    this.data = enrichBarDisplayData(this.data, this.barWidth);
    var split_bar_element = this.bars_group.append("g").attr("class", "split-bar");
    var bars = split_bar_element.selectAll(".share-bar")
      .data(this.data, function (x) {
        return x.id;
      });

    bars.transition().duration(TopBar.TRANSITION_DURATION)
      .each(function (d) {
        self._setBarElement(this, d);
      });

    bars.enter().append("rect")
      .attr("class", "share-bar")
      .each(function (d) {
        self._setBarElement(this, d);
      });

    bars.exit().each(function (d){
      let api = self.share_bar_tooltips[d.id];
      if (api) api.destroy(true);
    }).remove();

    this.height = TopBarGroup.height(1);
  } else {
    var split_bars = this.bars_group.selectAll(".split-bar")
      .data(this.data, function (x) {
        return x.id == -1 ? x.label : x.id;
      });

    split_bars.attr("transform", function (d, i) {
      return "translate(" + (d.parent_id ? -10 : 0) + "," + (i * (TopBar.HEIGHT + BARS_VERTICAL_MARGIN)) + ")";
    }).each(function (d) {
      self.split_bars[d.id].setData(d, type, measure);
    });

    split_bars.enter().append("g")
      .attr("class", "split-bar")
      .attr("transform", function (d, i) {
        return "translate(" + (d.parent_id ? -10 : 0) + "," + (i * (TopBar.HEIGHT + BARS_VERTICAL_MARGIN)) + ")";
      }).each(function (d) {
        var split_bar;
        split_bar = new TopBar(this, self.x, self.barWidth, type, self.configuration.topBar);
        split_bar.setData(d, type, measure);
        return self.split_bars[d.id] = split_bar;
      });

    split_bars.exit()
      .each(function (d) {
        self.split_bars[d.id].destroy();
        return delete self.split_bars[d.id];
      }).remove();

    this.height = TopBarGroup.height(this.data.length);

    if(data.is_parent){
      if(this.expandButton){
          this.expandButton.remove();
      }

      this.expandButton = this.element.append("image").attr("class","expand-children")
        .attr("width", SMALL_ICON_WIDTH).attr("height", SMALL_ICON_HEIGHT)
        .attr("xlink:href", self.open ? TRIANGLE_DOWN_ICON : TRIANGLE_RIGHT_ICON)
        .attr("y", (this.height - SMALL_ICON_HEIGHT) / 2)
        .attr("x", ICON_WIDTH - SMALL_ICON_WIDTH);

      this.label.classed("parent-label", true);

      function onClickExpand(){
        self.open = !self.open;
        if(typeof self.expandFunction == 'function'){
          self.expandFunction(data.id, self.open);
        }
        self.element.classed("open", self.open);
        self.expandButton.classed("open", self.open).attr("xlink:href", self.open ? TRIANGLE_DOWN_ICON : TRIANGLE_RIGHT_ICON);
        d3.event.stopPropagation();
      }

      this.expandButton.on("click", onClickExpand);
      this.label.on("click", onClickExpand);
      if(this.background) {
        this.background.on("click", onClickExpand);
        this.background.classed("parent-background", true);
      }
    } else {
      this.content.attr("transform", "translate(-10," + CONTENT_VERTICAL_MARGIN + ")");
    }
  }

  if (TopBar.isSplitted(type)) {
    this.divider = this.element.append("path")
      .classed("split-bar-cluster-divider", true)
      .attr("stroke-width", TopBar.DIVIDER)
      .attr("transform", "translate(" + (this.startPosition + TopBar.DIVIDER/2 + this.x(0)) + ",0)");
    this.divider.transition().duration(TopBar.TRANSITION_DURATION)
      .attr("d", "M0,0V" + (this.height))
      .attr("stroke-width", TopBar.DIVIDER)
      .attr("transform", "translate(" + (this.startPosition + TopBar.DIVIDER/2 + this.x(0)) + ",0)");
  }

  this.label.style('text-anchor', 'start')
  .attr("x", TopBarGroup.LABEL_LEFT_MARGIN)
  .attr("text-anchor", "start")
  .attr("y", (this.height / 2 + 2));

  if (this.remove) {
    this.remove_button
    .style('text-anchor', 'start')
    .attr("x", REMOVE_BUTTON_LEFT_MARGIN)
    .attr("text-anchor", "start")
    .attr("y", this.height / 2);
  }

  if (this.graphButton) {
    this.graphButton
    .attr("y", (this.height - ICON_HEIGHT) / 2)
    .attr("x", TopBarGroup.LABEL_WIDTH - ICON_WIDTH - ICON_MARGIN);
  }

  if (this.contentDriversButton) {
    this.contentDriversButton
    .attr("y", (this.height - ICON_HEIGHT) / 2)
    .attr("x", TopBarGroup.LABEL_WIDTH);
  }

  if (this.expandButton) {
    this.label.attr("x", TopBarGroup.LABEL_LEFT_MARGIN + SMALL_ICON_WIDTH);
  }

  if (this.icon_label) {
    this.icon_label
    .attr("x", 0)
    .attr("y", (this.height - ICON_HEIGHT) / 2);
  }

  if (this.background) {
    this.background
    .attr("width", TopBarGroup.LABEL_WIDTH + ICON_WIDTH + 10)
    .attr("height", this.height - 20)
    .attr("y", 10)
    .attr("rx", 4)
    .attr("ry", 4);

    this.background.classed("group-background", true);
  }

};

TopBarGroup.prototype._setBarElement = function(bar, record){
  let self = this;
  d3.select(bar)
    .attr("x", record.start)
    .attr("class", "share-bar " + (c.isString(record.class) ? record.class : TopBarGroup.DEFAULT_CLASS))
    .attr("height", TopBar.HEIGHT)
    .attr("width", record.width)
    .attr("title", record.title)
    .on("mouseover", function(){d3.event.stopPropagation()})
    .on("mouseout", function(){d3.event.stopPropagation()});
  if (record.title !== "") {
    add_tooltip(bar, 'info', {style: {classes: 'common-tooltip-info'}, prerender: true,
      events: {
        render: function(event, api) {
          self.share_bar_tooltips[record.id] = api;
        }
     }
    });
  }
};

TopBarGroup.prototype.destroy = function() {
  _.each(this.split_bars, (bar) => bar.destroy());
  _.each(this.share_bar_tooltips, (api) => api.destroy(true));
  this.element.selectAll("*").remove();
};

TopBarGroup.height = function(size) {
  return (size * TopBar.HEIGHT) + ((size - 1) * BARS_VERTICAL_MARGIN + (CONTENT_VERTICAL_MARGIN * 2));
};

TopBarGroup.prototype.setDomain = function(x) {
  this.x = x;
};

module.exports = TopBarGroup;
