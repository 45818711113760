"use strict";
var config = require("infra/config");
var c = require("infra/utils/common");
var CONSUMPTION_GEO_API = config.INSIGHTS_API + '/insights/consumption_geo';

module.exports = angular.module(__filename, [])
    .service("consumptionGeoService", ['baseInsightsService', function(baseInsightsService) {
        const MIN_OPACITY = 0.4;
        const MAX_OPACITY = 1.5;
        const formatFormula = {
          distribution: (score) => score * 100,
          skew: (score) => score,
          consumption: (score) => score * 100,
        };

        return {
            get: getConsumptionGeo,
        }

        function getConsumptionGeo(params) {
            var termMap = {};
            var requestParams = baseInsightsService.buildParams(termMap, params);
            // Add scale parameter
            requestParams.scale = params.scale;
            requestParams.measure = params.measure;
            requestParams.formula = params.formula;
            requestParams.zone_id = params.zoneId
            requestParams.origins = undefined;
            if (_.isEmpty(termMap)) return Promise.reject("No Terms");

            return baseInsightsService.postAsyncData(CONSUMPTION_GEO_API, requestParams)
              .then(({ data }) => {
                let allTerms = Object.keys(termMap);
                _.each(data.plot, (zone, zone_id) =>
                  _.each(zone.values, value => {
                      if (_.isNumber(value.score) && value.score != 0) {
                        allTerms = _.reject(allTerms, t => t === value.term);
                      }
                  })
                );
                var empty = _.isEmpty(data) ? [] : allTerms ;
                baseInsightsService.notifyInsufficient(empty);
                return Object.keys(termMap).length == allTerms.length ? Promise.reject(data) : data;
              }).then(data => buildResults(data, termMap, params))
                .catch(baseInsightsService.handleError);
        }

        function buildResults({plot, examples}, termsMaps, params) {
          if (_.isEmpty(plot)) return {}
          const max = formatFormula[params.formula](_.maxBy(_.flatten(_.map(_.values(plot), 'values')), 'score').score);
          const avg = formatFormula[params.formula](_.sumBy(_.flatten(_.map(_.values(plot), 'values')), 'score') / _.flatten(_.values(plot)).length);
          const chartData  = _.chain(plot).map((zoneData, zoneId) => {
            const zoneName = _.capitalize(zoneData.name);
            return {
              id: zoneId,
              maxStrength: formatFormula[params.formula](_.maxBy(zoneData.values, 'score').score),
              label: zoneName,
              values: _.map(zoneData.values, (v) => ({
                class: termsMaps[v["term"]].class,
                value: formatFormula[params.formula](v.score),
                title: params.formula == 'skew' ? buildSkewToolTip(termsMaps[v["term"]].display, v.score, zoneName, params.sub_geos) : null,
                id: termsMaps[v["term"]].id || termsMaps[v["term"]].text
              }))
            }
          }).orderBy('maxStrength', 'desc').value();
          return {chartData, mapData: buildMapData(plot, termsMaps, params), examples, max, avg};
        }

        function buildMapData(plot, termsMaps, params) {
          const termsLength = Object.keys(termsMaps).length;
          const newPlot = _.chain(plot).map((v,k) => {
            const maxScore = _.maxBy(v.values, 'score')
            const terms = _.values(_.omitBy(v.values, maxScore))
            return Object.assign({}, {key: k}, maxScore, {terms: terms})
          }).groupBy('term').value();
          const opacityDistributionFormula = (score, min, max, maxOpacity) => ((score - min) / (max - min) * (maxOpacity - MIN_OPACITY)) + MIN_OPACITY
          const opacitySkewFormula = (score, min, max) => {
            if (score <= 1) {
              return opacityDistributionFormula(score, min, 1, 1);
            }

            return (((score - 1) / (max - 1)) * 0.5) + 1;
          }

          const opacityFormula = (score, min, max) => {
            if ( termsLength > 2 || min == max) return 1;
            return params.formula == 'skew' && termsLength == 1 ? opacitySkewFormula(score, min, max) : opacityDistributionFormula(score, min, max, MAX_OPACITY)
          };

          return _.map(newPlot, values => {
            const max = _.maxBy(_.values(values), 'score').score;
            const min = _.minBy(_.values(values), 'score').score;
            const mapData = _.mapValues(values, zone =>
              Object.assign(
                {},
                zone,
                {classColor: termsMaps[zone["term"]].class},
                {opacity: opacityFormula(zone.score, min, max)}
              )
            );

            return {mapData, max, min}
          });
        }

        function buildSkewToolTip(seed, score, zone, regions) {
          let moreOrLess;
          const newScore = score.toFixed(2);
          if (newScore > 1) {
            moreOrLess = `x${newScore} times more`;
          } else if (newScore == 1) {
            moreOrLess = 'as';
          } else {
            moreOrLess = `x${(1/newScore).toFixed(2)} times less`;

          }
          return `${seed} is ${moreOrLess} likely to be consumed in ${zone} than in ${
            _.isEmpty(regions)
            ? 'the entire country'
            : 'all of the selected regions'}.
            `
        }

    }]);
