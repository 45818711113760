"use strict";
"use strict";
/* lodash is to match width not to be in global. like angular and jquery */
window._ = require('lodash');

require('common/lodash.srv.js');
require('moment-round');
require("angular");

var loginPage = require("./pages/login/login"),
    resetPwd = require("./pages/reset_password/reset_password"),
    dashboard = require("./pages/dashboard/dashboard"),
    insights = require("./pages/insights/insights"),
    discovery = require("./pages/discovery/discovery"),
    warroom = require("./pages/warroom/warroom"),
    settings = require("./pages/settings/settings"),
    alerts = require('./pages/alerts/alerts'),
    audienceBuilder = require("./pages/audience-builder/audience-builder"),
    audienceExplorer = require("./pages/audience-explorer/audience-explorer"),
    helpCenter = require("./pages/help-center/help-center"),
    nv = require("nvd3");

nv.dev = false;

var rootModule = angular.module("brand-intelligence", [
    require('ng-animate').name,
    require("angular-ui-router").name,
    require("angular-cache").name,
    require("angular-inview").name,
    require("angular-scroll").name,
    require("angular-click-outside").name,
    require('re-tree').name,
    require('ng-device-detector').name,

    require("infra/authentication/authentication-config").name,
    require('infra/app-constants').name,
    require('infra/input-directives').name,
    require("infra/user").name,
    require("infra/state-helper").name,
    require('common/program-selector.drv/program-selector.drv').name,
    require("infra/topbar/topbar").name,
    require("infra/menu/menu.js").name,
    require("infra/context/context").name,

    require("infra/notifications_deck/notification_deck").name,
    require("infra/error_handlers/global_http_errors").name,
    require("infra/error_handlers/error_mgmt.service").name,
    require("infra/error_handlers/logging-service").name,

    require("infra/context/filters/timeframe-mold").name,
    require("infra/context/filters/geo-mold").name,
    require("infra/context/filters/general-mold").name,
    require("infra/context/filters/terms-mold").name,
    require("infra/context/filters/boolean-mold").name,
    require("infra/context/filters/string-mold").name,
    require("infra/context/filters/audience-mold").name,
    require("infra/context/filters/sgTelcoAudience-mold").name,
    require("infra/context/filters/user-first-party-audience-mold").name,
    require("infra/context/filters/topics-mold").name,

    require("infra/user_access/abi-permissions").name,
    require("infra/device-detector").name,
    require("infra/cancellable-http").name,
    require('common/input-bar.drv/input-bar.drv.js').name,

    require("pages/settings/settings").name,
    require("pages/dashboard/dashboard").name,
    require("pages/insights/insights").name,
    require("pages/discovery/discovery").name,
    require("pages/warroom/warroom").name,
    require('pages/alerts/alerts').name,
    require("pages/audience-builder/audience-builder").name,
    require("pages/audience-explorer/audience-explorer").name,
    require("pages/reset_password/reset_password").name,

    require('common/sly-scrollbar.drv/slyScrollbar.drv.js').name,

    require('common/utils.srv.js').name,
    require('common/filters/slice.fltr.js').name,
    require('common/form-components/input-validators/email-form-validator.directive').name,
    require('common/form-components/input-validators/strong-password.directive').name,
    require('common/datePicker.drv/datePicker.drv.js').name,
    require('common/excelBuilder/excel.srv.js').name,
    require("common/modals/password/forgot-password.modal.service").name,
    require("common/modals/password/reset-password.modal.service").name,
    require("common/modals/notification/notification-modal.service").name,
    require("common/am-on-key-press.drv.js").name,
    require('common/tooltip/tooltip.drv.js').name,
    require('common/plus-minus/plus-minus.js').name,
    require('common/dotted-slider/dotted-slider.js').name,
    require("common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv").name,
    require("common/heat-map/heat-map").name,

    require('data/filters-partition.srv.js').name,
    require("data/geo-service").name,
    require("data/grid-data-layer").name,
    require("data/grid.srv").name,
    require("data/topics-tree.srv").name,
    require("data/insights/base-insights-service").name,
    require("data/dsp-service").name,

    require('common/helper-directives-collection/helper-directive-collection').name,
    require('common/am-input-collection/am-input-collection.js').name,
    require('common/am-toggle-button/am-toggle-button.js').name,
    require('common/am-image-toggle-button/am-image-toggle-button.js').name,
    require('common/am-dropdown-select/am-dropdown-select.js').name,
    require('common/am-linked-dropdown/am-linked-dropdown').name,
    require('common/am-container-collection/am-container-collection').name,
    require('common/empty-screen-message.drv/emptyScreenMessage.js').name,
    require('common/am-table-grid/am-table').name,
    require('common/quick-table/quick-table').name,
    require('common/quick-tree/quick-tree').name,
    require('common/am-autocomplete.drv/am-autocomplete.drv.js').name,
    require('common/content-drivers-pane/content-drivers-pane.drv.js').name,
    require('common/am-consumption-legend.drv/am-consumption-legend.drv.js').name,
    require('common/am-image-toggle-button/am-image-toggle-button').name,
    require('common/audience-filter/audience-filter').name,
    require('infra/mgmt-service-factory').name
]);

rootModule.config(["$compileProvider", "stateHelperProvider", "$urlRouterProvider", 'authenticationConfig', 'CacheFactoryProvider',
    function ($compileProvider, stateHelperProvider, $urlRouterProvider, authenticationConfig, CacheFactoryProvider) {
        $compileProvider.debugInfoEnabled(false);
        angular.extend(CacheFactoryProvider.defaults, { maxAge: 4 * 60 * 1000, deleteOnExpire: 'passive' });
        var rootStateConfig = {
            name: "root",
            url: "^",
            abstract: true,
            template: require("./brand-intelligence.html"),
            context: {
                terms: "TermsMold",
                keyTrends: "BooleanMold",
                programSources: "BooleanMold",
                discovery_timeframe: "TimeframeMold",
                topics: "TopicsMold",
                audience: "AudienceMold",
                sgTelcoAudience: "SgTelcoAudienceMold",
                geo: "GeoMold",
                channels: "ChannelsMold",
                bubblesChannels: "BubblesChannelsMold",
                gridChannels: "BubblesChannelsMold",
                insightsChannels: "InsightsChannelsMold",
                articleType: "ArticleTypeMold",
                insightsSubGeos: "GeneralMold",
                discoverySubGeos: "GeneralMold",
                language: "LanguageMold"
            },
            children: [dashboard, insights, discovery, warroom, settings, audienceBuilder, audienceExplorer, alerts, helpCenter]
        };

        stateHelperProvider.state(loginPage.stateConfig);
        stateHelperProvider.state(resetPwd.stateConfig);

        var isInit = true;
        authenticationConfig.doAfterAuthentication.push([ () => {
            if (isInit) {
                stateHelperProvider.state(rootStateConfig, "IgnoreRoot");
                $urlRouterProvider.otherwise(loginPage.stateConfig.url);
                isInit = false;
            }
        }]);
    }
]);

rootModule.run(init);
init.$inject = ["context", "user", "$rootScope", "utils", "$window", "$http", "filtersPartition", "geoService", "authentication", "$state", "$injector"];
function init(context, user, $rootScope, utils, $window, $http, filtersPartition, geoService, authentication, $state, $injector) {
    $http.defaults.headers.common['Abi-Version'] = window.version;
    $rootScope.context = context;
    $rootScope.user = user;
    $rootScope.menuOpen = false;
    $rootScope.filterMenuOpen = false;

    $rootScope.kdebug = sessionStorage.kdebug=="true";

    $rootScope.toggleMenu = function () {
        $rootScope.menuOpen = !$rootScope.menuOpen;
        utils.triggerResizeOnTransitionEnd($('menubar'));
    };
    $rootScope.toggleFilterBar = function () {
        $rootScope.filterMenuOpen = !$rootScope.filterMenuOpen;
        utils.triggerResizeOnTransitionEnd($('side-filters-menu'));
    };

    /** for filter menu **/
    $rootScope.audienceFilters = filtersPartition.audience;
    $rootScope.behavioralSegment = filtersPartition.behavioralSegment;
    $rootScope.testSegment = filtersPartition.testSegment;
    $rootScope.levelOfIntent = filtersPartition.levelOfIntent;
    $rootScope.Geos = geoService;
    $rootScope.streamsChannelsFilter = filtersPartition.streamsChannels;
    $rootScope.insightsChannelsFilter = filtersPartition.insightsChannels;
    $rootScope.insightsAssociationsChannelsFilter = filtersPartition.insightsAssociationsChannels;
    $rootScope.insightsTimingChannelsFilter = filtersPartition.insightsTimingChannels;
    $rootScope.insightsReferralsChannelsFilter = filtersPartition.insightsReferralsChannels;
    $rootScope.geoInsightsChannelsFilter = filtersPartition.geoInsightsChannels;
    $rootScope.bubblesChannelsFilter = filtersPartition.bubblesChannel;
    $rootScope.languageFilter = filtersPartition.language;
    $rootScope.gridChannelsFilter = filtersPartition.bubblesChannel;
    $rootScope.filter = filtersPartition;
    $rootScope.topicsDistribution = {};

    /* handle redirect */
    $rootScope.$on("$stateChangeStart", function (evnt, toState, toParams) {

        var redirect = toState.redirectTo;
        if (redirect) {
            if (angular.isString(redirect)) {
                evnt.preventDefault();
                $state.go(redirect, toParams);
            } else {
                var newState = $injector.invoke(redirect, null, {toState: toState, toParams: toParams});
                if (newState) {
                    if (angular.isString(newState)) {
                        evnt.preventDefault();
                        $state.go(newState);
                    } else if (newState.state) {
                        evnt.preventDefault();
                        $state.go(newState.state, newState.params);
                    }
                }
            }
        }
    });

    /* handle external */
    $rootScope.$on('$stateChangeStart', function (evnt, toState, toParams) {
        if (toState.external) {
            var target = toState.target || '_blank';
            evnt.preventDefault();
            $window.open(toState.url, target);
        }
    });

    angular.element($window).trigger('resize');

    authentication.authenticate();
}
