"use strict";
var svgInline = require("common/svg-inline"),
    tmpl = require("./drilldown.html");

var TAMPLETE_MAP = {
    'brand-index': require("./drilldown-menu-brand-index.html"),
    'brand-perceptions': require("./drilldown-menu-brand-perceptions.html"),
    'news': require("./drilldown-menu-news.html"),
    'associations': require("./drilldown-menu-associations.html")
};

require('qtip2');

var DEFAULT_SETTINGS = {
    events: {
        /* hide popup immediately after clicking */
        render: function (event, api) {
            var tooltip = api.elements.tooltip;
            tooltip.bind('click', function (event) {
                api.hide();
            });
        }
    },
    hide: {fixed: true, delay: 500, event: 'click mouseleave'},
    position: {my: 'top middle', at: 'bottom middle'},
    style: {classes: 'drilldown-popup', tip: {width: 20, height: 10}}
};

module.exports = angular.module(__filename, [
    svgInline.name
]).directive("drilldown", ["$compile", "$rootScope", "$timeout", "$state", "abiPermissions",
    function ($compile, $rootScope, $timeout, $state, abiPermissions) {
        return {
            restrict: "EA",
            template: tmpl,
            link: function ($scope, $elem, attr) {
                var newScope = $scope.$new();
                var popup_html = $compile(TAMPLETE_MAP[attr.name])(newScope);
                $timeout(function () {
                    var settings = $.extend(true, {content: popup_html}, DEFAULT_SETTINGS);
                    $elem.qtip(settings);
                }, 0);

                $scope.hasPermission = function (permission) {
                    return abiPermissions.hasPermission([permission]);
                };

                $scope.drillDown = function (state) {
                    // overwrite context's terms with program's terms = brand + competitors
                    var termsMold = $rootScope.context.current._terms_mold,
                        competitors = _.map($rootScope.context.program.competitors, 'name'),
                        brand = $rootScope.context.program.brand.name,
                        termsToAdd = [brand].concat(competitors);

                    termsMold.replace(termsToAdd, true).then(function () {
                        $state.go(state);
                    });
                }
            }
        }
    }
]);
