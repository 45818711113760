"use strict";
"use strict";

module.exports = angular.module(__filename, []).service("TimeframeMold", ["$q", function ($q) {
    function TimeframeMold(context) {
        var me = this;
        me._value = [1, "month"];
        me.default = [1, "month"];

        this.replace = function (val) {
            return $q.when(me._value = val || me.default);
        }.bind(this);
    }

    return TimeframeMold;
}]);
