"use strict";
var Orbis = require("common/bubbles/orbis"),
    concept_manager = require("common/bubbles/concept-manager"),
    tooltip = require("common/tooltip/tooltip"),
    discoveryViewSwitcher = require("common/discoveryViewSwitcher.drv/discoveryViewSwitcher.drv"),
    qtip = require('qtip2'),
    exclusionService = require("./exclusions-dialog/exclusions-dialog"),
    BaseWidget = require("../base_widget"),
    mixpanel = require('infra/mixpanel/mixpanel-discovery'),
    c = require("infra/utils/common"),
    skewService = require("data/audience-skew-service");


BubblesWidgetCtrl.$inject = ["$scope", "$state", "Discovery", "examplesPopupService", "quickRefinePopupService", "conceptsService", "exclusionService",
    "context", "$timeout", "$window", "mixpanelDiscovery", "topicsTree", "util", "abiPermissions", "filtersPartition", "errorsLogger", "SIGNS", "audienceMgmt", "audienceSkewService"];
function BubblesWidgetCtrl($scope, $state, discoveryService, examplesPopupService, quickRefinePopupService, conceptsService, exclusionService,
                           context, $timeout, $window, mixpanelDiscovery, topicsTree, util, abiPermissions, filtersPartition, errorsLogger, SIGNS, audienceMgmt, audienceSkewService) {

    var self = this;

    this.conceptsService = conceptsService;
    this.Discovery = discoveryService;
    this.examplesPopupService = examplesPopupService;
    this.quickRefinePopupService = quickRefinePopupService;
    this.exclusionService = exclusionService;
    this.topicsTree = topicsTree;
    this.errorsLogger = errorsLogger;
    this.SIGNS = SIGNS;
    this.context = context;
    this.util = util;
    this.abiPermissions = abiPermissions;

    var orbis = new Orbis("balls", abiPermissions.hasPermission(['story clustering']), this.context.current.bubblesChannels.value);
    $scope.orbis = orbis;
    $scope.$state = $state;
    $scope.concept_manager = new concept_manager();
    orbis.on("orbis.clicked_node", this.onClickNode.bind(this));
    this.$scope.velocity = false;
    this.$scope.rising_only = false;
    this.displayed_notifications = {};
    this.$timeout = $timeout;
    this.$window = $window;
    this.$scope.hasEmptyTerms = false;
    this.$scope.cacheBaster = null;
    this.$scope.noData = false;
    this.$scope.lifestyle = null;

    var lifestyles =audienceSkewService.getAudienceLifeStyle(filtersPartition, $scope.test_mode);


    function populateCustomSegments(newVal, oldVal) {
        if (oldVal && newVal.p_id == oldVal.p_id) return;
        audienceMgmt.list().then(data => {
            $scope.customSegments = audienceSkewService.getAudienceCustomSegments(data, lifestyles);
        });
    }
    mixpanelDiscovery.trackPageView('bubbles');
    var unbindContextListener = context.onChange(populateCustomSegments);
    populateCustomSegments();

    $scope.getSelectedLifestyle = function () {
        return $scope.showAudienceToggle() && self.$scope.lifestyle && self.$scope.lifestyle[0];
    };
    $scope.showAudienceToggle = function () {
        return this.bubblesChannels.value == 'articles';
    };
    $scope.$watch('lifestyle', function (newValue, oldValue) {
        if (_.isEmpty(newValue) && _.isEmpty(oldValue)) return;
        var lifestyle = $scope.getSelectedLifestyle();
        self.Discovery.config = {
            lifestyle: lifestyle
        };
        if (!lifestyle && $scope.velocity) return;
        if (lifestyle && $scope.velocity) $scope.velocity = false;
        self.startLoader()
        self.loadBubblesResults().then(function(res){ self.stopLoader() }, function(res){ self.stopLoader() });
    });

    $scope.$watch('bubblesChannels', function () {
        if (!$scope.showAudienceToggle() && $scope.lifestyle && $scope.lifestyle[0]) $scope.lifestyle = null;
    });

    this.Discovery.config = {
        isRisingOnly: $scope.rising_only
    };

    this.$scope.toggleHeatMap = function () {
        if ($scope.velocity && $scope.getSelectedLifestyle()) {
            $scope.lifestyle = null;
            self.$scope.orbis.show_velocity_overlay($scope.velocity);
            self.drawBubbles();
        } else {
            self.$scope.orbis.show_velocity_overlay($scope.velocity);
        }
    };

    this.$scope.toggleRisingOnly = function toggleRisingOnly() {
        self.Discovery.config = {
            isRisingOnly: $scope.rising_only
        };
        self.loadBubblesResults();
    };

    this.$scope.openExclusions = function openExclusions() {
        self.exclusionService.showModal($scope.program.id);
    };

    this.$scope.checkWidth = function () {
        return self.$window.innerWidth > 1322;
    };

    angular.element($window).bind('resize', function () {
        self.$scope.$apply();
    });

    this.trackBubbleClicked = function (bubble) {
        mixpanelDiscovery.trackBubbleClicked(bubble);
    };

    var refreshViewListener = $scope.$root.$on('refresh-view', function (event) {
        self.updateMe();
    });

    this.$scope.$on('$destroy', () => {
        refreshViewListener();
        unbindContextListener();
    });
}

BubblesWidgetCtrl.prototype.updateMe = function () {
    var self = this;
    if (self.$scope.$state.current.name.indexOf('bubbles') == -1) return;

    self.$scope.cacheBaster = Date.now();
    self.update(null, null);
};

BubblesWidgetCtrl.prototype._doUpdate = function (newVals, oldVals) {
    if (this.cancelPromise) this.cancelPromise();

    this.$scope.$root.topicsDistribution = null;
    /* do update 'newVals' 'oldVals' is not really sync with the true value on the context */
    var self = this, newVals = this.context.current;
    self.$scope.hasEmptyTerms = false;
    self.$scope.noData = false;

    var geoSer = this.$scope.$root.Geos;
    var audience = c.getAudience(this.$scope, this.$scope.bubblesChannels.value);
    if ((this.$scope.bubblesChannels.value == 'sg_telco') && !this.abiPermissions.hasPermission('sg telco audience filters')) {
      audience = [];
    }

    this.Discovery.config = {
        topics: newVals.topics,
        sensitiveContent: this.topicsTree.isSensitive,
        timeframe: newVals.discovery_timeframe,
        geos: geoSer.geosForChannel(newVals.geo, self.$scope.$state, self.context),
        sub_geos: geoSer.createRequestSubGeoParam(geoSer.subGeosForChannel(newVals.discoverySubGeos, self.$scope.$state, self.context)),

        audience: audience ? audience.filter(function (a) {
            return a && a.value
        }) : null,
        terms: newVals.terms,
        isKeyTrends: newVals.keyTrends,
        program: this.context.program,
        channels: newVals.channels,
        bubblesChannels: newVals.bubblesChannels,
        cacheBaster: self.$scope.cacheBaster,
        lifestyle: this.$scope.getSelectedLifestyle(),
        language: this.context.current._language_mold.getLanguage(self.$scope.$state, this.context).value,
        isCustom: newVals.bubblesShowCustomSources
    };


    return self.loadBubblesResults();
};

BubblesWidgetCtrl.prototype.onSelectNode = function onSelectNode(event) {
    var selected_word = JSON.parse(event.target.dataset.node_data).word;
    if (this.$scope.terms.length >= 7) {
        this.notificator.notify({body: "Sorry, the interest bar is limited to 7 seed interests"});
        this.$scope.$apply()
    } else {
        $(angular.element.find(".example-popup")).remove();
        this.$scope.addTerm(selected_word);
    }
};

BubblesWidgetCtrl.prototype.onDeleteNode = function onDeleteNode(event) {
    // todo: look on that for stale data on service
    console.log(event.target.dataset);
    var node_data = JSON.parse(event.target.dataset.node_data);
    var concept_manager = this.$scope.concept_manager;
    concept_manager.remove_concept(node_data.id);
    this.drawBubbles();
    this.conceptsService.delete(this.$scope.program.id, node_data.seed, node_data.word);
};

BubblesWidgetCtrl.prototype.onRefineSeed = function onRefineSeed(event) {
    var bindings = {
        required: this.handleRefineSeed.bind(this),
        included: this.handleRefineSeed.bind(this),
        excluded: this.handleRefineSeed.bind(this)
    };

    this.quickRefinePopupService.activate($(event.target), {data: JSON.parse(event.target.dataset.node_data)}, bindings);
};

BubblesWidgetCtrl.prototype.getTermByClass = function getTermByClass(clazz) {
    var currentTerm = this.Discovery.config.terms.filter(function (term) {
        return term.class == clazz;
    });

    return currentTerm[0];
};

BubblesWidgetCtrl.prototype.getTermIndex = function (term) {
    return this.Discovery.config.terms.indexOf(term);
};

BubblesWidgetCtrl.prototype.handleRefineSeed = function handleRefineSeed(event) {
    var inputBarController = angular.element("input-bar").controller('inputBar');
    var data = JSON.parse(event.target.dataset.node_data);
    var node_data = data.data;
    var booleanFields = {};
    var term = this.getTermByClass(node_data.class);
    booleanFields[data.field] = {class: term.class, id: parseInt(node_data.id), invalid: false, text: node_data.word, type: 'term'};

    inputBarController.editPhrase(this.getTermIndex(term), event, {}, booleanFields);
};

BubblesWidgetCtrl.prototype.onClickNode = function onClickNode(event) {
    var examples = [];
    (event.node.articles || []).map(function (example) {
        examples.push({image_url: example.image_url, desc: example.title, link_url: example.url, link_title: example.domain, user: example.user});
    });

    var segment = this.$scope.getSelectedLifestyle();

    var data = {
        node_data: {
            word: event.node.word,
            seed: event.node.seed.replace(this.SIGNS.POST, ""),
            id: event.node.id,
            class: event.node.color
        },
        title: event.node.word,
        consumption: event.node.score,
        audience: this.$scope.lifestyle && this.$scope.lifestyle.length ? event.node.audience : null,
        segment: segment ? segment.label : null,
        examples: examples
    };

    var bindings;
    if (!event.node.isSeed) {
        bindings = {select: this.onSelectNode.bind(this), 'quick-refine': this.onRefineSeed.bind(this), trash: this.onDeleteNode.bind(this)};
    }

    this.examplesPopupService.activate($(event.target), data, bindings);
    this.trackBubbleClicked(data.title);
};

BubblesWidgetCtrl.prototype.loadBubblesResults = function loadBubblesResults() {
    var self = this, $scope = this.$scope;

    var promises = this.Discovery.getBubbles();
    this.cancelPromise = promises.cancel;

    return promises.promise.then(function (result) {
        $scope.$root.topicsDistribution = result.topicsDistribution;
        var bubbles = result.bubbles;

        var concept_manager = $scope.concept_manager;
        concept_manager.reset();
        var terms = self.Discovery.config.terms.concat({text: 'trending', class: 'term-trending'});
        var channel = self.context.current.bubblesChannels.value;

        if (channel != "tweets" && channel != "facebook") {
            var social_terms = _(self.context.current.terms).filter(function (term) {
                return _.includes(['post', 'hashTag', 'mention'], term.type) || self.util.isSocialBooleanLogic(term);
            }).map('text').value();
            if (social_terms.length) {
                self.notify('Sorry, @mentions, @posts and #hashtags are supported only for Twitter & Facebook channel ('
                    + _(social_terms).map(self.util.getTermDisplayShort).join() + ')');
            }
        }

        var noDataConcepts = [];
        var noDataSeeds = [];
        $.each(bubbles, function (seed, values) {
            var color;
            try {
                color = _.find(terms, {text: seed}).class;
            } catch (error) {
                self.errorsLogger.logError(error, {seed: seed, values: values, terms: terms, config: self.Discovery.config, bubbles: bubbles});
                return;
            }

            var concepts = values.concepts;
            var seedObject = _.keys(concepts).length == 1 ? concepts[Object.keys(concepts)[0]] : {};

            if (_.isEmpty(concepts)) {
                var channel = self.context.current.bubblesChannels.value;

                if (channel == 'tweets' || (seed[0] != '#' && seed[0] != '@' && !seed.startsWith(self.SIGNS.POST))) {
                    self.notify("No concepts found for " + self.util.getTermDisplayShort(seed));
                } else {
                    noDataSeeds.push(self.util.getTermDisplayShort(seed));
                }
            } else if (!_.isEmpty(seedObject) && _.isEmpty(seedObject.articles)) {
                noDataConcepts.push(self.util.getTermDisplayShort(seed));
            }

            concept_manager.add_concepts(seed, concepts, color);
        });

        if (!_.isEmpty(noDataConcepts)) {
            var insufficientDataMsg = "Sorry, insufficient content about <seed>. Please try more general interests or filters.";
            self.notify(insufficientDataMsg.replace("<seed>", noDataConcepts.join(", ")));
        }

        self.drawBubbles();
        self.$scope.hasEmptyTerms = self.Discovery.config.terms.length == 0 && !self.Discovery.config.isKeyTrends;
        self.$timeout(function () {
            // prevent empty screen msg to show before bubbles animation ends
            self.$scope.noData = self.$scope.hasEmptyTerms ? false : noDataSeeds.length == self.Discovery.config.terms.length && !self.Discovery.config.isKeyTrends;
        }, 800);
    })
};

BubblesWidgetCtrl.prototype.notify = function (message) {
    if (!this.displayed_notifications[message]) {
        this.displayed_notifications[message] = true;
        this.notificator.notify({body: message});
        var self = this;
        this.$timeout(function () {
            delete self.displayed_notifications[message];
        }, 3000);
    }
};

BubblesWidgetCtrl.prototype.drawBubbles = function drawBubbles() {
    var $scope = this.$scope;
    var concept_manager = $scope.concept_manager;
    var concepts_res = concept_manager.concept_idx;
    var seeds_res = concept_manager.seed_idx;
    $scope.orbis.set_heat($scope.getSelectedLifestyle() || $scope.velocity, $scope.getSelectedLifestyle() ? "audience" : "score");
    if ($scope.getSelectedLifestyle()) $scope.orbis.show_audience_overlay(true);

    $scope.orbis.load(seeds_res, concepts_res, $scope.bubblesChannels.value);

    tooltip($("#balls .node"),
        'info no solo',
        {
            events: {
                show: function (event, api) {
                    if (!$(event.originalEvent.target).closest(".node").is(".pruned"))
                        event.preventDefault()
                }
            },
            style: {classes: 'common-tooltip-info', tip: {width: 10, height: 5}},
            position: {my: 'bottom center', at: 'center', adjust: {y: 5, method: 'shift'}}, hide: {delay: 100, event: 'mouseleave click', fixed: true}
        },
        true
    );
};

module.exports = angular.module(__filename, [
    require("../../data/discovery-service").name,
    require("../../common/examples-popup/examples-popup").name,
    require("../../common/examples-popup/sentiments-popup").name,
    require("../../common/examples-popup/quick-refine-seed").name,
    require("../../common/am-switch-button/am-switch-button").name,
    require("../../data/concepts-service").name,
    require("infra/mixpanel/mixpanel-discovery").name,
    exclusionService.name,
    discoveryViewSwitcher.name,
    require("data/audience-mgmt").name
]).directive("bubblesWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: require("./bubbles-widget.html"),
        scope: {
            timeframe: "=timeframe",
            terms: "=terms",
            program: "=program",
            keyTrends: "=keyTrends",
            updateKeyTrends: "=updateKeyTrends",
            addTerm: "=addTerm",
            topics: "=topics",
            geo: "=geo",
            language: "=language",
            sub_geos: "=subGeos",
            audience: "=audience",
            sgTelcoAudience: "=sgTelcoAudience",
            userFirstPartyAudience: "=userFirstPartyAudience",
            allTopics: "=allTopics",
            showCustomSources: "=",
            bubblesChannels: "=bubblesChannels"
        },
        controller: BubblesWidgetCtrl,
        controllerAs: 'bubblesWidgetCtrl'
    });
}]);
