"use strict";
module.exports = require("angular").module(__filename, [])
  .directive("amConsumptionLegend", [function() {
    return {
      restrict: 'E',
      template: require('./am-consumption-legend.drv.html'),
      scope: {
        size: '='
      },
      link: function ($scope, $element, $attr) {
        var element = d3.select($element);
        var svg = element[0][0].children('svg');
        svg.attr('height', $scope.size + 6);
        _.each(_.range(1,8),function(i){
          var block = svg.children('.heat-map.block-' + i);
          block.attr('x', 14 + ($scope.size + 2) * i)
            .attr('width', $scope.size)
            .attr('height', $scope.size);
        });
        var low = svg.children('.low-high.low');
        low.attr('x', 0)
          .attr('y', $scope.size - 2);
        low[0].style.fontSize = ($scope.size - 2) + 'px';
        var high = svg.children('.low-high.high');
        high.attr('x', 50 + 7 * $scope.size)
          .attr('y', $scope.size - 2);
        high[0].style.fontSize = ($scope.size - 2) + 'px';
      }
    }
  }]);
