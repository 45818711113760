"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, []).directive('contentDriver', [function () {
    return {
        restrict: "E",
        require: '^contentDriversPane',
        controllerAs: 'contentCtrl',
        scope: {
            alphabetized: '=?',
            url: '=',
            imageUrl: '=',
            title: '=',
            tagline: '=',
            icon: '=?',
            rt: '=?',
            letter: '=?',
            highlight: '=?',
            hide: '=?',
            termClass: '=?',
            label: '=?',
            selected: '=?',
            sentiment: '=?',
            withTermContent: '=?',
            confidence: '=?',
            date: '=?',
            disableLikeIcons: '@'
        },
        template: require('./content-driver.drv.html'),
        link: function (scope, iElement, iAttrs) {
            iElement.removeAttr('title');
        },
        controller: ['$scope', '$element', '$attrs', '$transclude',
            function (scope, element, attr, $transclude) {
            }
        ]
    }
}]);
