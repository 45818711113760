"use strict";
"use strict";
require("pages/targets/targets-common");

var config  = require("infra/config"),
              marketListHelper = require("pages/discovery/market-list");

editTargetCtrl.$inject = ['$scope', '$state', '$http', '$q', 'parentTab', 'CHANNEL', 'abiPermissions', 'context', 'TargetsCommon',  'dataType', 'currentTarget', 'Excel', 'close', 'ModalService', 'notificator', 'sentimentsPopupService', 'SENTIMENT'];
function editTargetCtrl($scope, $state, $http, $q, parentTab, CHANNEL, abiPermissions , context, TargetsCommon,  dataType, currentTarget, Excel, close, ModalService, notificator , sentimentsPopupService, SENTIMENT) {

    $scope.CHANNEL = CHANNEL;
    $scope.editTargetCtrl = this;
    $scope.Math = window.Math;
    $scope.hasPermission = abiPermissions.hasPermission;

    $scope.queries = {

        web_phrases: {
            columns: [
                {id: 'phrase', title: 'Phrase', flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'velocity', title: 'Velocity'},
                {id: 't', title: 'Topic'},
                {id: 'specifity', title: 'Specificity score'}
            ]
        },

        sg_telco_phrases: {
            columns: [
                {id: 'phrase', title: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'velocity', title: 'Velocity'},
                {id: 't', title: 'Topic'},
                {id: 'specifity', title: 'Specificity score'}
            ]
        },

        au_telco_phrases: {
            columns: [
                {id: 'phrase', title: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'velocity', title: 'Velocity'},
                {id: 't', title: 'Topic'},
                {id: 'specifity', title: 'Specificity score'}
            ]
        },

        social_phrases: {
            columns:[
                    {id: 'phrase', title: 'Phrase',  flex:2, template: 'partials/phrase-delete.partial.html'},
                    {id: 'seed', title: 'Seed'},
                    {id: 'score', title: 'Total Score'},
                    {id: 'relevancy', title: 'Relevancy Score'},
                    {id: 'mentions', title: 'Mutual Mentions'},
                    {id: 'rt_count', title: 'Retweets'},
                    {id: 'mentions_per_day', title: 'Estimated Volume'},
                    {id: 'velocity', title: 'Velocity'},
                    {id: 'specifity', title: 'Specificity score'},
                    {id: 'top_topic', title: 'Topic'},
                    {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
                ]
            },

        social_hashtags: {
            columns:[
                {id: 'hashtag', title: 'Hashtag', flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'mentions', title: 'Mutual Mentions'},
                {id: 'rt_count', title: 'Retweets'},
                {id: 'velocity', title: 'Velocity'},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
            ]
        },

        social_influencers: {
            columns:[
                {id: 'handle', title: 'Handle', flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'mentions', title: 'Mutual Mentions'},
                {id: 'retweets', title: 'Retweets'},
                {id: 'followers', title: 'Followers'},
                {id: 'klout_score', title: 'Klout Score'},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
            ]
        },

        social_audience:  {
            columns: [
                {id: 'handle', title: 'Handle', flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'seed', title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'mentions', title: 'Mutual Mentions'},
                {id: 'retweets', title: 'Retweets'},
                {id: 'followers', title: 'Followers'},
                {id: 'klout_score', title: 'Klout Score'},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'}
            ]
        },

        social_emojis:  {
            columns: [
                {id: 'id', title: 'Emoji', template: 'partials/emoji.partial.html'},
                {id: 'seed', search:true, title: 'Seed'},
                {id: 'score', title: 'Total Score'},
                {id: 'mentions', title: 'Mutual Mentions'},
                {id: 'retweets', title: 'Retweets'},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},
                {id: 'eid', title: 'Name'}
            ],
        },

        facebook_interests:{
            columns:[
                {id: 'id', title: 'Interest', flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'score', title: 'Total Score'},
                {id: 'estimated_reach', title: 'Estimated reach'},
                {id: 'facebook_category', title: 'Facebook category'},
                {id: 'relevancy', title: 'Relevancy Score'},
                {id: 'velocity', title: 'Velocity'}
            ]

        },

        facebook_pages:{
            columns:[
                {id: 'name', title: 'Name', flex:2, template: 'partials/phrase-delete.partial.html'},
                {id: 'score', title: 'Total Score'},
                {id: 'shares', title: 'Shares'},
                {id: 'likes', title: 'Likes'},
                {id: 'comments', title: 'Comments'},
                {id: 'top_topic', title: 'Topic'}
            ]

        },

        audience_phrases_phrases: {
            columns: [
                {id: 'phrase', title: 'Phrase', flex:'1-5', template: 'partials/phrase-delete.partial.html', flex:'0-7'},
                {id: 'audience_name', title: 'Audience Name', flex:'0-7'},
                {id: 'interest-portion', title: 'Consumption In Audience'},
                {id: 'segment-portion', title: 'Audience Portion in Consumption', flex:'1-5'},
                {id: 'uniqueness-index', title: 'Audience Skew', flex:'0-7'},
                {id: 'score', title: 'Significance Score'},
                {id: 'topic', title: 'Topic', flex:'0-7'}
            ],
            order: 'interest-portion',
            reverse: true
        }

    };

    $scope.queries['trends'] = {
        title: 'items',
        dataArray: [],
        order: 'name',
        loading: true
    };

    $scope.queries['dynamic'] = {
        title: 'items',
        dataArray: [],
        order: 'name',
        loading: true
    };

    $scope.manualPhrases = null;

    var me = this, closeMode = '';

    this.editingName = false;
    this.name = currentTarget.name;
    this.userName = '';

    $scope.init = function () {
        $scope.setTab();

        if($scope.hasManualTopics){
            $scope.dataTrees = { manualTopicsTree: $.extend(true, {} , {allSelectedLabel: 'All Topics',
                children: $scope.$root.topicsFilter.slice(0), checkedArray: []})
            };

            $scope.dataTrees.manualTopicsTree.saveChecked = function(checkedArray){
                me.currentTarget.topics = checkedArray.map(function(c){return c.id;});
                me.currentTarget.limitTopics = $scope.dataTrees.manualTopicsTree.checkedArray.length ? true : false;
                me.currentTarget.topical = me.currentTarget.limitTopics;
                me.updateTopics();
            }
        }

        me.loadData(); //calls showTables
    };

    $scope.isManualPhrases = function(){
        return $scope.tab == 'phrases';
    }

    $scope.getUser = function(){
        if(!me.currentTarget.u_id){
            me.userName = '';
            return;
        }else if(!me.userName){
            $http.get(config.USER_MGMT_API + '/users/'+me.currentTarget.u_id).
              success(function(user) {
                me.userName = user.username;
              });
        }
    }

    $scope.setTab = function (tab) {

        if(tab){
            $scope.tab = tab;
        }else if(parentTab){
            $scope.tab = parentTab;
        }else{
            //get 1st tab
            var tabs = Object.keys(currentTarget.results);
            $scope.tab = 'phrases';

            for(var i=0;i<tabs.length;i++){
                var tab = tabs[i];
                if($scope.queries[me.currentTarget.channel+"_"+tab]){
                    $scope.tab = tab;
                    break;
                }
            }

        }

        $scope.tabs = [];

        $scope.hasManualPhrases = $scope.isManualPhrases();
        $scope.hasManualTopics = CHANNEL.supportManualTopics(me.currentTarget.channel);


        for(var i in $scope.queries){
            if(i!="trends" && i!="dynamic" && i.indexOf(me.currentTarget.channel)!=-1){
                $scope.tabs.push({channel_tab:i, tab: i.substring(i.lastIndexOf("_")+1)});
            }
        };

        $scope.query = $scope.queries[me.currentTarget.channel+"_"+$scope.tab];
        $scope.queries['trends'].columns = $scope.query.columns.slice(0);
        $scope.queries['dynamic'].columns = $scope.query.columns.slice(0);

        $scope.queries['trends'].title = $scope.tab;
        $scope.queries['dynamic'].title = $scope.tab;

        if(tab){
            $scope.showTables();
        }
    }

    $scope.showTables = function () {
        var trends = [] , dynamic = [],
            query = me.currentTarget.results[$scope.tab] || [];

        $.each(query, function (i, cell) {
            if(cell.dynamic){
                dynamic.push(cell);
            }else{
                trends.push(cell);
            }
        });

        setTimeout(function(){

            $scope.queries['dynamic'].dataArray = dynamic.slice(0);//shows on "view current trends"
            $scope.queries['trends'].show(trends);
            var table = $("quick-table#trends");
            if(table.length==1){
                $scope.sentimentsPopupService.reset(table);
            }
        });
    };

    $scope.dynamicTrendsWindow  = function() {


        if(!me.currentTarget.dynamic) return;    

        me.loadingTrends = true;
        var url = config.USER_MGMT_API + '/programs/grid_target/'+me.currentTarget._id+'/update_dynamic'+TargetsCommon.POSTFIX;

        $http.post(url, me.currentTarget).then(function(res){

            me.currentTarget = $.extend(true, {}, res.data);

            me.loadingTrends = false;
            me.dynamicTrendsOpened = true;
            ModalService.showModal({
                template: require('./dynamic-trends.html'),
                controller: require('./dynamic-trends.js'),
                controllerAs: 'dynamicTrendsCtrl',
                inputs: {
                    parentScope: $scope
                }
            });
        });
    }

    $scope.manualPhrasesWindow  = function() {
        me.addManualPhrasesOpened = true;
        ModalService.showModal({
            template: require('./manual-phrases.html'),
            controller: require('./manual-phrases.js'),
            controllerAs: 'manualPhrasesCtrl',
            inputs: {
                parentScope: $scope
            }
        });
    }

    $scope.sentimentsPopupService = sentimentsPopupService;

    $scope.showSentimentsPopup = function (row, event) {
        var data = SENTIMENT.setRowData(row);
        $scope.sentimentsPopupService.activate($(event.target), data, {});
    };

    $scope.manualPhrasesClosed = function(){
        me.addManualPhrasesOpened = false;
    }

    $scope.net_sent_display = function(row) {
        return Math.min(100, Math.pow(row.net_sent, 2))/2;
    };

    $scope.sentiment_bar = function(row) {
        return row.net_sent > 0 ? 'positive' : row.net_sent < 0 ? 'negative' : '';
    };

    this.currentTarget = currentTarget;
    this.isActivated = false;
    this.limitTopics = false;
    this.limitTopicsOpened = false;
    this.dynamicTrendsOpened = false;
    this.addManualPhrasesOpened = false;
    this.dynamic = currentTarget.dynamic || false;

    this.activateOptions = [
        {name: 'Demo', id: 'demo', selected: false},
        {name: 'DSP', id: 'turn', selected: false}
    ];

    this.targetModes = [
        {title: 'Dynamic', value: 'dynamic'},
        {title: 'Static', value: 'static'}
    ];

    this.close = function () {
        if(this.validate()){
            close({action: closeMode, target: me.currentTarget});
        }
    };

    this.clicked = function(){
        if(me.limitTopicsOpened){
            me.limitTopicsOpened = false;
        }
    }

    this.validate = function(){

        if(0==$("#target-name").val().length){
            notificator.notify({body: "please enter target name."});
            return false;
        }else if($scope.hasManualTopics && me.limitTopics && !$scope.dataTrees.manualTopicsTree.checkedArray.length){
            notificator.notify({body: "please select at least one topic, or uncheck 'limit topics'."});
            return false;
        }

        return true;
    }


    this.loadData = function(andUpdate){
        $scope.queries['trends'].loading = true;

        setTimeout(function(){
             
            TargetsCommon.TargetData.targetToServer({target: me.currentTarget, username: $scope.$root.user.name});

            if($scope.hasManualTopics){
                $scope.dataTrees.manualTopicsTree.checkedArray = (me.currentTarget.topics || []).map(function(t){return {id: t};});
                $scope.dataTrees.manualTopicsTree.show();
            }

            $scope.getUser();

            $scope.queries['trends'].loading = false;

            //activated_to
            $scope.marketListHelper = marketListHelper;

            $scope.marketListHelper.init($scope, $http, context, me);
            $scope.marketListHelper.setCurrentTargetsMarkets(me);
            
            TargetsCommon.TargetData.targetToUI({
                target: me.currentTarget, context: context, action: 'edit-target'
            });

            me.limitTopics = me.currentTarget.limitTopics;


            $scope.showTables();

            if(andUpdate){
                $scope.broadcastRoot('updateTargets', {
                    target: me.currentTarget,
                    action: 'update-target'
                });
            }

        });
    };

    this.delete = function(){
      $scope.deleteTarget = me.currentTarget;
    }

    $scope.deleteConfirm = function(){
        TargetsCommon.deleteConfirm($scope);
        close('deleted');
    }

    this.cloneTarget = function () {
        
        let url = TargetsCommon.HOST+context.current.p_id+'/grid_target/'+me.currentTarget._id+'/clone'+TargetsCommon.POSTFIX;
        
        let target = $.extend(true, {}, me.currentTarget);
        TargetsCommon.TargetData.targetToServer({target: target, username: $scope.$root.user.name});
        
        $http.post(url, target).then(function (res) {
            me.currentTarget = res.data;
            $scope.broadcastRoot('updateTargets', {target: me.currentTarget, action: 'create-target'});
            notificator.success({body: 'Target "' + me.currentTarget.name + '" cloned'});
                
            close('cloned');
        })
    };

    this.removeFromTarget = function (itemToDelete) {
        var queryType = itemToDelete.dynamic? 'dynamic' : 'trends';
        var dataArray = $scope.queries[queryType].dataArray;
        dataArray.splice($.inArray(itemToDelete, dataArray), 1 );

        if(queryType=="trends"){
            $scope.queries[queryType].show(dataArray);
        }else{
            $scope.$broadcast('updateDynamicTrends');
        }

        TargetsCommon.removeFromTarget(me.currentTarget, $scope.tab, [itemToDelete]).then(function(res){
            me.currentTarget = $.extend(true, {}, res.data);
            $scope.broadcastRoot('updateTargets', {
                target: res.data, action: 'remove-from-target', tab: $scope.tab, items: [itemToDelete]
            });
        });
    };

    this.updateTarget = function (close) {

        TargetsCommon.TargetData.targetToServer({target: me.currentTarget, username: $scope.$root.user.name});

        $scope.queries['trends'].loading = true;
        $scope.queries['dynamic'].loading = true;

        let url = TargetsCommon.HOST+'grid_target/'+me.currentTarget._id+TargetsCommon.POSTFIX;

        $http.put(url, me.currentTarget).then(function(res){
            me.currentTarget = $.extend(true, {}, res.data);
            me.loadData('andUpdate');
        });

        if (close) {
            me.editMode = false;
            this.close();
        }
    };

    this.updateTopics = function () {

        TargetsCommon.TargetData.targetToServer({target: me.currentTarget, username: $scope.$root.user.name});

        let url = TargetsCommon.HOST+'grid_target/'+me.currentTarget._id+TargetsCommon.POSTFIX;

        $http.put(url, me.currentTarget).then(function(res){
            me.currentTarget = $.extend(true, {}, res.data);

            $scope.broadcastRoot('updateTargets', {
                target: me.currentTarget
            });
        });
    };
    
    this.export = function () {
        return $q.when(true).then(function () {
            let workbook = Excel.builder.createWorkbook();

            workbook.fileName = 'Target ' + me.currentTarget.name + ' ' + moment().format('YYYY-MM-DD HH:mm') + '.xlsx';

            $scope.tabs.forEach(function(t){
                if(t.tab != "dynamic" && t.tab != "trends"){

                    var nonDynamic = me.currentTarget.results[t.tab].filter(function(phrase){
                        return !phrase.dynamic;
                    });

                    Excel.addTableSheet(workbook,
                        nonDynamic,
                        me.genDesc($scope.queries[t.channel_tab]), {
                        name: t.tab
                    });
                }
            });

            if(me.supportDynamic()){
                Excel.addTableSheet(workbook,
                    $scope.queries['dynamic'].dataArray,
                    me.genDesc($scope.queries['dynamic']), {
                    name: 'dynamic'
                });
            }

            return workbook;
        });
    };

    this.openCloseEditName = function(editingName){
        me.editingName = undefined != editingName ? editingName : !me.editingName;

        if(me.editingName){
            setTimeout(function(){
                $(".edit-name").focus()
            });
        }
    }

    this.displayDate = function(){
        return moment(me.currentTarget.date_added).format('MMM D YYYY');
    }

    this.checkNameChange = function(e){
        if(e.keyCode==13 && this.validate()){ //enter
                this.nameChange('close');
        }
    }

    this.nameChange = function(close){

        if(close){
            me.editingName = false;
        }

        if($("#target-name").val() == this.name){
            return;
        }

        this.name = $("#target-name").val();

        if(this.validate()){
            me.updateTarget();
        }
    }

    this.genDesc = function(query){
        var desc = {};
        query.columns.forEach(function(col){
            desc[col.title] = {key: col.id, width: 20}
        });
        return desc;
    };

    this.supportDynamic = function(justChannel){
        var support = CHANNEL.supportDynamic(me.currentTarget.channel);
        if(!justChannel){
            support = support && me.isActivated;
        }
        return support;
    };

    this.toggleDynamic = function() {
        $scope.queries['trends'].loading = true;
        $scope.queries['dynamic'].loading = true;
        me.currentTarget.dynamic = !me.currentTarget.dynamic;
        me.updateTarget();
    };

    this.toggleActivate = function(){
        me.isActivated = !me.isActivated;

        if(me.isActivated){
            me.currentTarget.activated_to = [me.activateOptions[0].id];
        } else {
            $scope.marketListHelper.toggleMarket(false);

            me.currentTarget.activated_to.length = 0;
        }
        me.updateTarget();
    };

    this.toggleLimitTopics = function(){
        me.limitTopics = !me.limitTopics;

        if($scope.hasManualTopics && !me.limitTopics && $scope.dataTrees.manualTopicsTree.checkedArray.length){
            me.limitTopicsOpened = false;
            $scope.dataTrees.manualTopicsTree.clickAll('');
        }
    }

    $scope.toggleTopicsOpened = function(){
        me.limitTopicsOpened = !me.limitTopicsOpened;
    }

    $scope.activate = function (val) {
        me.currentTarget.activated_to = [val.id];
        $scope.marketListHelper.toggleMarket(val.id);
    };


    $scope.addManualPhrases = function(manualPhrases){

        $scope.manualPhrases = manualPhrases.slice(0);

        var alreadyExists = '', i, j,
            res = me.currentTarget.results[$scope.tab] || [],
            len = res.length,
            lenManual = $scope.manualPhrases.length;

        for(i=0; i<lenManual; i++){
            var x = $scope.manualPhrases[i];

            var manual = $scope.manualPhrases[i].text.toLowerCase();

            for(j=0; j<len; j++){
                var text = res[j].phrase || res[j].text;
                text = text.toLowerCase();
                if(text == manual && !res[j].dynamic){
                    alreadyExists += manual+" , ";
                    $scope.manualPhrases[i].alreadyExists=true;
                }
            }
        }

        if(alreadyExists){
            notificator.notify({body: alreadyExists.substring(0, alreadyExists.length-3)+" already exists"});
            $scope.manualPhrases = $scope.manualPhrases.filter(function(phrase){
                return !phrase.alreadyExists;
            });
        }

        if(!$scope.manualPhrases.length){
            return;
        }


        $scope.manualPhrases.forEach(function(phrase){

            phrase.manual = true;
            if((me.currentTarget.target_type||'').includes("audience")){
                phrase.audience_id = context.current.audience_id;
            }
            TargetsCommon.TargetData.convertManualPhrase(phrase);
        });



        TargetsCommon.addToTargetCall({
            target: me.currentTarget, 
            type: $scope.tab, 
            selected: $scope.manualPhrases
        }).then((target)=>{

            TargetsCommon.TargetData.targetToUI({
                target: target, context: context, action: 'edit-target'
            });

            me.currentTarget = $.extend(true, {}, target);
            $scope.showTables();
            $scope.manualPhrases = null;

            $scope.broadcastRoot('updateTargets', {
                target: target,
                action: 'add-to-target',
                selected: target.results[$scope.tab],
                tab: $scope.tab
            });
           
            setTimeout(function(){$(".manual #clear-all-seeds").trigger("click");});
        });
    };

    $scope.broadcastRoot = function(name, params){
        //window may have closed , thus deleting the ref
        if($scope && $scope.$root){
            $scope.$root.$broadcast(name, params);
        }
    }

    $scope.init();
}

module.exports = editTargetCtrl;
