"use strict";
var tmpl = require("./rt-associations-widget.html"),
	BaseWidget = require("../base_widget"),
	c = require("infra/utils/common"),
	rtAssociationsModule = require("data/rt-associations-service");

function rtAssociationsWidget(rtAssociationsService, associationTrendService, util) {
	this.rtAssociationsService = rtAssociationsService;
	this.associationTrendService = associationTrendService;
	this.util = util
}

rtAssociationsWidget.prototype._doUpdate = function (newVals,oldVals) {
	var self = this;
	const brandTerm = [_.find(this.$scope.terms, (term) => term.text == this.$scope.brandName)];
	if (_.isUndefined(brandTerm[0])) return false;

	const kwd_ids = this.util.getPhrasesToIdMap(brandTerm)
	const promise = this.associationTrendService.getAssociations('normal', 'absolute', c.getTimeframe(30, false), brandTerm, [], this.$scope.geos, [], kwd_ids, [], 'articles', 5)
	return promise.then(res =>
		this.rtAssociationsService.getVelocity(brandTerm[0].id, res.topData, this.$scope.geos).then(result => {
			self.$scope.textLimit = 25
			self.$scope.result = result.map(r => Object.assign(r, {abs: Math.abs(r.score)}))
		})
	)
};

rtAssociationsWidget.$inject = ["rtAssociationsService", "associationTrendService", "util"];
module.exports = angular.module(__filename, [
	rtAssociationsModule.name
]).directive("rtAssociationsWidget", [function () {
	return BaseWidget({
		restrict: "E",
		template: tmpl,
		controller: rtAssociationsWidget,
		scope: {
	        brandName: "=brandName",
			terms: "=terms",
			geos:  "=geos"
		}
	});
}]);
