"use strict";
module.exports = angular.module(__filename, [
    require("angular-modal-service").name
]).service('confirmAction', ['ModalService', function (ModalService) {
    this.getConfirmation = showConformationModal;

    //////////////////////////////

    function showConformationModal(message, txtConfirm, txtCancel, txtTitle, applyClass) {
        return ModalService.showModal({
            template: require('./confirmation.html'),
            bodyClass: applyClass,
            inputs: {message: message, txtConfirm: txtConfirm, txtCancel: txtCancel, txtTitle: txtTitle},
            controller: ['$scope', 'message', 'close', 'txtConfirm', 'txtCancel', 'txtTitle', ConformationModalController]
        });
    }

    function ConformationModalController($scope, message, close, txtConfirm, txtCancel, txtTitle) {
        $scope.txtTitle = txtTitle ? txtTitle : "Confirmation required";
        $scope.message = message;
        $scope.txtConfirm = txtConfirm || 'Yes';
        $scope.txtCancel = txtCancel || 'No';

        $scope.confirmSave = function (save) {
            close(save)
        };
        $scope.close = function () {
            close(false)
        };
    }
}]);
