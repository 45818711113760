"use strict";
var config = require("infra/config"),
    c = require("infra/utils/common");

module.exports = angular.module(__filename, [])
    .service('topicsTrendService', ['topicsTree', 'baseInsightsService', function(topicsTree, baseInsightsService) {
        var latestData;
        /*API*/
        this.getTrendsByTopics = getTrendsByTopics;
        this.getLatestData = getLatestData;

        var SERVER_URL = config.INSIGHTS_API + '/insights/get_views_by_topic?&disable_notification=1';

        var full_topic_tree = topicsTree.topics;
        var topic_hash = {};
        angular.forEach(full_topic_tree, function(topic){
            topic_hash[topic.id] = topic.name;
            angular.forEach(topic.children,function(child){
                topic_hash[child.id] = child.name;
            })
        });

        function getTrendsByTopics(params) {
            var termMap = {};
            var parameters = baseInsightsService.buildParams(termMap, params);
            if (_.isEmpty(termMap)) return Promise.reject("No Terms");

            parameters.to_normalize = params.to_normalize;
            parameters["topics"] = _.or(_.map(params.topics, 'id'), topicsTree.getAllIds());
            parameters.channel = params.channel;
            parameters.user_1st_party_audience = params.user_1st_party_audience;
            return baseInsightsService.postAsyncData(SERVER_URL, parameters).then(function(res) {
                var terms = params.terms.concat(params.boolean_logics || []);
                var super_topic_plot = res.data.super_topic_plot;
                var main_topic_plot = res.data.main_topic_plot;
                var flattenSuperTopicEntities = flatten(terms, super_topic_plot);
                var flattenMainTopicEntities = flatten(terms, main_topic_plot);
                var insufficient = isResponseDataEmpty(super_topic_plot);
                latestData = organizeByTopic(flattenSuperTopicEntities, flattenMainTopicEntities);
                baseInsightsService.notifyInsufficient(insufficient);
                return _.keys(super_topic_plot).length == insufficient.length ? Promise.reject({}) : latestData;
            }).catch(baseInsightsService.handleError);
        }

        function getLatestData() {
          return latestData;
        }

        function organizeByTopic(flattenSuperTopicEntities, flattenMainTopicEntities) {
            var grouped_main_topic = _.groupBy(flattenMainTopicEntities,'topic_id');
            var topics = [];
            function topicMap(entities, topic_id, is_other){
                return {
                    id: topic_id + (is_other ? "-other" : 0),
                    label: is_other ? "Other" : entities[0].topic_text,
                    values: entities.map(function (e) {
                        return {
                            id: e.term_id,
                            label: e.term_text,
                            consumption: e.consumption,
                            ratio: e.ratio,
                            class: e.term_class,
                            distribution: e.distribution,
                            skew: e.skew
                        }
                    })
                };
            }
            angular.forEach(
                _.groupBy(flattenSuperTopicEntities,'topic_id'),
                function(entities, topic_id) {
                    topic_id = parseInt(topic_id);
                    var topic_children_ids = _.map((_.find(full_topic_tree, function(topic){return topic.id == topic_id;}) || {}).children || [],'id');
                    topic_children_ids.push(topic_id);
                    var new_topic = topicMap(entities, topic_id);
                    if(!_.isEmpty(topic_children_ids)){
                        var children_topics = [];
                        angular.forEach(topic_children_ids,
                            function(child_topic_id){
                                if(grouped_main_topic.hasOwnProperty(child_topic_id)) {
                                    children_topics.push(topicMap(grouped_main_topic[child_topic_id], child_topic_id, child_topic_id == topic_id));
                                }
                            });
                        new_topic.children = children_topics;
                    }
                    topics.push(new_topic);
                });
            return topics;
        }

        function flatten(terms, plot) {
            var values = [];
            if (angular.isObject(plot)) {
                terms.forEach(function(term) {
                    var plot_data = plot[term.text] || plot[parseInt(term.id)];
                    if (angular.isObject(plot_data)) {
                        var dataPoints = plot_data.datapoints;
                        var max = sum(dataPoints);
                        dataPoints.forEach(function(topic) {
                            values.push({
                                topic_id: topic.topic_id,
                                topic_text: topic_hash[topic.topic_id],
                                term_id: term.id,
                                term_text: term.text,
                                term_class: term.class,
                                consumption: topic.consumption,
                                ratio: max == 0 ? 0 : topic.consumption / max,
                                distribution: topic.distribution,
                                skew: topic.skew
                            })
                        });
                    }
                });
            }
            return values;
        }

        function sum(datapoints) {
            return datapoints.reduce(function(sum, obj) {
                return sum + obj.value;
            }, 0)
        }

        function isResponseDataEmpty(plot) {
            var insufficient = [];
            _.each(plot, function(plotData, term){
              if (plotData.datapoints.length == 0) {
                insufficient.push(term);
              }
            });
            return insufficient;
        }
    }]);
