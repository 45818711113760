"use strict";
let spinnerId = 0;
module.exports = require("angular").module(__filename, [])
    .directive('amSpinner', [function () {
        return {
            restrict: 'A',
            scope: {
                "amSpinner": "="
            },
            link: function ($scope, $element, attrs) {
                var spinnerElement = angular.element("<div class='loader-overlay'></div><div class='loader'>&nbsp;</div>");
                $scope.$watch('amSpinner', function (showSpinner) {
                    showSpinner ? $element.append(spinnerElement) : spinnerElement.remove();
                });
            }
        }
    }]
);
