"use strict";
var programs = require('../programs/programs.js'),
    users = require('../users/users.js'),
    phrases = require('../phrases/phrases.js'),
    organizations = require('../organizations/organizations.js'),
    domains = require('../domains/domains.js'),
    targets = require('../targets/targets-settings.js'),
    mixpanel = require("../../infra/mixpanel/mixpanel-settings");

var settingsModule = angular.module(__filename, [
    programs.name,
    users.name,
    organizations.name,
    mixpanel.name,
    domains.name,
    targets.name
]);

SettingsController.$inject = ["mixpanelSettings"];
function SettingsController(mixpanelSettings) {

}

settingsModule.stateConfig = {
    name: 'settings',
    url: '/settings',
    template: require('./settings.html'),
    controller: SettingsController,
    display: 'Settings',
    family: 'settings',
    redirectTo: 'settings.programs',
    children: [
        programs.stateConfig,
        users.stateConfig,
        phrases.stateConfig,
        organizations.stateConfig,
        targets.stateConfig,
        domains.stateConfig
    ]
};

module.exports = settingsModule;
