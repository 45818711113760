"use strict";
var config = require("infra/config");

module.exports = angular.module(__filename, [])
    .service('conceptsService', ['$http', function ($http) {

        var CONCEPT_API = config.USER_MGMT_API + "/programs/";

        var getDeletedConcepts = function (program_id) {
            var url = CONCEPT_API + program_id + '/exclude_concepts';
            return $http.get(url).then(function (res) {
                return res.data;
            }, function (error) {
                return {};
            });
        };

        var getDeletedConceptsForSeeds = function (program_id, seeds, key_trends) {
            var url = CONCEPT_API + program_id + '/exclude_concepts';
            var seeds_copy = angular.copy(seeds);
            if (key_trends) seeds_copy = seeds_copy.concat(['trending']);
            return $http.get(url, {params: {'seeds[]': seeds_copy}}).then(function (res) {
                return res.data;
            });
        };

        var restoreConcepts = function (program_id, ids) {
            var url = CONCEPT_API + program_id + '/exclude_concepts';
            return $http.put(url, {deleted_concepts_ids: ids});
        };

        var restoreAllConcepts = function (program_id) {
            var url = CONCEPT_API + program_id + '/exclude_concepts';
            return $http.delete(url);
        };

        var deleteConcept = function (program_id, seed, concept) {
            var url = CONCEPT_API + program_id + '/exclude_concepts' + "/" + escape(seed) + "/" + escape(concept);
            return $http.post(url);
        };

        return {
            list: getDeletedConcepts,
            list_for_seeds: getDeletedConceptsForSeeds,
            restore: restoreConcepts,
            restore_all: restoreAllConcepts,
            delete: deleteConcept
        };
    }]
);
