"use strict";
module.exports = angular.module(__filename, [
    require('data/settings/program-service').name,
    require("angular-modal-service").name

]).controller('shareProgramController', ['$scope', '$rootScope', 'program', 'close', 'programService', 'notificator',
    function ($scope, $rootScope, program, close, programService, notificator) {

        programService.usersToShareWith(program.id).then(function (data) {
            $scope.shareUsers = _.reject(data.shared, user => user.id == $rootScope.user.id);
            $scope.users = data.can_share.concat($scope.shareUsers);
        });

        $scope.close = close;
        $scope.share = shareProgram;
        $scope.toggleUser = toggleUser;
        $scope.isUserSelected = isUserSelected;
        $scope.program = program;

        // close modal on navigate back
        var closeListener = $scope.$root.$on("$stateChangeStart", function () {
            close({reload: false})
        });
        $scope.$on('$destroy', closeListener);

        function shareProgram() {
            var requestData = {users: _.map($scope.shareUsers, 'id')};
            requestData.users.push($rootScope.user.id);
            programService.share($scope.program.id, requestData).then(function (data) {
                notificator.success({body: data.name + ' shared successfully'});
                close({reload: true});
            });
        }

        function toggle(element, array) {
            var index = _.findIndex(array, {id: element.id});
            var found = index != -1;
            found ? array.splice(index, 1) : array.push(element);
        }

        function toggleUser(user) {
            toggle(user, $scope.shareUsers);
            $scope.search = '';
        }

        function isUserSelected(id) {
            return _.find($scope.shareUsers, {id: id});
        }
    }
]);
