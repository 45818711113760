"use strict";
module.exports = require('angular').module(__filename,[])
	.service("GeneralMold", [function(){

		function GeneralMold(){
			var self = this;
			self._value = [];
			self.default = [];

			self.replace = function(val){
				return self._value = val || [];
			}.bind(this);

		}
		return GeneralMold;
	}]);