"use strict";
/**
 * Created by pery on 29/07/2015.
 */

/**
 * when we use ng-repeat angular not make space between element (and that good)
 * but there is case that you want some space (for example when you use text-align: justify)
 **/
module.exports = angular.module(__filename, [])
    .directive('spaceBetween', function () {
        return {
            restrict: "A",

            link: function (scope, element, attr, ctrl) {
                if (!scope.$last) {
                    element.after('&#32;');
                }
            }
        }
    }
);
