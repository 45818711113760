"use strict";
"use strict";
const config = require('infra/config');
class DspService {
  constructor($http, CacheFactory, user) {
    this.$http = $http;
    this.user = user;
    this.cache = CacheFactory('dspService', {
      storageMode: 'memory',
      maxAge: 60 * 60 * 1000 // 1 hour
    });
    this.marketCacheKey = 'markets';
    this.advertisersCachePrefix = 'advertisers';
    this.insertionOrderCachePrefix = 'insertion'
    this.packageCachePrefix = 'package';
    this.baseURL = config.USER_MGMT_API + "/dsp";
  }

  getMarkets() {
    return this._getResourceFromCacheOrHTTP(this.marketCacheKey, '/markets').then(markets =>
      this._parseResults(markets, 'name', 'marketId', 'market')
    );
  }

  listAdvertisers(marketId) {
    return this.getAdvertisers(marketId, null, {limit: -1})
  }

  getAdvertisers(marketId, prefix, params) {
      const cacheKey = `${this.advertisersCachePrefix}:${marketId}:${prefix}`;
      const newParams = Object.assign({limit: 10, q: prefix}, params);
      return this._getResourceFromCacheOrHTTP(cacheKey, `/${marketId}/advertisers/${this.getSessionId()}`, newParams).then(advertisers =>
        this._parseResults(advertisers, 'name', 'advertiserId', 'advertiser')
      );
  }

  listInsertionOrders(marketId, advertiserID) {
      return this.getInsertionOrders(marketId, advertiserID, null, {limit: -1});
  }

  getInsertionOrders(marketId, advertiserID, prefix, params) {
    const cacheKey = `${this.insertionOrderCachePrefix}:${advertiserID}:${prefix}`;
    const newParams = Object.assign({limit: 10, q: prefix}, params);
    return this._getResourceFromCacheOrHTTP(cacheKey, `/${marketId}/advertisers/${advertiserID}/insertion/${this.getSessionId()}`, newParams).then(insertions =>
      this._parseResults(insertions, 'name', 'insertionOrderId', 'insertion')
    );
  }

  listPackages(marketId, insertionId) {
    return this.getPackages(marketId, insertionId, null, {limit: -1})
  }

  getPackages(marketId, insertionId, prefix, params) {
    const cacheKey = `${this.packageCachePrefix}:${insertionId}:${prefix}`;
    const newParams = Object.assign({limit: 10, q: prefix}, params);
    return this._getResourceFromCacheOrHTTP(cacheKey, `/${marketId}/insertion_order/${insertionId}/packages/${this.getSessionId()}`, newParams).then(packages =>
      this._parseResults(packages, 'packageName', 'packageId', 'package')
    );
  }

  getMarketFromId(id) {
    return this.getMarkets().then(markets => _.find(markets, { id }));
  }

  getAdvertiserFromId(id, marketId) {
    return this.getAdvertisers(marketId, id).then(advertisers => _.find(advertisers, { id }));
  }

  getInsertionOrderById(id, advertiserId, marketId) {
    return this.getInsertionOrders(marketId, advertiserId, id).then(insertions => _.find(insertions, { id }));
  }

  getSessionId() {
    return this.user.id;
  }

  _setMarket(marketId) {
    const url = this.baseURL + "/markets"
    marketId == this.currentMarket
      ? Promise.resolve()
      : this.$http.post(url, {market_id: marketId, session_id: this.getSessionId()}).then(() => this.currentMarket = marketId)
  }

  _getResourceFromCacheOrHTTP(cacheKey, path, params = {}) {
    const URL = this.baseURL + path;
    const cacheResults = this.cache.get(cacheKey);

    return cacheResults ? Promise.resolve(cacheResults) : this.$http.get(URL, {params}).then(res => {
      this.cache.put(cacheKey, res.data);
      return res.data
    });
  }

  _parseResults(results, nameField, idField, nameKeyInResult) {
    const sortedList = _.chain(results).pickBy(_.identity).sortBy(o => parseInt(o[idField])).value();
    return _.map(sortedList, item => ({
      [nameKeyInResult]: `${item[idField]} - ${item[nameField]}`,
      id: item[idField],
      name: item[nameField]
    }));
  }
}

module.exports = require("angular").module(__filename, []) .service("dspService", ['$http', 'CacheFactory', 'user', DspService]);
