"use strict";
require("Chart.js");
const warVars = require("pages/warroom/warroom-vars");

module.exports = {

    sentiment: {
        labels: [],
        datasets: [
            {
                label: 'Negative Tweets',
                borderColor: warVars.colors.neg,
                backgroundColor: warVars.colors.neg,
                data: []
            },
            {
                label: 'Neutral Tweets',
                borderColor: warVars.colors.neu,
                backgroundColor: warVars.colors.neu,
                data: []
            },
            {
                label: 'Positive Tweets',
                borderColor: warVars.colors.pos,
                backgroundColor: warVars.colors.pos,
                data: []
            }
        ]
    },

    //events
    sentimentHandler: function(scope, e) {
        let ts, date_end, pos;

        if(!e.length) {
            return;
        }

        pos = e[0]._index;

        ts = scope.selected.phraseOrGroup.stats[pos].ts;
        if(scope.selected.phraseOrGroup.stats.length > pos + 1) {
            date_end = scope.selected.phraseOrGroup.stats[pos + 1].ts;
        } else {
            date_end = new Date(new Date(ts).getTime() + (new Date(ts) - new Date(scope.selected.phraseOrGroup.stats[pos - 1].ts))).toISOString();
        }
        scope.selected.tweetsSentiment = 'all';
        scope.clickEntity({entity: scope.selected.phraseOrGroup, date: ts, date_end: date_end});
    },

    summaryHandler: function(scope, sen) {
        scope.$apply();
        scope.changeSentiment(sen);
    },

    setGraphOptions: function(scope) {

        this.sentiment.labels = [];

        this.sentiment.datasets.forEach(function(dat) {
            dat.data = [];
        });

        this.options = $.extend(true, {}, scope.CHART.DEFAULT_OPTIONS);

        const largeScreen = window.outerHeight > 900;
        const font = largeScreen ? 14 : 12;

        this.options.maintainAspectRatio = false;
        this.options.animation = false;

        this.options.scales.xAxes[0].ticks.fontSize = font;
        this.options.scales.yAxes[0].ticks.fontSize = font;

        this.options.scales.xAxes[0].ticks.maxTicksLimit = 6;
        this.options.scales.yAxes[0].ticks.beginAtZero = true;
        this.options.scales.yAxes[0].stacked = true;

        const that = this;
        this.options.onClick = function(e, e2) {
            that.sentimentHandler(scope, e2);
        }
    },

    get: function(scope, resizing) {
        //called on update & click group

        this.setGraphOptions(scope);
        const that = this;

        let summary;

        let total = 0, pos = 0, neu = 0, neg = 0,
            pos_max = {val: 0, ts: null},
            neu_max = {val: 0, ts: null},
            neg_max = {val: 0, ts: null},
            all_max = {val: 0, ts: null};


        let stats = scope.selected.phraseOrGroup ? scope.selected.phraseOrGroup.stats : [];
        const format = scope.time.minutes > 60 * 24 ? "day" : "hour";

        $.each(stats, function(i, stat) {

            that.sentiment.labels.push(scope.timeDisplay(stat.ts, format));
            that.sentiment.datasets[0].data.push(stat.neg);
            that.sentiment.datasets[1].data.push(stat.neu);
            that.sentiment.datasets[2].data.push(stat.pos);

            total += stat.total;
            pos += stat.pos;
            neu += stat.neu;
            neg += stat.neg;

            if(stat.pos > pos_max.val) {
                pos_max = {val: stat.pos, ts: stat.ts, point_index: i};
            }

            if(stat.neu > neu_max.val) {
                neu_max = {val: stat.neu, ts: stat.ts, point_index: i};
            }

            if(stat.neg > neg_max.val) {
                neg_max = {val: stat.neg, ts: stat.ts, point_index: i};
            }

            if(stat.total > all_max.val) {
                all_max = {val: stat.total, ts: stat.ts, point_index: i};
            }

        });

        //create chart
        if(!this.sentimentChart || resizing) {

            if(this.sentimentChart) {
                this.sentimentChart.destroy();
            }


            try {

                this.sentimentChart = scope.CHART.NEW_CHART($("#sentiment_graph"), {
                    type: 'line',
                    data: that.sentiment,
                    options: that.options
                });

            } catch(er) {}


            setTimeout(function() {
                $("#summary_graph").css("opacity", 1);
                $("#summary_neg").bind("click", function(e) {that.summaryHandler(scope, 'neg');});
                $("#summary_neu").bind("click", function(e) {that.summaryHandler(scope, 'neu');});
                $("#summary_pos").bind("click", function(e) {that.summaryHandler(scope, 'pos');});
                $("#summary_total").bind("click", function(e) {that.summaryHandler(scope, 'all');});

                $("#max_graph").css("opacity", 1);
            });
        }

        //update chart
        if(that.sentiment.labels.length > 1 && that.sentimentChart) {

            const hi = warVars.getSize().hi;
            const max = scope.maxed == 'main_graph';
            let summaryHeight;

            if(hi < 700) {
                summaryHeight = max ? 16 : 4;
            } else if(hi < 800) {
                summaryHeight = max ? 24 : 6;
            } else {
                summaryHeight = max ? 48 : 12;
            }

            that.sentimentChart.data.labels = that.sentiment.labels;
            that.sentimentChart.data.datasets = that.sentiment.datasets;
            scope.CHART.UPDATE_CHART(that.sentimentChart);

            $("#pos_max_ts").html(scope.timeDisplay(pos_max.ts, format));
            $("#neu_max_ts").html(scope.timeDisplay(neu_max.ts, format));
            $("#neg_max_ts").html(scope.timeDisplay(neg_max.ts, format));
            $("#all_max_ts").html(scope.timeDisplay(all_max.ts, format));
            $("#all_max").attr('ts', all_max.ts);
            $("#pos_max").attr('ts', pos_max.ts);
            $("#neu_max").attr('ts', neu_max.ts);
            $("#neg_max").attr('ts', neg_max.ts);
            $("#all_max").attr('point_index', all_max.point_index);
            $("#pos_max").attr('point_index', pos_max.point_index);
            $("#neu_max").attr('point_index', neu_max.point_index);
            $("#neg_max").attr('point_index', neg_max.point_index);


            $("#summary_neg_val").html(neg);
            $("#summary_neu_val").html(neu);
            $("#summary_pos_val").html(pos);

            $("#summary_neg_hi").css("height", Math.round(Math.max(summaryHeight * neg / total, 1)) + "vh");
            $("#summary_neu_hi").css("height", Math.round(Math.max(summaryHeight * neu / total, 1)) + "vh");
            $("#summary_pos_hi").css("height", Math.round(Math.max(summaryHeight * pos / total, 1)) + "vh");
        }

    } //end of get

};

