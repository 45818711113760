"use strict";
var angularUtils = require('common/angularUtils');

module.exports = angular.module(__filename, [
  require("../../../../common/am-switch-button/am-switch-button").name,
  require("../../../../common/am-input-collection/am-input-collection.js").name
]).directive('permissionsPanel', ['$timeout', function ($timeout) {

    const NONE_SELECTED = Symbol.for('None Selected');
    const ALL_SELECTED = Symbol.for('All Selected');
    const SOME_SELECTED = Symbol.for('Some Selected');

    return {
        restrict: 'E',
        require: ['^form', 'ngModel'],
        template: require('./permissions-panel.drv.html'),
        scope: {
          list: '=',
          countries: '=',
          geoModel: '=?',
          fullModel: '=?'
        },
        controller: ["$scope", function($scope) {
          $scope.allSelected = undefined;
          $scope.someSelected = undefined;
          $scope.noneSelected = true;
          $scope.selectAllChecked = undefined;
          $scope.countriesFullModel = {};

          $scope.init = function() {
            var self = this;
            $scope.updateSelectedCounts();
            $scope.initPermissionForest();
            if ($scope.ngModel.$pristine) {
              angularUtils.ngModelSkipDirty($scope.ngModel, $scope.updateForm)();
            } else {
              $scope.updateForm();
            }
            $scope.ngModel.$render = function() {
              var newSet = _.castArray(self.ngModel.$viewValue);
              _.each(self.list, function(permission) {
                permission.on = _.includes(newSet, permission.id);
              });
            };
            $scope.initialized = true;
          };

          var setIndeterminateGroup = function(parent) {
            // set indeterminate when all the children are not all 'off' or all 'on'
            parent.indeterminate = ( (parent.viewableChildrenCount > parent.viewableOnChildrenCount) || (parent.viewableIndeterminateChildrenCount > 0) ) && (parent.viewableOnChildrenCount > 0);
          };

          var initIndeterminateGroup = function(parent) {
            if (parent.initGroup) return;
            parent.viewableChildrenCount = 0;
            parent.viewableOnChildrenCount = 0;
            parent.viewableIndeterminateChildrenCount = 0;
            parent.indeterminate = false;
            parent.initGroup = true;
          };

          var initIndeterminateGroupStep = function(parent, child) {
            initIndeterminateGroup(parent);
            parent.viewableChildrenCount = parent.viewableChildrenCount + 1;
            parent.viewableOnChildrenCount = parent.viewableOnChildrenCount + !!child.on;
            parent.viewableIndeterminateChildrenCount = parent.viewableIndeterminateChildrenCount + !!child.indeterminate;
            setIndeterminateGroup(parent);
          };

          $scope.initPermissionForest = function() {
            if ($scope.list) {
              $scope.forest = _.clone($scope.list);
              var _forest = _($scope.forest);
              var children = _forest.filter(function(o) {return _.has(o,"parent")});
              children.forEach(function(child) {
                var parent = _forest.find({id: child.parent.id});
                if (child.display) {
                  initIndeterminateGroupStep(parent, child);
                }
                if (!_.isArray(parent.children)) {
                  parent.children = [];
                }
                parent.children.push(child);
                _.remove($scope.forest, child);
              });
              $scope.forest = _.groupBy($scope.forest, "group");
              $scope.initForestExternals();
            }
          }

          $scope.initForestExternals = function() {
            $scope.forest['applications.external'] = _.remove($scope.forest['applications'], {name: 'show blocked applications in UI'});
          }

          $scope.updateModel = function() {
            $scope.ngModel.$setViewValue(_($scope.list).filter(function(o){ return o.on && !o.disabled; }).map('id').value());
            $scope.fullModel = _.reduce( $scope.list, function(h, o) {
              if (o.disabled) return h;
              var key;
              if (o.indeterminate) {
                key = "indeterminate";
              } else if (o.on) {
                key = "selected";
              } else {
                key = "unselected";
              }
              h.permissions[key] = _.concat(h.permissions[key] || [], o.id);
              return h} , {permissions: {}});
            $scope.fullModel["countries"] = $scope.countriesFullModel;
          };

          $scope.allMarked = function() {
            if (!_.isObject($scope.list)) {
              return NONE_SELECTED;
            }
            var displayAmount = _($scope.list).filter(function(p) { return p.display; }).size();
            var markedOn = _($scope.list).filter(function(p) { return p.display; }).reduce(function(prev, curr) { return prev + curr.on - !!curr.indeterminate; }, 0);
            var markedIndeterminate = _($scope.list).filter(function(p) { return p.display; }).reduce(function(prev, curr) { return prev + !!curr.indeterminate; }, 0);
            if (markedOn == 0 && markedIndeterminate == 0) {
              return NONE_SELECTED;
            } else if (markedOn == displayAmount) {
              return ALL_SELECTED;
            } else {
              return SOME_SELECTED;
            }
          };

          $scope.updateForm = function(params) {
            $scope.updateSelectedCounts(params);
            $scope.updateModel();
            $scope.validate();
          }

          $scope.updateSelectedCounts = function(params) {
            if (params) {
              $scope.allSelected  = params.allSelected;
              $scope.someSelected = params.someSelected;
              $scope.noneSelected = params.noneSelected;
              $scope.selectAllChecked = params.allSelected;
              $scope.selectAllIndeterminated = false;
            } else {
              var allMarked = $scope.allMarked();
              $scope.allSelected  = (allMarked === ALL_SELECTED);
              $scope.someSelected = (allMarked === SOME_SELECTED);
              $scope.noneSelected = (allMarked === NONE_SELECTED);
              $scope.selectAllChecked = (allMarked === ALL_SELECTED || allMarked === SOME_SELECTED);
              $scope.selectAllIndeterminated = (allMarked === SOME_SELECTED);
            }
          };

          $scope.togglePermission = function(permission, value) {
            if (_.isString(permission)) {
              permission = _.find($scope.list, {name: permission});
            }
            if (value === undefined) {
              value = permission.on;
            } else {
              permission.on = value;
            }
            if (permission.children) {
              permission.initGroup = false; // reset indeterminate value and recalculate it:
              _(permission.children).filter(function(p) { return p.display; }).each(function(child) {
                child.on = value;
                child.indeterminate = false;
                initIndeterminateGroupStep(permission, child);
              });
              permission.on = !(permission.viewableOnChildrenCount === 0)
            }
            $scope.updateForm();
          };

          $scope.$on('togglePermission', function(event, data) {
            $scope.togglePermission(data.permission, data.value);
          });

          $scope.toggleSubPermission = function(permission) {
            var parent = _.find($scope.list, {id: permission.parent.id});
            parent.viewableOnChildrenCount = (parent.viewableOnChildrenCount || 0) + (permission.on ? 1 : -1);
            parent.viewableIndeterminateChildrenCount = Math.max((parent.viewableIndeterminateChildrenCount || 0) - !!permission.indeterminate, 0);
            permission.indeterminate = false;
            parent.on = !(parent.viewableOnChildrenCount === 0);
            setIndeterminateGroup(parent);
            $scope.updateForm();
          };

          $scope.selectAllPermissions = function(value) {
            _($scope.list).filter(function(p) { return p.display; }).each(function(p) { p.on = !!value; p.indeterminate = false; } );
            _($scope.list).filter(function(p) { return p.display; }).filter(function(p) { return p.children; }).each(function(p) {
              p.viewableOnChildrenCount = value ? p.viewableChildrenCount : 0;
              p.viewableIndeterminateChildrenCount = 0;
            });
            var params = {allSelected: (value === true), noneSelected: (value === false), someSelected: false};
            $scope.updateForm(params);
          };

          $scope.$on('selectAllPermissions', function(event, value) {
            $scope.selectAllPermissions(value);
          });

          $scope.hasPermission = function(permissionName) {
            return _.find($scope.list, {name: permissionName});
          };

          $scope.hasVisibilePermission = function(permissionName) {
            return _.find($scope.list, {name: permissionName, display: true});
          };

          $scope.filterToDisplay = function(permission) {
            return permission.display;
          };

          $scope.isPermissionSelected = function(permissionName) {
            var p = $scope.hasPermission(permissionName);
            return p && p.on;
          };
        }],

        link: function ($scope, $element, $attr, $ctrls) {
          $scope.$formCtrl = $ctrls[0];
          $scope.ngModel = $ctrls[1];
          $scope.validate = function() {
            $scope.$formCtrl.$setValidity("permissions__none_selected", !$scope.noneSelected);
            $scope.validateSpecialPermissions();
            $scope.validateSubPermissions();
            $scope.validateGeos();
            $scope.permissionsErrors = _.keys($scope.$formCtrl.$error);
          };


          $scope.validateGeos = function() {
            if ($scope.geoModel != undefined) {
              $scope.$formCtrl.$setValidity("geos", $scope.countriesFullModel.status && $scope.countriesFullModel.status != Symbol.for("None Selected"));
            }
          };


          $scope.validateSubPermissions = function() {
            _($scope.list).filter('children').each(function(permission) {
              if (permission.on) {
                $scope.validateSubPermission(permission);
              } else {
                $scope.$formCtrl.$setValidity("permissions__sub_application__" + permission.name, true);
              }
            });
          };

          $scope.validateSubPermission = function(permission) {
            var allSiblings = permission.children;
            var atLeastOneSiblingOn = _.reduce(allSiblings, function(r, s) { return (r || s.on) }, false);
            $scope.$formCtrl.$setValidity("permissions__sub_application__" + permission.name, atLeastOneSiblingOn);
          };

          $scope.validateSpecialPermissions = function() {
            var scoreBreakdownValidity = true,
                normalizeValidity = true;

            if ($scope.hasVisibilePermission('score breakdown')) {
              scoreBreakdownValidity = !$scope.isPermissionSelected('score breakdown') || $scope.isPermissionSelected('discovery');
              $scope.$formCtrl.$setValidity("permissions__score_breakdown", scoreBreakdownValidity);
            }

            if ($scope.hasVisibilePermission('normalize')) {
              normalizeValidity = !$scope.isPermissionSelected('normalize') || $scope.isPermissionSelected('insights');
              $scope.$formCtrl.$setValidity("permissions__normalize", normalizeValidity);
            }
          };


          $scope.$watch('list', function(newval, oldval, $scope) {
            if (newval === undefined) return;
            $scope.init();
          });

          $scope.$watch('geoModel', function(newval, oldval, $scope) {
            _.set($scope.fullModel, "countries", $scope.countriesFullModel);
            $scope.validateGeos();
          });
        }
    }
}]);
