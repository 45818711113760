"use strict";
//to use: parent needs scope.prediction = {}
//for public methods vars

var utilModule = require("infra/util.js");
var config = require("infra/config");
var chart = null;

module.exports = angular.module(__filename, [utilModule.name])
    .directive("prediction", ['$http', '$timeout', 'util', 'CHART', function ($http, $timeout, util, CHART) {

    return {
        restrict: 'E',
        template: require("./grid.discovery.prediction.html"),
        scope: true,
        link: function (scope, elem, attrs, ngModel) {
            //events
            window.onresize = function () {
                if (scope.prediction.data) {
                    scope.getGraphCB();
                }
            };

            scope.$watch('prediction.phrases', function (newVal, oldVal) {
                if (scope.prediction.phrases && scope.prediction.phrases.length) {
                    scope.getGraph();
                }
            }, true);

            scope.prediction.open = function(){ //public method
                scope.loading = true;
                scope.prediction.opened = true;
                scope.inputBarSize();

                scope.randomNoises = [];
                for(var i=0;i<100;i++){
                    scope.randomNoises.push(Math.random() * 0.7 + 1);
                }

                if(scope.response){
                    scope.getGraphCB();
                }
            }


            scope.prediction.close = function () { //public method
                scope.prediction.opened = false;
                if (chart) {
                    chart.destroy(); 
                }
                $timeout(function () {
                    chart = null;
                });
            };

            scope.selectedTab = localStorage["prediction_tab"] || "consumption";

            scope.selectTab = function (tab) {
                scope.selectedTab = tab;
                localStorage["prediction_tab"] = tab;
                scope.getGraphCB();
            };

            scope.getGraph = function () {
                scope.loading = true;

                var kwd_ids = {}, included = [];

                scope.prediction.phrases.forEach(function (term) {
                    if (term.type == 'booleanLogic' || term.type == 'programBL') {
                        AddarrayText(term.included);
                        AddarrayText(term.required);
                        AddarrayText(term.excluded);
                    } else {
                        kwd_ids[term.text.toLowerCase()] = term.id;
                        included.push(term.text);
                    }
                });

                function AddarrayText(arr) {
                    arr = arr || [];
                    arr.forEach(function (x) {
                        kwd_ids[x.text.toLowerCase()] = x.id;
                    });
                }

                var phrases = {
                    id: new Date().getTime(),
                    text: "consumption",
                    type: "booleanLogic",
                    required: [],
                    included: included,
                    excluded: []
                };

                var bl = util.getTerms(scope.prediction.phrases, true).slice(0);
                if (included.length) {
                    bl = bl.concat(phrases);
                }

                var params = {
                    disable_notification: 1,
                    examples_mode: 'consumption',
                    channel: scope.prediction.channel,
                    kwd_ids: kwd_ids,
                    'boolean_logics': bl,
                    sensitive_content: false,
                    timeframe_end: moment().format("YYYY-MM-DD"),
                    timeframe_start: moment().add(-1, 'months').format("YYYY-MM-DD"),
                    with_prediction: true
                };
                
                $http({method: 'POST', url: config.SEARCH_API + '/insights', data: params})
                    .success(function (response) {
                        scope.getGraphCB(response);
                    }).error(function (e) {
                        console.log(e);
                    });
            };

            scope.getGraphCB = function (response) {
                scope.inputBarSize();

                if (response) { //saved so we can manipulate data without extra calls
                    //sort
                    var arr = [];
                    for (var i in response.consumption) {
                        arr.push({date: i, value: response.consumption[i]});
                    }

                    arr = arr.sort(function (a, b) {
                        return (new Date(a.date)).getTime() - (new Date(b.date)).getTime();
                    });

                    scope.response = arr.slice(0);
                }

                if (!chart) { //new chart
                    var ops = CHART.DEFAULT_OPTIONS;
                    ops.animation = false;


                    chart = CHART.NEW_CHART($("#prediction-chart"),
                        {
                            type: 'line',
                            data: scope.dataToGraph(scope.response),
                            options: ops
                        }
                    );
                } else { //update chart
                    chart.data.datasets = scope.dataToGraph(scope.response).datasets;
                    CHART.UPDATE_CHART(chart);
                }

                scope.loading = false;
            }

            scope.inputBarSize = function(){

               var height = Math.max($("prediction tags").height()*1+ 14, 40);
               
               $("prediction input-bar, prediction textarea").css("height", height);

               $("prediction input-bar").css("margin-top", 90-height);
            };

            scope.dataToGraph = function (response) {
                if (scope.selectedTab == 'consumption') {
                    return scope.consumptionToGraph(response);
                } else {
                    return scope.reachToGraph(response);
                }
            };

            scope.consumptionToGraph = function (response) {
                var data = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Selected Interests',
                            borderColor: '#fff',
                            backgroundColor: 'transparent',
                            data: []
                        },
                        {
                            label: 'Forecast',
                            borderColor: '#44b1c8',
                            backgroundColor: 'transparent',
                            data: []
                        },
                        {
                            label: 'Upper Confidence Bound',
                            borderColor: '#44b1c8',
                            backgroundColor: 'transparent',
                            borderWidth: 1,
                            borderDash: [2, 2],
                            data: []
                        },
                        {
                            label: 'Lower Confidence Bound',
                            borderColor: '#44b1c8',
                            backgroundColor: 'transparent',
                            borderWidth: 1,
                            borderDash: [6, 4],
                            data: []
                        }
                    ]
                };

                var total = {},
                    prediction = {},
                    prediction_top = {},
                    prediction_bottom = {};

                //set all labels
                response.forEach(function (cell) {
                    data.labels.push(cell.date);
                });

                var length = data.labels.length, conncetedVal = null;

                response.forEach(function (cell, i) {
                    data.datasets[0].data.push(cell.value.total);
                    data.datasets[1].data.push(cell.value.prediction);
                    data.datasets[2].data.push(cell.value.prediction_top);
                    data.datasets[3].data.push(cell.value.prediction_bottom);
                });

                //connect between past & future
                for (var i = 0; i < data.datasets[0].data.length; i++) {
                    var val = data.datasets[0].data[i];
                    if (val != null) {
                        conncetedVal = val;
                    } else {
                        data.datasets[1].data[i - 1] = conncetedVal;
                        data.datasets[2].data[i - 1] = conncetedVal;
                        data.datasets[3].data[i - 1] = conncetedVal;
                        break;
                    }
                }

                return data;
            };

            scope.reachToGraph = function (response) {
                var data = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Selected Interests',
                            borderColor: '#fff',
                            backgroundColor: 'transparent',
                            data: []
                        },
                        {
                            label: 'Forecast',
                            borderColor: '#44b1c8',
                            backgroundColor: 'transparent',
                            data: []
                        }
                    ]
                };

                function getReach(val, i) {
                    if (val == null) {
                        return null;
                    }
                    var noise = scope.randomNoises[i%100];
                    return Math.round(val * noise * 1000);
                }

                var total = {},
                    prediction = {};

                // set all labels
                response.forEach(function (cell) {
                    data.labels.push(cell.date);
                });

                var length = data.labels.length, connectedVal = null;

                response.forEach(function (cell, i) {
                    data.datasets[0].data.push(getReach(cell.value.total, i));
                    data.datasets[1].data.push(getReach(cell.value.prediction, i));
                });

                // connect between past & future
                for (var i = 0; i < data.datasets[0].data.length; i++) {
                    var val = data.datasets[0].data[i];
                    if (val != null) {
                        connectedVal = val;
                    } else {
                        data.datasets[1].data[i - 1] = connectedVal;
                        break;
                    }
                }

                return data;
            }
        }
    }
}]);
