"use strict";
module.exports = angular.module(__filename, [
]).directive('amPlaceholder', function() {
  return {
    restrict: 'A',
    scope: {
      amPlaceholder: '='
    },
    link: function($scope, $elem, $attr) {
      $scope.$watch('amPlaceholder', function(newValue, oldValue) {
        if (newValue != undefined) {
          $elem[0].placeholder = $scope.amPlaceholder;
        }
      });
    }
  }
});
