"use strict";
"use strict";

module.exports = angular.module(__filename, [
    require("angular-modal-service").name
]).service('abiNotificationModal', ['ModalService', '$timeout', function (ModalService, $timeout) {
    this.show = show;

    function show(type, title, text, timeout) {
        return ModalService.showModal({
            template: require('./template.html'),
            controller: ['$scope', 'close', function (scope, close) {
                var t = timeout || 10000;
                scope.title = title;
                scope.text = text;

                if (type == 'good') {
                    scope.icon = 'icon-tick_in_circle';
                } else if (type == 'bad') {
                    scope.icon = 'icon-exclamation_in_circle';
                }

                $timeout(function () {
                    close()
                }, t);
            }]
        });
    }
}]);
