"use strict";
var Template = require("./top-association-widget.html"),
    d3 = require("d3"),
    c = require("infra/utils/common"),
    ex = require("infra/utils/export"),
    mixpanel = require("infra/mixpanel/mixpanel-insights"),
    BaseWidget = require("../base_widget"),
    TopAssociationService = require("data/insights/association-trend-service"),
    TopChart = require("common/charts/top-chart"),
    TopBar = require("common/charts/top-bar"),
    keywords = require("data/keywords.srv"),
    examplesDataModel = require("data/insights/examples-data-model"),
    consumptionTrendService = require("data/insights/consumption-trend-service"),
    TrendChart = require('common/charts/trend-chart');

var topChartConfiguration = {
    topBarGroup: {
        graph: {},
        examples: {}
    }
};

var isDefined = angular.isObject,
    isArray = angular.isArray;

var isNumber = function (n) {
    return angular.isNumber(n) && !isNaN(n);
};

/** relative or absolute
 *
 *  relative = Strength (intersection divided by union).
 *  Association Strength is a global score  for association that  is received from the server.
 *
 *  absolute = Related consumption (Max-Index).
 *  Related Consumption is a relative score that shows the largest co-occurrence association as 100,
add */
const DEFAULT_VALUE_MEASURE = 'absolute';
const DEFAULT_VIEW_TYPE = 'normal';
const TREND_LINE_ERROR_MESSAGE = "The Graph is currently unavailable";
const associationExportName = 'Associations';
const consumptionExportTabName = 'Association Trend';
const strengthTemplate = _.template("Strength <%= parseInt(consumption).toLocaleString('en') %>, <%= consumptionAvg > 0 ? '+' : ''%><%= consumptionAvg %>% from Avg");
const consumptionTemplate = _.template("Consumption <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg");
const skewTemplate = _.template("Skew <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg");
const ASSOC_LIMIT = 60;

function TopAssociationController($scope, $rootScope, $timeout, $state, associationTrendService, mixpanelInsights, util,
                                  examplesDataModel, consumptionTrendService, insightsExportService, permissions, baseInsightsService) {

    var self = this;
    this.service = associationTrendService;
    this.consumptionService = consumptionTrendService;
    this.insightsExportService = insightsExportService;
    this.parameters = {};
    this.util = util;
    this.permissions = permissions;
    this.baseInsightsService = baseInsightsService;

    $scope.context = $rootScope.context;
    $scope.noData = true;
    $scope.trendLineError = false;
    $scope.enableUserTerms = c.isArray($scope.userTerms) && $scope.userTerms.length > 0;
    $scope.termsByUser = false;
    $scope.filterType = DEFAULT_VIEW_TYPE;
    $scope.valueMeasure = DEFAULT_VALUE_MEASURE;
    $scope.inProcess = false;
    $scope.showGraph = false;
    $scope.examplesData = examplesDataModel;
    $scope.spinner = false;
    $scope.legendTrends = [];
    $scope.suggestedAssociations = [];
    $scope.assocLimit = ASSOC_LIMIT;

    this.timeframe = null;
    this.terms = null;
    this.armed = false;
    this.topChart = null;
    this.userChart = null;
    this.chartData = null;
    this.chart = new TrendChart(this, "association-consumption-chart", {
        type: 'daily',
        force_max: true,
        agg_examples_mode: true,
        templates: {relative: strengthTemplate,
                    absolute: consumptionTemplate,
                    sov: consumptionTemplate,
                    skew: skewTemplate
        }
    });
    this.consumptionChartData = {
        chart: [],
        range: [],
        max: 100
    };
    this.selectedAssociation = {id: null};
    this.$scope = $scope;
    this.backupUserTerms = [];
    this.apiError = false;
    this.removedAssociation = false;
    this.addedAssociation = false;
    this.clearedAll = false;
    mixpanelInsights.trackPageView('associations');
    function trackAssociations() {
        var snapshot = angular.copy($rootScope.context.current);
        snapshot.measure = $scope.valueMeasure;
        var associations = $scope.userTerms;
        $rootScope.context.current.user_associations = associations;
        mixpanelInsights.trackAssociations(associations);
    }

    $scope.setType = function (type) {
        self.unselectAssociation();
        if (type != self.$scope.filterType) {
            self.$scope.filterType = type;
            self.$scope.inProcess = true;
            self.performUpdate(true);
        }
    };

    var getMeasureMax = function(data, measure) {
      if (_.isEmpty(data)) return null;
      if (measure === "skew") {
        let maxSkew = _.maxBy(data, "maxSkew").maxSkew;
        return maxSkew;
      } else {
        return 100;
      }
    };

    $scope.setValueMeasure = function (measure) {
        self.unselectAssociation();
        if (measure != self.$scope.valueMeasure) {
            self.$scope.valueMeasure = measure;
            self.measure = measure;
            topChartConfiguration.topBarGroup.graph.showGraph = self.showGraph;
            topChartConfiguration.topBarGroup.examples.showExamples = self.showExamples;
            if (self.userChart) self.userChart.destroy();
            self.userChart = new TopChart(self.type, self.removeUserAssociation,
                '#user-split-bar-chart', self.$scope.showGender ? {} : topChartConfiguration);
            if ( c.isArray($scope.userTerms) && $scope.userTerms.length > 0 ) {
                c.sortByNumeric(self.userData, self.service.getSortKey(self.measure));
                self.showUserAssociations(true);
                setRecordDescription(self.userData, self.measure);
                self.measureMax = getMeasureMax(self.userData, self.measure);
                self.userChart.draw(self.userData, self.type, self.measure, self.measureMax);
            }
            self.onResizeGraph();
            trackAssociations();
        }
    };

    $scope.addAllSuggestedAssociations = function () {
        var visibleItems = [];
        var parent = document.getElementsByClassName("suggested-associations-tags")[0];
        _.each(parent.getElementsByTagName("tag"), function (tag){
            if (tag.offsetTop < parent.offsetTop + parent.offsetHeight){
                  visibleItems.push(tag.getElementsByClassName("box text")[0].innerHTML);
              }
        });
        _.each($scope.suggestedAssociations, function (term) {
            term.text = term.label;
        });

        var terms = _.uniqBy(_.union($scope.userTerms,_.filter($scope.suggestedAssociations, function(o) {
                                                                                                      return _.includes(visibleItems, o.text)
                                                                                                    })),'id')
        if (terms.length <= ASSOC_LIMIT){
          angular.copy($scope.userTerms, self.backupUserTerms);
          $scope.userTerms = terms.slice(0, ASSOC_LIMIT);
          this.addedAssociation = true;
          trackAssociations();
        }else{
          self.notificator.notify({body: "Only " + ASSOC_LIMIT +" associations can be selected. Please narrow your search."});
        }


    };

    $scope.addSuggestedAssociation = function(term) {
        if ($scope.userTerms.length > ASSOC_LIMIT - 1){
          self.notificator.notify({body: "Only " + ASSOC_LIMIT + " associations can be selected. Please narrow your search."});
          return;
        }
        var suggestion = {
            id: term.id,
            text: term.label
        };

        angular.copy($scope.userTerms, self.backupUserTerms);
        self.addedAssociation = true;
        $scope.userTerms.push(suggestion);
        $scope.userTerms = _.uniqBy($scope.userTerms, 'id').slice(0, ASSOC_LIMIT);

        trackAssociations();
    };

    $scope.shouldDisplaySkew = function() {
        return $scope.filterType == 'normal';
    };

    $scope.shouldDisplaySov = function(){
        return $scope.filterType === 'normal' && _.isArray($scope.trends) && $scope.trends.length >= 2;
    };

    $scope.shouldDisplayTypeSelection = function() {
        return $scope.insightsChannel.value == 'articles' && !_.includes(['sov','skew'], $scope.valueMeasure);
    };

    function addAssociation() {
        if ($scope.currentUserTerms.length <= 0 && !self.clearedAll) {
            return;
        }

        self.clearedAll = false;
        var inputBarController = angular.element("input-bar.test-association").controller('inputBar');
        inputBarController.removeInvalidTerms();
        if ($scope.currentUserTerms.length == 0) return;
        if ($scope.userTerms.length > ASSOC_LIMIT - 1){
          self.notificator.notify({body: "Only " + ASSOC_LIMIT + " associations can be selected. Please narrow your search."});
          return;
        }
        _.each($scope.currentUserTerms, function (term) {
            term.id = c.getTermId(term);
        });

        angular.copy($scope.userTerms, self.backupUserTerms);
        self.addedAssociation = true;
        $scope.userTerms = _.uniqBy(_.union($scope.userTerms, $scope.currentUserTerms), 'id').slice(0, ASSOC_LIMIT);

        trackAssociations();
        $scope.currentUserTerms = [];
    }

    $scope.currentUserTerms = [];
    $scope.userTerms = $scope.userTerms || [];
    $scope.$watch('currentUserTerms', addAssociation);

    this.onResize = function () {
        if (this.armed) {
            this.userChart.draw(this.userData, this.type, this.measure, this.measureMax);
        }
        this.onResizeGraph();
    };

    this.performUpdate = function (noNewSuggestions) {
        $scope.isSuggestionsOpen = false;
        $scope.inProcess = true;
        self.armed = false;
        $scope.noData = true;
        self.type = c.isString($scope.filterType) && self.channel == 'articles' ? $scope.filterType : DEFAULT_VIEW_TYPE;
        self.measure = c.isString($scope.valueMeasure) ? $scope.valueMeasure : DEFAULT_VALUE_MEASURE;

        self.user_1st_party_audience = JSON.stringify(_.map($scope.userFirstPartyAudience,function(item) {
            return item.value;
        }));

        noNewSuggestions = noNewSuggestions || false;
        self.startLoader();
        return self.service.getAssociations(self.type, self.measure, self.timeframe, self.terms, $scope.userTerms,
            self.geo, self.topics, self.kwd_ids, self.boolean_logics, self.channel, ASSOC_LIMIT, self.parameters.sub_geos, c.getAudience($scope, self.channel),self.user_1st_party_audience).then(function (response) {
                self.chartData = response;
                $scope.noData = false;
                $scope.inProcess = false;
                $scope.enableUserTerms = true;
                self.armed = true;
                $scope.showGender = ($scope.filterType == 'gender' && self.channel == 'articles');
                c.sortByNumeric(response.userData, self.service.getSortKey(self.measure));
                self.userData = response.userData;
                self.topData = response.topData;
                if (c.isArray($scope.userTerms) && $scope.userTerms.length > 0 && c.isArray(response.userData)) {
                    $scope.termsByUser = true;
                    self.showUserAssociations(true);
                }
                $timeout(function () {
                    topChartConfiguration.topBarGroup.graph.showGraph = self.showGraph;
                    topChartConfiguration.topBarGroup.examples.showExamples = self.showExamples;
                    self.userChart = new TopChart(self.type, self.removeUserAssociation,
                        '#user-split-bar-chart', self.$scope.showGender ? {} : topChartConfiguration);
                    if ( c.isArray($scope.userTerms) && $scope.userTerms.length > 0 ) {
                        self.showUserAssociations(true);
                        setRecordDescription(response.userData, self.measure);
                        self.measureMax = getMeasureMax(response.userData, self.measure);
                        self.userChart.draw(response.userData, self.type, self.measure, self.measureMax);
                    }
                    var ids = _.map(response.userData, function(o){
                                                         return o.id;
                                                       });
                    self.showSuggestedAssociations(_.filter(noNewSuggestions ? $scope.suggestedAssociations : response.topData, function(o){
                                                                                return !_.includes(ids, o.id)
                                                                              }));
                    self.onResizeGraph();
                    self.stopLoader();
                }, 0);
                $scope.inProcess = false;
            }, function (error) {
                if (!self.skipLoad){
                  self.stopLoader();
                }
                self.clear(true);
                if(error.hasOwnProperty('status') && error.status == -1) {
                  self.apiError = true;
                  $scope.userTerms = self.backupUserTerms;
                  trackAssociations();
                }
                $scope.$emit('insightsError', error);
            });
    };

    this.clearCustomReferences = function () {
        $scope.enableUserTerms = false;
        $scope.userTerms = [];
        this.service.clearCustomReferences();
    };

    this.clear = function (update) {
        $scope.noData = true;
        $scope.inProcess = false;
        $scope.termsByUser = false;
        this.timeframe = null;
        this.terms = null;
        this.geo = null;
        this.topics = null;
        this.armed = false;
        $scope.examplesData.examples.association = [];
        $scope.examplesData.visible_examples = [];
        $scope.examplesData.filters = [];
        $scope.examplesData.filter_type = '';
        $scope.suggestedAssocations = [];
        this.unselectAssociation();
        if (update) {
            var type = c.isString($scope.filterType) ? $scope.filterType : DEFAULT_VIEW_TYPE;
            if (c.is(this.userChart))
                this.userChart.setData([], type, $scope.valueMeasure);
        }
    };

    this.removeAssociation = function (association, associations, key, chart) {
        self.unselectAssociation();
        if (c.is(chart)) {
            if (c.is(associations) && c.isString(key) && c.isArray(associations[key]) && c.is(association)) {
                var data = []; // TODO Use index instead
                _.each(associations[key], function (entry) {
                    if (entry.id != association.id && entry.label != association.label) {
                        data.push(entry);
                    }
                });
                associations[key] = data;

                self.service.recalculate($scope.filterType, associations);
                self.userChart.setData(associations['userData'], $scope.filterType, $scope.valueMeasure, getMeasureMax(associations['userData'], $scope.valueMeasure));
            } else {
                chart.setData([], $scope.filterType, $scope.valueMeasure);
            }
        }
    };

    this.showGraph = function (selectedSeed) {
        if (selectedSeed.id != self.selectedAssociation.id) {
            self.closeAssociationObjects();
        }
        $scope.AssociationTitle = "Association trend of \"" + selectedSeed.label + "\" with";
        $scope.showGraph = true;
        $scope.examplesData.alphabetized = true;
        $scope.examplesData.open = true;
        $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        self.getAssociationData(selectedSeed);

        self.insightsExportService.addReportFunction(self.getExportReport, true);
        self.insightsExportService.addReportFunction(self.getConsumptionExportReport, false);
    };

    this.showExamples = function (selectedSeed) {
        if (selectedSeed.id != self.selectedAssociation.id) {
            self.closeAssociationObjects();
        }
        $scope.examplesData.open = true;
        $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        self.getAssociationData(selectedSeed);
    };

    this.onResizeGraph = function () {
        if ($scope.showGraph) {
            var tickMax = Math.ceil(self.consumptionChartData.max / 10) * 10;
            self.chart.draw(self.consumptionChartData, tickMax, $scope.valueMeasure);
            self.chart.addCircles($scope.examplesData.visible_examples);
        }
    };

    this.getAssociationData = function (selectedSeed) {
        $scope.trendLineError = false;
        var associated;
        if (isFinite(selectedSeed.id) || ['@', '#'].indexOf(selectedSeed.id[0]) != -1) {
            associated = {text: selectedSeed.label, id: selectedSeed.id};
        } else {
            associated = _.find($scope.userTerms, {id: selectedSeed.id});
        }

        if (selectedSeed.id != self.selectedAssociation.id && isDefined(self.timeframe) && isArray($scope.trends)) {
            $scope.spinner = true;
            self.resetContentDriversPaneFilters();
            self.resetHighlightedLetter();
            $scope.examplesData.examples.association = [];
            $scope.examplesData.visible_examples = [];

            if ($scope.trends.length < 1) {
                // TODO Clean data
            } else {
                self.selectedAssociation = associated;
                self.chart.resetHighlightedCircle();
                this.parameters.measure = $scope.valueMeasure == 'sov' ? 'absolute' : $scope.valueMeasure;
                this.parameters.associated = associated;
                this.parameters.user_1st_party_audience = _.map($scope.userFirstPartyAudience,function(item) {
                    return item.value;
                });
                console.log(this.parameters.user_1st_party_audience);
                $scope.examplesData.promise = self.consumptionService.getAssociationTrendLine(self.parameters, associated);
                return $scope.examplesData.promise.then(function (data) {
                    if (isDefined(data) && isArray(data.averages) && isNumber(data.max)) {
                        $scope.examplesData.examples.association = data.examples;
                        _.each($scope.examplesData.examples.association, function (ex) {
                            ex.class = $scope.termClasses[ex.keyword];
                        });
                        $scope.examplesData.examples.association = _.filter($scope.examplesData.examples.association, function (example) {
                            return $scope.termClasses[example.keyword]
                        });
                        $scope.examplesData.visible_examples = $scope.examplesData.examples.association;
                    }
                    $timeout(function () {
                        self.consumptionChartData = data;
                        self.onResizeGraph();
                        $scope.spinner = false;
                    }, 0);
                }, function (err) {
                    $scope.trendLineError = true;
                    $scope.spinner = false;
                });
            }
        } else {
            $timeout(function () {
                self.onResizeGraph();
                $scope.spinner = false;
            }, 0);
        }
    };

    $scope.hasTrendLineError = function () {
        $scope.trendLineErrorMsg = TREND_LINE_ERROR_MESSAGE;
        return $scope.trendLineError;
    };

    this.showSuggestedAssociations = function(suggested) {
        angular.copy(suggested, $scope.suggestedAssociations);
    };

    var setRecordDescription = function(data, measure) {
      var recordDescriptionFn = (record) => undefined;
      if (measure === 'skew') {
        recordDescriptionFn = function({seed, assoc}) {
          let skew = TopBar.prototype.formatLabel(assoc.skew);
          switch (true) {
            case (skew == 0):
              return `${assoc.display} is unlikely to be consumed with ${seed.label}.`;
            case (skew < 1):
              let reciprocal_skew = TopBar.prototype.formatLabel(1 / assoc.skew);
              return `${assoc.display} is x${reciprocal_skew} times less likely to be consumed with ${seed.label} than with other associations.`;
            case (skew == 1):
              return `${assoc.display} is as likely to be consumed with ${seed.label} as it is with other associations.`;
            default:
              return `${assoc.display} is x${skew} times more likely to be consumed with ${seed.label} than with other associations.`;
          }
        }
      }
      _.each(data, function(record) {
        _.each(record.values, function(assoc) {
          assoc.title = recordDescriptionFn({seed: record, assoc: assoc});
        });
      });
    }

    this.showUserAssociations = function (show) {
        if (!(c.isArray(self.terms) || c.isArray(self.boolean_logics))) return;
        $scope.termsByUser = show;
        var numberOfTerms = self.measure == 'sov' ? 1 : self.terms.length + self.boolean_logics.length;
        var h = (numberOfTerms * 18) + 38;
        h = c.isArray(self.userData) ? self.userData.length * h : 0;
        h = (h > 0 && DEFAULT_VIEW_TYPE != $scope.filterType) ? (h + 51) : (h > 0) ? h + 23 : h;
        c.setElementHeight('user-associations-container', h, h);
    };

    this.removeUserAssociation = function (association) {
        if (c.is(self) && c.is(association)) {
            var userTerms = [];
            self.removedAssociation = true;
            _.each($scope.userTerms, function (entry) {
                if (entry.id != association.id) {
                    userTerms.push(entry);
                }
            });
            $scope.userTerms = userTerms;
            trackAssociations();
            self.service.setCustomReferences(userTerms);
            self.removeAssociation(association, self.chartData, 'userData', self.userChart);
            self.userData = self.chartData.userData;
            self.showUserAssociations(userTerms.length > 0);
        }
    };


    $scope.clearUserAssociations = function () {
        $timeout(function () {
          self.clearCustomReferences();
            $scope.userTerms = [];
            $rootScope.context.current.user_associations = [];
            trackAssociations();
            self.clearedAll = true;
            self.showUserAssociations(false);
        });
    };

    $scope.closeGraph = this.hideGraph = function () {
        $scope.showGraph = false;
        $scope.examplesData.alphabetized = false;
    };

    this.unselectAssociation = function () {
        document.getElementsByClassName("selected-row")[0] && document.getElementsByClassName("selected-row")[0].classList.remove("selected-row");
        self.selectedAssociation = {id: null};
        self.closeAssociationObjects();
        $scope.examplesData.open = true;
        $scope.$root.$broadcast('openContentDrivers', "content_drivers");
        self.setConsumptionContentExamples(false);
        self.insightsExportService.addReportFunction(self.getExportReport, true)
    };

    this.getExportReport = function (format) {
        var titles = [format('Association', 'bold'), format('Seed', 'bold')];
        var columns = [{width: 45}, {width: 35}];
        if ($scope.filterType == 'gender') {
            titles.push(format('Strength - Male', 'bold'));
            columns.push({width: 18});
            titles.push(format('Consumption - Male', 'bold'));
            columns.push({width: 18});
            titles.push(format('Strength - Female', 'bold'));
            columns.push({width: 18});
            titles.push(format('Consumption - Female', 'bold'));
            columns.push({width: 18});
        } else {
            titles.push(format('Strength', 'bold'));
            columns.push({width: 18});
            titles.push(format('Consumption', 'bold'));
            columns.push({width: 18});
            titles.push(format('Skew', 'bold'));
            columns.push({width: 18});

            if($scope.shouldDisplaySov()){
                titles.push(format('SOV', 'bold'));
                columns.push({width: 18});
            }
        }
        var table = [titles];
        var type = c.isString($scope.filterType) ? $scope.filterType : DEFAULT_VIEW_TYPE;
        var result = self.service.getValuesByAssociationTable(self.parameters, type, self.measure, format);
        table = table.concat(result);
        return {name: associationExportName, columns: columns, table: table};
    };

    this.closeAssociationObjects = function () {
        $scope.examplesData.alphabetized = false;
        $scope.examplesData.open = false;
        self.hideGraph();
    };

    this.getConsumptionExportReport = function (format) {
        var report = {name: consumptionExportTabName, columns: [], table: []};
        var terms = self.util.getValidTerms($scope, self.parameters);
        if (c.isArray(terms)) {
            report.columns = [{width: 19}];
            var info = [format('Selected Association:', 'bold'), self.selectedAssociation.text];
            var blank = [];
            var titles = [format('Date', 'bold')];
            _.each(terms, function (term) {
                var text = self.selectedAssociation ? term.text + " with " + self.selectedAssociation.text : term.text;
                titles.push(format(text, 'bold'));
                report.columns.push({width: 25});
            });
            report.table = [info, blank, titles];
            var result = self.consumptionService.getValuesByDateTable(terms, format, self.parameters.to_normalize ? 'numeric' : 'integer');
            report.table = report.table.concat(result);
        }
        return report;
    };

    this.getExportExamples = function (format) {
        var report = {name: 'Content Drivers', columns: [], table: []};
        var selectedAssociation = self.selectedAssociation.text || 'None';
        var info = [format('Selected Association:', 'bold'), selectedAssociation];
        var blank = [];
        var seedTitle = self.selectedAssociation ? 'Seed and Association' : 'Seed';
        var titles = [format('Date', 'bold'), format(seedTitle, 'bold'), format('Content Drivers', 'bold')];
        report.table = [info, blank, titles];
        report.columns = [{width: 19}, {width: 25}, {width: 100}];
        var result = self.consumptionService.getExamples($scope.trends, format, $scope.examplesData.visible_examples, self.selectedAssociation);
        report.table = report.table.concat(result);
        return report;
    };

    this.setConsumptionContentExamples = function (doUpdate) {
        if ($scope.examplesData.examples.articles_consumption.length > 0 && !doUpdate) {
            angular.copy($scope.examplesData.examples.articles_consumption, $scope.examplesData.visible_examples);
        } else {
            $scope.examplesData.promise = self.consumptionService.get(self.parameters, {});
            return $scope.examplesData.promise.then(function (data) {
                if (isDefined(data)) {
                    $scope.examplesData.icon = data.icon;
                    $scope.examplesData.examples.articles_consumption = data.examples;
                    _.each($scope.examplesData.examples.articles_consumption, function (ex) {
                        ex.class = $scope.termClasses[ex.keyword];
                    });
                    $scope.examplesData.examples.articles_consumption = _.filter($scope.examplesData.examples.articles_consumption, function (example) {
                        return $scope.termClasses[example.keyword]
                    });
                    if ($state.includes('*.association')) {
                        angular.copy($scope.examplesData.examples.articles_consumption, $scope.examplesData.visible_examples);
                    }
                }
            }, function (err) {
                $scope.trendLineError = true;
                $scope.spinner = false;
            });
        }
    };

    this.resetContentDriversPaneFilters = function () {
        _.each($scope.examplesData.filters, function (trend) {
            trend.show = true;
        });
    };

    this.resetHighlightedLetter = function () {
        self.chart.resetHighlightedCircle();
        $scope.$emit('resetLetterClicked');
    };

    $scope.unselectAssociation = this.unselectAssociation;

    $scope.$on('contentDriverMouseover', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('contentDriverMouseout', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, false);
    });

    $scope.$on('contentDriverClick', function (event, letter) {
        self.chart.resetHighlightedCircle();
        self.chart.letterClicked = letter;
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('widgetPanelClick', function () {
        self.resetContentDriversPaneFilters();
        self.chart.resetHighlightedCircle();
    });

    $scope.$on('contentDriversPaneOpen', function (event) {
        if (!self.selectedAssociation.id) {
            self.setConsumptionContentExamples(false);
        }
    });

    $scope.resetHighlightedCircle = function () {
        self.chart.resetHighlightedCircle();
    };

    this.emitEventForChartCircle = function (eventType, letter) {
        $scope.$emit(eventType, letter);
    };

    insightsExportService.setTitle('Associations');
    insightsExportService.addReportFunction(this.getExportReport);
    insightsExportService.setExampleFunction(this.getExportExamples);

    $scope.$on('$destroy', function() {
      if (self.userChart) self.userChart.destroy();
    });
}

TopAssociationController.prototype._doUpdate = function (values,changedVals) {
    var $scope = this.$scope;
    var self = this;
    this.trends = $scope.terms;
    this.hideGraph();
    if (this.selectedAssociation.id) {
        this.unselectAssociation();
    }
    $scope.examplesData.visible = true;
    $scope.examplesData.open = true;
    $scope.$root.$broadcast('openContentDrivers', "content_drivers");
    $scope.examplesData.visible_examples = [];
    $scope.examplesData.examples.association = [];
    $scope.examplesData.alphabetized = false;
    $scope.showGraph = false;

    if (this.apiError) {
        this.apiError = false;
        return;
    }

    if (!this.$scope.insightsChannel.hasOwnProperty('value') || ['articles', 'tweets', 'sg_telco','au_telco'].indexOf(this.$scope.insightsChannel.value) == -1) {
        this.$scope.context.current.insightsChannels = this.$scope.insightsChannel = {"label": "All", "value": 'articles'};
    }

    this.channel = this.parameters.channel = this.$scope.insightsChannel.value;
    var nonPhrases = false;
    if (this.channel == 'tweets') {
        c.validateNonPosts($scope.terms, this.notificator);
    } else {
        nonPhrases = c.validateNonPhrases($scope.terms, this.channel, this.notificator);
    }

    c.validateTimeframeSocial(changedVals, this.channel, $scope.timeframe, this.notificator);
    this.parameters = this.util.buildInsightsParameters(_.pick($scope, ['insightsChannel', 'addTerm', 'topics', 'terms', 'active', 'geo', 'sub_geos', 'timeframe', 'audience', 'sgTelcoAudience','userFirstPartyAudience']), true);
    c.logUpdate('Associations', this.parameters);
    this.parameters.user_1st_party_audience = _.map($scope.userFirstPartyAudience,function(item) {
        return item.value;
    });
    this.timeframe = this.parameters.timeframe;
    this.terms = this.parameters.terms;
    this.geo = this.parameters.geo;
    this.topics = this.parameters.topics;
    this.boolean_logics = this.parameters.boolean_logics;
    this.audience = this.parameters.audience;
    this.kwd_ids = this.parameters.kwd_ids;
    $scope.trends = this.parameters.trends;
    $scope.termClasses = {};
    _.each($scope.trends, function (trend) {
        $scope.termClasses[trend.text] = $scope.termClasses[trend.id] = trend.class;
    });
    $scope.examplesData.filter_type = 'trend';
    $scope.examplesData.terms = $scope.trends;
    angular.copy($scope.trends, $scope.examplesData.filters);
    $scope.examplesData.filters = _.filter($scope.examplesData.filters, function (t) {
        return nonPhrases ? ['mention', 'hashTag', 'post'].indexOf(t.type) === -1 && !self.util.isSocialBooleanLogic(t) : t.type != 'post';
    });
    $scope.legendTrends = $scope.examplesData.filters;
    if($scope.valueMeasure == 'sov' && $scope.trends.length < 2){
        $scope.valueMeasure = DEFAULT_VALUE_MEASURE;
    }
    this.resetContentDriversPaneFilters();
    this.resetHighlightedLetter();
    this.insightsExportService.initSummaryFields();
    this.insightsExportService.addSummaryFields({
        label: 'Selected Association:', value_f: function (entry, field) {
            return self.selectedAssociation.text || "None";
        }
    });
    var export_parameters = [];
    if(this.baseInsightsService.shouldSendAudience(this.parameters.channel, this.permissions)) {
        this.insightsExportService.addSummaryFields(ex.AUDIENCE_SUMMARY_FIELD);
        export_parameters =  _.extend({}, this.parameters, {audience: _.map(c.getAudience($scope, this.parameters.channel), 'summary')});
    } else {
        export_parameters = _.clone(this.parameters);
    }

    this.insightsExportService.setParams(export_parameters);

    if (this.terms.length < 1 && this.boolean_logics.length < 1) {
        this.clear(true);
        $scope.$emit('insightsError', "No Terms");
    } else {
        this.setConsumptionContentExamples(true);
        if (this.removedAssociation) {
            this.removedAssociation = false;
            return;
        }
        if (this.addedAssociation) {
            this.addedAssociation = false;
            return this.performUpdate(true);
        }
        return this.performUpdate();
    }
};

TopAssociationController.$inject = ["$scope", "$rootScope", "$timeout", "$state", "associationTrendService",
        "mixpanelInsights", "util", "examplesDataModel", "consumptionTrendService", "insightsExportService",
        "abiPermissions", "baseInsightsService"];

module.exports = angular.module(__filename, [
    TopAssociationService.name,
    mixpanel.name,
    examplesDataModel.name,
    consumptionTrendService.name,
    require('common/tags-viewer.drv/tags-viewer.drv').name
]).directive("topAssociationWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: Template,
        scope: {
            timeframe: "=",
            active: "=",
            terms: "=",
            geo: "=",
            sub_geos: "=subGeos",
            topics: "=",
            insightsChannel: '=',
            audience: '=',
            sgTelcoAudience: '=',
            userFirstPartyAudience: '=',
            addTerm: "=",
            userTerms: '=',
            cacheBaster: '='
        },
        controller: TopAssociationController
    });
}]);
