"use strict";
var articleTmpl = require('./article-brick.drv.html'),
    videoTmpl = require('./video-brick.drv.html'),
    tweetTmpl = require('./tweet-brick.drv.html'),
    facebookTmpl = require('./facebook-brick.drv.html'),
    instagramTmpl = require('./instagram-brick.drv.html'),
    pinterestTmpl = require('./pinterest-brick.drv.html'),
    dotdotdot = require("jquery.dotdotdot");

var BRICK_TYPE_TO_TMPL_MAP = {
    article: articleTmpl,
    video: videoTmpl,
    tweet: tweetTmpl,
    facebook: facebookTmpl,
    instagram: instagramTmpl,
    sg_telco: articleTmpl,
    au_telco: articleTmpl,
    pinterest: pinterestTmpl
};

module.exports = angular.module(__filename, [
    require("common/broken-img-fallback.drv").name
]).directive('amBrick', ['$compile', '$timeout', function ($compile, $timeout) {
    return {
        restrict: 'EA',
        link: function (scope, element, attr) {
            var newScope = scope.$new(true);
            var brickData = angular.fromJson(attr.brickData);
            if (brickData['image_height']) {
                brickData['image_height'] = Math.round(brickData['image_height'] / brickData['image_width'] * 263);
            }
            if (brickData['post_image']) {
                brickData['post_image']['height'] = Math.round(brickData['post_image']['height'] / brickData['post_image']['width'] * 263);
            }
            newScope.brickData = brickData;
            newScope.deleteBrick = scope.deleteBrick(attr.id);
            newScope.trackBrick = scope.trackBrick;
            newScope.capitalize = _.capitalize;
            newScope.min = _.min;
            newScope.brickRollover = scope.brickRollover;
            var template = BRICK_TYPE_TO_TMPL_MAP[attr.type];

            element.html($compile(template)(newScope));
            $timeout(function () {
                element.find('.dotdotdot').each(function () {
                    $(this).dotdotdot({after: ".read-more"})
                });
                element.find('.dotdotdot-publish-date').each(function () {
                    $(this).dotdotdot({after: ".publish-date"})
                });
            }, 0);
        }
    }
}]).filter('fromNow', function () {
    return function (date) {
        // replacing spaces with unicode of &nbsp;
        return moment(date).fromNow().replace(/ /g, '\u00A0');
    }
});
