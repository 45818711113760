"use strict";
var resetPasswordModule = angular.module(__filename, []);

function resetPwdController($scope, $stateParams, $state, userMgmt, abiNotificationModal) {
    var token = $stateParams.t;

    $scope.pwdConformationError = false;
    $scope.beforeUpdate = true;
    $scope.update = validateAndUpdate;

    function validateAndUpdate(form) {
        if (form.password == form.passwordConfirm) {
            updatePwd(form);
        } else {
            $scope.pwdConformationError = true;
        }
    }

    function updatePwd(form) {
        $scope.isBusy = true;
        userMgmt.resetPassword(token, form).then(function (res) {
            $scope.beforeUpdate = false;
            abiNotificationModal.show('good', 'Password updated', 'Feel free to login with your new password').then(function (modal) {
                modal.close.then(function () {
                    $state.go('login');
                });
            });
        }, function (error) {
            $scope.beforeUpdate = false;
            abiNotificationModal.show('bad', 'Update failed :(', 'This link is no longer valid').then(function (modal) {
                modal.close.then(function () {
                    $state.go('login');
                });
            });
        });
    }
}

resetPwdController.$inject = ['$scope', '$stateParams', '$state', 'userMgmt', 'abiNotificationModal'];

resetPasswordModule.stateConfig = {
    name: "reset_password",
    url: "/reset_password",
    template: require("./reset_password.html"),
    params: {t: null},
    controller: resetPwdController
};

module.exports = resetPasswordModule;
