"use strict";
"use strict";
 
manualPhrasesCtrl.$inject = ['$scope', '$q', 'context', '$timeout', 'parentScope', 'close'];
function manualPhrasesCtrl($scope, $q, context, $timeout, parentScope, close) {

    $scope.manualPhrases = [];

    $scope.add = function(){
        parentScope.addManualPhrases($scope.manualPhrases);
        this.close();
    }

    $scope.close = function () {
        parentScope.manualPhrasesClosed();
        close({});
    };

    $timeout(function(){
    	$(".manual-phrases textarea").focus();
    });
}

module.exports = manualPhrasesCtrl;
 
