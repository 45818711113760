"use strict";
"use strict";
 
createTargetCtrl.$inject = ['$scope', '$state', '$http', '$q', 'context', 'notificator', 'TargetsCommon', 'params', 'close'];
function createTargetCtrl($scope,  $state, $http, $q, context, notificator, TargetsCommon, params, close) {

    $scope.createTargetCtrl = this;

    $scope.name = '';
    
    $scope.init = function () {
        setTimeout(function(){$("#target-name").focus();},300);
    };

    this.close = function () {
        close({});
    };

    this.checkEnter = function($event){

        if($event.keyCode==13){
            this.createTarget();
        }
    }

    this.createTarget = function(){

        params.name = $("#target-name").val();
        params._user_name = $scope.$root.user.name;
        params._program_name = context.program.name;

        //validation
        let nameLength = 50, nameMsg = 'target name exceeded the max length (1-'+nameLength+')';
        if (params.name.length > nameLength) {
            notificator.error({body: nameMsg});
            return;
        }
        
        TargetsCommon.TargetData.targetToServer({target: params, username: $scope.$root.user.name});

        let r = $scope.$root,
            url = TargetsCommon.HOST+'/'+context.current.p_id+'/grid_target'+TargetsCommon.POSTFIX;

        $http.post(url, params).success((res)=>{

            notificator.success({body: 'Target "' + params.name + '" created'});
            r.$broadcast('updateTargets', {target: res, action: 'create-target'});
        
        }).error((res)=>{
            let error = res.errors ? res.errors[''] : 'error creating target.';
            notificator.error({body: error});
        });

        this.close();
    }

    $scope.init();
}

module.exports = createTargetCtrl;
 
