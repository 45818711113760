"use strict";
var conceptsService = require("data/concepts-service");
var ModalService = require("angular-modal-service");
var exclusions_dialog = require('./exclusions-dialog.html');

module.exports = angular.module(__filename, [
    conceptsService.name,
    ModalService.name
]).service("exclusionService", ["ModalService", function (ModalService) {
    return {
        showModal: function (program) {
            ModalService.showModal({
                template: exclusions_dialog,
                inputs: {program: program},
                controller: ["$scope", "program", "close", "conceptsService", function ($scope, program, close, conceptsService) {
                    $scope.program = program;
                    $scope.loading = true;
                    $scope.search = '';
                    $scope.order = 'seed';
                    $scope.reverse = false;
                    $scope.concepts = [];

                    function closeOnClickOutside(event) {
                        if (event.target.id == "exclusions-dialog-overlay") {
                            $scope.close();
                        }
                    }

                    document.addEventListener('click', closeOnClickOutside);

                    $scope.close = function () {
                        document.removeEventListener('click', closeOnClickOutside);
                        close();
                    };

                    $scope.getDeletedConcepts = function () {
                        $scope.concepts = [];
                        conceptsService.list($scope.program).then(function (data) {
                            $scope.concepts = data;
                            $scope.loading = false;
                        });
                    };

                    $scope.restoreDeletedConcepts = function () {
                        var selected_concepts = [];
                        angular.forEach($scope.concepts, function (concept) {
                            if (concept.check) {
                                delete concept.check;
                                selected_concepts.push(concept.id);
                            }
                        });

                        $scope.concepts = $scope.concepts.filter(function (n) {
                            return n.checked !== true;
                        });

                        conceptsService.restore($scope.program, selected_concepts).then(function () {
                            $scope.getDeletedConcepts();
                        });
                    };

                    $scope.sort = function (column) {
                        if ($scope.order === column) {
                            $scope.reverse = !$scope.reverse;
                        }
                        $scope.order = column;
                    };

                    $scope.anyChecked = function () {
                        return $scope.concepts.some(function (concept) {
                            return concept.check;
                        });
                    };

                    $scope.checkAllClicked = function () {
                        var check = !$scope.areAllChecked();

                        angular.forEach($scope.concepts, function (concept) {
                            concept.check = check;
                        });
                    };

                    $scope.areAllChecked = function () {
                        var checked = $scope.concepts.reduce(function (memo, concept) {
                            return memo + (concept.check ? 1 : 0);
                        }, 0);

                        return (checked === $scope.concepts.length && checked > 0);
                    };

                    $scope.getDeletedConcepts();
                }]
            });
        }
    }
}]);
