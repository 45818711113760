"use strict";
var config = require("infra/config");

module.exports = angular.module(__filename, [])
    .service('programBooleanLogicService', ['$http', 'context', 'confirmAction', 'util', function ($http, context, confirmAction, util) {
        var HOST = config.USER_MGMT_API;
        var suffix = '?disable_notification=1';

        function currentProgram() {
            return context.program;
        }

        function base_url(program_id) {
            var p_id = program_id || currentProgram().id;
            return HOST + '/programs/' + p_id + '/seed_refinement';
        }

        return {
            fixProperties: fixProperties,
            list: getProgramBooleanLogics,
            create: saveBooleanLogicToProgram,
            update: updateProgramBooleanLogic,
            delete: deleteProgramBooleanLogic
        };

        function fixProperties(bl) {
            util.ignoreSerialize(bl, 'created_at');
            util.ignoreSerialize(bl, 'updated_at');
            util.ignoreSerialize(bl, 'display');

            _.each(_([bl.required, bl.included, bl.excluded]).flatten().compact().value(), function (term) {
                util.ignoreSerialize(term, 'invalid');
                util.ignoreSerialize(term, 'display');
            });
        }

        function getProgramBooleanLogics(p_id) {
            return $http.get(base_url(p_id), {cache: false}).then(function (res) {
                if (!p_id) currentProgram().boolean_logics = res.data;
                _.each(res.data, fixProperties);
                return res.data;
            });
        }

        function saveBooleanLogicToProgram(bl) {
            var url = base_url() + suffix;
            return $http.post(url, bl).then(
                function (res) {
                    var resData = successResponse(res);
                    if (!resData.failed) {
                        resData.display = util.getTermDisplay(resData);
                        currentProgram().boolean_logics.push(resData);
                    }

                    return resData;
                }, errorResponse);
        }

        function updateProgramBooleanLogic(bl_id, params, p_id) {
            var url = base_url(p_id) + '/' + bl_id + suffix;
            const onSuccess = (res) => {
              var resData = successResponse(res);
              if (!resData.failed && p_id == currentProgram().id) {
                  var cp = currentProgram();
                  var index = _.findIndex(cp.boolean_logics, function (bl) {
                      return bl.id === bl_id;
                  });

                  cp.boolean_logics.splice(index, 1);
                  cp.boolean_logics.push(resData);
              }

              return resData;
            }

            return $http.put(url, params)
              .then(onSuccess, err =>
                err.status !== 409 ? errorResponse(err) : showDialogForPBLInAlert(onSuccess, err)
              );
        }

        function deleteProgramBooleanLogic(bl_id, p_id) {
            var url = base_url(p_id) + '/' + bl_id + suffix;
            const onSuccess = () => {
              if (p_id == currentProgram().id) {
                  var cp = currentProgram();
                  var index = _.findIndex(cp.boolean_logics, function (bl) {
                      return bl.id === bl_id;
                  });

                  cp.boolean_logics.splice(index, 1);
              }
            }
            return $http.delete(url)
              .then(onSuccess)
              .catch(err =>
                err.status !== 409 ? errorResponse(err) : showDialogForPBLInAlert(onSuccess, err)
              );
        }

        function successResponse(response) {
            // handle server side failed save...
            var resData = response.data;
            var errorKeys = resData.errors ? Object.keys(resData.errors) : [];
            var failed = errorKeys.length > 0;
            var error_text = errorKeys.map(function (key) {
                return key + ' ' + resData.errors[key]
            }).join();
            return failed ? {error: error_text, failed: failed} : resData;
        }

        function errorResponse() {
            return Promise.reject({error: "Something went wrong, our engineers are working on it", failed: true});
        }

        function showDialogForPBLInAlert(onSuccess, httpConfig) {
          const msg = "The program interest is used by Alerts, changing it will change the alert dynamics. Are you sure you want to proceed?";
          return confirmAction.getConfirmation(msg, "Save", "Cancel", "Alert Notification")
            .then(modal => modal.close)
            .then(save => {
              if (save) {
                const keyToExtend = httpConfig.config.method == 'DELETE' ? 'params' : 'data';
                httpConfig.config[keyToExtend] = Object.assign({} ,httpConfig.config[keyToExtend], {force: true});
                return $http(httpConfig.config).then(onSuccess).catch(errorResponse);
              }

              return Promise.reject({cancelDelete: true});
            });
        }
    }]
);
