"use strict";
var common = require("infra/utils/common");
var add_tooltip = require('../tooltip/tooltip.js')
var ELEMENT_SELECTOR = '.donut-chart-drv';
var DEFAULT_OPTIONS = { donutColors: ['#44b0c7', '#6BCAD7', '#ADE0E8','#EAF7F9', 'red', 'grey', 'yellow', 'green', 'pink'], labelColor: '#cacaca', labelFont: '14px Helvetica', highlightedLabelColor: 'white'}
var MIN_SEGMENT_PERCENT = 5;

module.exports = angular.module(__filename, [
 ]).directive('donutChart', ['$window', function($window) {
    return {
        restrict: 'E',
        template: require('./am-donut-chart.drv.html'),
        scope: {
            "options": "=?",
            "data": "=",
        },
        link: function ($scope, $element) {
            var options = _.merge({}, DEFAULT_OPTIONS, $scope.options);
            var elemWidth, elemHeight, newElemWidth, newElemHeight;
            [elemWidth, elemHeight] = getElementWidthAndHeight();
            var w,h;

            angular.element($window).bind('resize', saveNewSizes);
            $scope.$on('$destroy', () => angular.element($window).off('resize', saveNewSizes))

            $scope.$watch('data', function () {
                onResize();
            });

            function saveNewSizes() {
              [newElemWidth, newElemHeight] = getElementWidthAndHeight();
              if (newElemWidth != elemWidth || newElemHeight != elemHeight){
                  newElemWidth = elemWidth;
                  newElemHeight = elemHeight;
                  onResize();
              }
            }

            function getElementWidthAndHeight(){
                var pos = d3.select(ELEMENT_SELECTOR)[0][0].getBoundingClientRect();
                return [pos.width, pos.height]
            }

            function onResize(){
                if (!_.isEmpty($scope.data)) drawGenderChart($scope.data)
            }

            function drawGenderChart(data) {

                // var data = [
                //     {label: "Caucasian", value: 3},
                //     {label: "Hispanic", value: 27},
                //     {label: "African American", value: 70},
                //     {label: "Asian American", value: 3}
                // ];

                function wrapText(text, width) {
                    text.each(function() {
                        var text = d3.select(this),
                            words = text.text().split(/\s+/).reverse(),
                            word,
                            line = [],
                            lineNumber = 0,
                            lineHeight = 0, // ems
                            y = text.attr("y"),
                            dy = 1,
                            tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em"),
                            isPercentElement = false;
                        while (word = words.pop()) {
                            line.push(word);
                            tspan.text(line.join(" "));
                            if (isPercentElement || tspan.node().getComputedTextLength() > width) {
                                line.pop();
                                tspan.text(line.join(" "));
                                line = [word];
                                tspan = text.append("tspan").attr("x", 0).attr("y", y).text(word).attr("dy", dy + lineHeight + "em")//.attr("dy", lineHeight + dy + "em").text(word);
                                lineHeight += 1;
                            }
                            isPercentElement = false;
                        }
                    });
                }

                function changeLegendLabelColor(label, color) {
                    var labelElem = d3.select(legend[0].filter(function(l){ return l.__data__.label == label})[0]);
                    labelElem.select("text").style({"fill": color});
                }

                function changeArcLabelColor(label, color) {
                    var arcLabel = textElement[0].filter(function(a){ return a.__data__.data && a.__data__.data.label == label})[0];
                    d3.select(arcLabel).select("text").style({"fill": color});
                }

                function addLegendInfoIcon(legend){
                    var otherLabel = legend[0].filter(function(l){ return l.__data__.label == "Other"});
                    if (_.isEmpty(otherLabel)) return;
                    var pos = d3.select(otherLabel[0]).select("text")[0][0].getBoundingClientRect();
                    var infoIcon = d3.select(otherLabel[0]).append("foreignObject")
                    infoIcon.attr("width", 10)
                        .attr("height", 10)
                        .attr("x", pos.width + legendRectSize + legendSpacing + 4)
                        .attr("y", -1)
                        .append("xhtml:body")
                        .style("font", options.labelFont)
                        .html(`<i class="icon-info" title="${otherLabel[0].__data__.tooltip}"></i>`)
                        .on('mouseover', function(d){
                            d3.select(infoIcon[0][0]).select("i").style("color", "white")
                            changeArcLabelColor("Other", "white")
                        })
                        .on('mouseout', function(d){
                            d3.select(infoIcon[0][0]).select("i").style("color", options.labelColor)
                            changeArcLabelColor("Other", options.labelColor)
                        })
                     add_tooltip(infoIcon.select(".icon-info"), 'info', {style: {classes: 'common-tooltip-info'}, position: { adjust: { x: 4 }}});
                }

                function drawLegend(){

                    var legendRoot = svg
                        .append("svg:g").classed('legend-element', true);

                    var legend = legendRoot 
                        .selectAll('.legend')
                        .data(dataSortedByValue)
                        .enter()
                        .append('g')
                        .attr('class', 'legend')
                        .attr('transform', function(d, i) {
                            var height = legendRectSize + legendSpacing;
                            var vert = i * height;
                            return 'translate(0,' + vert + ')';
                        });

                    legend.append('rect')
                        .attr('width', legendRectSize)
                        .attr('height', legendRectSize)
                        .style('fill', function(d) { return d.color; })
                        .style('stroke', function(d) { return d.color; });

                    legend.append('text')
                        .attr('x', legendRectSize + legendSpacing)
                        .attr('y', legendRectSize)
                        .text(function(d) { return d.label; })
                        .style('font', options.labelFont)
                        .style('fill', options.labelColor);
                    
                    legendRoot.attr('transform', function() {
                        var horz = w - legendRoot[0][0].getBoundingClientRect().width
                        return 'translate(' + horz + ',0)';
                    });

                    addLegendInfoIcon(legend)
                    return legend;
                }

                function calcLabelPosition(d){
                    var c = arc.centroid(d),
                        x = c[0],
                        y = c[1],
                        h = Math.sqrt(x*x + y*y),
                        numOfDigits = d["value"].toString().length,
                        spaceFromDonut = labelr + 8 * numOfDigits,
                        side = (d.endAngle + d.startAngle)/2 > Math.PI ? -1 : 1;
                        return [x/h * spaceFromDonut, y/h * (labelr + 8)]
                }

                var sum = _.sum(_.map(data, 'value'));
                var percents = common.roundPercents(_.map(data, function(d) { return d.value * 100 / sum }))
                 _.each(data, function(d,i){ d.percents = percents[i]});
                 data = data.filter(function(d){ return d.percents > 0 })
                 var smallSegments = data.filter(function(d){ return d.percents < MIN_SEGMENT_PERCENT })
                 if (smallSegments.length > 1) {
                     data = data.filter(function(d){ return d.percents >= MIN_SEGMENT_PERCENT })
                     var otherTooltip = _.orderBy(smallSegments, 'percents', 'desc').map(function(s){return `${s.label}: <span class='donut-chart-tooltip-percents'>${s.percents}%</span>`}).join('<br>')
                     data.push({percents: _.sum(_.map(smallSegments, 'percents')), label: 'Other', value: _.sum(_.map(smallSegments, 'value')), tooltip: otherTooltip})
                 }
                 var dataSortedByValue = _.orderBy(data, 'value', 'desc');
                 _.each(dataSortedByValue, function(d,i){ d.color = options.donutColors[i] })
                 var maxValue =  _.maxBy(data, 'value');
                 maxValue.highlight = true;

                 var legendRectSize = 12;
                 var legendSpacing = 6;
                 [w, h] = getElementWidthAndHeight();
                 d3.select(ELEMENT_SELECTOR).select("svg").remove();
                 var svg = d3.select(ELEMENT_SELECTOR).append("svg:svg");
                 var vis = svg.data([data]).append("svg:g")
                 var legend = drawLegend();  
                 var legendPos = svg.select('.legend-element')[0][0].getBoundingClientRect(); 
                 var legendWidth = legendPos.width;

                 var r = Math.min(w - legendWidth + 30, h) / 2 - 30,
                    labelr = r + 5,
                    donut = d3.layout.pie().padAngle(0.025),
                    arc = d3.svg.arc().innerRadius(r * .88).outerRadius(r),
                    highlightedArc = d3.svg.arc().innerRadius(r * .86).outerRadius(r * 1.02);                   

                 var arcs = vis.selectAll("g.arc")
                    .data(donut.value(function(d) {
                        return d.value
                    }))
                    .enter().append("svg:g")
                    .attr("transform", "translate(" + r + "," + r + ")");

                 arcs.append("svg:path")
                    .attr("fill", function(d, i) { return d.data.color })
                    .attr("d", function(d) { return d.data.highlight ? highlightedArc(d) : arc(d) })
                    .attr("startradius", 100)
                    .on('mouseover', function(d){
                        changeLegendLabelColor(d.data.label, "white");
                    })
                    .on('mouseout', function(d){
                        changeLegendLabelColor(d.data.label, options.labelColor);
                    })

                 var textElement = arcs.append("svg:g").attr("class", "text-element");
                 textElement.attr("visibility", function(d) {
                        return d.data.highlight ? "hidden" : "visible"
                    })
                 .append("svg:text")
                    .attr("dy", "0.35em")
                    .attr("text-anchor",  "middle")
                    .text(function(d, i) {
                        return d.data.percents + '%';
                    })
                    .style("fill", options.labelColor)
                    .style("font", options.labelFont)
                    .attr("transform", function(d) {
                        var [x, y] = calcLabelPosition(d)
                        return "translate(" + x +  ',' + y +  ")";
                    })

                // Larger element for mouse hover
                  
                var hoverLabelsElem = svg.data([data]).append("svg:g").classed('hover-label', true)
                var hoverLabelsArcs = hoverLabelsElem.selectAll("g.arc")
                    .data(donut.value(function(d) {
                        return d.value
                    }))
                    .enter().append("svg:g")
                    .attr("transform", "translate(" + r + "," + r + ")");
                var hoverTextElement = hoverLabelsArcs.append("svg:g").attr("class", "text-element");
                hoverTextElement.attr("visibility", function(d) {
                        return d.data.highlight ? "hidden" : "visible"
                    }).append("svg:text").text(function(d, i) {
                        return d.data.percents + '%';
                    })
                    .style("font", options.labelFont)
                    .style("fill", "transparent")
                    .style("font", options.labelFont)
                    .attr("dy", "0.35em")
                    .attr("text-anchor",  "middle")
                    .attr("transform", function(d) {
                        var [x, y] = calcLabelPosition(d)
                        return "translate(" + x +  ',' + y +  ") scale(2.5,2.5)";
                    });

                hoverTextElement.on('mouseover', function(d){
                        changeLegendLabelColor(d.data.label, "white");
                    })
                    .on('mouseout', function(d){
                        changeLegendLabelColor(d.data.label, options.labelColor);
                    })                      
                
                svg.select(".text-element[visibility='hidden']")[0][0].remove();    
                
                var xOffset = (w / 2 - r),
                    yOffset = (h - r * 2) / 2 + 10;

                // Change xOffset if donut collide with legend
                var donutPos = vis[0][0].getBoundingClientRect();
                var svgPos = svg[0][0].getBoundingClientRect();
                if (donutPos.top + yOffset < legendPos.bottom && donutPos.right + xOffset > legendPos.left) {
                    xOffset = legendPos.left - donutPos.right;
                    xOffset = Math.max(xOffset, svgPos.left - donutPos.left)   
                }   

                vis.attr("transform", "translate(" + xOffset+ "," + yOffset + ")");
                hoverLabelsElem.attr("transform", "translate(" + xOffset+ "," + yOffset + ")");    


                // Inside Text
                 var circleForInsideText = svg.data([[{value: 1}]])
                    .append("svg:donutcircle:g")
                    .attr("transform", "translate(" + xOffset+ "," + yOffset + ")");                    

                 var arcForInsideText = circleForInsideText.selectAll("g.arc")
                    .data(donut.value(function(d) {
                        return 1;
                    }))
                    .enter().append("svg:g")
                    .attr("transform", "translate(" + (r + 0) + "," + r + ")");

                 arcForInsideText.append("svg:path")
                    .attr("d", arc)
                    .attr("visibility", "hidden")

                 arcForInsideText.append("text")
                    .attr("dy", "-.35em")
                    .style("text-anchor", "middle")
                    .attr("fill", options.highlightedLabelColor)
                    .style("font", "22px Helvetica Bold")
                    .text(maxValue.percents + '%');


                 arcForInsideText.append("text")
                    .attr("dy", "2em")
                    .style("text-anchor", "middle")
                    .attr("fill", options.highlightedLabelColor)
                    .style("font", "16px Helvetica")
                    .text(maxValue.label)
                    .call(function(d) {
                        wrapText(d, "60")
                    });

            }
        }
    }
}]);
