"use strict";
const BaseWidget = require("../base_widget"),
    c = require("infra/utils/common"),
    TopChart = require("common/charts/top-chart"),
    widgetConfigurations = require('./insights-geo-widget-configurations');

InsightsGeoController.$inject = ['$scope', '$rootScope', '$window', '$timeout', 'context', '$state', 'consumptionGeoService', 'util', 'examplesDataModel', 'insightsExportService', 'geoService', 'notificator', 'abiPermissions'];

//Define variable outside the controller because the controller initialize every time we change state or clear the input bar
let usaLoaded = false;

function InsightsGeoController($scope, $rootScope, $window, $timeout, context, $state, consumptionGeoService, util, examplesDataModel, insightsExportService, geoService, notificator, abiPermissions) {
  $scope.examplesData = examplesDataModel;
  insightsExportService.setTitle('Geo');
  const topBarElement = angular.element('.top-container')
  $scope.$watch(() => topBarElement.width(), () => $scope.narrowMode = topBarElement.innerWidth() < 900)
  this.$scope = $scope;
  this.util = util;
  this.insightsExportService = insightsExportService;
  this.insightsExportService.addSummaryFields({label: "Geo type", value_f: () => $scope.scale.label})
  this.insightsExportService.addSummaryFields({label: "Metric", value_f: () => $scope.formula.label})
  if (!this.$scope.$root.context.current.insightsChannels.hasOwnProperty('value') || widgetConfigurations.supportedChannels.indexOf(this.$scope.insightsChannel.value) == -1) {
      this.$scope.$root.context.current.geoInsightsChannels = $scope.insightsChannel = $rootScope.geoInsightsChannelsFilter[0];
  }

  $scope.heatMapVisiable = false;
  $scope.scalingOptionsByChannel = widgetConfigurations.scalingOptionsByChannel
  $scope.scalingOptions = $scope.scalingOptionsByChannel[$scope.insightsChannel.value]
  $scope.scale = $scope.scalingOptions[0];
  $scope.scaleId = {id: $scope.scale.id};


  $scope.measureOptions = widgetConfigurations.measureOptions;
  $scope.measure = $scope.measureOptions[0]

  $scope.formulaOptions = widgetConfigurations.formulaOptions;
  $scope.formula = $scope.formulaOptions[0]

  $scope.visualizationModesOptions = widgetConfigurations.visualizationModesOptions;
  $scope.visualizationMode = $scope.visualizationModesOptions[0]

  this.selectedZoneId = "";
  const zoneClickListener = $scope.$on('zoneClicked', ($event, data) => {
    if (!(data.name && !data.score) && this.selectedZoneId != _.capitalize(data.id)) {
      this.getExamples(data, $scope.terms, 'name');
      this.selectedZoneId = _.capitalize(data.id)
    }
  });

  const channelChangedListener = $scope.$watch('insightsChannel', () => {
    $scope.scalingOptions = _.filter($scope.scalingOptionsByChannel[$scope.insightsChannel.value], scale => abiPermissions.hasPermission(scale.permission));
    $scope.scale = $scope.scalingOptions[0];
    $scope.$emit('hideSubGeos', $scope.scale.hideSubGeos);
    $scope.scaleId = {id: $scope.scale.id};
  });

  $scope.$on('$destroy', () => {
    zoneClickListener();
    channelChangedListener();
    $scope.examplesData.title = null;
  });

  this.getParams = (terms) => {
    const parameters = Object.assign({}, {terms}, _.pick($scope, ['topics', 'geo', 'timeframe', 'channel', 'insightsChannel','sub_geos']))
    const params = this.util.buildInsightsParameters(parameters);
    params.scale = $scope.scale.value;
    params.measure = $scope.measure.value;
    params.formula = $scope.formula.value;
    params.sub_geos = geoService.createRequestSubGeoParam(parameters.sub_geos);
    delete params.audience;

    return params;
  }
  this.$scope.mapChanged = (scaleId) => {
    $scope.scale = _.find($scope.scalingOptions, ['id', scaleId]) || $scope.scalingOptions[0];
    $scope.scaleId = {id: $scope.scale.id};
    this._doUpdate();
  };

  this.$scope.formulaChanged = formula => {
    $scope.formula = formula;
    this._doUpdate();
  }

  this.$scope.visualizationModeChanged = mode => {
    $scope.visualizationMode = mode;
    this.redrawElements()
  }

  this.performUpdate = (params) => {
    this.startLoader();

    return consumptionGeoService.get(params).then(results => {
      if (results == undefined) {
        $scope.$emit('insightsError', "No Data");
        throw "consumptionGeoService no results"
      }
      const data = {};
      $scope.results = results
      _.each(results.mapData, mapData => {
        const x = mapData.mapData;
        _.each(x, d => data[d.key] = d);
      });
      $scope.data = data;
      this.redrawElements()

      this.updateExamples(results.examples);
      this.setUpHeatMapOptions(results.mapData, params);
      this.stopLoader();
    }).catch(() => {
      this.stopLoader();
      $scope.results = {};
      $scope.data = {};
      this.redrawElements()
    });
  }

  this.redrawElements = () => {
    setTimeout(() => {
      if ($scope.visualizationMode.value == 'chart') {
          widgetConfigurations.topChartConfiguration[$scope.formula.value].topBarGroup.examples.showExamples = (data) => {
            if (this.selectedZoneId != _.capitalize(data.id)) {
              this.getExamples(data, $scope.terms, 'label')
              this.selectedZoneId = _.capitalize(data.id)
            }
          };
          this.chart = new TopChart('normal', null,
          '#geo-bar-chart', widgetConfigurations.topChartConfiguration[$scope.formula.value]);
          this.chart.draw($scope.results.chartData, 'normal', 'value', $scope.results.max);
      } else {
        const data = {};
        _.each($scope.results.mapData, mapData => _.each(mapData.mapData, d => data[d.key] = d));
        $scope.data = data;
      }
    })
  }

  this.getExamples = (data, terms, zoneNameField) => {
    const params = this.getParams(terms);
    if (data[zoneNameField]) {
      params.zoneId = data.id;
      $scope.examplesData.title = _.capitalize(data[zoneNameField]);
    } else {
      $scope.examplesData.title = null;
    }

    $scope.$root.$broadcast('openContentDrivers', "content_drivers");
    $scope.examplesData.promise = consumptionGeoService.get(params)
    $scope.examplesData.promise.then(data => this.updateExamples(data.examples))
                               .catch(() => {});
  }

  this.updateExamples = examples => {
    $scope.examplesData.terms = $scope.terms.filter(function (t) {
      return ['mention', 'hashTag', 'post'].indexOf(t.type) === -1;
    });
    angular.copy($scope.examplesData.terms, $scope.examplesData.filters);
    var termClasses = {};
    _.each($scope.examplesData.terms, function(trend) {
      termClasses[trend.text] = termClasses[trend.id] = trend.class;
    });
    _.each($scope.examplesData.filters, function(trend) {
        trend.show = true;
    });
    $scope.examplesData.icon = examples.icon;
    $scope.examplesData.examples.consumption = examples;
    _.each($scope.examplesData.examples.consumption, function(ex, i) {
        ex.class = termClasses[ex.keyword];
        ex.withTermContent = true;
        ex.trend_label = ex.keyword;
    });
    $scope.examplesData.examples.consumption = _.filter($scope.examplesData.examples.consumption, function(example) {
        return termClasses[example.keyword]
    });

    angular.copy($scope.examplesData.examples.consumption, $scope.examplesData.visible_examples);
  }

  this.setUpHeatMapOptions = (mapData, params) => {
    const isSkew = $scope.formula.value == 'skew' && params.terms.length == 1;
    const options = _.map(mapData, data => {
      const {min, max} = data
      const element = angular.element(`.${data.mapData[0].classColor}`)[0];
      if (_.isUndefined(element)) return null

      const classColor = $window.getComputedStyle(element, null).fill
      const color = classColor.replace(/[^\d,]/g, '').split(',');

      return {
        min: min === max ? 0 : min,
        max,
        minColor: 0.4,
        maxColor: 1.5,
        blocksColors: [color],
        midValue: isSkew ? 1 : null,
        title: data.mapData[0].term,
        labelFormat: (d) => {
          switch ($scope.formula.value) {
            case 'distribution':
              return (d * 100).toFixed(1) + "%";
            case 'consumption':
              return (d * 100).toFixed(2) ;
            default:
              return d.toFixed(2)
          }
        },
      }
    });
    $scope.heatMapOptions = _.omit(options, _.isUndefined)
    $scope.heatMapVisiable = true
  }

  this.onResize = ()  => {
    $timeout(() => {
      if(this.chart) {
        this.chart.draw($scope.results.chartData, 'normal', 'value', $scope.results.max);
      }
    }, 0);
  };
  this.validateTimeframe = () => {
    let timeframe = $scope.timeframe;
    let notification_message = null;
    if ($scope.insightsChannel.value == 'articles') {
      if ((timeframe[1] === 'month' && parseInt(timeframe[0]) > 1) || (timeframe[1] === 'year')) {
        $scope.timeframe = [1, 'month'];
      }
    }
  }
  const getExportReport = (format) => {
    const firstLine = [format('Zone', 'bold')];
    _.each($scope.terms, (t) => firstLine.push(format(t.display, 'bold')))
    const columns = [{
        width: 35
    }];
    const table = [firstLine];
      _.each($scope.results.chartData, ({label, values}) => {
        const arr = new Array($scope.terms.length + 1);
        arr[0] =_.capitalize(label);

        _.each(values, ({id, value}) => {
          const index = _.findIndex($scope.terms, (t) => t.id == id);
          arr[index + 1] = value
        })
        table.push(arr)
    });
    return {
        name: 'Geo',
        columns: columns,
        table: table
    };
  }

  insightsExportService.addReportFunction(getExportReport);
}

InsightsGeoController.prototype._doUpdate = function(values) {
  const $scope = this.$scope;
  const selectedGeos = $scope.$root.context.current._geo_mold._value;
  if ($scope.insightsChannel.value == 'articles' && !usaLoaded && !(selectedGeos.length == 1 && selectedGeos[0].id == "840")) {
    usaLoaded = true;
    this.notificator.notify({body: 'Geos view is available only for United States'})
  }
  this.validateTimeframe()
  $scope.heatMapVisiable = false;
  $scope.examplesData.open = true;
  $scope.examplesData.visible = true;
  $scope.examplesData.alphabetized = false;
  $scope.examplesData.filter_type = 'trend';
  $scope.examplesData.title = null;
  c.validateNonPhrases($scope.terms, null, this.notificator);
  const params = this.getParams($scope.terms);
  const promise = this.performUpdate(params);
  this.insightsExportService.setParams(params);
  $scope.examplesData.promise = promise
  return promise;
};

module.exports = angular.module(__filename, [
  require('data/insights/consumption-geo-service').name,
  require('common/map-component/countries/australia/australia-map-component').name,
  require('common/map-component/countries/usa/usa-map-component').name,
  require('common/map-component/countries/singapore/singapore-map-component').name

])
.directive("insightsGeoWidget", [function() {
    return BaseWidget({
        restrict: "E",
        template: require('./insights-geo-widget.html'),
        scope: {
            timeframe: "=",
            terms: "=",
            geo: "=",
            sub_geos: "=subGeos",
            topics: "=",
            insightsChannel: '=',
            cacheBaster: "="
        },
        controller: InsightsGeoController
    });
}]);
