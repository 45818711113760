"use strict";
module.exports = angular.module(__filename, []).directive('loading', ['$q',
    function($q) {
        return {
            restrict: "A",
            link: function(scope, element, attr, ctrl) {
                /*init*/
                if ($(element).css('position') == 'static') {
                    element.css('position', 'relative');
                }
                var loaderDiv = angular.element("<div class='loader-overlay'></div><div class='loader'>&nbsp;</div>");
                var up = null;

                /*watch on promises*/
                scope.$watch(attr.loading + '.$$state.status', function(status) {
                    if (status == undefined)
                        return;
                    if (status <= 0)
                        element.append(loaderDiv);
                    else
                        loaderDiv.remove();
                    }
                );
            }
        }
    }
]);
