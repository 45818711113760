"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    require('./content-driver/content-driver.drv.js').name
]).directive('contentDriversPane', ['directiveBuildHelper', 'TargetsCommon', function (directiveBuildHelper, TargetsCommon) {
    var dataPattern = '(@model as)? (@url is url of)? (@) (@title for)? (_instance_ in @source@) (track by @index)?';
    var Parser = directiveBuildHelper.compile(dataPattern);
    return {
        restrict: "E",
        controllerAs: 'contentCtrl',
        transclude: true,
        replace: 'element',
        template: require('./content-drivers-pane.drv.html'),
        scope: {
            driversTitle: '=?',
            filters: '=?',
            filterType: '=?'
        },
        link: function (scope, iElement, iAttrs, controller) {
            iElement.removeAttr('title');
        },
        controller: ['$scope', '$element', '$attrs', '$transclude', '$rootScope', '$timeout', 'utils', 
            function (scope, element, attr, $transclude, $rootScope, $timeout, utils) {

                if(scope.$parent.contentDriversTargets){
                    scope.contentDriversTab = "target_drawer";
                    open();  
                }else{
                    scope.contentDriversTab = "content_drivers";
                }

                scope.$on('openContentDrivers', function (event, tab) {
                    if(scope.$parent.contentDriversTargets){
                        open(tab);
                    }  
                });

                scope.$on('closeContentDrivers', function () {
                    close();
                });

                scope.filterNonDynamic = function(cell){
                    return !cell.dynamic;
                };

                _.extend(this, {
                    clickTab: clickTab,
                    close: close,
                    removeItem: removeItem,
                    removeFolder: removeFolder,
                    openCloseFolder: openCloseFolder,
                    toggleByFilter: toggleByFilter,
                    getDisplay: getDisplay,
                    removeAll: removeAll
                });

                function open(tab) {
                    utils.triggerResize(element);

                    if(tab){
                        scope.contentDriversTab = tab;
                    }

                    $(".main-content-page").addClass("content-drivers-open");
                    scope.targetFolders = scope.$parent.targetFolders;
                    scope.targetFoldersLength = scope.$parent.targetFoldersLength;
                    scope.contentDriversOpen = true;
                    scope.$parent.contentDriversOpen = true;
                }

                function clickTab(tab){

                    scope.contentDriversTab=tab;
  
                    if(!scope.contentDriversOpen){
                        open();
                        scope.$emit('contentDriversPaneOpenByClick');
                    }
                }

                function close() {
                    scope.contentDriversOpen = false;
                    scope.$parent.contentDriversOpen = false;
                    utils.triggerResize();
                    $(".main-content-page").removeClass("content-drivers-open");
                }

                function openCloseFolder(key){
                    if(scope.targetFoldersLength>1){
                        scope.targetFolders[key].open = !scope.targetFolders[key].open;
                    }
                }

                function toggleByFilter(filter) {
                    scope.$emit("contentDriversPaneToggled", filter);
                }

                function getDisplay(phrase, key){

                    if(phrase.manual){
                        return phrase.text; //icon handled in view
                    }
                    else if(key=='phrases'){
                        return phrase.phrase;

                    }else if(key=='hashtags'){
                        return phrase.hashtag;

                    }else if(key=='influencers' || key=='audience'){
                        return phrase.handle;

                    }else if(key=='pages'){
                        return phrase.name;

                    }else if(key=='interests'){
                        return phrase.id;

                    }else if(key=='emojis'){
                        return '';//handled in view
                    }
                }

                function removeItem(item, dataType){
                    removeItemCB([item], dataType);
                }

                function removeAll(){
                    TargetsCommon.removeAllFromTarget(scope.$parent.currentTarget).then(function(target){          
                        scope.removingAll = false;
                        scope.$root.$broadcast('updateTargets', {
                          action: 'remove-all-from-target',
                          target: target});
                    });
                }

                function removeFolder(dataType){
                    removeItemCB(scope.$parent.currentTarget.results[dataType], dataType);
                }

                function removeItemCB(items, dataType){
                    TargetsCommon.removeFromTarget(scope.$parent.currentTarget, dataType, items).then(function(target){
                        
                        scope.$root.$broadcast('updateTargets', {
                          action: 'remove-from-target', 
                          tab: dataType, 
                          items: items,
                          target: target});

                    });
                }
            }
        ]
    }
}]);
