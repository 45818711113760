"use strict";
"use strict";

var customFilter = require("infra/context/filters/custom-filter");
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    require("widgets/bubbles-widget/bubbles-widget").name,
    require("widgets/streams-widget/streams-widget").name,
    require("data/discovery-export-service").name,
    require("data/stream-export-service").name,
    require("infra/context/filters/channels-mold").name,
    require("infra/context/filters/bubblesChannels-mold").name,
    require("infra/context/filters/articleType-mold").name,
    require("infra/mixpanel/mixpanel-discovery").name,
    require("infra/context/filters/language-mold").name,
    require('./grid.discovery.prediction').name,
    require('pages/discovery/grid.queries').name,
    require("pages/programs/program_settings/program-settings").name,
    require("infra/user_access/abi-permissions").name
]);

DiscoveryController.$inject = ["$scope", "$rootScope", "$http", "$state", "mixpanelDiscovery", "programService", "ModalService", "context", "filtersPartition", "streamsService", "abiPermissions"];
function DiscoveryController(scope, $rootScope, $http, $state, mixpanelDiscovery, programService, ModalService, context, filtersPartition, streamsService, permissions) {

    scope.$state = $state;
    scope.context = context;
    scope.refreshAudienceSummary = 0;

    $rootScope.Geos.setSubGeos(scope, () => context.current._discoverySubGeos_mold, c.getChannels($state, context));
    const listener = $rootScope.$on('$stateChangeSuccess', ()=> {
        $rootScope.Geos.setSubGeos(scope, () => context.current._discoverySubGeos_mold, c.getChannels($state, context));
    });

    scope.$on('$destroy', listener);

    scope.supportLanguage = function () {
        return context.current._language_mold.supportLanguage($state, context);
    };

    scope.editSources = function () {
        programService.program(context.program.id).then(function (program) {
            ModalService.showModal({
                template: require('pages/programs/program_settings/program-settings.html'),
                inputs: {program: program, initialView: 'connect sources'},
                controller: 'programSettingsController'
            });
        }, function () {
            console.log("failed Edit");
            notificator.error({body: "Edit canceled! Could not load program, please check internet connection"});
        });
    };

    var closeListener = context.onChange(mixpanelDiscovery.trackDiscoverySearch);
    scope.$on('$destroy', closeListener);
    scope.audienceSummaryOrder = _.compact(_.map(filtersPartition.genders.concat(filtersPartition.children).concat(filtersPartition.age)
        .concat(filtersPartition.income).concat(filtersPartition.racesWithAll), "summary"));

    scope.gridAdvancedSummary = function () {
        return customFilter.summary('gridAdvanced', context.current.adv);
    };

    scope.gridAdvancedTitles = function (text) {
        return customFilter.gridAdvanced.titles[text];
    };

    scope.gridAdvancedShow = function () {
        return $state.includes('discovery.grid') && context.current.adv && Object.keys(context.current.adv).length;
    };

    scope.discoveryGeos = function () {
        var geos = $rootScope.Geos.serverValue();
        return $rootScope.Geos.geosForChannel(geos, $state, context);
    };

    scope.showAudienceFilter = function () {
        var channels = c.getChannels($state, context);
        if ($state.is('discovery.streams') && (context.current.showCustomSources || (channels.includes('sg_telco') && (channels.length > 1)))) return false;

        var channel_sources = _.groupBy(channels, c.getSource);
        var shouldDisplayAudience = _.size(channel_sources) == 1 && _(['articles','sg_telco']).includes(_(channel_sources).keys().first());
        var hasSgTelcoAudiencePermission = permissions.hasPermission('sg telco audience filters');
        if (channel_sources['sg_telco']) shouldDisplayAudience = shouldDisplayAudience && hasSgTelcoAudiencePermission;
        return shouldDisplayAudience;
    };

    scope.hideGeoFilter = function () {
        var showCustomSources = ($state.is('discovery.streams') && context.current.showCustomSources) || ($state.is('discovery.bubbles') && context.current.bubblesShowCustomSources);
        return showCustomSources || scope.showSubGeos();
    };

    scope.showSubGeos = function () {
        var channels = c.getChannels($state, context);
        return $rootScope.Geos.showSubGeos(channels);
    };

    scope.hideMediaTypeFilter = function () {
        return $state.is('discovery.streams') && (!context.current.showCustomSources && !scope.hideMediaFilter)
    };

    scope.unsocialState = function () {
        if ($state.is('discovery.bubbles') || $state.includes('discovery.grid')) {
            var channels = c.getChannels($state, context);
            var noTweets = channels.indexOf('tweets') == -1;
            var noFacebook = channels.indexOf('facebook') == -1;
            return noTweets && noFacebook;
        }

        if ($state.is('discovery.streams')) {
            var mappingField = context.current.showCustomSources ? 'type' : 'value';
            return context.current.channels.length > 0 ? _.intersection(_.map(context.current.channels, mappingField), ['tweets', 'instagram','facebook']).length == 0 : false;
        }
    };

    scope.$watch('context.current._gridChannels_mold._value', channelChanged);
    scope.$watch('context.current._channels_mold._value', channelChanged);
    scope.$watch('context.current._bubblesChannels_mold._value', channelChanged);

    function channelChanged() {
        //media types
        var streamsChannels = streamsService.getChannels(context.current.channels);
        var mediaTypes = _.flatten(Object.values(_.pick(filtersPartition.mediaTypes, streamsChannels)));
        var intersectionFilters = _.uniqBy(mediaTypes, 'value');

        context.current._articleType_mold.replace(_.intersectionBy(context.current.articleType, intersectionFilters, 'value'));

        // Show the filters only when there is more then 1 type for each channel
        scope.hideMediaFilter = streamsChannels.length >= mediaTypes.length;
        scope.mediaTypes = intersectionFilters;
        var channels = c.getChannels($state, scope.context);
        scope.channels = channels;

        $rootScope.Geos.setSubGeos(scope, () => context.current._discoverySubGeos_mold, c.getChannels($state, context));
    }
}

var bubbles = require('./bubbles.discovery.js').stateConfig;
var streams = require('./streams.discovery.js').stateConfig;
var grid = require('./grid.discovery.js').stateConfig;

module.exports.stateConfig = {
    name: "discovery",
    url: "/discovery",
    template: require("./discovery.html"),
    display: "Discovery",
    abstract: true,
    reload: false,
    context: {},
    data: {
        permissions: ["discovery"]
    },
    children: [bubbles, streams, grid],
    controller: DiscoveryController,
    controllerAs: "discoveryCtrl"
};
