"use strict";
module.exports = angular.module(__filename, []).provider('stateHelper', ["$stateProvider",
    function ($stateProvider) {
        var self = this;
        var contextDefinition = {};

        /**
         * Recursively sets the states using $stateProvider.state.
         * Child states are defined via a `children` property.
         *
         * 1. Recursively calls itself for all descendant states, by traversing the `children` properties.
         * 2. Converts all the state names to dot notation, of the form `grandfather.father.state`.
         * 3. Sets `parent` property of the descendant states.
         *
         * @param {Object} state - A regular ui.router state object.
         * @param {Array} [state.children] - An optional array of child states.
         * @param {String} keepOriginalNames - prevent dot notation on this state and all children state.
         *      Use "IgnoreRoot" to prevent dot notation on this state only, children of this state
         *      will have use their own names, but second degree children and up will use do notation based on it's own
         *      paren'ts name.
         *
         *      Use "CurrentOnly", to only prevent dot notation on this state. children state will use this state's name
         *      as a parent (in dot notation).
         */
        this.state = function (state, keepOriginalNames) {
            state.reloadOnSearch = false;
            state.params = state.params || {};

            Object.keys(state.context || {}).forEach(function (k) {
                // populate ui-router params with the right routerType for each context attribute defined in the page
                contextDefinition[k] = {
                    mold: state.context[k].mold ? state.context[k].mold : state.context[k]
                }
            });

            if (!keepOriginalNames) {
                fixStateName(state);
            }

            $stateProvider.state(state);

            if (state.children && state.children.length) {
                var children = [];
                // flatten children array
                [].concat(state.children).forEach(function (raw) {
                    if (raw.stateConfig) {
                        raw = raw.stateConfig;
                    }
                    children = children.concat(raw);
                });
                children.forEach(function (childState) {
                    childState.parent = state;
                    self.setNestedState(childState, shouldFixChildNames(keepOriginalNames));
                });
            }

            return self;
        };

        this.setNestedState = this.state;

        this.$get = function () {
            return {
                contextDefinition: contextDefinition
            }
        };

        function fixStateName(state) {
            if (state.parent) {
                state.name = state.parent.name + '.' + state.name;
            }
        }

        /**
         * Determines if child names should be fixed
         *
         * @param keepOriginalNames
         */
        function shouldFixChildNames(keepOriginalNames) {
            // backward compatibility: we proactively guard against non string since true/false might be used in the old protocol
            var lowercase = keepOriginalNames && keepOriginalNames.toLowerCase && keepOriginalNames.toLowerCase();
            if (lowercase == "ignoreroot") {
                return "currentonly";
            } else if (lowercase == "currentonly") {
                return false;
            }
            return keepOriginalNames;
        }
    }
]);