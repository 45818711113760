"use strict";
var validate = require('./validate');

// Language types validators from validate.js
var is = validate.is;
var isNumber = validate.isNumber;
var isArray = validate.isArray;
var isString = validate.isString;

var FAILED_DATA_DEFAULT_MESSAGE = 'FAILED_DATA_DEFAULT_MESSAGE'; // 'Failed to retrieve the data from server.',
var INVALID_DATA_MESSAGE = 'Invalid data retrieved from the server.';
var ERROR_STATUS_DEFAULT_TITLE = INVALID_DATA_MESSAGE;
var ERROR_STATUS_DEFAULT = 551;

var getServerError = function (response, m) {
    var eo = {
        warning: false,
        status: ERROR_STATUS_DEFAULT,
        title: ERROR_STATUS_DEFAULT_TITLE,
        messages: []
    };
    console.error('Server Error: ' + JSON.stringify(response));
    eo.messages.push(isString(m) ? m : FAILED_DATA_DEFAULT_MESSAGE);
    if (is(response)) {
        eo.messages.push('');
        eo.status = isNumber(response.status) ? response.status : eo.status;
        if (isString(response.statusText) && response.statusText.length > 0) {
            eo.title = response.statusText;
            eo.messages.push('[' + eo.status + '] ' + response.statusText + ':');
        }
        if (is(response.data) && isString(response.data.error) && response.data.error.length > 0) {
            eo.messages.push(response.data.error);
        }
    }
    return eo;
};

var logUpdate = function (title, values) {
    console.log('[' + title + '] doUpdate ' + JSON.stringify(values.timeframe) + ': ' + JSON.stringify(values.terms));
};

var log = function (message, obj1, obj2) {
    if (obj2) {
        console.log(message + (obj1 ? JSON.stringify(obj1) : '') + ': ', obj2);
    } else {
        console.log(message + (obj1 ? JSON.stringify(obj1) : ''));
    }
};

module.exports = {
    getServerError: getServerError,
    logUpdate: logUpdate,
    log: log
};
