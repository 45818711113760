"use strict";
/* multi level table

to use:
=======

1. scope.dataTrees = {
    some-tree: {
        checkedArray: [{id: id1}, {id: id2}, {id: id3}]

        children: [
          {id:..., name:...},
          {id:..., name:...},
          {id:..., name:..., children:{...}}
        ]
    }
}

if page has several trees, please place them all in
scope.dataTrees{
 some-tree:{...},
 another-tree:{...}
}

if the dataTree is part of the side filters menu, add customFilter so the filter can be reset
some-tree:{customFilter: true}

then do <quick-tree tree-name="some-tree"></quick-tree>

2. then, scope.dataTrees.some-tree.show()

3. page should have scope.dataTrees.some-tree.saveChecked(checkedArray)
   tree will call it with debounce

4. optional scope.dataTrees params:
   * allSelectedLabel
   * showSearch - default=false
   
5. optional node params:
  * type
  * selectable - default=true
  *


*/


var tmpl = require("./quick-tree.html"),
    debounce = 1000;

module.exports = angular.module(__filename, []).directive("quickTree", ['$timeout', '$sce', function ($timeout, $sce) {
    return {
        restrict: 'E',
        template: tmpl,
        scope: true,
        
        link: function (scope, elem, attrs, ngModel) {

            if (attrs.treeName && !_.isEmpty(scope.dataTrees)) {
                scope.dataTree = scope.dataTrees[attrs.treeName];
                scope.dataTree.id = attrs.treeName;
            }

            scope.initLeafs = function() {
              var checkedHash = {};
              (scope.dataTree.checkedArray||[]).forEach(function(checked){
                checkedHash[checked.id] = checked;
              });

              var initLeaf = function(leaf, parent) {
                var parent = parent || {id: null};
                leaf.parentID = parent.id;
                leaf.shown = true;

                var checkedVal = checkedHash[leaf.id];

                leaf.checked = (parent.checked  === "checked" || checkedVal) ? "checked" : "";
                
                if (checkedVal) {
                  _.extend(checkedVal, leaf);
                }

                if (leaf.children) {
                  leaf.children.forEach(function(child){
                    initLeaf(child, leaf);
                  });
                } else {
                  scope.checkParentState(leaf);
                }
              };

              initLeaf(scope.dataTree, null);
            };

            scope.clickLeaf = function(leaf, checked){
                leaf.checked=checked;

                (leaf.children || []).forEach(function(child){ 
                    scope.clickLeaf(child, checked);                 
                });
                
                scope.checkParentState(leaf);
            };


            scope.clickLeafs = function(leaf, checked){
                scope.clickLeaf(leaf, checked);
                scope.genCheckedArray();
                scope.genSummaryStr();

                //clear search
                var prevSearch = scope.searchStr;
                scope.searchStr = '';
                if(prevSearch){
                  scope.search();
                }
                
                //save
                $timeout.cancel(scope.dataTree.clickTimer);
                scope.dataTree.clickTimer = $timeout(function() {

                  scope.dataTree.saveChecked(scope.dataTree.checkedArray.slice(0));

                }, debounce);
                
            };

            scope.openCloseLeafs = function(leaf, opened){  
                leaf.opened = opened;
                leaf.shown = true;
                (leaf.children || []).forEach(function(child){ 
                    scope.openCloseLeafs(child, opened);                 
                });
            };

            scope.search = function(){
              scope.searchStr = scope.searchStr.toLowerCase();

              var leaf = scope.dataTree.children.length > 1 ? 
                  scope.dataTree : scope.dataTree.children[0];

              if(scope.searchStr.length>=2){  
                scope.searchLeafs(leaf);
              }else{
                leaf.shown = true;
                (leaf.children || []).forEach(function(child){ 
                    scope.openCloseLeafs(child, false);                 
                });
              }
            };



            scope.searchLeafs = function(leaf){
                
                var leafHasString = leaf.name.toLowerCase().includes(scope.searchStr);
                leaf.shown = leafHasString;
                leaf.opened = leaf.shown;

                (leaf.children || []).forEach(function(child){ 
                    scope.searchLeafs(child);                 
                });

                if(leafHasString){
                  scope.showParent(leaf);
                }
            };


            scope.showParent = function(leaf){
              var parent = scope.getParent(leaf);

              if(parent && parent.name){
                parent.shown = true;
                parent.opened = true;
                scope.showParent(parent);
              }
            };

            scope.checkParentState = function(leaf){

              //if only some children are checked, checkbox class = "semi"
              //if none are checked, uncheck the parent too

              if(!leaf.parentID){
                return;
              }

              var par = scope.getParent(leaf);
              par.numOfChecked = 0;

              par.children.forEach(function(child){
                if(child.checked == 'checked'){
                  par.numOfChecked++;
                }
                else if(child.checked == 'semi'){
                  par.numOfChecked+=0.5;
                }
              });

              par.checked = 
                par.numOfChecked == par.children.length ? 'checked' :
                  par.numOfChecked == 0 ? '' : 'semi';

              scope.checkParentState(par);
            };


            scope.getParent = function(leaf){
               return scope.getLeafParent(scope.dataTree, leaf.parentID);
            };

            scope.getLeafParent = function(leaf, parentID){
               
               if(!parentID){
                return null;
               }else if(leaf.id == parentID){
                    return leaf;
               }else if (leaf.children){
                    var i, par = null, len = leaf.children.length;
                    for(i=0; i<len; i++){
                        par = scope.getLeafParent(leaf.children[i], parentID);
                        if(par){
                          break;
                        }
                    }
                    return par;
               }
               return null;
            };

            scope.markSearch = function(text){
              if (!scope.searchStr) {
                return $sce.trustAsHtml(text);
              }
              
              return $sce.trustAsHtml(
                text.replace(new RegExp(scope.searchStr, 'gi'), '<span class="checked">$&</span>'));
            };

            function isLeafChecked(leaf){
                return leaf.checked !== undefined && leaf.checked != '';
            }
            
            scope.genCheckedArray = function(){

              var checkedArray = [];
              genCheckedLeaf(scope.dataTree);

              function genCheckedLeaf(leaf){
                if(leaf.checked === 'checked' && leaf.name){
                  checkedArray.push({
                      id: leaf.id, type: leaf.type, name: leaf.name
                  });
                }
                
                (leaf.children||[]).forEach(genCheckedLeaf);
              }

              scope.dataTree.checkedArray = checkedArray;
            };

            scope.genSummaryStr = function(){

                //check if all are selected / not selected
                var all = true, checkedClass = null;
                
                //check which chlidren to search
                var kids = scope.dataTree.children  || [];
                var children = kids.length == 1 && kids[0].children.length ? 
                      kids[0].children : kids;

                children.forEach(function(child){

                  if(!all) return;

                  if(null==checkedClass){
                    checkedClass = child.checked;
                  }

                  if(child.checked != checkedClass && (isLeafChecked(child) || isLeafChecked({checked: checkedClass}))){
                    all = false;
                  }
                });

                if(all){
                  scope.summaryStr = scope.dataTree.allSelectedLabel || 'All';
                  scope.dataTree.summaryStr = scope.summaryStr;
                  return;
                }

                var str = '', leaf= '', len = 0, MAX_SHOWN = 4;
                var max_items = scope.dataTree.maxSummaryItems || MAX_SHOWN;

                scope.dataTree.checkedArray.forEach(function(leaf){
                    if(leaf && leaf.name){
                        if(len < max_items){
                            str += leaf.name+', ';
                        }
                        len++;
                    }
                });
                str = str.substring(0, str.length-2) + ( len > max_items ? " and " + (len - max_items) + " other" : "");

                scope.summaryStr = str;
                scope.dataTree.summaryStr = str;
            };



            // public methods

            scope.dataTree.clickAll = function(checkedAll){
              if(undefined==checkedAll){

                checkedAll = 'checked';

                if(scope.dataTree.checkedAll=='checked' ||
                  scope.dataTree.children[0].checked){
                  checkedAll = '';
                }
              }

              scope.clickLeafs(scope.dataTree, checkedAll);
            };

            scope.dataTree.isAllChecked = function(){
              
              var children = scope.dataTree.children;
              if(scope.dataTree.children.length==1){
                children = scope.dataTree.children[0].children;
              }

              var checked = null, all = true;
              children.forEach(function(child){
                if(checked == null){
                  checked = child.checked;
                }else{
                  if(child.checked != checked){
                    all = false;
                  }
                }
              });

              return all;
            }


            scope.dataTree.show = function(){

              //init & show tree

              scope.searchStr = '';
              scope.summaryStr = '';
              scope.dataTree.name = scope.dataTree.name || '';
              scope.dataTree.checkedArray = scope.dataTree.checkedArray || [];
              scope.dataTree.clickTimer = $timeout(function() {});

              if(scope.dataTree.children.length==1){
                scope.dataTree.children[0].opened = true;
              }
              
              scope.initLeafs();
              scope.genSummaryStr();
            }


        }          
    }

}]);
