"use strict";
module.exports = angular.module(__filename, [
    require('data/settings/user-mgmt').name,
    require('common/modals/password/reset-password.modal.service').name
]).controller('editUserModalController', ['$scope', 'user', 'close', 'notificator', 'abiPermissions', 'userMgmt', 'mgmtServiceFactory', 'resetPasswordModal', 'geoService','$q',
    function ($scope, user, close, notificator, abiPermissions, userInterface, mgmtServiceFactory, resetPasswordModal, geoService,$q) {
        // handle new user
        $scope.loading = true;
        $scope.saving = false;

        var orgService = mgmtServiceFactory.createService('organization');

        var currentUser = $scope.$root.user;
        $scope.init = function() {
          var options_id = null;

          if (user == undefined || user.id == undefined) {
            user = {};
            user.programs = [];
            options_id = $scope.$root.user.account.id;
            $scope.action = "create";
          } else {
            options_id = user.account.id;
            $scope.action = "update";
          }
          $scope.user = angular.copy(user);
          $scope.getOptions(options_id).then(function() {

            $scope.permissions = $scope.formatPermissions(user);
            $scope.countries = $scope.formatCountries(user);
            $scope.initUser();
          }).then(function () {
            $scope.loading = false;
          });
        }

        $scope.getOptions = function(id) {
          return orgService.customRequest('GET', '/' + id + '/options').then(function (data) {
              $scope.options = data;
              $scope.options.programs = $scope.options.programs.filter(function (p) {
                  return !(/interests/.test(p.name));
              });
              $scope.options = filterOptions($scope.options);
          });
        };

        function filterOptions(options) {
            var hasPermission = currentUser.userType.match(/super admin/i);
            return {
                programs: options.programs,
                permissions: options.permissions,
                countries: options.countries,
                account: hasPermission ? options.account : [currentUser.account],
                user_types: hasPermission ? options.user_types : filterUserTypes(options.user_types)
            };

            function filterUserTypes(types) {
                return angular.copy(types).filter(function (t) {
                    return [1, 2, 3].indexOf(t.id) != -1;
                });
            }
        };

        $scope.formatPermissions = function(user) {
          var gPermissions = angular.copy($scope.options.permissions);
          if (!_.isObject(user) || (user.id == undefined)) {
            return gPermissions.map( function(p) {
              p.on = p.default_on;
              return p;
            });
          } else {
            return gPermissions.map( function(p) {
              p.on = _.includes(_.map(user.permissions, 'id'), p.id);
              return p;
            });
          }
        };

        $scope.formatCountries = function(user) {
          var gCountries = angular.copy($scope.options.countries);
          if (!_.isObject(user) || (user.id == undefined)) {
            return gCountries.map( function(c) {
              c.selected = false;
              return c;
            });
          } else {
            return gCountries.map( function(c) {
              c.selected = _.includes(_.map(user.countries, 'id'), c.id);
              return c;
            });
          }
        };

        $scope.initUser = function() {
            // remove my interests from programs
            $scope.user.programs = $scope.user.programs.filter(function (p) {
                return !(/interests/.test(p.name))
            });

            $scope.user.account_id = user.account ? user.account.id : currentUser.account.id;
            $scope.user.user_type_id = user.user_type ? user.user_type.id : 2;
            $scope.user.permission_ids = _($scope.permissions).filter('on').map('id').value();
            $scope.user.program_ids = user.programs ? idsMapper(user.programs) : [];
        }

        function idsMapper(array) {
            return array.map(function (x) {
                return x.id.toString();
            });
        }

        function toggle(id, array) {
            var index = array.indexOf(id.toString());
            var found = index != -1;
            found ? array.splice(index, 1) : array.push(id.toString());
        }

        // close modal on navigate back
        var closeListener = $scope.$root.$on("$stateChangeStart", function () {
            close({reload: false})
        });
        $scope.$on('$destroy', closeListener);



        $scope.close = close;
        $scope.update = handleSubmit;
        $scope.setPassword = setPassword;
        $scope.toggleProgram = toggleProgram;
        $scope.isProgramSelected = isProgramSelected;

        $scope.changeOrganization = function(account_id) {
          var newOrg = _.find($scope.options.account, {id: account_id});
          $scope.loading = true;

          $scope.getOptions(account_id).then(function() {
            $scope.permissions = $scope.formatPermissions();
            $scope.validateUserTypePermissions();
            $scope.loading = false;
            if ($scope.action == "update") {
              notificator.notify({body: "User permissions were reset to " + newOrg.name + "'s granted permissions."});
            }
          });
        };

        $scope.validateUserTypePermissions = function() {
            if ($scope.user.user_type_id == 1) {
              $scope.$broadcast('selectAllPermissions', false);
              $scope.$broadcast('togglePermission', {permission: 'discovery', value: true});
            };
        };

        function toggleProgram(programId) {
            toggle(programId, $scope.user.program_ids);
            $scope.user.programs = $scope.options.programs.filter(function (p) {
                $scope.search = '';
                return $scope.user.program_ids.indexOf(p.id.toString()) != -1;
            });
        }

        function isProgramSelected(id) {
          return _.isObject($scope.user.program_ids) && $scope.user.program_ids.indexOf(id.toString()) != -1;
        }

        function setPassword(userId, shouldReload, showCancel) {
            $scope.saving = true;
            resetPasswordModal.show(userId, showCancel).then(function (modal) {
                modal.close.then(function (success) {
                    if (success) close({reload: shouldReload});
                });
            });
        }

        function handleSubmit(user) {
            $scope.saving = true;
            if (user.id) {
                updateUser(user);
            } else {
                saveUser(user);
            }
        }

        function saveUser(user) {
            userInterface.add(user).then(function (responseUser) {
                notificator.success({body: responseUser.name + ' successfully saved. An activation E-mail has been sent to the user'});
                close({reload: true});
            });
        }

        function updateUser(user) {
            userInterface.update(user.id, user).then(function (responseUser) {
                if (currentUser.id == responseUser.id) {
                    angular.extend(currentUser, responseUser);
                    abiPermissions.init(responseUser);
                }
                notificator.success({body: responseUser.name + ' successfully updated'});
                close({reload: true});
            });
        }

      $scope.init();
    }
]);
