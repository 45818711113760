"use strict";
module.exports = {
    sum:function (arr) {
        if (arr.length == 0) {
            return 0;
        } else {
            return arr.reduce( function(prev, current){ return prev + current } );
        }
    },
    mean: function(arr) {
        if (arr.length == 0) {
            return 0;
        } else {
            return this.sum(arr) / arr.length;
        }
    },
    variation: function (arr) {
        if (arr.length == 0) {
            return 0;
        } else {
            var mean_value = arr.mean();
            return arr.map( function(x){ return Math.pow( (x - mean_value), 2 ) } ).mean();
        }
    }
}