"use strict";
"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
]).factory("UserFirstPartyAudienceMold", ['filtersPartition', 'filterMoldService', function (filtersPartition, filterMoldService) {
    return function UserFirstPartyAudienceMold() {
        this._value = [];
        this.default = [];

        var collection = filtersPartition.userFirstPartyAudience;

        filterMoldService.getDefaultFilterMoldFunction.call(this, collection);
    }
}]);