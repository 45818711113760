"use strict";
var mixPanel = require('./mixpanel-base');

module.exports = angular.module(__filename, [
    mixPanel.name
]).service('mixpanelSettings', ['$state', '$window', 'context', 'mixpanelService', function ($state, $window, context, mixpanelService) {

    var widgetName = mixpanelService.extractWidget($state.current);
    var appName = 'Settings';

    function trackPageView(widget) {
        widgetName = widget;
        mixpanelService.trackPageView(appName, widgetName);
    }

    return {
        trackPageView: trackPageView
    }
}]);
