"use strict";
"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name,
    require('./filter-mold-service').name
 ]).factory("LanguageMold", ['filtersPartition', 'filterMoldService', 'abiPermissions' , '$timeout', '$rootScope', 'notificator',
 	function (filtersPartition, filterMoldService , abiPermissions , $timeout , $rootScope, notificator) {
    return function LanguageMold() {
        this._value = [];
        this.default = {label: "English", value: "en"};
        this.prevValue = null;

        this.getLanguage = function($state, context, forceChange){

            /*
            on each tab/channel, we check if selected language is supported.
            if not, we clear seeds

            apology
            =======
            clearning seeds inside a "get" function
            is NOT great design patterns but 
            other (numerous) solutions failed because:

            1. many components like input bar dont have ordered set+get 
            2. many events are triggered on every tab/channel change
            3. there were too many edge states to handle            
            */
            
            this.prevValue = this.prevValue || $.extend(true, {}, this._value);
            var newValue = this.supportLanguage($state, context) ? this._value : this.default;

            if(this.prevValue.value != newValue.value || forceChange){

                var hasSeeds = context.current.terms.length;
                this.prevValue = $.extend(true, {}, newValue);
                $rootScope.$broadcast('clear-all-seeds');
                
                if(!forceChange && hasSeeds){
                    notificator.notify({body: "Seeds reset because language setup has changed"});
                }
            }

            return newValue;
        }

        this.supportLanguage = function ($state, context) {
    
            if(false == abiPermissions.hasPermission('spanish')){
        		return false;
        	}

        	var page = $state.current.name, 
        	    channels = c.getChannels($state, context);

	        return page.includes("discovery") && 
                (channels.length == 0 || channels.includes('tweets'));
	    }

	    this.isAutoComplete = function($state, context){
	    	var lan = this.getLanguage($state, context).value;
	    	return lan =='en';
	    }

        filterMoldService.getDefaultFilterMoldFunction.call(this, filtersPartition.language);
     }
 }]);
