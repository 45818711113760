"use strict";
module.exports = angular.module(__filename, [
    require("angular-modal-service").name,
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
]).controller('organizationEditController', ['$scope', 'organization', 'permissions', 'countries', 'close', '$q', 'service', 'notificator', 'confirmAction','geoService',
    function ($scope, organization, permissions, countries, close, $q, service, notificator, confirmAction) {

        $scope.close = close;
        $scope.saving = false;

        $scope.init = function() {
          if (organization.id == undefined) {
            organization.max_users = 100,
            organization.max_admins = 5;
            $scope.action = "create";
          } else {
            $scope.action = "update";
          }
          $scope.organization = angular.copy(organization);
          $scope.permissions = permissions;
          $scope.countries = countries;
          $scope.organization.permission_ids = _($scope.permissions).filter('on').map('id').value();
          $scope.organization.country_ids = _($scope.countries).filter('selected').map('id').value();
          $scope.initialOrg = angular.copy($scope.organization);
        };

        $scope.save = function(org) {
            if (org.id) {
                $scope.update(org);
            } else {
                $scope.create(org);
            }
        };

        $scope.create = function(org) {
            service.create(org).then(function (data) {
                notificator.success({body: data.name + ' was saved successfully'});
                close({reload: true});
            });
        };

        function executeUpdate(value) {
          var scope = value.scope, org = value.org;
          scope.saving = true;
          service.update(org.id, org).then(function (data) {
            if (data.reset_users.length > 0){
              notificator.success({body: data.name + ' was updated successfully. Geos were reset for the following users: ' + data.reset_users.toString()});
            } else {
              notificator.success({body: data.name + ' was updated successfully'});
            }
            close({reload: true});
          });
        };

        function checkRemovedPermissions(value) {
          var scope = value.scope, org = value.org;
          return $q(function(resolve, reject) {
            var removedPermissions = _.difference(scope.initialOrg.permission_ids, scope.organization.permission_ids);
            var hasRemovedPermissions = !_.isEmpty(removedPermissions);
            var removedGeos = _.difference(scope.initialOrg.country_ids, scope.organization.country_ids);
            var hasRemovedGeos = !_.isEmpty(removedGeos);
            if (hasRemovedPermissions || hasRemovedGeos) {
              var confirmationMsg = "Removed permissions will affect all the users under the organization '" + org.name + "'.";
              var txtConfirm      = "OK";
              var txtCancel       = "Cancel";
              var txtTitle        = "Apply Permissions";
              confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, txtTitle).then(function (modal) {
                modal.close.then(function (confirmation) {
                  if (confirmation) {
                    resolve({scope: scope, org: org});
                  } else {
                    reject({scope: scope, org: org});
                  }
                });
              });
            } else {
              resolve({scope: scope, org: org});
            }
          });
        };

        function checkNewPermissions(value) {
          var scope = value.scope, org = value.org;
          return $q(function(resolve, reject) {
            var newPermissions = _.difference(scope.organization.permission_ids, scope.initialOrg.permission_ids);
            var s = (newPermissions.length > 1) ? 's' : '';
            var hasNewPermissions = !_.isEmpty(newPermissions);
            var newGeos = _.difference(scope.organization.country_ids, scope.initialOrg.country_ids);
            var hasNewGeos = !_.isEmpty(newGeos);
            if (hasNewPermissions || hasNewGeos) {
              var confirmationMsg = "Apply new permission" + s + " to all '" + org.name + "' users?";
              var txtConfirm      = "Yes";
              var txtCancel       = "No";
              var txtTitle        = "Apply Permissions";
              confirmAction.getConfirmation(confirmationMsg, txtConfirm, txtCancel, txtTitle).then(function (modal) {
                modal.close.then(function (confirmation) {
                  org.apply_new_permissions = confirmation;
                  resolve({scope: scope, org: org});
                });
              });
            } else {
              resolve({scope: scope, org: org});
            }
          });
        };

        $scope.update = function(org) {
          checkRemovedPermissions({scope: $scope, org: org}).then(checkNewPermissions).then(executeUpdate);
        };

        $scope.init();
    }
]);
