"use strict";
module.exports = angular.module(__filename, [])
    .service("cancellableHttp", ["$http", "$q", function ($http, $q) {
       
        function cancellableHttp(config) {
            var defer = $q.defer();

            var _config = _.clone(config);
            _config['timeout'] = defer.promise;
            var _http = $http(_config);

            _http.cancel = function () {
                defer.resolve('AMOBEE_CANCEL_OK');
            };  

            _http.cancellableThen = function (resolve, error) {
               var _then = _http.then(resolve, error);
                _then.cancel = _http.cancel;
                _then.cancellableCatch = function (error) { 
                    var _catch = _then.catch(error);
                    _catch.cancel = _then.cancel;
                    return _catch;
                }
                _then.cancellableCatch.cancel = _then.cancel;
                return _then;        
            };       
            
            return _http;    
        }

        return {
            $http: cancellableHttp
        }
    }]
);
