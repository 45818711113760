"use strict";
"use strict";
const config = require('infra/config');

module.exports = require("angular").module(__filename, [])
    .service("tvService", ['$http', function ($http) {

        const GET_SHOWS_URL = config.USER_MGMT_API + '/tv/shows';

        return {
            getShows: getShows
        };

        function getShows(ids) {
            return $http.post(GET_SHOWS_URL, {'ids': ids}).then((res)=>{
                return res.data
            })
        }
    }]
);
