"use strict";
/**
 * Created by Pery on 13/12/2015.
 */

/* init PARSE RESULT and OPTIONS list  */
var dataPattern = '(@model as)? (@icon is icon of)? (@label for)? (_instance_ in @source@) (index by @index)?';
// var dataPattern = '(@model as)? (@label for)? (_instance_ in @source@) (index by @index)?';

/***********************************
 * am-input-controller
 **********************************/

module.exports = ['$scope', '$element', '$attrs', '$q', 'directiveBuildHelper', '$timeout', "ModalService",
    function (scope, element, attr, $q, directiveBuildHelper, $timeout, ModalService) {
        var ngModelCtrl = element.controller('ngModel'),
            amContainerCtrl = element.controller('amContainer'),
            me = this;

        if (attr.data) {
            var Parser = directiveBuildHelper.compile(dataPattern);
            var parser = Parser(attr.data, scope.$parent);
            var getters = parser.raw.getters;

            getters.model = getters.model || getters.instance;
            var noExplicitIdentifier = !getters.index;
            scope.options = parser.source;

            /* options() -> inputCtrl.options */
            parser.onSourceUpdate(function () {
                me.options = parser.source();
                /*todo: in next version should support arrays ref and array of indexes*/
            });
        }

        _.extend(this, {
            returingArray: true,
            inUpdateState: false,
            remove: [],
            modelValue: [],
            placeholder: [],
            options: [],

            /** Editor **/
            editor: {
                addOption: addOption,
                removeOption: removeOption,
                setSelected: setSelected,
                reset: reset,
                openOption: openOptionsList,
                closeOption: closeOptionsList,
                toggleOption: toggleOpen,
                getOptions: getOptions,
                getSelected: getSelected
            },

            /** for Inputs with DATA attrs **/
            parser: parser,
            states: {
                new: {
                    stage1: goState_New_stage1,
                    stage2: goState_New_stage2
                }
            },
            intersection: intersection,
            getSelectedOptionFromOutside: syncViewFromModelAndOptions,
            commitSelection: commitSelection,

            removeHelper: arrayReduce,
            setAll: setAll
        });

        /** Data manipulation **/
        if (attr.data) {
            if (attr.placeholder) {
                try {
                    var placeholder = scope.$parent.$eval(attr.placeholder);
                    me.placeholder = [parser.converter(placeholder)];
                } catch (ex) {
                    me.placeholder = [{
                        label: attr.placeholder,
                        index: -1
                    }]
                }
            }
        }

        /** ------------- Editor ------------- **/
        function setSelected(outerOption) {
            $timeout(function () {
                var option = _.find(me.options, ['model', outerOption]);
                scope.selectingValue(option);
            })
        }

        function getSelected() {
            var selectedOption = _.find(me.options, ['selected', true]);
            return selectedOption;
        }

        function addOption(outerOption) {
            var source = parser.raw.source();
            source.push(outerOption);
        }

        function removeOption(outerOption) {
            var source = parser.raw.source();
            _.remove(source, outerOption);
            //arrayReduce(   me.options, [ parser.converter( outerOption ) ] );
        }

        function reset() {
            setViewValue([]);
        }


        /** ------------- handle 'open' class --------------- **/
      
        function toggleOpen(event) {    

            element.toggleClass('open');
                me.$event = event;
                if (element.hasClass('open')) {
                    //event.stopPropagation();
                    watchForGlobalClosedClick();
                }else{
                    console.log("CLOSING");
                }
        }

        function getOptions() {
            return me.options;
        }

        

        function watchForGlobalClosedClick() {
            setTimeout(function () {
                document.addEventListener('click', closeOptionsList, false);
            });
            /** todo: add remove event listener on destroy **/
        }

        function openOptionsList(event, withGlobalClose) {
            element.addClass('open');
            me.$event = event;
            withGlobalClose && watchForGlobalClosedClick();
        }

        function closeOptionsList(event) {
            me.$event = event;
            element.removeClass('open');
            document.removeEventListener('click', closeOptionsList, false);
            event.stopPropagation();
            goState_New_stage1();
        }

        /** ------------- states ---------------**/
        function goState_New_stage1() {
            element.find('.bottom input').hide();
            element.find('.bottom button').show();

            element.find('.bottom .create-new').show();
        }

        function goState_New_stage2() {
            element.find('.bottom input').show().focus();
            element.find('.bottom button').hide();

            element.find('.bottom .create-new').hide();
        }

        /*************************
         * Helper function
         **************************/

        function setViewValue(viewValue) {
            viewValue = makeViewValue(viewValue);
            ngModelCtrl.$setViewValue(viewValue);
        }

        function makeViewValue(viewValue) {
            if (viewValue.length == 0) {
                viewValue = intersection(me.options, me.placeholder);
                if (viewValue.length == 0) {
                    viewValue = me.placeholder;
                }
            }
            setAll(me.options, 'selected', false);
            setAll(viewValue, 'selected', true);
            return viewValue;
        }

        function syncViewFromModelAndOptions() {

            var viewValue = intersection(me.options, me.modelValue);
            return makeViewValue(viewValue);
        }

        function commitSelection() {
            var viewValue = ngModelCtrl.$viewValue.concat(); //shallow clone
            arrayReduce(viewValue, me.placeholder);

            if (me.returingArray) {
                /* remove all options from outer model before insert the fresh one */
                arrayReduce(me.modelValue, me.options);
                me.modelValue = me.modelValue.concat(viewValue);
            } else {
                me.modelValue = viewValue;
            }

            //setAll(me.options, 'selected', false);
            //setAll(viewValue, 'selected', true);

            return _.map(me.modelValue, 'model');
        }

        function intersection(options, outerModels) {
            var out = [];

            outerModels.forEach(function (obj) {
                if (noExplicitIdentifier) {
                    out.push(_.find(options, ['model', obj.model]));
                } else {
                    out.push(_.find(options, ['index', obj.index]));
                }
            });
            return _.compact(out);
        }

        function arrayReduce(array1, byArray2) {
            array1 = _.array(array1);
            byArray2 = _.array(byArray2);

            byArray2.forEach(function (obj) {
                if (noExplicitIdentifier) {
                    _.remove(array1, ['model', obj.model]);
                } else {
                    _.remove(array1, ['index', obj.index]);
                }
            });
            return array1;
        }

        function setAll(array, key, value) {
            array.forEach(function (option) {
                option[key] = value;
            })
        }
    }
];
