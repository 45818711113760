"use strict";
/*
common target functions and event handlers
to use:
=======
1. include this on every page that has targets
2. include targets-common-partial.html
3. if page needs, include targets-delete-partial.html
4. call getTargets
5. handle updateTargets event
6. page needs to define functions like createTargetWindow 
7. if page has contet drivers (right target pane)
     * include entire content-drivers files and logic
     * scope.contentDriversTargets = true;
*/

var config = require("infra/config"),
    c = require("infra/utils/common");

module.exports = angular.module(__filename, [require("data/target-data").name])
    .service('TargetsCommon', ['$http', 'context', 'CHANNEL', 'TargetData', 'notificator', 'ModalService',
        function ($http, context, CHANNEL, TargetData, notificator, ModalService) {

            return {

                HOST: config.USER_MGMT_API + '/programs/',
                POSTFIX: "?disable_notification=1",
                DEFAULT_TARGET_TYPE: 'trends',
                loading: false,
                TargetData: TargetData,

                targetsQueriesUrl: function(params){
                    let url = this.HOST;
                },

                getTargets: function(params){

                    //so it wont get called while still loading
                    if(this.loading){
                        return;
                    }
                    this.loading = true;

                    let url = config.USER_MGMT_API + '/targets/all';
                        
                    if(params.$state){ //only targets from current channel+program         
                        let channel = CHANNEL.name(c.getChannels(params.$state, context)[0]),
                            p_id = context.current.p_id;
                        
                        url += '?program_id='+p_id+'&channel='+channel;
                    }

                    let targetsPromise = new Promise((resolve,reject)=> {
                        $http.get(url).success(targets=> {
                            resolve(targets);
                        });
                    });

                    let marketsPromise = this.getMarkets();

                    Promise.all([targetsPromise, marketsPromise]).then(data => { 
                        let targets = data[0],
                            markets = data[1];

                        TargetData.markets = $.extend(true, {}, markets);

                        this.initTargets(targets);
                        params.scope.targets = Array.from(targets);  

                        this.updateTargetsHandler({action: 'update-target-list'}, params.scope);
                        if(undefined != params.cb){
                            params.cb();
                        }

                        this.loading = false;
                    });
                },

                getMarkets: function(){
                    return new Promise((resolve,reject)=> {
                        if (TargetData.markets){
                            return resolve(TargetData.markets);
                        }
                        $http.get(config.USER_MGMT_API + '/dsp/markets')
                            .success(data=> {
                                let markets = data.reduce(
                                    (res,market)=>{
                                        res[market.marketId]=market.name;
                                        return res;
                                    },{});

                                resolve(markets);
                            });
                        })
                },

                openTargets: function(scope){

                    ModalService.showModal({
                        template: require('../../pages/targets/targets-modal.html'),
                        controller: require('../../pages/targets/targets-modal.js'),
                        controllerAs: 'targetsController',
                        inputs: {
                            parent: scope
                        }
                    }).then(function (modal) {
                        modal.close.then(function () {
                        });
                    });
                },

                initTargets: function(targets){
                    var that = this;
                    $.each(targets, function(i, t){
                      that.TargetData.targetToUI({target: t});
                    });
                },

                showUpdatedList: function($scope, targets){
                  //show list and moves scroll
                  $scope.queries.targetlist.saveScroll();
                  $scope.queries.targetlist.show(targets);
                  $scope.queries.targetlist.loadScroll();
                },


                edit: function(target, tab, $scope){

                  var that = this;
                  $http.get(this.HOST+target.program_id+"/grid_target/"+target._id+this.POSTFIX).then(function(res){
                    that.TargetData.targetToUI({target: res.data, action: 'edit-target', context: context});
                    
                    if($scope){
                        $scope.editTargetOpened = true;
                    }

                    ModalService.showModal({
                        template: require('../discovery/modal/modalEditTarget.html'),
                        controller: require('../discovery/modal/modalEditTarget.js'),
                        controllerAs: 'editTargetCtrl',
                        inputs: {
                          currentTarget: res.data,
                          parentTab : tab,
                          dataType: ''
                        }
                    }).then(function (modal) {
                         modal.close.then(function () {
                            if($scope){
                                $scope.editTargetOpened = false;
                            }

                        });
                    });; 

                  });
                },


                deleteConfirm: function($scope){
                  var id = $scope.deleteTarget._id, r = $scope.$root,
                      name = $scope.deleteTarget.name;

                  $http.delete(this.HOST + $scope.deleteTarget.program_id+"/grid_target/"+id+this.POSTFIX).then(function(){
                    $scope.deleteTarget=null;
                    r.$broadcast('updateTargets', {action: 'remove-target', _id: id});

                    notificator.success({body: 'Target "' + name + '" deleted'});

                  });
                },

                createTargetWindow: function(target){
                    
                    ModalService.showModal({
                        template: require('../../pages/targets/create-target.html'),
                        controller: require('../../pages/targets/create-target.js'),
                        controllerAs: 'createTargetCtrl',
                        inputs: {
                            params: target
                        }
                    });
                },


                addToCurrentTarget: function (scope) {
                    //add to current target or create new target

                    var selected = $.extend(true, [], scope.query.selected);

                    if (selected && selected.length == 0) {
                        return
                    }

                    if (!scope.currentTarget.name) {
                        scope.createTargetWindow(scope.query.selected);
                    } else {
                        var alreadyExists = '', i, j,
                            res = scope.currentTarget.results && scope.currentTarget.results[scope.tab] ?
                                    scope.currentTarget.results[scope.tab] : [],
                            len = res.length,
                            lenSelected = selected.length;

                        for (i = 0; i < lenSelected; i++) {
                            var phrase = selected[i];
                            for (j = 0; j < len; j++) {
                                if (res[j].id == phrase.id && !res[j].dynamic) {
                                    alreadyExists += phrase[scope.query.columns[0].id] + " , ";
                                    phrase.alreadyExists = true;
                                }
                            }
                        }

                        if (alreadyExists) {
                            notificator.notify({body: alreadyExists.substring(0, alreadyExists.length - 3) + " already exists in this target"});
                        }

                        selected = selected.filter(function (phrase) {
                            return !phrase.alreadyExists;
                        });

                        if (!selected.length) {
                            return;
                        }

                        //add to results
                        if(!scope.currentTarget.results){
                            scope.currentTarget.results = {};  
                        }
                        if(!scope.currentTarget.results[scope.tab]){
                            scope.currentTarget.results[scope.tab] = [];
                        }
                        scope.currentTarget.results[scope.tab] = 
                            scope.currentTarget.results[scope.tab].concat(selected);
                        
                        this.TargetData.targetToServer({target: scope.currentTarget, username: scope.$root.user.name});

                        let url = this.HOST+'grid_target/'+scope.currentTarget._id+this.POSTFIX;

                        $http.put(url, scope.currentTarget).then((res)=>{
                            this.updateTargetsHandler({target: res.data, action: 'add-to-target', tab: scope.tab}, scope);
                        });
                    }
                },

                addToTargetCall: function(params) {
                    //params: target, type, selected
                    
                    let content = {};
                    content[params.type] = params.selected;

                    let url = this.HOST+params.target.program_id+"/grid_target/"+params.target._id+'/data'+this.POSTFIX;
                    return $http.put(url, content).then((res) =>{
                        notificator.success({body: params.selected.length + ' '+this.typePlural(params.type, params.selected.length)+' added to target "' + params.target.name + '"'});
                        return res.data;
                    });

                },

                removeFromTarget: function(target, type, words) {

                    var headers = {"Content-Type": "application/json;charset=utf-8"},
                        content = {};
                    content[type] = words;

                    let url = this.HOST+target.program_id+'/grid_target/'+target._id+'/data'+this.POSTFIX;

                    return $http({
                        method: 'DELETE',
                        url:url, 
                        data: content, 
                        headers: headers
                        }).then((res) =>{
                            notificator.success({
                                body: words.length + ' '+this.typePlural(type, words.length)+' removed from target "' + target.name + '"'
                        });
                        return res;
                    });
                          
                },

                removeAllFromTarget: function(target){
                    var headers = {"Content-Type": "application/json;charset=utf-8"},
                        content = {};

                    let tab = Object.keys(target.results).length==1 ? 
                            Object.keys(target.results)[0] : 'concepts';

                    for(let i in target.results){
                    content[i] = target.results[i] || [];
                    }

                    let url = this.HOST+target.program_id+'/grid_target/'+target._id+'/data'+this.POSTFIX;
    
                    return $http({
                        method: 'DELETE',
                        url:url, 
                        data: content, 
                        headers: headers
                        }).then((res) =>{
                            notificator.success({
                                body: 'All '+tab+' removed from target "' + target.name + '"'
                        });
                        return res;
                    });

                },


                updateTargetsHandler: function (params, scope) {
                    //main handler of all update events
                    //once it updates, sends 'updateTargetsCB'

                    params = params || {};

                    var oldId = scope.currentTarget._id,
                        channel = CHANNEL.name(context.current.gridChannels);

                    function findTarget(_id){
                        function sameId(row){return row._id == _id;}

                        var targets = scope.targets.filter(sameId) ;
                        return targets.length ? targets[0] : {};
                    }

                    if (params.action == "remove-target") {
                        
                        function otherId(row){return row._id != params._id;}
                        scope.targets = scope.targets.filter(otherId);

                        if(params._id == scope.currentTarget._id){
                            scope.currentTarget = {};
                            context.current["target_" + channel] = '';
                        }

                        params.targets = scope.targets.slice(0);

                    } else if (params.action == "update-target") {

                        var tar = findTarget(params.target._id);
                        tar = $.extend(true, tar, params.target);
                        this.TargetData.targetToUI({target: tar});

                        scope.currentTarget = $.extend(true, {}, tar);

                        params.targets = scope.targets.slice(0);

                    } else if (params.action == 'add-to-target' && scope.contentDriversTargets) {

                        if(!scope.currentTarget.results){
                            scope.currentTarget.results = {};
                            scope.currentTarget.results[params.tab] = [];
                        }

                        scope.currentTarget.results[params.tab] = scope.currentTarget.results[params.tab].concat(params.selected || scope.query.selected);
                        scope.currentTarget.results[params.tab] = _.uniq(scope.currentTarget.results[params.tab], function (item, key, a) {
                            return item.id;
                        });

                        scope.query.selected = [];
                        scope.query.dataArray.forEach(function (cell) {
                            cell.selected = false;
                        });

                        scope.selectAll = false;

                        scope.$root.$broadcast('openContentDrivers', "target_drawer");
                    
                    } else if (params.action == 'remove-from-target' && scope.contentDriversTargets) {

                        var ids = params.items.map(function (item) {
                            return item.id;
                        });

                        scope.currentTarget.results[params.tab] = scope.currentTarget.results[params.tab].filter(function (item) {
                            return ids.indexOf(item.id) == -1;
                        });

                    }else if (params.action == 'remove-all-from-target' && scope.contentDriversTargets) {

                        for(let i in scope.currentTarget.results){
                            scope.currentTarget.results[i] = [];
                        }

                        scope.$broadcast('remove-all-from-target');

                    } else if (params.action == 'create-target') {

                        this.TargetData.targetToUI({target: params.target});
                        scope.currentTarget = $.extend(true, {}, params.target);
                        scope.targets.push(params.target);
                        context.current["target_" + channel] = scope.currentTarget._id; 

                        scope.$root.$broadcast('openContentDrivers', "target_drawer");

                    } else if (params.action == 'switch-target') {
                        scope.currentTarget = $.extend(true, {}, params.target);
                        context.current["target_" + channel] = scope.currentTarget._id; 

                        //later calls openContentDrivers

                    } else if(params.action == 'update-target-list'){
                      scope.currentTarget = {}; //reset target
                    }

                    //default: get target from context id
                    if(!scope.currentTarget.name){
                      var _id = context.current["target_" + channel];
                      scope.currentTarget = findTarget(_id);
                    }

                    scope.currentTarget.loaded = true;

                    let url = this.HOST+scope.currentTarget.program_id+"/grid_target/"+scope.currentTarget._id+this.POSTFIX;

                    TargetData.targetToCurrentTarget(scope, url);

                    //update all listernes that targets changed
                    scope.$root.$broadcast('updateTargetsCB', params);
                    
                }, //end of updateTargetsHandler 

                

                toggleSelected: function (scope, row, $event) {
                    //shift+click checks/uncheckes all checked

                    if($event){
                        var index = scope.query.viewArray.indexOf(row),
                            prev = scope.prevIndexSelected == undefined ? index : scope.prevIndexSelected,

                            start = Math.min(index, prev),
                            end = Math.max(index, prev);

                        if ($event.shiftKey) {
                            for (var i = start; i <= end; i++) {
                                scope.query.viewArray[i].selected = row.selected;
                            }
                        }

                        scope.prevIndexSelected = index;
                    }

                    scope.query.selected = (scope.query.dataArray || []).filter(function (row) {
                        return row.selected;
                    });
                },

                typePlural: function(type, length){
                    let title =  type; //type = phrases | hashtags | etc ...

                    if(title.endsWith('s')){
                        title = title.slice(0, -1);
                    }

                    if(length > 1){
                      title+='s';
                    };

                    return title;
                }

            }
        }
    ]
);
