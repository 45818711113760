"use strict";
var Template = require("./sentiment-widget.html"),
    c = require("infra/utils/common"),
    ex = require("infra/utils/export"),
    BaseWidget = require("../base_widget"),
    StackedChart = require("../../common/charts/stacked-chart"),
    BarChart = require("../../common/charts/vertical-bar-chart"),
    mixpanel = require("../../infra/mixpanel/mixpanel-insights"),
    SentimentService = require("data/insights/sentiment-trend-service");

var sentimentAverageConfiguration = {
    margin: -18,
    element: 'sentiment-average-chart',
    tooltip: 'left'
};

var CHART_ENTRIES = [{key: "positive", source: "pos", order: 1, class: "positive-sentiment", units: "%"},
    {key: "normal", source: "neu", order: 2, class: "normal-sentiment", units: "%"},
    {key: "negative", source: "neg", order: 3, class: "negative-sentiment", units: "%"}];

var sentimentExportName = 'Sentiment Breakdown';

function SentimentController($scope, $rootScop, $timeout, sentimentTrendService, util, examplesDataModel, insightsExportService, mixpanelInsights) {
    var self = this;
    this.service = sentimentTrendService;
    this.insightsExportService = insightsExportService;
    this.parameters = {};
    this.util = util;

    $scope.noData = true;
    $scope.inProcess = false;
    $scope.trends = [];
    $scope.chartArray = [];
    $scope.examplesData = examplesDataModel;
    mixpanelInsights.trackPageView('sentiment');
    this.charts = {};
    // this.chartData = this.service.getEmptySentiments();
    this.barChart = new BarChart(this, CHART_ENTRIES, sentimentAverageConfiguration);

    this.onResize = function () {
        if (c.is(this.charts) && this.chartData) {
            $timeout(function () {
                var data = self.parameters.to_normalize ? self.chartData.averages : self.chartData.sums;
                _.each(data,function(term,i){
                    term.text = term.label;
                    term.display = self.util.getTermDisplay(term);
                });
                self.barChart.setData(data, self.chartData.maxChart, self.wide);
                $timeout(function () {
                    var bh = c.getElementHeight('sentiment-view-port');
                    bh = (self.chartData.chart.length > 1) ? bh / 2 - 33 : bh - 35;
                    c.setElementProperty('sentiment-view-port', 'overflow-y',
                        (self.chartData.chart.length < 3) ? 'hidden' : 'auto');
                    _.each (self.charts, function(entry, index) {
                    	var key = 'sentiment-chart-frame-' + index;
                    	var el = $(document.getElementById(key));
                    	var ch = el.height();
                    	if (ch != bh) {
                    		el.height(bh);
                    		el.css('minHeight', bh);
                    	}
                    });
                    $timeout(function () {
                        _.each(self.charts, function (entry) { // index = termKey
                            entry.chart.draw(entry.data, self.chartData.range, entry.max);
                        });
                    }, 0);
                }, 0);
            }, 0);
        }
    };

    this.clearData = function (update) {
        $scope.inProcess = false;
        $scope.trends = [];
        this.chartData = this.service.getEmptySentiments();
        this.charts = {};
        $scope.chartArray = [];
        if (update) {
            self.onResize();
        }
        $scope.noData = true;
        $scope.examplesData.visible_examples = [];
        $scope.examplesData.examples.sentiment = [];
        $scope.examplesData.filter_type = '';
        $scope.examplesData.filters = [];
    };

    this.getSummaryReport = function (format, parameters, date, fields) {
      var summary = ex.getQuerySummary(format, parameters, date, fields);
      var terms = self.util.getValidTerms($scope, parameters);
      if (c.isArray(terms)) {
        summary.table.push([]);
        var summary_metric = self.parameters.to_normalize ? "Consumption Index" : "Total Mentions";
        summary.table.push(["", format(summary_metric, 'bold'), format("Positive", 'bold'), format("Neutral", 'bold'), format("Negative", 'bold')]);
        var summaryTable = self.service.getSummary(terms, self.parameters.to_normalize);
        _.each(summaryTable, function(s) {
          summary.table.push([format(s.display,  'bold'),
                              format(self.parameters.to_normalize ? s.value : parseInt(s.value), self.parameters.to_normalize ? 'numeric' : 'integer'),
                              format(s.positive, 'percent'),
                              format(s.neutral,  'percent'),
                              format(s.negative, 'percent')]);
        });
      }

      return summary;
    };

    this.getExportReport = function (format) {
        var report = {name: sentimentExportName, columns: [], table: []};
        var terms = self.util.getValidTerms($scope, self.parameters);
        if (c.isArray(terms)) {
            var titles = [format('Date', 'bold')];
            report.columns = [{width: 18}];
            _.each(terms, function (term) {
                titles.push(format(term.display + ' (Positive)', 'bold'));
                report.columns.push({width: 25});
                titles.push(format(term.display + ' (Neutral)', 'bold'));
                report.columns.push({width: 25});
                titles.push(format(term.display + ' (Negative)', 'bold'));
                report.columns.push({width: 25});
                titles.push(format(term.display + (self.parameters.to_normalize ? ' (Consumption Index)' : ' (Total Mentions)'), 'bold'));
                report.columns.push({width: 30});
            });
            report.table = [titles];
            var result = self.service.getValuesByDateTable(terms, format, self.parameters.to_normalize);
            report.table = report.table.concat(result);
        }
        return report;
    };

    this.getExportExamples = function (format) {
        var report = {name: 'Content drivers', columns: [], table: []};
        report.table = [[format('Date', 'bold'), format('Seed', 'bold'), format('Sentiment', 'bold'), format('URL', 'bold')]];
        report.columns = [{width:18}, {width:25} ,{width:18}, {width:100}];
        var result = self.service.getSentimentExamplesForExport($scope.trends, format);
        report.table = report.table.concat(result);
        return report;
    };

    this.toNormalize = function () {
        return self.parameters.to_normalize;
    };

    this.emitEventForChartCircle = function(eventType, letter){
        $scope.$emit(eventType, letter);
    };

    this.resetHighlightedCirclesFromCharts = function(allCharts) {
        _.each(self.charts, function (entry, index) {
            if (allCharts || self.isCurrentLetterClicked(entry.chart.letterClicked)) {
                entry.chart.resetHighlightedCircle();
            }
        });
    };

    this.isCurrentLetterClicked = function (currentClickedLetter) {
        return (c.isString(self.$scope.$parent.letterClicked) && c.isString(currentClickedLetter) &&
            self.$scope.$parent.letterClicked != currentClickedLetter)
    };

    this.letterExistsInData = function(data, letter) {
        return _.some(data, function(entry) {
            return entry.letter == letter;
        });
    };

    this.setContentDriversPane = function() {
        $scope.examplesData.alphabetized = true;
        $scope.examplesData.filter_type = 'sentiment';
        $scope.examplesData.filters = [{sentiment: 'pos', show: true}, {sentiment: 'neg', show: true}];
        $scope.examplesData.visible_examples = [];
        $scope.examplesData.examples.sentiment = [];
    };

    $scope.$on('contentDriverMouseover',function(event, letter) {
        _.each(self.charts, function(entry, index) {
            entry.chart.highlightCircleFromLetter(letter, true);
        });
    });

    $scope.$on('contentDriverMouseout',function(event, letter){
        _.each(self.charts, function(entry, index) {
            entry.chart.highlightCircleFromLetter(letter, false);
        });
    });

    $scope.$on('contentDriverClick',function(event, letter) {
        _.each(self.charts, function(entry, index) {
            if (self.letterExistsInData(entry.data.examples, letter)) {
                entry.chart.letterClicked = letter;
                entry.chart.highlightCircleFromLetter(letter, true);
            }
        });
    });

    $scope.$on('widgetPanelClick', function() {
        self.resetHighlightedCirclesFromCharts(true);
    });

    $scope.$on('resetHighlightedCirclesExceptClicked', function() {
        self.resetHighlightedCirclesFromCharts(false);
    });

    this.$scope = $scope;

    insightsExportService.setTitle('Sentiments');
    insightsExportService.setSummaryFunction(this.getSummaryReport);
    insightsExportService.addReportFunction(this.getExportReport);
    insightsExportService.setExampleFunction(this.getExportExamples);
}

SentimentController.prototype._doUpdate = function(values) {
   var $scope = this.$scope;
   $scope.examplesData.visible = $scope.insightsChannel.value == 'tweets';
   $scope.examplesData.open = $scope.insightsChannel.value == 'tweets';
   this.setContentDriversPane();
   this.resetHighlightedCirclesFromCharts(true);
   $scope.$emit('resetLetterClicked');
   c.validateNonPhrases($scope.terms, $scope.insightsChannel.value, this.notificator);

   this.parameters = this.util.buildInsightsParameters($scope);
   $scope.trends = this.parameters.trends;
   sentimentAverageConfiguration.precision = this.parameters.to_normalize ? 1 : 0;
   c.logUpdate('Sentiments', this.parameters);
   $scope.inProcess = true;

   this.insightsExportService.setParams(this.parameters);

   var self = this;
   // TODO return promise after resolving instead of
   $scope.examplesData.promise = this.service.getSentiments(this.parameters, null);
   return $scope.examplesData.promise.then(function(data) {
         $scope.inProcess = false;
         $scope.examplesData.icon = data.icon;
         $scope.examplesData.examples.sentiment = data.examples;
         self.$scope.noData = false;
         self.charts = {};
         self.chartData = data;
         var ca = [];
         var sentimentConfiguration = {
             force_max: true,
             agg_examples_mode: $scope.examplesData.visible
         };
         if (c.isArray(self.chartData.chart)) _.each($scope.trends, function(t, i) {
             _.each(self.chartData.chart, function(d, j) {
                 if (c.is(d.term) && t.id == d.term.id) {
                     var key = c.getKey(d.term.id, d.term.text);
                     if (self.charts[key] == null) {
                         self.charts[key] = {
                             key: key,
                             label: d.term.text,
                             display: self.util.getTermDisplay(d.term),
                             max: d.max,
                             chart: new StackedChart(self, "sentiment-chart-" + key, CHART_ENTRIES, null, sentimentConfiguration),
                             data: d
                         };
                         ca.push(self.charts[key]);
                     }

                 }
             });
         });
         angular.copy($scope.examplesData.examples.sentiment, $scope.examplesData.visible_examples);
         self.$scope.chartArray = ca;
         self.onResize();
   }, function(error) {
       self.clearData(true);
       $scope.$emit('insightsError', error);
   });
};

SentimentController.$inject = ["$scope", "$rootScope", "$timeout", "sentimentTrendService", "util", "examplesDataModel", "insightsExportService", "mixpanelInsights"];

module.exports = angular.module(__filename, [
    SentimentService.name
]).directive("sentimentWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: Template,
        scope: {
            topics: "=",
            terms: "=",
            active: "=",
            insightsChannel: '=insightsChannel',
            timeframe: "=",
            geo: "=",
            cacheBaster: "="
        },
        controller: SentimentController
    });
}]);
