"use strict";
var tmpl = require("./geo-widget.html"),
    Datamap = require("datamaps"),
    geoService = require("data/geo-service"),
    BaseWidget = require("../base_widget");

function GeoWidgetCtrl(geoService) {
    this.geoService = geoService;
    this.$scope.result = {countries_codes: {}};
    this.onResize();
}

GeoWidgetCtrl.$inject = ["geoService"];

GeoWidgetCtrl.prototype.onResize = function () {
    var aspect = 406 / 159;
    var map_element = $("#map-container");
    var parent = $("#widget-container");
    var targetWidth = parent.width();
    var targetHeight = parent.height();

    var element = document.getElementById("map-container");
    element.innerHTML = "";

    if (targetWidth / targetHeight > aspect) {
        map_element.css("width", targetHeight * aspect);
        map_element.css("height", targetHeight);
        //console.debug(targetWidth, targetHeight, "\> aspect", targetWidth / targetHeight, targetHeight * aspect, targetHeight);
    } else {
        map_element.css("width", targetWidth);
        map_element.css("height", targetWidth / aspect);
        //console.debug(targetWidth, targetHeight, "\< aspect", targetWidth / targetHeight, targetWidth, targetWidth / aspect);
    }

    this.$scope.map = new Datamap({
        element: map_element[0],
        geographyConfig: {
            borderWidth: 0,
            highlightFillColor: '#fff',
            highlightBorderWidth: 0,
            popupTemplate: function (geography, data) {
                return '<div class="geo-tootlip">' + geography.properties.name + '</div>';
            }
        },
        fills: {
            LOW: '#81878c',
            HIGH: '#fff',
            defaultFill: '#81878c'
        },
        data: this.$scope.result.countries_codes
    });
};

GeoWidgetCtrl.prototype._doUpdate = function (values) {
    var self = this;
    return this.geoService.get(self.$scope.brandName, self.$scope.geos).then(function (result) {
        if (!result) return;
        var prev_countries = self.$scope.result.countries_codes;
        for (var key in prev_countries) {
            prev_countries[key] = {fillKey: 'defaultFill'};
        }
        self.$scope.result = result;

        self.$scope.map.updateChoropleth($.extend(prev_countries, result.countries_codes));
    });
};

module.exports = angular.module(__filename, [
    geoService.name
]).directive("geoWidget", ["geoService", "$window", function (geoService, $window) {
    return BaseWidget({
        restrict: "E",
        template: tmpl,
        scope: {
            brandName: "=brandName",
            geos: "=geos",
            sensitiveContent: "=sensitiveContent"
        },
        controller: GeoWidgetCtrl
    });
}]);
