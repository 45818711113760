"use strict";
function StreamsController($rootScope, discoveryStreamService) {
    $rootScope.createExcelWorkbook = discoveryStreamService.getWorkbook;
    $rootScope.helpPage = 'Content_Streams';
}

StreamsController.$inject = ["$rootScope", "discoveryStreamService"];

module.exports.stateConfig = {
    name: "streams",
    url: "/streams",
    template: require("./streams.discovery.html"),
    display: "Discovery",
    data: {
      permissions: ['content streams']
    },
    controller: StreamsController,
    context:{}
};
