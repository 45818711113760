"use strict";
const angular = require("angular"),
    BaseWidget = require("../base_widget"),
    audienceTableView = require('../../widgets/audience-table-view-widget/audience-table-view-widget'),
    mixpanel = require("../../infra/mixpanel/mixpanel-audience");

AudienceInterestsWidgetController.$inject = ['audienceInsightsService', 'TargetsCommon', 'util', 'context', '$state', 'filtersPartition', 'segmentInterestExportService', 'mixpanelAudience', 'audienceTableStructure'];

function AudienceInterestsWidgetController(audienceInsightsService, TargetsCommon, infraUtil, context, $state, filtersPartition, segmentInterestExportService, mixpanelAudience, audienceTableStructure) {
    const self = this;
    const audienceTables = audienceTableStructure.tableStructure;
    const $scope = this.$scope;
    const segment = context.current.audience_segment;

    const typeSort = {
        portion: "percents",
        composition: "value"
    };
    const sortMapperHack = {
        portion: 'interest-portion',
        composition: 'uniqueness-index'
    };

    self.mixpanelAudience = mixpanelAudience;
    self.infraUtil = infraUtil;
    self.audienceInsightsService = audienceInsightsService;
    self.segmentInterestExportService = segmentInterestExportService;
    self.all_ages = filtersPartition.age.map(a => a.summary);
    self.exportButton = angular.element(document.querySelector('.export'))[0];

    $scope.interestTypes = [{value: 'websites', label: 'Websites'}, {value: 'phrases', label: 'Phrases'}];
    $scope.outputTypes = [{value: 'bars', icon: 'icon-bar-chart', label: ""}, {value: 'table', icon: 'icon-table', label: ''}];
    $scope.query = audienceTables['interests'];

    $scope.selectedView = 'bars';
    $scope.LastSelectedView = 'bars';
    $scope.sortByTypes = [
        {
            value: 'portion',
            label: 'Consumption',
            tooltip: {
                text: 'Sort by how popular the interest is among the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        },
        {
            value: 'composition',
            label: 'Skew',
            tooltip: {
                text: 'Sort by the extent to which the interest is over-indexed within the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        }
    ];

    //TARGETS
    $scope.tab = $scope.interestTypes[0].value;
    $scope.tabs = [$scope.tab];
    $scope.currentTarget = {};
    $scope.targets = [];
    $scope.contentDriversTargets = false;

    TargetsCommon.getTargets({scope: $scope, $state: $state});

    const updateTargetsHandlerWatch = $scope.$root.$on('updateTargets', (event, obj) => {
        TargetsCommon.updateTargetsHandler(obj, $scope);
    });

    //target events
    $scope.$on('$destroy', () => {
        updateTargetsHandlerWatch(); //clears watch
    });

    $scope.$on('remove-all-from-target', () => {
        $scope.resetSelection();
    });

    $scope.openTargets = () => {
        TargetsCommon.openTargets($scope);
    };

    $scope.editTarget = () => {
        if($scope.currentTarget) TargetsCommon.edit($scope.currentTarget);
    };

    $scope.addToCurrentTarget = () => {
        const audience_id = context.current.audience_id;
        $scope.query.selected.forEach(sel => { sel.audience_id = audience_id });

        TargetsCommon.addToCurrentTarget($scope);
    };

    $scope.createTargetWindow = phrases => {

        /*audience params:
        target_type: audience_interests
        type:        phrases | websites
        channel:     audience_phrases | audience_websites - display: web (for now)
        */

        const channel = $scope.audienceChannel(),
            channelType = channel.replace("audience_", ""),
            queryName = audienceInsightsService.QUERY_NAMES.channelType;

        let results = {[channelType]: phrases};

        TargetsCommon.createTargetWindow({
            target_type: 'audience_interests',
            name: '',
            channel: channel,
            u_id: $scope.$root.user.id,
            seeds: [],
            query: audienceInsightsService.getSegmentParams(segment, queryName),
            results: results
        });
    };

    $scope.audienceChannel = () => {
        return context.current.audience_channel ? context.current.audience_channel : `audience_${$scope.tab}`;
    };

    $scope.toggleSelected = (row, $event) => {
        TargetsCommon.toggleSelected($scope, row, $event);
    };

    $scope.toggleSelectedBar = bar => {
        const row = $scope.query.dataArray.find(o => o.id === bar.id);
        if(!row) return;
        row.selected = bar.selected;
        $scope.toggleSelected(row, null);
    };

    $scope.toggleSelectedAllBar = (selectAll, bars) => {
        if(selectAll) {
            const barIds = bars.map(bar => bar.id);
            $scope.query.selected = $scope.query.dataArray.filter(o => barIds.includes(o.id));
            $scope.query.selected.forEach(row => row.selected = true);
        } else {
            $scope.query.dataArray.forEach(row => row.selected = false);
            $scope.query.selected = [];
        }
    };

    //END OF TARGETS

    $scope.$root.$on("$stateChangeStart", () => {
        self.exportButton.hidden = false;
    });

    function getInterestPromise() {
        return self.$scope.interestType === 'phrases' ? phrasesPromise : websitesPromise;
    }

    function showBars(show) {
        let bars = angular.element(document.querySelector('selectable-bar-chart div.scroll-container'));
        show ? bars.show() : bars.hide()
    }

    function setTableHead(type) {
        // Dynamicly set quick-table properties.
        $scope.query.columns[0].title = type;
        $scope.query.title = type;
        if(self.$scope.interestType === 'phrases') {
            $scope.query.columns[0].template = 'partials/phrase-select.partial.html';
            $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the phrase';
            $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the phrase';
            $scope.query.doSummary = () => {}
        } else {
            $scope.query.columns[0].template = 'partials/audience-domain.partial.html';
            $scope.query.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience visits the website';
            $scope.query.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience visits the website';
        }

    }

    $scope.getNoDataMsg = () => {
        const dataTypeHumanReadablePlural = self.$scope.interestType === 'websites' ? 'websites' : 'phrases';
        return `Sorry, we couldn't find relevant ${dataTypeHumanReadablePlural} on this topic for your target audience.`
    };

    function audienceConsumptionComparisonTooltip(obj) {
        const percentValue = obj["interest-portion"].toFixed(1);
        const value = obj["uniqueness-index"].toFixed(1);

        const isWebsitesType = self.$scope.interestType === 'websites';
        const clientsVerb = isWebsitesType ? 'consumed' : 'are interested in';

        let uniquenessValueQuantifier;
        if(value === 1) uniquenessValueQuantifier = 'as';
        if(value > 1) uniquenessValueQuantifier = 'x' + value + ' times more';
        if(value < 1) uniquenessValueQuantifier = 'x' + (1 / value).toFixed(1) + ' times less';

        const avgConsumerComparison = value === 1 ? 'as the average consumer' : 'than the average consumer';


        return `${percentValue}% of the target audience ${clientsVerb} ${obj.phrase}` + '<br/>' +
               `Consumers of ${obj.phrase} are ${uniquenessValueQuantifier} likely to be in the target audience ${avgConsumerComparison}`;
    }

    $scope.loadTopicInterests = topic => {
        self.topic = topic;
        const isWebsitesType = self.$scope.interestType === 'websites';
        $scope.loading = true;
        return getInterestPromise().then(data => {
            let interests = (topic.id === 'All Interests') ? (data.words || []) : (data.words || []).filter(interest => interest.topic === topic.id);

            if(topic.id !== 'All Interests') {
                $scope.selectedView = 'bars';
                showBars(true);
            } else {
                $scope.selectedView = $scope.LastSelectedView;
                if($scope.selectedView === 'table') showBars(false);
            }

            interests = interests.map(interest => ({
                id: interest['phrase-id'], title: interest['phrase'], portion: interest['interest-portion'], composition: interest['uniqueness-index'],
                percents: interest[sortMapperHack[self.$scope.sortBy]],
                value: interest['interest-portion'].toFixed(1) + '% | ' + interest['uniqueness-index'].toFixed(1),
                url: isWebsitesType ? 'http://' + interest['phrase'] : null,
                tooltip: audienceConsumptionComparisonTooltip(interest)
            }));
            interests = _.orderBy(interests, self.$scope.sortBy, 'desc').slice(0, 100);
            self.infraUtil.normalize(interests, 'percents', 'percents');
            self.$scope.interests = interests;

            self.$scope.title = topic.id;

            //add "id" for targets
            if(data.words && data.words.length && !data.words[0].id) {
                data.words.forEach(word => word.id = word["phrase-id"]);
            }

            self.$scope.tablesData = data.words;
            $scope.loading = false;
            $scope.resetSelection();
        })
    };

    $scope.resetSelection = () => {
        if($scope.query && $scope.query.selected && $scope.query.selected.length) {
            $scope.query.selected = [];
            $scope.query.dataArray.forEach(row => row.selected = false);
            $scope.interests.forEach(row => row.selected = false);
        }
        setTimeout(() => {
            $scope.selectedMax = $scope.selectedView === 'bars' ? ($scope.interests || []).length : $scope.query.dataArray.length
        });

        if($("#select-all-checkbox").attr("checked") === "checked") $("#select-all-checkbox").trigger("click");
    };

    $scope.onInterestTypeChange = type => {
        setTableHead(type);
        if(self.topic) self.$scope.loadTopicInterests(self.topic).then(() => $scope.query.show(self.$scope.tablesData));
        context.current.audience_channel = "audience_" + type;
        $scope.tab = type;
        $scope.tabs = [type];
        TargetsCommon.getTargets({scope: $scope, $state: $state});

        const [useTargets, event] = $scope.interestType === 'phrases' ? [true, 'openContentDrivers'] : [false, 'closeContentDrivers'];
        $scope.contentDriversTargets = useTargets;
        $scope.$root.$broadcast(event, "target_drawer");

        $scope.resetSelection();
    };

    $scope.onOutputViewsChange = type => {
        self.$scope.LastSelectedView = type;
        $scope.resetSelection();
        if(type === 'table') {
            showBars(false);
            $scope.query.show(self.$scope.tablesData);
        } else {
            showBars(true);
        }
    };

    $scope.onSortChange = type => {
        if($scope.topicsData) {
            $scope.topicsData.forEach(i => i.percents = type === 'portion' ? i.percentValue : i.value);
            self.infraUtil.normalize($scope.topicsData, 'percents', 'percents');
            $scope.topicsData = _.orderBy($scope.topicsData, typeSort[type], 'desc');
        }

        ($scope.interests || []).forEach(i => i.percents = i[$scope.sortBy]);
        self.infraUtil.normalize(($scope.interests || []), 'percents', 'percents');
        if($scope.interests) $scope.interests = _.orderBy($scope.interests, $scope.sortBy, 'desc');

        $scope.resetSelection();
    };

    $scope.export = () => {};

    $scope.disableSearches = () => false;

    $scope.$root.createExcelWorkbook = $scope.export;

    $scope.calcLeft = () => {
        let barTitle = angular.element(document.querySelector('selectable-bar-chart .chart-title'));
        return `${$scope.title ? barTitle.width() + 20 + 20 : 0}px`;
    };

    const targetRatio = context.current.audience_segment_target_ratio,
        targetRatioObj = targetRatio ? {'wanted-intenders-ratio': targetRatio} : undefined;
    const topicsPromise = audienceInsightsService.getSegmentData(segment, audienceInsightsService.QUERY_NAMES.topics, targetRatioObj);
    const websitesPromise = audienceInsightsService.getSegmentData(segment, audienceInsightsService.QUERY_NAMES.websites, targetRatioObj);
    const phrasesPromise = audienceInsightsService.getSegmentData(segment, audienceInsightsService.QUERY_NAMES.phrases, targetRatioObj);

    const distributionPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(context.current.audience_segment);

    $scope.topicsPromise = topicsPromise.then(data => {
        if(!data) return;
        let topicsData = data["words"].map(topic => {
            const percentValue = topic["interest-portion"].toFixed(1);
            const value = topic["uniqueness-index"].toFixed(1);
            const tooltip = audienceConsumptionComparisonTooltip(topic);
            return {id: topic["phrase-id"], percents: topic["interest-portion"], percentValue, value, tooltip}
        });
        self.$scope.topicsData = _.orderBy(topicsData, 'percents', 'desc');
        return self.$scope.topicsData;
    });

    $scope.export = () => {
        Promise.all([topicsPromise, websitesPromise, phrasesPromise, distributionPromise])
            .then(([topics, websites, phrases, distribution]) => {
                topics = _.orderBy(topics.words, 'interest-portion', 'desc');
                let excel = segmentInterestExportService.exportToExcel(topics, websites.words, null, phrases.words, segment, distribution);
                segmentInterestExportService.downloadExcel(excel);
                mixpanelAudience.trackExport();
            });
    };

    $scope.$root.createExcelWorkbook = $scope.export;
}

module.exports = require("angular").module(__filename, [
    require("../../data/audience-insights-service").name,
    require('data/segment-interest-export-service').name,
    require("../../common/topic-selector.drv/topic-selector.drv").name,
    require("widgets/audience-table-view-widget/audience-table-struct").name,
    require("../../common/selectable-bar-chart.drv/selectable-bar-chart.drv").name,
    mixpanel.name
])
    .directive("audienceInterestsWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-interests-widget.html"),
        scope: {},
        controller: AudienceInterestsWidgetController
    })]);
