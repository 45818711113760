"use strict";
"use strict";
var customFilter = require("infra/context/filters/custom-filter");

module.exports = angular.module(__filename, [
    require('infra/directiveBuildHelper').name,
    require("infra/debouncer").name
]).directive('sideFiltersMenu', ['context', function (context) {
    return {
        restrict: "E",
        controllerAs: 'sideFiltersMenuCtrl',
        controller: ['$scope', function ($scope) {
            var component = [];

            this.add = function (c) {
                var existScope = component.filter(function (element) {
                    return element.filterName == c.filterName;
                })[0];

                component = _.without(component, existScope);
                component.push(c);
            };

            this.closeAll = function () {};

            $scope.$root.hasActiveFilter = function (state) {
                function audienceAllowed() {
                    var bubblesChannels = context.current.bubblesChannels.value;
                    return (state == 'discovery.bubbles' && bubblesChannels == 'articles')
                        || state == 'discovery.streams'
                        || (state.includes('discovery.grid') && bubblesChannels == 'articles');
                }

                return _.some(component, function (scope) {
                    if (scope.filterName == 'Audience') {
                        return state.includes('discovery.') && audienceAllowed() && context.current.audience.length > 0;
                    }

                    if (scope.radio) {
                        var items = scope.items();
                        return state.startsWith('warroom') && items && !angular.equals(items[0], scope.selectedItems[0]);
                    } else if ((scope.filterName == 'channels') && state != 'discovery.streams') {
                        return false;
                    } else {
                        return !scope.allSelected && !_.isEmpty(scope.selectedItems);
                    }
                });
            };

            this.resetFilters = function () {
                angular.forEach(component, function (scope) {
                    if (!scope.radio) scope.resetFilters();
                });

                //reset custom filters
                for (var i in context.current) {
                    if (context.current[i] && context.current[i].customFilter) {
                        customFilter.reset(context.current[i]);
                    }
                }

                if ($scope.dataTrees) {
                    _.forEach($scope.dataTrees, function (dataTree) {
                        if (dataTree.customFilter) {
                            dataTree.clickAll('');
                        }
                    });
                }

            };
        }]
    }
}]).directive('amSelect', ["$window", "$timeout", "directiveBuildHelper", "$document", "debouncer",
    function ($window, $timeout, directiveBuildHelper, $document, debouncer) {
        var dataPattern = '(@modelMapper as)? (@label for)? (_instance_ in @source@) (branch by @branch)? (index by @index)?';
        var parse = directiveBuildHelper.compile(dataPattern);
        var idSeq = 1;

        return {
            restrict: "E",
            require: ['ngModel', '?^sideFiltersMenu'],
            transclude: true,
            template: require('./select-tree.drv.html'),
            priority: 10,
            scope: {
                data: '@',
                metaData: '=',
                topValues: '=',
                showTopValues: '=',
                enableCustomSources: '=',
                showCustomSources: '=',
                title: '@',
                limit: '=',
                summarySize: '@',
                toolbar: '=',
                summary: '=',
                radio: '=',
                filterName: '@',
                treeStyle: '=tree',
                summaryUnselectedText: '@',
                summaryAllSelectedText: '@',
                onSelect: '&',
                onOpen: '&',
                disabled: '=',
                startOpened: '=',
                alwaysOpen: '=',
                skin: '@',
                selectAllLabel: '@',
                closeOnClickOutside: '@',
                closeOnSelect: '@',
                openUp: '@',
                radioButtonsSkinOptionWidth: '@',
                lastSelected: '=?lastSelected'
            },
            compile: function (tElement, tAttrs) {
                return function link($scope, element, attr, ctrl) {
                    var id = idSeq++;
                    var menu = ctrl[1];
                    var ctrl = ctrl[0];
                    var debounce = attr.debounce ? attr.debounce * 1 : null;
                    $scope.context = $scope.$root.context;
                    $scope.scrollContainerElement = element.children('.menu').children('.scroll-container');
                    $scope.element = element;
                    $scope.onOpen = $scope.onOpen ? $scope.onOpen() : null;
                    $scope.selectAllLabel = $scope.selectAllLabel || 'Select All';
                    var onclick = function () {
                        if ($scope.disabled) {
                            closeMenu();
                            return;
                        }
                        $scope.open = !$scope.open;
                        if ($scope.open) {
                            // a little powerful, should be handle with controller
                            menu && menu.closeAll();
                            $scope.openList(true);
                        } else {
                            $scope.openList(false);
                        }

                        $scope.$digest();
                    };

                    $scope.isRadioButtonsSkin = function () {
                        return $scope.skin == 'radio-buttons';
                    };

                    var closeMenu = function () {
                        if (!$scope.open || $scope.alwaysOpen) return;
                        $scope.open = false;
                        $scope.openList(false);
                    };

                    $scope.closeMenu = closeMenu;

                    if ($scope.closeOnClickOutside) {
                        $document.on('click', function (e) {
                            if (element !== e.target && !element[0].contains(e.target)) {
                                closeMenu();
                            }
                        });
                    }

                    var setViewValue = ctrl.$setViewValue;
                    if (attr.debounceGroup) {
                        ctrl.$setViewValue = function () {
                            var value = arguments;
                            debouncer.debounce(id, attr.debounceGroup, function () {
                                setViewValue.apply(ctrl, value);
                            }, debounce)
                        };
                    } else if (attr.debounce) {
                        ctrl.$setViewValue = _.debounce(ctrl.$setViewValue, attr.debounce * 1, {trailing: true});
                    }

                    if ($scope.openUp) {
                        var menuSubElement = element[0].querySelector('.menu');
                        var bottom = element[0].getBoundingClientRect().top - document.body.getBoundingClientRect().top;
                        menuSubElement.style.bottom = "calc( 100% - " + bottom + "px )";
                        menuSubElement.style.width = element[0].getBoundingClientRect().width + "px";
                    }

                    menu && menu.add($scope);
                    $scope.showTopValuesToggle = attr.topValues;
                    $scope.openList = function (status) {
                        status ? element.addClass('open') : element.removeClass('open');
                        $scope.open = status;
                        if (status && $scope.onOpen) $scope.onOpen();
                    };
                    /** init title if found */
                    if ($scope.startOpened || $scope.alwaysOpen) {
                        $scope.openList(true);
                    }

                    if (!$scope.alwaysOpen) {
                        element.find('select-title')
                            .append('<i class="icon-Triangle_Down"></i>')
                            .on('click', onclick);

                        if ($scope.skin == 'audience' || $scope.isRadioButtonsSkin()) {
                            element.find('summary').on('click', onclick);
                        }
                    }

                    function setHeightForRadioButtonsSkin() {
                        var LINE_HEIGHT = 35;
                        var MENU_TOP_PADDING = 13;
                        var num_of_items = $scope.items().length;
                        var num_of_cols = parseInt($scope.element.width() / parseInt($scope.radioButtonsSkinOptionWidth));
                        if (num_of_cols == 0) num_of_cols = 1;
                        var height = Math.ceil(num_of_items / num_of_cols) * LINE_HEIGHT + MENU_TOP_PADDING;
                        $scope.scrollContainerElement.css('height', height + 'px');
                        // fix for chrome bug that sometimes not repaint after css change
                        $($scope.element[0]).css('display', 'table').height();
                        $($scope.element[0]).css('display', 'block');
                    }

                    if ($scope.isRadioButtonsSkin()) {
                        angular.element($window).bind('resize', function () {
                            setHeightForRadioButtonsSkin()
                        });
                    }

                    if ($scope.toolbar && $scope.radio) {
                        console.error('in directive am-select toolbar=true & radio=true not design work together')
                    }

                    var removeListener = $scope.$root.$watch('topicsFilter', parseResults);
                    $scope.$on('$destroy', removeListener);

                    parseResults();

                    function customSourcesEnabledItems() {
                        return $scope.showCustomSources ?  _.filter($scope.items(), 'original.custom') : _.reject($scope.items(), 'original.custom');
                    }

                    function getItems() {
                        return $scope.enableCustomSources ? customSourcesEnabledItems() : _.reject($scope.items(), 'original.custom');
                    }

                    $scope.sortedItems = function () {
                        if ($scope.enableCustomSources) {
                            return customSourcesEnabledItems();
                        }

                        if (!$scope.showTopValues || !$scope.topValues) return getItems();
                        var items = getItems().slice(0);
                        return items.sort(function (a, b) {
                            return $scope.topValues.indexOf(a.index) - $scope.topValues.indexOf(b.index);
                        });
                    };

                    var amountAllItems;
                    var amountSelectedItems = 0;

                    function forEachNode(root, cb) {
                        var items;
                        if (!cb) {
                            cb = root;
                            items = getItems();
                        } else {
                            items = angular.isArray(root) ? root : [root];
                        }
                        (items || []).forEach(function (item, j) {
                            cb(item, null, j);
                            angular.forEach(item.branch, function (subItem, i) {
                                cb(subItem, item, i);
                            });
                        });
                    }

                    function parseResults() {
                        var parseResult = parse(attr.data, $scope.$parent);
                        parseResult.useTreeWrapper();
                        /* default for modelMapper */
                        /* create function that retrieve all items from source */
                        $scope.more = 0;
                        $scope.items = parseResult.source;
                        $scope.converter = parseResult.converter;
                    }

                    $scope.hasCustomSources = function() {
                        return _.filter($scope.items(), 'original.custom').length > 0;
                    };

                    $scope.resetFilters = function () {
                        markedAllKeyAs(null, 'selected', false);
                        commitSelected();
                    };

                    $scope.toggleSelectAll = function () {
                        var selected = ($scope.allSelected = !$scope.allSelected);
                        markedAllKeyAs(getItems(), 'selected', selected);
                        commitSelected();
                    };

                    $scope.toggleAndUpdate = function (item, parent) {
                        $timeout(function () {
                            $scope.toggleSelected(item, parent);
                        }, 0);
                    };

                    $scope.toggleSelected = function (item, parent) {
                        if ($scope.radio) {
                            markedAllKeyAs(null, 'selected', false);
                            item.selected = true;
                            commitSelected();
                            $scope.lastSelected = _.findIndex(getItems(), 'selected');               
                        } else {
                            checkLimit(item);
                            item.selected = !item.selected;
                            $scope.updateAfterChecked(item, parent);
                        }
                        attr.onSelect && $scope.onSelect({$value: ctrl.$modelValue});
                    };

                    $scope.subCheckboxToggle = function (item, parent) {
                        item.selected = !item.selected;
                        $timeout(function () {
                            $scope.toggleSelected(item, parent);
                        }, 0);
                    };

                    function checkLimit(item) {
                        if ($scope.selectedItems && $scope.limit == $scope.selectedItems.length) {
                            if (!item.selected) {
                                // TODO: Royi, still bug in here.. doesn't remove the last element that has been added.
                                var first = $scope.selectedItems.shift();
                                markedAllKeyAs(first, 'selected', false);
                            }
                        }
                    }

                    /* can be toggle selected or toggle open depend of logical business */
                    $scope.toggle = function (item, parent) {
                        if (item.index) {
                            $scope.toggleSelected(item, parent);
                        } else {
                            $scope.toggleOpening(item, parent);
                        }
                    };

                    function markedAllKeyAs(items, key, mark) {
                        var items = items || $scope.items();

                        forEachNode(items, function (item, parent) {
                            item[key] = mark;
                        });
                    }

                    function countSelectedItem(items) {
                        return items.reduce(function (val, item) {
                            return item.selected ? ++val : val
                        }, 0);
                    }

                    $scope.updateAfterChecked = function (item, parent) {
                        if (parent) {
                            var numSelected = countSelectedItem(parent.branch);
                            parent.selected = getSelected(numSelected, parent.branch);
                        } else {
                            /*item it is the parent*/
                            markedAllKeyAs(item, 'selected', item.selected);
                        }

                        function getSelected(numSelected, branch) {
                            var total = branch ? branch.length : 0;

                            return numSelected == 0 ? false : (numSelected == total ? true : 'partial');
                        }

                        /*todo: update state of select all*/
                        commitSelected();
                    };

                    $scope.toggleOpening = function (item) {
                        var isOpen = !item.open;
                        if (isOpen) {
                            /** close other menu **/
                            markedAllKeyAs(null, 'open', false);
                        }
                        item.open = isOpen;
                    };

                    function commitSelected() {
                        var selectedItems = [];
                        /** find all selected */
                        forEachNode(function (item, parent) {
                            if (item.selected == true /**true mean not indeterminate**/) {
                                /** save space by not save evey child if parent is selected **/
                                selectedItems.push(item);
                                if ($scope.radio) {
                                    return false; // break loop
                                }
                            }
                        });
                        $scope.allSelected = (selectedItems.length == amountAllItems);
                        ctrl.$setViewValue(selectedItems);
                        $scope.selectedItems = selectedItems;
                        /**for print the number of selected items that not shows**/
                        $scope.more = (selectedItems.length - $scope.$eval(attr.summarySize));
                        if ($scope.closeOnSelect) closeMenu();
                    }

                    /**view -> model**/
                    ctrl.$parsers.push(function (values) {
                        if ($scope.radio) {
                            if (!values.length) return null;
                            return values[0].modelMapper;
                        } else {
                            return values.map(function (item) {
                                return item.modelMapper;
                            });
                        }
                    });

                    /**model -> view**/
                    var hashItems = {}; // should be a Weak map

                    function rebuildHash() {
                        //var indexOf = parseResult.index;
                        hashItems = {};
                        amountAllItems = 0;
                        forEachNode(function (item, parent) {
                            hashItems[item.index] = item;
                            amountAllItems++;
                        });
                        hashItems.builded = true;
                    }

                    $scope.$watch('items()', function (vals, oValue) {
                        $scope.showCustomSources = $scope.showCustomSources && $scope.hasCustomSources();
                        vals && rebuildHash();
                        syncSelection();
                        commitSelected();
                    });

                    $scope.$watch('showCustomSources', function (val, oldVal) {
                        if (val != oldVal) $scope.resetFilters();
                        $scope.lastSelected = _.isNumber($scope.lastSelected) ? $scope.lastSelected : 0;
                        if ($scope.filterName == "bubblesChannels" && !$scope.showCustomSources) $scope.toggle(getItems()[$scope.lastSelected], null);
                        rebuildHash();
                        syncSelection();
                        commitSelected();
                    });

                    ctrl.$formatters.push(function (selectedItems) {
                        if (angular.isUndefined(selectedItems)) return;
                        $scope.selectedItems = $scope.radio ? [selectedItems] : selectedItems;
                        $scope.selectedItems = _.map($scope.selectedItems, $scope.converter);
                        syncSelection();
                    });

                    function syncSelection() {
                        if (!$scope.items || !$scope.items()) return;
                        if (!$scope.selectedItems && !$scope.isRadioButtonsSkin()) return;
                        if (!hashItems.builded) return;
                        if ($scope.isRadioButtonsSkin()) setHeightForRadioButtonsSkin();
                        var selectedItems = $scope.selectedItems;
                        amountSelectedItems = 0;

                        angular.forEach(hashItems, function (item, i) {
                            if (item.selected) {
                                item.selected = false;
                                item.justUpdate = true;
                            }
                        });
                        angular.forEach(selectedItems, function (item) {
                            if (hashItems[item.index]) {
                                hashItems[item.index].selected = true;
                                hashItems[item.index].justUpdate = true;
                                amountSelectedItems++;
                            }
                        });
                        forEachNode(function (item, parent) {
                            if (item.justUpdate) {
                                delete item.justUpdate;
                                $scope.updateAfterChecked(item, parent);
                            }
                        });
                    }
                }
            }
        }
    }
]);
