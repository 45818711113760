"use strict";
/*all backend <-> UI data conversions
main functions: 
* targetToServer 
* targetToUI
* targetToCurrentTarget - UI <-> UI conversion for target right pane
*/


module.exports = angular.module(__filename, [])
    .service('TargetData', ['CHANNEL', '$http',
    function (CHANNEL, $http) {

    return {
        markets: null,

        targetToServer: function(params){
            this.fixTargetParams(params);
            this.removeDisplayValues(params.target);
        },

        targetToUI: function(params){
     
            //convert backend values to UI

            let {action, target, username, context } = params;
  
            if(action==='edit-target'){ //calculate inner values like phrases
                this.fixTargetParams({target: target, username: username});
                this.convertManualPhrases(target.results.phrases||[]);
                this.convertAudience(target, context);
            }

            this.addDisplayValues(target);
        },

        fixTargetParams: function(params){

            //fix undefined => some-value, etc

            let {action, target, username} = params;

            //topical
            if(typeof target.topics==="string"){
                target.topics = target.topics.split(",").map(function(cell){return Number(cell)});
            }
            target.limitTopics = target.topics && target.topics.length ? true : false;
            target.topical = target.limitTopics;

            //activated_to
            target.activated_to = target.activated_to || [];

            //dynamic
            target.dynamic = target.dynamic ? true : false;

            //update_by
            if(username){
                target.updated_by = username;
            }
        },

        removeDisplayValues: function(target){

            //remove phrase params
            for(let i in target.results||{}){
                let arr = target.results[i];
                arr.forEach((p)=>{
                    this.removeParams.phrases.forEach((param)=>{
                        delete p[param];
                    });
                });
            }

            //remove target display params
            this.removeParams.target.forEach((param)=>{
                delete target[param];
            });
        },

        addDisplayValues: function(target){

            //add target display values

            let activated_to = Array.isArray(target.activated_to) ? target.activated_to[0] : target.activated_to;
            
            /*activated_to - complex, as we need to sort by:
            1 - market A
            2 - market B
            x - market x
            All Markets
            Demo
            Not Activated
            */
            var MAX_ID=10000;
            if (activated_to === undefined){
                target.activated_to_display = "Not Activated";
                target.activated_to_sorted = MAX_ID+2;
            }else {
                let market_id = target.market_id;
                if (market_id === undefined || market_id === ""){
                    target.activated_to_display = activated_to;
                    target.activated_to_sorted = MAX_ID+1;
                }else if (market_id === 0){
                    target.activated_to_display = "All Markets";
                    target.activated_to_sorted = MAX_ID+0;
                }else {
                    target.activated_to_display = market_id + " - " + this.markets[market_id];
                    target.activated_to_sorted = 1*market_id;
                }
            }

            target.id = target._id; //for quick-table

            target.full_name = target.name;
            target.full_id = target._id;
            target.updated_by = target.updated_by || "";
            target.channel_display = CHANNEL.nameDisplay(target.channel);

            target.date_added_formated = dateFormat(target.date_added);
            target.date_updated_formated = dateFormat(target.date_updated); 
            target.target_type_display = this.targetTypeDisplay(target.target_type);

            function dateFormat(d){
                return moment(d).format("MMM DD, YYYY (HH:mm:ss A)");
            }  
        },

        targetTypeDisplay: function(target_type){
            var type = target_type || this.DEFAULT_TARGET_TYPE;

            if(type==="audience_interests"){
                return "Audience Interests";
            }else{
                return "Trends";
            }
        },

        convertAudience: function(target, context){
            //audience name
            if((target.target_type||'').includes("audience")){
                var ids = context.current.audience_ids;
                for(var i in target.results){
                    var dataArray = target.results[i];
                    dataArray.forEach((cell) =>{
                        cell.audience_name = ids[cell.audience_id] || "Untitled Audience";
                    });
                }
            }
        },

    
        targetToCurrentTarget: function (scope, url) {
            //when loading context, targets just have id.
            //here we load their "results": phrases,hastags,etc

            if(scope.currentTarget._id && !scope.currentTarget.results){
                
                $http.get(url).then((res) =>{
                    scope.currentTarget = $.extend(true, {}, res.data);
                    this.targetToCurrentTargetCB(scope);
                    scope.$root.$broadcast('openContentDrivers', "target_drawer");
                }); 

            }else{ 
              this.targetToCurrentTargetCB(scope); 
            }    
        },

        targetToCurrentTargetCB: function (scope) {
            //more target params for content-drivers
            scope.currentTarget.noResults = true;
            scope.currentTarget.dynamicResults = 0;

            var ids = {}, results = scope.currentTarget.results,
                dynamic = scope.currentTarget.dynamic &&
                    scope.currentTarget.activated_to &&
                    scope.currentTarget.activated_to.length;

            for (var i in results) {
                results[i].forEach(function (cell) {
                    cell.display = cell.term ? (cell.term.display || cell.term.text) : cell.seed;

                    if (cell.dynamic) {

                        if (dynamic) {
                            scope.currentTarget.dynamicResults++;
                        }

                    } else {
                        scope.currentTarget.noResults = false;
                        ids[cell.id + i] = true;
                    }
                });
            }

            if (scope.currentTarget.noResults) {
                ids = null;
            }

            scope.disabledIds = ids;

            if (!scope.targetFolders) {
                scope.targetFolders = {};
            }

            for (var i in scope.currentTarget.results) {
                if (!scope.targetFolders[i]) {
                    scope.targetFolders[i] = {open: true};
                }
            }

            //remove folders not in this tab
            scope.targetFoldersLength = Object.keys(scope.targetFolders).length;
            for (var i in scope.targetFolders) {
                if ((scope.tabs||['']).indexOf(i) === -1) {
                    scope.targetFolders[i] = null;
                    scope.targetFoldersLength--;
                }
            }

            scope.currentTarget.loaded = true;

        }, //end of targetToCurrentTargetCB


        convertManualPhrase: function (phrase) {
            phrase.display = phrase.text;
            phrase.phrase = phrase.text;
        },

        convertManualPhrases: function (phrases) {
            phrases.forEach((phrase) => {
                if(phrase.manual){
                    this.convertManualPhrase(phrase);
                }
            });
        },

        removeParams: {
                
            target: [
                'full_name',
                'full_id',
                'channel_display',
                'activated_to_display',
                'activated_to_sorted',
                'date_added_formated',
                'date_updated_formated',
                'target_type_display',
                'noResults'
            ],
            
            phrases: [
                'examples',
                'uniqueness-index',
                'display-uniqueness-index',
                'title-uniqueness-index']
        }

    }

}]);