"use strict";
//works with audience-segment-builder-helper included in parent page

const config = require('infra/config');
const common = require("infra/utils/common");

module.exports = require("angular").module(__filename, [])
    .service("audienceSkewService", ['$q', '$http', 
        function($q, $http) {

            return {
                DEFAULTS: {
                    'added-topics-number': 1000,
                    'only-phrase-id': true,
                    'search-type': 'keywords',
                    'users-sample-size': 1400, 
                    'words-sample-size': 300
                },

                AUDIENCE_INSIGHTS_URL: config.AUDIENCE_PROFILER_API + '/api/1/ui-related-keywords2',
            
                getAudienceLifeStyle : function(filtersPartition, test_mode){
                    return _(filtersPartition.behavioralSegment)
                    .filter((segment) => segment.debugOnly ? test_mode : true)
                    .sortBy(o => o.label.toLowerCase())
                    .map(s => _.merge(s, {segment: [{type: "lifestyle", value: s.value, operand: {value: "and"}}]}))
                    .value();
                },

                getAudienceCustomSegments : function(data, lifestyles){
                    return _.sortBy(_.map(data, s => ({label: s.name, value: s.name, segment: s.segments})).concat(lifestyles), o => o.label.toLowerCase());
                }
            }
            
        }
]);

