"use strict";
module.exports = angular.module(__filename, [
    require("angular-modal-service").name
]).directive('programSelector', ['$rootScope', '$timeout', 'programService', 'notificator', function ($rootScope, $timeout, programService, notificator) {
    return {
        restrict: 'E',
        template: require('./program-selector.drv.html'),
        scope: {},
        link: function (scope) {
            scope.listOpen = false;
            scope.programs = $rootScope.user.programs;
            scope.selected = scope.programs[0];
            var context = $rootScope.context;

            scope.clearPlaceholder = function (event) {
                event.currentTarget.placeholder = '';
            };

            scope.setPlaceholder = function (event, placeholder) {
                event.currentTarget.placeholder = placeholder;
            };

            scope.select = function (option) {
                context.changeContext(option);
                selectProgram(option);
                scope.closeOptionsList();
            };

            scope.create = function () {
                if (scope.newName.length < 2) {
                    notificator.error({body: 'Program name is too short'});
                    return;
                }

                var program = {
                    name: scope.newName,
                    competitors: [],
                    creator_id: $rootScope.user.id,
                    account_id: $rootScope.user.account.id
                };
                programService.create(program).then(function (data) {
                    scope.programs.push(data);
                    $rootScope.user.programs.push(data);
                    scope.select(data);
                    notificator.success({body: data.name + ' was saved successfully'});
                    $rootScope.$broadcast('new-program');
                });

                scope.closeOptionsList();
            };

            scope.toggleOpen = function () {
                scope.listOpen = !scope.listOpen;
            };

            scope.closeOptionsList = function () {
                scope.listOpen = false;
                scope.search = '';
                scope.newName = '';
            };

            var removeListener = $rootScope.$watch('context.program', function () {
                selectProgram(context.program);
            }, true);

            scope.$on('$destroy', removeListener);

            scope.$watch(function () {
                return $rootScope.user.programs;
            }, syncUserPrograms, true);

            scope.$on('program-removed', function (event, p_id) {
                var index = _.findIndex($rootScope.user.programs, {id: p_id});
                $rootScope.user.programs.splice(index, 1);
                if (p_id == scope.selected.id) scope.select(scope.programs[0]);
            });

            function syncUserPrograms() {
                scope.programs = $rootScope.user.programs.map(function (program) {
                    program.selected = (_.find(scope.programs, {id: program.id}) || {}).selected;
                    return program;
                });
            }

            function selectProgram(program) {
                scope.programs.forEach(function (p) {
                    p.selected = false;
                });

                var selectedOption = _.find(scope.programs, {id: program.id});
                if (selectedOption) {
                    selectedOption.selected = true;
                    $timeout(function () {
                        scope.selected = selectedOption;
                    });
                } else {
                    scope.select(scope.programs[0]);
                }
            }
        }
    }
}]);
