"use strict";
'use strict';

module.exports = angular.module(__filename, []).config(["authenticationConfig",
    function (authenticationConfig) {
        authenticationConfig.doAfterAuthentication.push(["user", "abiPermissions", function (user, abiPermissions) {
            abiPermissions.init(user);
        }]);
    }
]).service('abiPermissions', function () {
    var userPermissions = [];

    return {
        init: initPermissions,
        hasPermission: hasPermission,
        getPermissions: getPermissions,
        hasPermissionForState: hasPermissionForState,
        addGeosPermissions: addGeosPermissions
    };

    function initPermissions(user) {
        userPermissions = user.permissions.map(function (permission) {
            return permission.name;
        });
    }

    function addGeosPermissions(geos) {
      userPermissions = userPermissions.concat(geos);
    }

    function getPermissions() {
      return angular.copy(userPermissions);
    }

    function hasPermission(permissions) {
        if (permissions === undefined) {
          return true;
        }
        const arr = _.castArray(permissions);
        var intersection = _.intersection(userPermissions, arr);
        return intersection.length == arr.length;
    }

    function hasPermissionForState(state) {
        if (!state.permissions || state.permissions.length == 0) {
            return true;
        }

        return hasPermission(state.permissions);
    }
}).directive('permissions', ['abiPermissions', function (abiPermissions) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var permissions = scope.$eval(attrs.permissions);
            if (!abiPermissions.hasPermission(permissions)) {
                element.remove();
            }
        }
    }
}]).directive('roles', function() {
    return {
        restrict: 'A',
        scope: {
            roles: '='
        },
        link: function (scope, element, attrs) {
            var userType = scope.$root.user.userType;
            if (scope.roles.indexOf(userType) == -1) {
                element.remove();
            }
        }
    }
});
