"use strict";
var c = require("infra/utils/common");

module.exports = angular.module(__filename, []).service('geoService', ['$http', '$q', '$timeout', 'errorMgmt', 'topicsTree','authenticationConfig', 'abiPermissions',
    function ($http, $q, $timeout, errorMgmt, topicsTree, authenticationConfig , abiPermissions) {
        var GEOS = [
            {label: 'United States', id: '840', cc: 'US'},
            {label: 'UK', id: '826', cc: 'GB'},
            {label: 'Australia', id: '036', cc: 'AU'},
            {label: 'Canada', id: '124', cc: 'CA'},
            {label: 'Indonesia', id: '360', cc: 'ID'},
            {label: 'Ireland', id: '372', cc: 'IE'},
            {label: 'New Zealand', id: '554', cc: 'NZ'},
            {label: 'Philippines', id: '608', cc: 'PH'},
            {label: 'Singapore', id: '702', cc: 'SG', sub_type: 'user.home.electoral_division'}
        ];

        var abiPermissions = abiPermissions;

        var STATES = _.keyBy(GEOS, 'label'),
            STATES_CHANNELS = {
            au_telco: STATES.Australia,
            sg_telco: STATES.Singapore,
            articles: STATES['United States']
        }

        var ALL_GEOS = GEOS.slice(0);

        var numOfallSupportedGeos = 0;
        var GEOS_HASH = _.keyBy(GEOS, 'id');
        var ALL_GEOS_HASH = _.keyBy(ALL_GEOS, 'id');
        var urlConfig = require("infra/config");
        var GEO_API = urlConfig.INSIGHTS_API + "/dashboard/geo";
        var COUNTRIES_URL = urlConfig.USER_MGMT_API + '/bi_supported_countries?disable_notifications=1';
        var ALL_COUNTRIES_URL = urlConfig.USER_MGMT_API + '/bi_all_supported_countries?disable_notifications=1';
        var ORG_COUNTRIES_URL = urlConfig.USER_MGMT_API + '/account_countries?disable_notifications=1';
        var EXPORT_COUNTRIES_URL = urlConfig.USER_MGMT_API + '/bi_supported_countries_all_users?disable_notifications=1';

        // After authentication get all geos.
        authenticationConfig.doAfterAuthentication.push(["authInfo", "$q",() => getAllGeos()]);

        function getGeos(user_id) {
            return $http({
                url: COUNTRIES_URL + "&user_id=" + user_id,
                method: 'GET',
                cache: false,
                async: false,
                timeout: 6000
            }).then(function success(res) {
                GEOS = res.data;
                numOfallSupportedGeos = GEOS.length
                abiPermissions.addGeosPermissions(_.map(GEOS, g => `geos.${g.cc}`))
                return GEOS_HASH = _.keyBy(GEOS, 'id');
            }, function failure(err) {
                console.log("GEO fetch from server failed")
            });
        }

        function getAllGeos() {
            return $http({
                url: ALL_COUNTRIES_URL,
                method: 'GET',
                cache: false,
                async: false,
                timeout: 6000
            }).then(function success(res) {
                ALL_GEOS = res.data;
                numOfallSupportedGeos = ALL_GEOS.length;
                return GEOS_HASH = _.keyBy(ALL_GEOS, 'id');
            }, function failure(err) {
                console.log("GEO fetch from server failed")
            });
        }

        function getGeosForUser(user_id) {
            return $http({
                url: COUNTRIES_URL + "&user_id=" + user_id,
                method: 'GET',
                cache: false,
                async: false,
                timeout: 6000
            }).then(function success(res) {
                return _.keyBy(res.data, 'id');
            }, function failure(err) {
                console.log("GEO fetch from server failed")
            });
        }
        function getGeoOptions(account_id) {
          return $http({
            url: ORG_COUNTRIES_URL+"&account_id="+account_id,
            method: 'GET',
            cache: false,
            async: false,
            timeout: 6000
          }).then(function success(res) {
            return res.data;
          }, function failure(err) {
            console.log("GEO fetch from server failed")
          });
        }

        function getGeosForOrg(account_id) {
          return $http({
            url: ORG_COUNTRIES_URL+"&account_id="+account_id,
            method: 'GET',
            cache: false,
            async: false,
            timeout: 6000
          }).then(function success(res) {
            return _.keyBy(res.data, 'id');
          }, function failure(err) {
            console.log("GEO fetch from server failed")
          });
        }

        function geosForChannel(geos, $state, context, ids) {
            //some channels support only 1 geo

            var arr, channels = c.getChannels($state, context);

            if (channels.length==1 && channels[0] == 'sg_telco') {
                arr = [STATES.Singapore];
            }else if(channels.length==1 && channels[0] == 'au_telco'){
                arr = [STATES.Australia];
            }else if(channels.length==2 && channels.includes('au_telco') && channels.includes('sg_telco')){
                arr = [STATES.Singapore , STATES.Australia];
            }else{
                arr = geos;
            }

            arr = handleSupportedCountries(arr)
            if(ids && arr.length && arr[0].id){
                arr = _.map(arr, "id");
            }
            return arr;
        }

        function subGeosForChannel(sub_geos, $state, context) {
            var channels = c.getChannels($state, context);

            if (!sub_geos) return [];

            //some channels support only 1+ geo
            return showSubGeos(channels) ? sub_geos.slice(0) : [];
        }

        // TODO: BROKEN, DOESN'T WORK WITHOUT CONTEXT.
        function showSubGeos(channels, stateName = null) {
          if(channels && channels.length == 1){

            if(channels[0] == ['au_telco']){
                return true;
            } else if (stateName == 'insights.geo' && channels[0] == 'articles') {
              return true;
            } else if (channels[0] == ['sg_telco'] && abiPermissions.hasPermission('SG Telco Electoral Filter')) {
                return true;
            }
          }

          return false;
        }

        function getGeosForExport() {
          return $http({
            url: EXPORT_COUNTRIES_URL,
            method: 'GET',
            cache: false,
            async: false,
            timeout: 6000
          }).then(function success(res) {
            return res.data;
          }, function failure(err) {
            console.log("GEO fetch from server failed")
          });
        }

        function getSubGeos(){

            var deferred = $q.defer();

            $http({
                url: urlConfig.USER_MGMT_API+"/bi_all_supported_regions",
                method: "get",
                contentType: "application/json"

              }).success(function(tree) {
                deferred.resolve(convertSubGeos(tree));
            }).error(function(){
                deferred.resolve({});
            });

            return deferred.promise;
        }

        function convertSubGeos(tree){
            function convertSGTelcoModel(tree) {
                var sg = STATES_CHANNELS.sg_telco,
                sg_geos = tree[sg.id];

                sg_geos = sg_geos.map(function(obj){
                    obj.type = sg.sub_type;
                    obj.id   = obj.name.toLowerCase();
                    return obj;
                });
                return tree;
            }

            function verifySelectableField(node) {
                node.selectable = node.selectable !== false;
                (node.children || []).forEach(verifySelectableField);
            }


            tree = convertSGTelcoModel(tree);

            _.values(tree).forEach((top_region) => { top_region.forEach(verifySelectableField); });

            return tree;
        }

        function setSubGeos(scope, getSubGeosMoldFunc, channels, stateName = null) {
            var channel = channels[0];
            var subGeosTreeHelperInstance = null;
            let subGeosMold = getSubGeosMoldFunc();

            //links context.current.sub_geos > scope.dataTrees.subGeos
            //in pages that need sub geos,
            //place this at page init & channel change event.

            //page dataTree
            var treeExists = scope.dataTrees && scope.dataTrees.subGeos;
            if(!treeExists){
                if(!scope.dataTrees){
                    scope.dataTrees = {};
                }
                scope.dataTrees.subGeos = {customFilter:true};
                if (subGeosMold) {
                    scope.dataTrees.subGeos.saveChecked = function(checkedArray){
                        if (scope.$root.subGeosTree) {
                            var valToSet = subGeosTreeHelperInstance.checkedArrayToContextSubGeos(checkedArray.filter((c) => c.type));
                            getSubGeosMoldFunc().replace(valToSet);
                        }
                    }
                }
            }

            //root subGeosTree has all states
            if(!scope.$root.subGeosTree){
                scope.$root.subGeosTree = {loading:true};
                getSubGeos().then(function(tree){
                    scope.$root.subGeosTree = tree;
                    subGeosTreeHelperInstance = indexedSubGeosTreeHelper(scope.$root.subGeosTree);
                    subGeosTreeForChannel(scope, subGeosMold, channel, subGeosTreeHelperInstance, stateName);
                });
            }else if(!scope.$root.subGeosTree.loading){
                if (!subGeosTreeHelperInstance) {
                    subGeosTreeHelperInstance = indexedSubGeosTreeHelper(scope.$root.subGeosTree);
                }
                subGeosTreeForChannel(scope, subGeosMold, channel, subGeosTreeHelperInstance, stateName);
            }
        }

        function subGeosTreeForChannel(scope, subGeosMold, channel, subGeosTreeHelperInstance, stateName = null){

            //set sub geos datatTree for this page by channel

            if(false == showSubGeos([channel], stateName)){
                return;
            }

            let state = STATES_CHANNELS[channel];
            let subGeosMoldVal = (subGeosMold || {})._value || [];
            let checkedArray;

            if (subGeosTreeHelperInstance.contextSubGeosHelper(subGeosMoldVal).stateTreeContainsContextSubGeos(state.id)) {
                checkedArray = subGeosTreeHelperInstance.contextSubGeosHelper(subGeosMoldVal).getAllCheckedSubGeos();
            } else {
                //mold not for current state - reset checked sub geos
                checkedArray = [];
                if (subGeosMold) {
                    subGeosMold.replace([]);
                }
            }

            if(scope.dataTrees.subGeos.name == state.label){
                scope.dataTrees.subGeos.checkedArray = checkedArray;
                $timeout(function(){
                    scope.dataTrees.subGeos.show();
                });
                return; //same channel - dont remake tree
            }

            if(channel=='sg_telco'){
                scope.subGeosFilterTitle = 'audience location';
                scope.subGeosExportTitle = 'audience location';
            }else{
                scope.subGeosFilterTitle = 'geo';
                scope.subGeosExportTitle = 'sub geos';
            }

            var stateTree = scope.$root.subGeosTree[state.id];
            var tree = {
            name: state.label,
            allSelectedLabel: state.label,
            showSearch: true,
            children: [
                {id: state.id,
                 name:state.label,
                 children: stateTree}
                ]
            };

            scope.dataTrees.subGeos.children = null;
            scope.dataTrees.subGeos = $.extend(true, scope.dataTrees.subGeos, tree);
            scope.dataTrees.subGeos.checkedArray = checkedArray;
            $timeout(function(){
                scope.dataTrees.subGeos.show();
            });
        }

        function createRequestSubGeoParam(subGeos) {
            return c.isArray(subGeos) ?
                getOnlySelectableTopSubGeos(subGeos)
                : [];
        }

        var config = {whitelistGeos: []};
        var _allValidGeosList = [];

        /*API*/
        return Object.defineProperties({
            get: get,
            serverValue: serverValue,
            setWhiteListGeos: setWhiteListGeos,
            isAllGeos: isAllGeos,
            objToIdArray: objToIdArray,
            objToCcArray: objToCcArray,
            getGeos: getGeos,
            setSubGeos: setSubGeos,
            genSummaryByIds: genSummaryByIds,
            getGeoOptions: getGeoOptions,
            getGeosForOrg: getGeosForOrg,
            getGeosForUser: getGeosForUser,
            geosForChannel: geosForChannel,
            showSubGeos: showSubGeos,
            createRequestSubGeoParam: createRequestSubGeoParam,
            subGeosForChannel: subGeosForChannel,
            handleSupportedCountries: handleSupportedCountries,
            getGeosForExport: getGeosForExport,
            indexedSubGeosTreeHelper: indexedSubGeosTreeHelper,
            getOnlySelectableTopSubGeos: getOnlySelectableTopSubGeos,
            STATES: STATES
        }, {
            geos: {
                get: function () {
                    return _allValidGeosList;
                }
            },

            allGeos: {
              get : function(){
                return ALL_GEOS;
              }
            }
        });
        /*-------------------*/

        function genSummaryByIds(geos){
            var arr =  geos.length == 0 || geos.length == GEOS.length ? ['All Geos'] :
                geos.map(function(geo){
                    return GEOS_HASH[geo].label;
                });
            return arr.join(", ");
        }

        function isAllGeos(array) {
            return array.length == numOfallSupportedGeos;
        }

        function objToIdArray(geos) {
            return objToPropertyArray(geos, 'id');
        }

        function objToCcArray(geos) {
            return objToPropertyArray(geos, 'cc');
        }

        function objToPropertyArray(geos, property) {
            var _geos = handleSupportedCountries(geos);
            return _geos.map(function (g) {
                return g[property]
            });
        }

        function handleSupportedCountries(geos) {
            var _geos = geos.length ? geos : GEOS;
            return (isAllGeos(_geos)) ? GEOS : _geos;
        }

        function setWhiteListGeos(whitelist_geos) {
            config.whitelistGeos = whitelist_geos;
            if (!_.isEmpty(whitelist_geos)) {
                return _allValidGeosList = _.at(GEOS_HASH, whitelist_geos);
            } else {
                return _allValidGeosList = GEOS;
            }
        }

        function indexedSubGeosTreeHelper(subGeosTree) {
            function buildSubGeosIndex() {
                var index = {};

                function addNodeToIndex(node) {
                    index[node.id] = node;
                    (node.children || []).forEach(addNodeToIndex);
                }

                _.values(subGeosTree).forEach((subGeosTop) => _.values(subGeosTop).forEach(addNodeToIndex));
                return index;
            }

            var index = buildSubGeosIndex();

            function reverseSelectableSubGeosToTopChecked(selectableSubGeos) {
                selectableSubGeos = selectableSubGeos.slice(0);
                var selectableMap = {};
                selectableSubGeos.forEach((node) => {
                    selectableMap[node.id] = node;
                })

                function getChildrenSelectableStatus(node) {
                    if (!node.children) {
                        return [];
                    }

                    return _.flatten(node
                        .children
                        .map((nodeChild) => {
                           var indexedChild = index[nodeChild.id];
                           var retVal = [selectableMap[nodeChild.id]];
                           if (!retVal && !indexedChild.selectable) {
                               retVal = getSelectableChildrenList(nodeChild);
                           }
                           return _.flatten(retVal)
                        }));
                }

                _.values(index).filter((node) => !node.selectable).forEach((nonSelectableNode) => {
                    var allSelectableChildren =getChildrenSelectableStatus(nonSelectableNode);

                    if (allSelectableChildren.length > 0 &&
                        allSelectableChildren.indexOf(undefined) == -1) {
                            selectableMap[nonSelectableNode.id] = { id: nonSelectableNode.id, type: nonSelectableNode.type, selectable: false };
                            // add the parent to the return list, the removal of children will be later for efficiency purposes
                            selectableSubGeos[selectableSubGeos.indexOf(allSelectableChildren[0])] = selectableMap[nonSelectableNode.id];

                            var selectableIDs = allSelectableChildren.map((child) => child.id).forEach((k) => {
                                delete selectableMap[k];
                            });
                    }
                });
                return selectableSubGeos.filter((node) => {
                    return selectableMap[node.id];
                });
            }

            function flattenStateTreeIds(tree) {
                var flattenedStateTree = [];
                _.each(tree, function (subTree) {
                    flattenedStateTree.push(_.pick(subTree, 'id'));
                    if (_.isArray(subTree.children)) {
                        flattenedStateTree = _.concat(flattenedStateTree, flattenStateTreeIds(subTree.children));
                    }
                });

                return flattenedStateTree;
            }

            function contextSubGeosHelper(contextCheckedSubGeos) {
                function outputNode(node) {
                    return _.pick(node, "id", "name", "type");
                }

                function getAllCheckedSubGeos() {
                    var resMap = {};

                    function addNodeToResult(node) {
                        resMap[node.id] = outputNode(node);
                        (node.children || []).forEach(addNodeToResult);
                    }

                    contextCheckedSubGeos
                        .map((thinNode) => (index[thinNode.id]))
                        .forEach(addNodeToResult);

                    return _.values(resMap);
                }

                function getOnlyTopCheckedSubGeos() {
                    var removeSet = {}
                    _.flatten(contextCheckedSubGeos.map((sg) => (index[sg.id].children))).filter((sg) => sg != undefined).forEach((sg) => {
                        removeSet[sg.id] = 1;
                    });

                    return contextCheckedSubGeos
                        .filter((sg) => !removeSet[sg.id])
                        .map((thinNode) => (index[thinNode.id]))
                        .map((node) => outputNode(node));
                }

                function stateTreeContainsContextSubGeos(stateId) {
                    return _.isEmpty(_.differenceBy(contextCheckedSubGeos, flattenStateTreeIds(subGeosTree[stateId]), 'id'));
                }

                return {
                    getAllCheckedSubGeos: getAllCheckedSubGeos,
                    getOnlyTopCheckedSubGeos: getOnlyTopCheckedSubGeos,
                    stateTreeContainsContextSubGeos: stateTreeContainsContextSubGeos
                };
            }



            function checkedArrayToContextSubGeos(checkedArray) {
                var removeSet = {};

                function addToRemoveSet(node) {
                    removeSet[node.id] = 1;
                    (node.children || []).forEach(addToRemoveSet);
                }

		var topChecked = checkedArray.forEach((node) => {
                    node = index[node.id];
                    if (!removeSet[node.id] && node.children && node.selectable) {
                        node.children.forEach(addToRemoveSet);
                    }
                });

                var contextSubGeos = checkedArray.filter((node) => {
                    return !removeSet[node.id];
                }).map ((node) => ({id: node.id, type: node.type, selectable: index[node.id].selectable}));
                return contextSubGeos;
            }

            return {
                contextSubGeosHelper: contextSubGeosHelper,
                checkedArrayToContextSubGeos: checkedArrayToContextSubGeos,
                reverseSelectableSubGeosToTopChecked: reverseSelectableSubGeosToTopChecked,
                flattenStateTreeIds: flattenStateTreeIds
            };
        }

        function getOnlySelectableTopSubGeos(contextCheckedSubGeos) {
            return contextCheckedSubGeos
                .filter((sg) => sg.selectable)
                .map ((sg) => ({id: sg.id, type: sg.type}));
        }


        function get(term, geos) {
            return $http({
                url: GEO_API,
                method: 'GET',
                cache: true,
                params: {
                    phrase: term,
                    "cc_filter[]": _.map(serverValue(geos), 'id'),
                    timeframe_start: moment().subtract(1, "month").startOf('day').format(),
                    timeframe_end: moment().startOf('hour').format(),
                    sensitive_content: topicsTree.isSensitive
                }
            }).then(function (res) {
                if (!res) return false;
                var country_map = {};
                var most_consumed = [];
                var data = _.sortBy(res.data, 'val').reverse();

                for (var i = 0; i < Math.min(data.length, 15); i++) {
                    var row = data[i];
                    if (i < 5) {
                        country_map[row['cc']] = {fillKey: 'HIGH'};
                        if (i == 0) {
                            most_consumed.push(row['name']);
                        }
                    } else {
                        country_map[row['cc']] = {fillKey: 'LOW'};
                    }
                }

                return {countries_codes: country_map, countries_labels: most_consumed.join(', ')};
            }, function (err) {
                errorMgmt.widgetServiceError('Geo', err);
            });
        }

        /** just shortcut function*/
        function serverValue(geos) {
            return _.or(geos, _allValidGeosList);
        }
    }
]);
