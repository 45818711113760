"use strict";
_.mixin({
    /** get list of values and return the first that not empty,
     * benefit of using that function is it can handle empty string and object
     */
    or: defaults,
    mapPick: function (objs, keys) {
        return _.map(objs, function (obj) {
            return _.pick(obj, keys);
        });
    },
    mapAt: function (objs, keys) {
        return _.map(objs, function (obj) {
            var index = -1,
                length = keys.length,
                result = [];

            while (++index < length) {
                var key = keys[index];
                if (_.has(obj, key)) {
                    result.push(_.get(obj, key));
                }
            }
            return result;
        });
    },
    array: function (obj) {
        if (obj instanceof Array) {
            return obj;
        } else if (typeof obj == 'string' || typeof obj == 'number') {
            return [obj];
        } else if (!obj) {
            return [];
        } else {
            return [obj];
        }
    },
    /**
     * Utility: create a function that get object and set some property on it, if property not exist it created
     * useful for forEach style methods
     */
    setter: function (k, v) {
        return function (obj) {
            _.set(obj, k, v);
        }
    },
    /**
     *  helper func to save some code when you want just to
     *  add or remove objs from array by the situation
     */
    manipulateArray: function (array, opt, objs) {
        objs = _.isArray(objs) ? objs : [objs];
        if (opt === 'add' || opt === true) {
            for (var o, i = 0; o = objs[i]; i++) {
                array.push(o);
            }

        }
        if (opt === 'remove' || opt === false) {
            for (var o, i = 0; o = objs[i]; i++) {
                _.remove(array, o);
            }
        }
    }
});

function defaults(val) {
    var len = arguments.length;
    var isNotEmpty = _.negate(_.isEmpty);

    for (var i = 0; i < len; i++) {
        var can = arguments[i];
        if (isNotEmpty(can)) {
            return can;
        }
        if (_.isBoolean(can)) {
            return can;
        }
        if (_.isNumber(can)) {
            return can;
        }
    }
    return arguments[i - 1];
}
