"use strict";
function TopicController($rootScope) {
}

TopicController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "topic",
    url: "/topic",
    template: require("./topic.insights.html"),
    display: "Insights",
    controller: TopicController,
    data: {
      permissions: ['topics']
    },
    context: {}
};
