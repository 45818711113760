"use strict";
const BaseWidget = require("../base_widget");
const common = require("infra/utils/common");
const mixpanel = require("infra/mixpanel/mixpanel-audience");

UserHistoryController.$inject = ['$scope', 'audienceInsightsService', 'userHistoryExportService', 'context', 'util', 'filtersPartition', 'abiPermissions', 'mixpanelAudience'];

const SCROLL_ANIMATION_DURATION = 500;
const AGGREGATED_TIME_FRAME = 30;
const URL_LINE_HEIGHT = 45;
const MATCHING_MOMENT_TOP_PADDING = 10;
const HISTORY_TITLE_HEIGHT = 51;
const MOMENT_TOP_OFFSET = 8;

function UserHistoryController($scope, audienceInsightsService, exportService, context, util, filtersPartition, abiPermissions, mixpanelAudience) {
    const defaultInViewOptions = {offsetBottom: -150, debounce: 10};
    this.$scope = $scope;
    this.audienceInsightsService = audienceInsightsService;
    this.context = context;
    this.util = util;
    this.segment = context.current.audience_segment;
    this.all_ages = filtersPartition.age.map(a => a.summary);
    this.mixpanelAudience = mixpanelAudience;

    $scope.historyRenderPromises = {};
    $scope.timelineGrouped = [];
    $scope.scrolledMomentId = null;
    $scope.summaryBarChartOptions = {resize: true};

    const hasSearchTermsPermission = abiPermissions.hasPermission(['full search terms']);
    const container = angular.element(document.getElementById('user-history-container'));

    const activityMapping = {
        shopping: {selector: 'site', display: "Shopped on ", icon: "shopping_round"},
        social: {selector: 'site', display: "Socialized on ", icon: "socialized_round"},
        streaming: {selector: 'site', display: "Watched ", icon: "watch_round"},
        news: {selector: 'site', display: "Read news on ", icon: "read_round"},
        finance: {selector: 'site', display: "Accessed their ", icon: "finance_round"},
        email: {selector: 'site', display: "Checked their ", icon: "email_round"},
        search: {selector: 'query', display: "Searched for ", icon: "search_round"},
        learn: {selector: 'term', display: "Learned about ", icon: "learn_round", summary_label: "learning"},
        navigation: {selector: 'site', display: 'Navigated in ', icon: "navigate_round"},
        online_drive: {selector: 'site', display: "Accessed their ", icon: "document_round", summary_label: "docs"},
        travel: {selector: 'site', display: 'Planned a trip on ', icon: "travel_round"},
        muic: {selector: 'site', display: 'Listened to ', icon: "music_round"},
        translation: {selector: 'site', display: 'Translated on ', icon: "translate_round"},
        gaming: {selector: 'site', display: 'Played on ', icon: "play_round"},
        download: {selector: 'site', display: 'Downloaded from ', icon: "download_round"},
        read: {display: "Read ", icon: "visited_round"},
        default: {display: "Visited ", icon: "visited_round"}
    };

    function scrollToElement(elementId) {
        const element = angular.element(document.getElementById(elementId));
        if(_.isEmpty(element)) return;
        container.scrollToElement(element, 0, SCROLL_ANIMATION_DURATION);
    }

    $scope.scrollToDate = function(date) {
        scrollToElement('history-' + date);
        mixpanelAudience.trackScrollDate();
    };

    function scrollToMoment(momentId) {
        scrollToElement('moment-' + momentId);
        $scope.isMomentInView[momentId] = true;
    }

    function getFirstMomentAfterView() {
        const top = container.scrollTop() - MOMENT_TOP_OFFSET;
        for(let momentId = 0; momentId < $scope.matchingMoments.length; momentId++) {
            if($scope.matchingMoments[momentId] >= top) return momentId;
        }
        return 0;
    }

    function getFirstMomentBeforeView() {
        const top = container.scrollTop() - MOMENT_TOP_OFFSET;
        for(let momentId = $scope.matchingMoments.length - 1; momentId >= 0; momentId--) {
            if($scope.matchingMoments[momentId] <= top) return momentId;
        }
        return $scope.matchingMoments.length - 1;
    }

    function getMomentLabel(moment) {
        return activityMapping[moment].summary_label || moment;
    }

    $scope.trackClickEvent = function(url_clicked) {
        mixpanelAudience.trackClickEvent(url_clicked);
    };

    $scope.scrollToNextMoment = function() {
        mixpanelAudience.trackMatchingMoments();

        if(!$scope.hasMoments) return;

        if($scope.scrolledMomentId != null && $scope.isMomentInView[$scope.scrolledMomentId])
            $scope.scrolledMomentId = ($scope.scrolledMomentId + 1) % $scope.matchingMoments.length;
        else
            $scope.scrolledMomentId = getFirstMomentAfterView();

        if(!$scope.isMomentInView[$scope.scrolledMomentId]) scrollToMoment($scope.scrolledMomentId)
    };

    $scope.scrollToPrevMoment = function() {
        mixpanelAudience.trackMatchingMoments();

        if(!$scope.hasMoments) return;

        if($scope.scrolledMomentId != null && $scope.isMomentInView[$scope.scrolledMomentId])
            $scope.scrolledMomentId = $scope.scrolledMomentId === 0 ? $scope.matchingMoments.length - 1 : $scope.scrolledMomentId - 1;
        else
            $scope.scrolledMomentId = getFirstMomentBeforeView();

        if(!$scope.isMomentInView[$scope.scrolledMomentId])
            scrollToMoment($scope.scrolledMomentId)
    };

    $scope.dateInView = function(date, inView) {
        if(inView) {
            $scope.isDateInViewSideBar.add(date);
            $scope.isDateInView[date] = inView;
        } else {
            $scope.isDateInViewSideBar.delete(date);
        }

        // make sure only the latest date in the viewport is marked
        $scope.currentDateInView = Math.max(...$scope.isDateInViewSideBar);
    };

    $scope.momentInView = function(index, inView) {
        $scope.isMomentInView[index] = inView;
    };

    $scope.$watch('errorOccurred', () => {
        if($scope.errorOccurred) this.stopLoader();
    });

    $scope.$watch('userId', () => {
        $scope.hasMoments = false;
        if($scope.userId) {
            this.getUserHistory($scope.userId);
        } else if(!$scope.errorOccurred) {
            this.spinnerOnWhenNoUserSelected = true;
            this.startLoader();
        }
    });

    this.mapActivities = function(value) {
        const out = {
            url: value[2].trim(),
            domain: value[0],
            highlighted: value[3],
            ts: value[1]
        };

        const activityObject = value[4];
        const activityType = (activityObject && activityMapping[activityObject.activity]) ? activityMapping[activityObject.activity] : activityMapping['default'];
        out.activityText = activityType.display;
        out.display = activityType.selector ? activityObject[activityType.selector] : value[0];
        out.display = unescape(out.display);
        out.icon = activityType.icon;
        if(!activityObject || activityObject.activity === "read" || activityObject.activity === "news" || (activityObject.activity === "learn" && !activityObject.term))
            out.title = value[6];
        if(activityObject && activityObject.category)
            out.category = activityObject.category;
        if(activityObject && activityObject.term)
            out.term = activityObject.term;
        const hideSearchTerms = activityObject && activityObject.activity === "search" && (!hasSearchTermsPermission || !activityObject.query);
        if(hideSearchTerms) {
            let siteName = out.domain;
            if(siteName.endsWith('.com') || siteName.endsWith('.org')) siteName = siteName.split('.')[siteName.split('.').length - 2];
            out.display = siteName;
            out.url = out.url.substring(0, out.url.indexOf(out.domain) + out.domain.length);
            out.activityText = "Searched on ";
        }
        if(activityObject && activityObject.activity === "learn" && !activityObject.term) {
            out.activityText = "Studied on ";
            out.display = activityObject.site;
        }
        return out;
    };

    this.calcHeight = function(data) {
        $scope.inViewOptionsMap = {};
        let totalHeight = 0;
        _.each(data, function(historyInfo, date) {
            // the height of the title that aggregate all the hours per day.
            let height = HISTORY_TITLE_HEIGHT;
            Object.values(historyInfo).forEach(values => { height += (values.length * URL_LINE_HEIGHT) }); // URL line height

            $scope.inViewOptionsMap[date] = {height, totalHeight};
            totalHeight += height;
            // The element in-view options
            // the element height must be bigger then the abs(offsetBottom) otherwise the element wont be displayed
            $scope.inViewOptionsMap[date].inViewOptions = Object.assign({}, defaultInViewOptions, {offsetBottom: Math.max((-1 * height) + 1, -150)});
        });
    };

    this.renderUser = function() {
        $scope.isDateInViewSideBar = new Set();
        // Grouping the urls by date
        const timelineGrouped = _.chain($scope.userHistory)
            .groupBy(o => moment(o['ts']).format("YYYY-MM-DD"))
            .mapValues(groupedDates => {// Grouping the urls by hour for each date
                return _.groupBy(groupedDates, timeObject => moment(timeObject['ts']).ceil(AGGREGATED_TIME_FRAME, 'minutes').format("hh:mmA"))
            }).value();

        const dates = Object.keys(timelineGrouped);
        $scope.allDates = _.reverse(common.getDateRange(_.last(dates), _.head(dates)).sort());
        $scope.isDateInView = _.zipObject(dates);
        this.calcHeight(timelineGrouped);

        // Make sure that at first render all the element at the viewport will be rendered
        // also marking the first date as selected
        this.markFirstDatesAsInView();
        $scope.dateInView(dates[0], true);

        $scope.matchingMoments = [];
        _.each(timelineGrouped, function(urlsGroupedByTime, date) {
            _.flatten(Object.values(urlsGroupedByTime)).forEach((url, i) => {
                if(url.highlighted) $scope.matchingMoments.push($scope.inViewOptionsMap[date].totalHeight +
                                                HISTORY_TITLE_HEIGHT - MATCHING_MOMENT_TOP_PADDING + i * URL_LINE_HEIGHT);
            });
        });
        $scope.hasMoments = !_.isEmpty($scope.matchingMoments);
        $scope.isMomentInView = {};
        $scope.scrolledMomentId = null;
        $scope.timelineGrouped = timelineGrouped;
        $scope.userId = $scope.userData.id;
        this.stopLoader();
    };

    this.markFirstDatesAsInView = function() {
        const containerHeight = angular.element('#user-history-container').prop('offsetHeight');
        let currentDisplayedHeight = 0;
        _.each($scope.isDateInView, function(value, key) {
            $scope.isDateInView[key] = true;
            currentDisplayedHeight += $scope.inViewOptionsMap[key].height;
            if(currentDisplayedHeight > containerHeight) return false;
        });
    };

    this.handleUserHistory = function(timeline) {
        const currentMilliseconds = new Date().getTime();
        let momentId = 0;
        return _.chain(timeline)
            .filter(o => o[1] < currentMilliseconds) // filters dates bigger than current (timestamp on url is time of user entering it)
            .mapValues(groupedDates => this.mapActivities(groupedDates))
            .map(url => url.highlighted ? Object.assign(url, {momentId: momentId++}) : url)
            .value();
    };

    this.getSegmentSummary = function(segment) {
        return audienceInsightsService.getSegmentData(segment, 'xw').then(data => {
            if(!data) return false;
            const [ageDistLabels, ageDistValues] = this.util.prepareAgeDistDataToAgeChart(this.all_ages, null, data['age-distribution'], false);
            const male = (data['gender-distribution']['male'] * 100).toFixed(), female = 100 - male;
            return {
                gender: {male: male, female: female},
                age: {labels: ageDistLabels, values: ageDistValues[0]},
                segmentSize: data['intenders-ratio-in-geo']
            };
        })
    };

    this.getMomentSummary = function(timeline, retrieveOnlyTopMoments) {
        let momentsSummary = _.countBy(_.compact(_.map(timeline, url => url[4]))
            .map(t => t.activity).filter(activity => activityMapping[activity]));
        const sum = _.sum(Object.values(momentsSummary));
        const max = Math.max(...Object.values(momentsSummary));
        const orderedMoments = Object.keys(momentsSummary).sort((a, b) => momentsSummary[b] - momentsSummary[a]);

        momentsSummary = orderedMoments.map(activity => ({
            activity: getMomentLabel(activity),
            value_rounded: (momentsSummary[activity] / sum * 100).toFixed(1),
            value: (momentsSummary[activity] / sum * 100),
            bar_val: momentsSummary[activity] / max * 100,
            icon: activityMapping[activity].icon
        }));

        return retrieveOnlyTopMoments ? _.take(_.filter(momentsSummary, moment => +moment.value >= 1), 10) : momentsSummary;
    };

    const thisCtrl = this;
    $scope.$root.createExcelWorkbook = function() {
        mixpanelAudience.trackExport();
        const {segment, userId} = thisCtrl, exampleId = $scope.exampleId;

        return thisCtrl.lastPromise.then(res => {
            const filteredHistory = thisCtrl.handleUserHistory(res.timeline);
            const momentsSummary = thisCtrl.getMomentSummary(res.timeline);

            return audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(segment).then(distribution => {
                const excel = exportService.exportToExcel(distribution, segment, filteredHistory, res['profile'], userId, momentsSummary);
                excel.fileName = "Timeline - Example #" + exampleId + ".xlsx";
                exportService.downloadExcel(excel);
            });
        })
    };
}

UserHistoryController.prototype.getUserHistory = function(userId) {
    if(this.spinnerOnWhenNoUserSelected) this.spinnerOnWhenNoUserSelected = false;
    else this.startLoader();

    angular.element(document.getElementById('user-history-container')).scrollTop(0, 0);

    this.segment = this.context.current.audience_segment;
    this.userId = userId;
    this.lastPromise = this.audienceInsightsService.userHistory(this.segment, userId);

    const $scope = this.$scope;
    this.lastPromise.then(res => {
        $scope.userProfile = res['profile'];
        $scope.userId = userId;
        $scope.userData = _.extend(res, {id: userId});
        $scope.userHistory = this.handleUserHistory(res['timeline']);
        $scope.momentSummary = this.getMomentSummary(res['timeline'], true);
        this.renderUser();
    })
};

module.exports = angular.module(__filename, [
    require('data/user-history-export-service').name,
    require('common/fav-icon-displayer.drv/fav-icon-displayer.drv').name,
    require('common/am-gauge-chart.drv/am-gauge-chart.drv').name
]).directive("userHistoryWidget", [() => BaseWidget({
    restrict: "E",
    template: require("./user-history-widget.html"),
    scope: {
        userId: '=',
        exampleId: '=',
        errorOccurred: '='
    },
    controller: UserHistoryController
})]);
