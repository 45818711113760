"use strict";
class BaseMapComponent {
  constructor(country) {
    this.country = country
  }

  setMapNameFromScale(scale) {
    this.mapName = `${this.country}/${this.mapSizes[scale]}`;
  }

  getPopupFunction(formula) {
    switch (formula) {
      case 'skew':
        return this.skewPopup.bind(this);
      case 'distribution':
        return this.distributionPopup.bind(this);
      case 'consumption':
        return this.consumptionPopup.bind(this);
      default:
        throw new Error(`${formula} has no implementation of popup`)
    }
  }

  skewPopup(geo, data) {
    if (!data) return '';
    const zoneData = data.zoneData;
    const zoneName = this.formatZoneName(geo.properties.name)
    const zoneScore = zoneData.score.toFixed(2)
    let moreOrLess;
    let bodyViewScore;
    if (zoneScore > 1) {
      moreOrLess = `<b>x${zoneScore}</b> times more`;
    } else if (zoneScore == 1) {
      moreOrLess = 'as'
    } else {
      moreOrLess = `<b>x${(1/zoneScore).toFixed(2)}</b> times less`;
    }

    const title = this.getPopupTitle(zoneName, zoneScore);
    const body = `<span class="area-popup-body">
                  ${zoneData.term} is ${moreOrLess} likely to be consumed in
                  <br/>${zoneName} than in ${
                    _.isEmpty(this.selectedRegions)
                    ? 'the entire country'
                    : 'all of the selected regions'}.
                  </span>`
    const footer = this.getPopupFooter(zoneData.terms, (score) => score.toFixed(2));
    return this.getPopupTemplate(title, body, footer);
  }

  distributionPopup(geo, data) {
    const zoneData = data.zoneData;
    const zoneName = this.formatZoneName(geo.properties.name)
    const zoneScore = (zoneData.score * 100).toFixed(2) + '%'
    const title = this.getPopupTitle(zoneName, zoneScore);
    const body = `<span class="area-popup-body">
                  ${zoneScore} of the content about ${zoneData.term} in<br/>${
                    _.isEmpty(this.selectedRegions)
                    ? _.capitalize(this.country)
                    : 'all of the selected regions'} is consumed in ${zoneName}.
                  </span>`
    const footer = this.getPopupFooter(zoneData.terms, (score) => (score * 100).toFixed(2) + "%");
    return this.getPopupTemplate(title, body, footer);
  }

  consumptionPopup(geo, data) {
    if (!data) return '';
    const zoneData = data.zoneData;
    const zoneName = this.formatZoneName(geo.properties.name)
    const title = `<span class="area-popup-title">${zoneName} consumption:</span>`;
    const scoreFunc = (score) => (score * 100).toFixed(2);

    const termLabel = `${zoneData.term}: <b>${scoreFunc(zoneData.score)}</b>`;
    const allTerms = [termLabel].concat(_.map(zoneData.terms, term =>`${term.term}: <b>${scoreFunc(term.score)}</b>`))
    const footer = `<span class="area-popup-footer">${allTerms.join(' ; ')}</span>`;

    return this.getPopupTemplate(title, '', footer)
  }

  getPopupTemplate(title, body, footer) {
    return `<div class="map-popup">
      ${title}
      ${body}
      ${footer}
    </div>
    <div class="arrow-down arrow-border"></div>
    <div class="arrow-down arrow-fill"></div>
    `
  }

  getPopupTitle(name, score) {
    return `<span class="area-popup-title">${name}: ${score}</span>`
  }

  getPopupFooter(terms, fixScoreFunc) {
    const footer = _.map(terms, term =>`${term.term}: <b>${fixScoreFunc(term.score)}</b>`)
    return `<span class="area-popup-footer">${footer.join(' ; ')}</span>`
  }

  formatZoneName(name) {
    return _.startCase(_.toLower(name));
  }
}


module.exports = BaseMapComponent
