"use strict";
const audienceExplorerModule = angular.module(__filename, [
    require('../../widgets/audience-demographics-widget/audience-demographics-widget').name,
    require('../../widgets/audience-tv-widget/audience-tv-widget').name,
    require('../../widgets/audience-table-view-widget/audience-table-view-widget').name,
    require('../../widgets/audience-interests-widget/audience-interests-widget').name,
    require('../../widgets/user-history-widget/user-history-widget').name,
    require('../../widgets/user-examples-dropdown-widget/user-examples-dropdown-widget').name,
    require("../../infra/mixpanel/mixpanel-audience").name,
    require("../../common/am-tabs.drv/am-tabs.drv").name
]);

audienceExplorerModule.stateConfig = [{
    name: "audience-explorer",
    url: "/audience-explorer",
    template: require("./audience-explorer.html"),
    display: "Explore Audience",
    controller: audienceExplorerController
}];

audienceExplorerController.$inject = ["$scope", "abiPermissions", "mixpanelAudience"];

function audienceExplorerController($scope, abiPermissions, mixpanelAudience) {
    const debugUser = $scope.$root.user.userType === 'debug';
    const tabs = [
        {label: "Websites", value: "domain", order: 2, debugOnly: true},
        {label: "Keywords", value: "keywords", order: 4, debugOnly: true},
        {label: "Consumed Phrases", value: "web", order: 5, debugOnly: true},
        {label: "TV Shows", value: "tv", order: 6, debugOnly: false, permissions: "tv"}
    ];

    $scope.navToPrevTab = () => {
      $scope.selectedTab = $scope.prevTab || 'demographics'
    }

    $scope.trackExploreTabs = function (selectedTab) {
        $scope.prevTab = $scope.selectedTab
        let tab = _.find(tabs, {value: selectedTab}).label;
        mixpanelAudience.trackPageView(tab);
    };

    $scope.trackRefine = function () {
        mixpanelAudience.trackRefine();
    };

    $scope.$watch('selectedUser', function () {
        if ($scope.selectedTab == 'timeline') mixpanelAudience.trackTimelineUserExample($scope.selectedUser);
    });

    if (abiPermissions.hasPermission(['demographics'])) tabs.push({label: "Demographics", value: "demographics", order: 1, debugOnly: false});
    if (abiPermissions.hasPermission(['search'])) tabs.push({label: "Searches", value: "searches", order: 3, debugOnly: false});
    if (abiPermissions.hasPermission(['interests'])) tabs.push({label: "Interests", value: "wiki", order: 4, debugOnly: false});
    if (abiPermissions.hasPermission(['timeline'])) tabs.push({label: "Timeline", value: "timeline", order: 7, debugOnly: false});

    $scope.tabs = _.sortBy(tabs.filter(tab => debugUser ? true : !tab.debugOnly), "order", "desc")
}

module.exports = audienceExplorerModule;
