"use strict";
module.exports = angular.module(__filename, [
    require("infra/context/filters/behavioralSegment-mold").name // todo: delete me
]).service('lifestylesModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(lifestyle) {
        return ModalService.showModal({
            template: require('./lifestyles-modal.html'),
            inputs: {lifestyle: lifestyle || {}},
            controller: ['$scope', 'close', 'filtersPartition', 'lifestyle', LifestylesModalCtrl]
        });
    }

    function LifestylesModalCtrl($scope, close, filtersPartition, lifestyle) {
        $scope.isNewSegment = _.isEmpty(lifestyle);
        $scope.segment = [lifestyle];
        $scope.close = close;
        $scope.test_mode = $scope.$root.user.userType === 'debug';

        $scope.segments = filtersPartition.behavioralSegment.filter(function (segment) {
            return segment.debugOnly ? $scope.test_mode : true
        });

        $scope.isValid = function () {
            return $scope.segment[0];
        };

        $scope.save = function () {
            close(_.extend({type: "lifestyle", operand: lifestyle.operand}, $scope.segment[0]))
        }
    }
}]);
