"use strict";
'use strict';

module.exports = angular.module(__filename, [])
    .service('tvExportService', ['Excel', 'segmentInterestExportService',
        function(Excel, segmentInterestExportService) {
            function createSummaryWorksheet(workbook, formatter, segment, demographicsData) {
                const worksheet = workbook.createWorksheet({name: 'Summary'});
                worksheet.setData(segmentInterestExportService.getSegmentSummaryData(segment, demographicsData, formatter));
                worksheet.setColumns([{width: 25}, {width: 25}, {width: 18}, {width: 18}, {width: 25}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }


            function createTvShowsWorksheet(workbook, interests, interestType, formatter) {
                const worksheet = workbook.createWorksheet({name: 'TV Shows'});

                let titleLine = [
                    formatter('TV Show', 'bold'),
                    formatter('Consumption In Audience', 'bold'),
                    formatter('Audience Portion In Consumption', 'bold'),
                    formatter('Audience Skew', 'bold'),
                    formatter('Significance Score', 'bold'),
                    formatter('Genre', 'bold'),
                    formatter('Network', 'bold')
                ];

                let data = interests.map(interest => [
                    interest.phrase,
                    formatter(interest['interest-portion'] / 100, 'percent'),
                    formatter(interest['segment-portion'] / 100, 'percent'),
                    formatter(interest['uniqueness-index'], 'numeric'),
                    formatter(interest['score'], 'numeric'),
                    formatter(interest['genre'], 'text'),
                    formatter(interest['network'], 'text')
                ]);

                data.unshift(titleLine);
                worksheet.setData(data);
                worksheet.setColumns([{width: 20}, {width: 18}, {width: 18}, {width: 18}, {width: 18}]);
                workbook.addWorksheet(worksheet);
            }

            const fileNamer = segmentInterestExportService.momentBasedFileNamerGen('TV Shows');

            function exportToExcel(shows, demographicsData, segment) {
                const workbook = Excel.builder.createWorkbook();
                const formatter = Excel.formater(workbook);

                createSummaryWorksheet(workbook, formatter, segment, demographicsData);
                createTvShowsWorksheet(workbook, shows, 'xw', formatter);

                workbook.fileName = fileNamer();
                return workbook;
            }

            function downloadExcel(excel) {
                return segmentInterestExportService.excelDownloader(excel, fileNamer);
            }

            return {exportToExcel, downloadExcel};
        }
    ]);
