"use strict";
module.exports = angular.module(__filename, []).directive("amImageToggleButton", ["$timeout", function ($timeout) {
    return {
        restrict: 'E',
        scope: {
            values: '=',
            selected: '=?',
            onSelect: '&',
            disabled: '=',
            withTip: '='
        },
        template: require('./am-image-toggle-button.html'),
        link: function ($scope, $element, $attr) {
            $scope.onHoverIcon = $attr.onHoverIcon;
            var self = this;
            self.onSelect = $scope.onSelect() || angular.noop;

            $scope.select = function (value) {
                if (!($scope.isSelected(value) || value.disabled)) {
                    $scope.selected = value.value;
                    $timeout(function () {
                        self.onSelect(value.value)
                    }, 0)
                }
            };
            $scope.$watch('onSelect', () => {
                self.onSelect = $scope.onSelect() || angular.noop;
            });

            $scope.isSelected = function (value) {
                return $scope.selected == value.value;
            }
        }
    }
}]);
