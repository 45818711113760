"use strict";
/**
 * Created by Pery on 27/01/2015.
 */
module.exports = angular.module(__filename, [])
    .service('utils', ["$rootScope", "$timeout", "$window", function ($rootScope, $timeout, $window) {
        var transitionEvent = findTransitionEvent();

        /*API*/
        return {
            triggerResize: triggerResize,
            triggerResizeOnTransitionEnd: triggerResizeOnTransitionEnd
        };

        function findTransitionEvent() {
            var el = document.createElement('fakeelement');
            var transitions = {
                'transition': 'transitionend',
                'OTransition': 'oTransitionEnd',
                'MozTransition': 'transitionend',
                'WebkitTransition': 'webkitTransitionEnd'
            };

            for (var t in transitions) {
                if (el.style[t] !== undefined) {
                    return transitions[t];
                }
            }
        }

        function triggerResize() {
            $timeout(function () {
                angular.element($window).trigger('resize');
                $rootScope.$broadcast('resizeTransitionEnd');
            }, 1);
        }

        function triggerResizeOnTransitionEnd($element) {
            if (transitionEvent) {
                $element.one(transitionEvent, function () {
                    triggerResize()
                })
            } else {
                triggerResize();
            }
        }
    }]
);
