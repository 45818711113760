"use strict";
var Template = require("./consumption-index-widget.html"),
    c = require("infra/utils/common"),
    ex = require("infra/utils/export"),
    BaseWidget = require("../base_widget"),
    TrendChart = require("../../common/charts/trend-chart"),
    BarChart = require("../../common/charts/vertical-bar-chart"),
    ConsumptionService = require("data/insights/consumption-trend-service"),
    mixpanel = require("infra/mixpanel/mixpanel-insights"),
    examplesDataModel = require("data/insights/examples-data-model");

var consumptionExportTabName = 'Consumption Trends';

var consumptionConfiguration = {
    element: 'consumption-chart',
    parent: 'consumption-index-widget',
    detectTimeOrDate: true,
    force_max: true,
    agg_examples_mode: true,
    templates: {
      non_normalized: _.template("<%= parseInt(consumption).toLocaleString('en') %> interactions, <%= consumptionAvg > 0 ? '+' : ''%><%= consumptionAvg %>% from Avg"),
      normalized: _.template("Consumption <%= parseFloat(consumption.toFixed(1)) %>, <%= (consumptionAvg > 0) ? '+' : '' %><%= consumptionAvg %>% from Avg")
    }
};

var consumptionAverageConfiguration = {
    title: 'Average',
    element: 'consumption-average-chart',
    parent: 'consumption-index-widget',
    margin: 50,
    pad: 0,
    grouped: true
};

var DEFAULT_SUMMARY_PARAMETERS = ex.DEFAULT_SUMMARY_PARAMETERS.concat([{
    key: 'associated',
    label: 'Association'
}]);

var CHART_ENTRIES = [{
    key: 'value',
    source: 'value',
    order: 1,
    class: '',
    units: ''
}];

ConsumptionIndexController.$inject = [
    "$scope", "$timeout", "$state", "consumptionTrendService", "util", "abiPermissions", "examplesDataModel", "insightsExportService", "mixpanelInsights", "geoService", "baseInsightsService"
];

function ConsumptionIndexController($scope, $timeout, $state, consumptionTrendService, util, abiPermissions, examplesDataModel, insightsExportService, mixpanelInsights, geoService, baseInsightsService) {
    var self = this;
    this.service = consumptionTrendService;
    this.insightsExportService = insightsExportService;
    this.geoService = geoService;
    this.parameters = {};
    this.util = util;
    this.$state = $state;
    this.permissions = abiPermissions;
    this.baseInsightsService = baseInsightsService;
    $scope.noData = true;
    $scope.showDataInProcess = false;
    $scope.inProcess = false;
    $scope.trends = [];
    $scope.trendAverages = [];
    $scope.examplesData = examplesDataModel;
    $scope.legendTrends = [];
    this.chartData = {};
    this.chart = new TrendChart(this, "consumption-chart", consumptionConfiguration);

    this.emitEventForChartCircle = function (eventType, letter) {
        $scope.$emit(eventType, letter);
    };

    this.barChart = new BarChart(this, CHART_ENTRIES, consumptionAverageConfiguration);
    mixpanelInsights.trackPageView('consumption');
    if (abiPermissions.hasPermission(['normalize'])) {
        $scope.toNormalize = $scope.insightsChannel.value != 'tweets';
    }

    function isOneWeekOrLessRange(){
        if($scope.timeframe[1] === 'day'){
            return true;
        }
        var timeframe = c.getTimeframe($scope.timeframe);
        var enDate = moment(timeframe.end).toDate();
        var stDate = moment(timeframe.start).toDate();
        return (enDate - stDate)/(1000 * 60 * 60 * 24) <= 7;
    }

    this.onResize = function () {
        var data = self.parameters.to_normalize ? self.chartData.averages : self.chartData.sums;
        $scope.showDataInProcess  =  isOneWeekOrLessRange();
        self.barChart.setData(data, self.chartData.maxChart, self.wide);
        $timeout(function () {
            self.chart.draw(self.chartData, self.chartData.max, self.parameters.to_normalize ? 'normalized' : 'non_normalized');
            self.chart.addCircles($scope.examplesData.visible_examples);
        }, 0);
    };

    this.clearData = function (update) {
        $scope.inProcess = false;
        $scope.trends = [];
        $scope.trendAverages = [];
        $scope.examplesData.visible_examples = [];
        $scope.examplesData.filters = [];
        $scope.examplesData.filter_type = '';
        //TODO - clear all examples?
        this.chartData = {};
        if (update) {
            $timeout(function () {
                self.onResize();
            }, 0);
        }
        $scope.noData = true;
    };

    this.getExportReport = function (format) {
        var report = {
            name: consumptionExportTabName,
            columns: [],
            table: []
        };
        var terms = self.util.getValidTerms($scope, self.parameters);
        if (c.isArray(terms)) {
            var titles = [format('Date', 'bold')];
            report.columns = [{
                width: 18
            }];
            _.each(terms, function (term) {
                titles.push(format(self.util.getTermDisplay(term), 'bold'));
                report.columns.push({
                    width: 25
                });
            });
            report.table = [titles];
            var result = self.service.getValuesByDateTable(terms, format, self.parameters.to_normalize ? 'numeric' : 'integer');
            report.table = report.table.concat(result);
        }
        return report;
    };

    this.getExportExamples = function (format) {
        var report = {
            name: 'Content drivers',
            columns: [],
            table: []
        };
        report.table = [
            [format('Date', 'bold'), format('Seed', 'bold'), format('URL', 'bold')]
        ];
        report.columns = [{width: 18}, {width: 25}, {width: 100}];
        var result = self.service.getExamples($scope.trends, format);
        report.table = report.table.concat(result);
        return report;
    };

    $scope.updateNormalize = function (toNormalize) {
        $scope.toNormalize = !$scope.toNormalize;
    };

    $scope.$on('contentDriverMouseover', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('contentDriverMouseout', function (event, letter) {
        self.chart.highlightCircleFromLetter(letter, false);
    });

    $scope.$on('contentDriverClick', function (event, letter) {
        self.chart.resetHighlightedCircle();
        self.chart.letterClicked = letter;
        self.chart.highlightCircleFromLetter(letter, true);
    });

    $scope.$on('widgetPanelClick', function () {
        self.chart.resetHighlightedCircle();
    });

    this.$scope = $scope;
    insightsExportService.setTitle('Consumption');
    insightsExportService.addReportFunction(this.getExportReport);
    insightsExportService.setExampleFunction(this.getExportExamples);
}

ConsumptionIndexController.prototype._doUpdate = function (values, changedVals) {
    this.chart.resetHighlightedCircle();
    var $scope = this.$scope;
    $scope.$emit('resetLetterClicked');
    $scope.examplesData.open = true;
    $scope.examplesData.visible = true;
    $scope.examplesData.alphabetized = true;
    $scope.examplesData.filter_type = 'trend';
    $scope.$root.$broadcast('openContentDrivers', "content_drivers");

    var nonPhrases = c.validateNonPhrases($scope.terms, $scope.insightsChannel.value, this.notificator);
    if (changedVals.insightsChannel && this.permissions.hasPermission(['normalize'])) {
        $scope.toNormalize = $scope.insightsChannel.value != 'tweets';
    }

    this.parameters = this.util.buildInsightsParameters($scope);
    this.parameters.hourly_consumption_trends = this.permissions.hasPermission(['hourly consumption trends']);

    this.parameters.user_1st_party_audience = _.map($scope.userFirstPartyAudience,function(item) {
        return item.value;
    });

    $scope.trends = this.parameters.trends;
    $scope.termClasses = {};

    $scope.examplesData.visible_examples = [];
    $scope.examplesData.examples.consumption = [];
    $scope.examplesData.terms = $scope.trends;
    $scope.legendTrends = nonPhrases ? $scope.trends.filter(function (t) {
        return ['mention', 'hashTag', 'post'].indexOf(t.type) === -1;
    }) : $scope.trends;
    angular.copy($scope.legendTrends, $scope.examplesData.filters);
    _.each($scope.examplesData.terms, function (trend) {
        $scope.termClasses[trend.text] = $scope.termClasses[trend.id] = trend.class;
    });
    _.each($scope.examplesData.filters, function (trend) {
        trend.show = true;
    });

    consumptionAverageConfiguration.title = this.parameters.to_normalize ? 'Average' : 'Total';
    consumptionAverageConfiguration.precision = this.parameters.to_normalize ? 1 : 0;
    c.logUpdate('Consumption Trends', this.parameters);
    $scope.inProcess = true;

    this.parameters.measure = $scope.measure;
    this.insightsExportService.initSummaryFields();
    var export_parameters = [];
    if(this.baseInsightsService.shouldSendAudience(this.parameters.channel, this.permissions)) {
        this.insightsExportService.addSummaryFields(ex.AUDIENCE_SUMMARY_FIELD);
        export_parameters =  _.extend({}, this.parameters, {audience: _.map(c.getAudience($scope, this.parameters.channel), 'summary')});
    } else {
        export_parameters = _.clone(this.parameters);
    }
    this.insightsExportService.setParams(export_parameters);
    var self = this;
    $scope.examplesData.promise = this.service.get(this.parameters, null);
    return $scope.examplesData.promise.then(function (data) {
        self.$scope.noData = false;
        self.chartData = data;
        self.$scope.trendAverages = data.averages;
        $scope.examplesData.icon = data.icon;
        $scope.examplesData.examples.consumption = data.examples;
        _.each($scope.examplesData.examples.consumption, function (ex, i) {
            ex.class = $scope.termClasses[ex.keyword];
        });
        $scope.examplesData.examples.consumption = _.filter($scope.examplesData.examples.consumption, function (example) {
            return $scope.termClasses[example.keyword]
        });
        if (self.$state.includes('*.consumption')) {
            angular.copy($scope.examplesData.examples.consumption, $scope.examplesData.visible_examples);
        }
        self.onResize();
        self.$scope.inProcess = false;
    }, function (error) {
        self.clearData(true);
        $scope.$emit('insightsError', error);
    });
};

module.exports = angular.module(__filename, [ConsumptionService.name])
    .directive("consumptionIndexWidget", [function () {
        return BaseWidget({
            restrict: "E",
            template: require("./consumption-index-widget.html"),
            scope: {
                topics: "=",
                terms: "=",
                active: "=",
                insightsChannel: '=insightsChannel',
                audience: '=',
                sgTelcoAudience: '=',
                userFirstPartyAudience: '=',
                timeframe: "=",
                geo: "=",
                sub_geos: "=subGeos",
                toNormalize: "=?",
                measure: "=?",
                graphTitle: "=?",
                showContentDrivers: "=?",
                cacheBaster: "="
            },
            controller: ConsumptionIndexController
        });
    }]
);
