"use strict";
if (window.config) {
    module.exports = window.config;
} else {
    const location = window && window.location && window.location.host;

    module.exports = {
        USER_MGMT_API: "//user-mgmt." + location,
        AUTHENTICATION_API: "//authentication." + location,
        SNAPSHOT_API: "//snapshot." + location,
        TRENDLINE_API: "//consumption." + location,
        DISCOVERY_API: "//discovery." + location,
        SEARCH_API: "//abi-content-search." + location,
        INSIGHTS_API: "//abi-insights." + location,
        AUDIENCE_PROFILER_API: "//audience-profiler-ui." + location,
        TWITTER_API: "//twitter-api." + location,
        REALTIME_API: "//abi-realtime." + location,
        LOGGING_API: "//user-mgmt." + location,
        KEYWORDS_SUGGESTIONS_API: "//kw-suggest." + location
    };
}
