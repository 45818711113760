"use strict";
const BaseWidget = require("../base_widget"),
    mixpanel = require("../../infra/mixpanel/mixpanel-insights"),
    c = require("infra/utils/common");

ConsumptionChannelController.$inject = ["$scope", 'consumptionChannelService', 'util', '$timeout', 'insightsExportService', 'mixpanelInsights'];

function ConsumptionChannelController($scope, comsumptionChannelService, util, $timeout, insightsExportService, mixpanelInsights) {
    const self = this;
    mixpanelInsights.trackPageView('channels');
    this.performUpdate = function() {
        return self.service.get(self.parameters).then(function(response) {
            $scope.noData = false;
            $scope.inProcess = false;
            $timeout(function() {
                self.setChannelData(response);
            });
        }, function(error) {
            self.clear(true);
            $scope.$emit('insightsError', error);
        });
    };

    this.setChannelData = function(channelData) {
        const maxValue = _(channelData).map('max').max();
        $scope.factor = 100 / ((maxValue > 0) ? maxValue : 100);
        _.each(channelData, function(channel) {
            _.each(channel.values, function(trend) {
                trend.original = trend.value;
                trend.value *= $scope.factor / 100;
            });
        });

        $scope.barChartBreakEven = 100 / maxValue;
        $scope.channelData = channelData;
    };

    this.clear = function(update) {
        $scope.noData = true;
        $scope.inProcess = false;
        $scope.channelData = [];
    };

    this.getExportReport = function(format) {
        const firstLine = [format('Channel', 'bold')];
        const columns = [{width: 35}];
        _.each($scope.trends, function(term) {
            firstLine.push(format(term.text, 'bold'));
            columns.push({width: 35});
        });
        const table = [firstLine];
        const data = self.service.getLatestData();
        _.each(data, function(channel) {
            const tableLine = [channel.text];
            _.each(channel.values, (value) => tableLine.push(format(value.original, 'numeric')));
            table.push(tableLine);
        });
        return {name: 'Channels', columns, table};
    };

    function initWidgetParameters() {
        $scope.factor = 1;
        $scope.precision = 2;
        self.service = comsumptionChannelService;
        self.util = util;
        self.insightsExportService = insightsExportService;
        self.$scope = $scope;
        self.parameters = {};
        self.timeframe = null;
        self.terms = null;
        self.clear();
        insightsExportService.addReportFunction(self.getExportReport);
    }

    function calcBarsWidth() {
        const barChartEl = angular.element(document.querySelector('am-bar-chart'));
        const barChartWidth = barChartEl[0] ? parseInt(barChartEl.css('width').replace("px", '')) : 0;
        return (barChartWidth + 20) * $scope.channelData.length; // + 20 for the margin-right foreach bar group
    }

    initWidgetParameters();

    $scope.calculateAvgLineHeight = function() {
        const barsSliderEl = angular.element(document.querySelector('.channel-sliders'));
        const barAvgEl = angular.element(document.querySelector('.bar-avg'));
        if(barsSliderEl[0] && barAvgEl[0]) { // array containing one DOM element is received unless none is found
            const barsHeight = parseInt(barsSliderEl.css('height').replace("px", ''));
            const avgBottom = parseInt(barAvgEl.css('bottom').replace("px", ''));
            return (barsHeight - avgBottom - 11) * $scope.barChartBreakEven; // 11 is the height of the values above the bar
        } else
            return 0;
    };

    $scope.showButtons = function() {
        const channelFrameEl = angular.element(document.querySelector('.channel-frame'));
        const parentWidth = channelFrameEl[0] ? parseInt(channelFrameEl.css('width').replace("px", '')) : 0;
        return calcBarsWidth() > parentWidth - 90; // -90 for the index block
    }
}

ConsumptionChannelController.prototype._doUpdate = function(values) {
    const $scope = this.$scope;
    c.validateNonPhrases($scope.terms, null, this.notificator);
    this.parameters = this.util.buildInsightsParameters(_.pick($scope, ['topics', 'terms', 'active', 'geo', 'timeframe', 'userFirstPartyAudience'], false, true));
    this.parameters.user_1st_party_audience = _.map($scope.userFirstPartyAudience,function(item) {
        return item.value;
    });
    $scope.trends = this.parameters.trends;
    $scope.inProcess = true;
    
    return this.performUpdate();
};

module.exports = angular.module(__filename, [
    require('data/insights/consumption-channel-service').name
])
    .directive("consumptionChannelWidget", [() => BaseWidget({
        restrict: "E",
        template: require('./consumption-channel-widget.html'),
        scope: {
            timeframe: "=",
            active: "=",
            terms: "=",
            geo: "=",
            topics: "=",
            userFirstPartyAudience: '=',
            cacheBaster: "="
        },
        controller: ConsumptionChannelController
    })]);
