"use strict";
require("stacktrace");
var html2canvas = require("html2canvas");
var platform = require('platform');
var config = require('infra/config');
var LOG_JS_ERROR_API = config.LOGGING_API + "/log_js_error";

module.exports = angular.module(__filename, []).service("errorsLogger", ["$window", "$injector", function ($window, $injector) {
    var numOfErrorsInSession = 0;

    function handleError(exception, cause) {
        StackTrace.fromError(exception).then(sendErrorToServer(exception, cause)).catch(handleLoggingFailure)
    }

    var handleErrorThrottled = _.throttle(handleError, 60000, {'leading': true, 'trailing': false});

    function captureScreenshot() {
        return new Promise(function (resolve, reject) {
            html2canvas(document.body, {
                onrendered: function (canvas) {
                    resolve(canvas.toDataURL("image/jpeg").substr("data:image/jpeg;base64,".length));
                }
            });
        });
    }

    function getDisplayInfo() {
        return {
            screen_res: (screen.width || '') + ' x ' + (screen.height || ''),
            browser_avail_dimensions: (screen.availWidth - (window.outerWidth - window.innerWidth)) + ' x ' + (screen.availHeight - (window.outerHeight - window.innerHeight)),
            browser_actual_dimensions: (window.innerWidth || '') + ' x ' + (window.innerHeight || '')
        }
    }

    function getPlatformInfo() {
        try {
            return {platform: platform.description, layout: platform.layout, agent: platform.ua}
        } catch (error) {
            return "N/A (error when extracting platform info)";
        }
    }

    function getErrorDetails(exception, cause, stackframesArr) {
        var stacktrace = stackframesArr.map(function (sf) {
            return sf.toString();
        }).join('\n');

        var errorMessage = exception.message;
        var context = $injector.get("context");
        var user = $injector.get("user");
        var userContext = angular.copy(context.current);
        var errorDetails = {
            message: errorMessage,
            trace: stacktrace,
            cause: cause,
            url: $window.location.href,
            context: userContext,
            user: user,
            platform: getPlatformInfo(),
            display: getDisplayInfo(),
            num_of_errors_in_session: numOfErrorsInSession
        };

        return new Promise(function (resolve, reject) {
            captureScreenshot().then(function (screenshot) {
                errorDetails['screenshot'] = screenshot;
                resolve(errorDetails);
            });
        });
    }

    function sendErrorToServer(exception, cause) {
        return function (stackframesArr) {
            getErrorDetails(exception, cause, stackframesArr).then(function (errorDetails) {
                // must inject $http this way to avoid circular dependency
                var $http = $injector.get("$http");
                try {
                    $http.post(LOG_JS_ERROR_API, errorDetails).then(angular.noop, function (sendingError) {
                        console.error("Could not send error to server!", sendingError);
                    });
                } catch (loggingError) {
                    console.error("Could not send error to server!", loggingError);
                }
            });
        };
    }

    function handleLoggingFailure(err) {
        console.error('Could not log error msg', err.message);
    }

    function logError(exception, cause) {
        var isDevelopment = $window.location.href.indexOf(".amobee.com") == -1;
        if (isDevelopment) return;

        numOfErrorsInSession++;
        handleErrorThrottled(exception, cause);
    }

    return {
        logError: logError
    }
}]).config(["$provide", function ($provide) {
    $provide.decorator("$exceptionHandler", ["$window", "$delegate", "errorsLogger", function ($window, $delegate, errorsLogger) {
        $window.onerror = function handleGlobalError(message, fileName, lineNumber, columnNumber, error) {
            // If this browser does not pass-in the original error object, create a new error object based on what we know.
            if (!error) {
                error = new Error(message);
                error.fileName = fileName;
                error.lineNumber = lineNumber;
                error.columnNumber = (columnNumber || 0);
            }

            errorsLogger.logError(error);
        };

        return function (exception, cause) {
            // call the original $exceptionHandler that will log the error to the console
            $delegate(exception, cause);
            // send error to server
            errorsLogger.logError(exception, cause);
        }
    }]);
}]);