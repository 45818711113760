"use strict";
const angular = require("angular"),
    BaseWidget = require("../base_widget"),
    common = require("infra/utils/common");

AudienceDemographicsWidgetController.$inject = ['audienceInsightsService', 'context', 'filtersPartition'];

function AudienceDemographicsWidgetController(audienceInsightsService, context) {
    const $scope = this.$scope;
    const dataTypesToggleValues = [
        {
            value: 'distribution',
            label: 'Distribution',
            tooltip: {
                text: 'How your audience is distributed between demographic segments',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        },
        {
            value: 'skew',
            label: 'Skew',
            tooltip: {
                text: 'The extent to which the demographic segment is over-indexed within the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        }
    ];
    $scope.ethnicityBarChartOptions = {labelFont: '13px Helvetica', highlightedLabelFont: '13px Helvetica'}; // todo remove after responsive-bar-chart colliding labels problem is fixed

    this.audienceInsightsService = audienceInsightsService;
    this.segment = context.current.audience_segment;

    let segmentDemographics = this.segment.find(segment => segment.type === "demographics");
    $scope.geo = segmentDemographics && segmentDemographics.geo && segmentDemographics.geo[0];
    $scope.isUSA = $scope.geo && $scope.geo.cc === 'US';

    let exportButton = angular.element(document.querySelector('.export'))[0];
    exportButton.hidden = true;
    $scope.$on('$destroy', () => { exportButton.hidden = false; });

    $scope.dataTypesToggleValues = dataTypesToggleValues;

    $scope.data = {distribution: {}, skew: {}};

    $scope.loadingPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(this.segment)
        .then(data => {
                if(!data) return;
                switch(data.status) {
                    case 'ok':
                        // insert skew tooltips
                        _.each(data.skew.gender, (o, gender) => {
                            o.tooltip = skewTooltip(`${_.capitalize(gender)}s`, o.value)
                        });
                        data.skew.genderAge.forEach(o => {
                            o.male.tooltip = skewTooltip(`Males of age ${o.label}`, o.male.value);
                            o.female.tooltip = skewTooltip(`Females of age ${o.label}`, o.female.value);
                        });
                        data.skew.ethnicity.forEach(o => {
                            o.tooltip = skewTooltip(`${o.label}s`, o.value);
                        });
                        data.skew.income.forEach(o => {
                            o.tooltip = skewTooltip(`Residents of households with income of ${o.label} USD`, o.value);
                        });

                        $scope.data = data;
                        $scope.selectedDataType = 'distribution';
                        break;
                    case 'error':
                        switch(data.error.reason) {
                            case "selectionTooWide":
                                $scope.too_wide = true;
                                break;
                            case "selectionTooNarrow":
                                $scope.too_narrow = true;
                                break;
                        }
                        break;
                }
            }
        );

    function skewTooltip(topicPlural, value) {
        switch(value) {
            case 1:
                return `${topicPlural} are as likely to be in the target audience as the average consumer`;
            case 0:
                return `${topicPlural} are unlikely to be in the target audience`;
            default:
                const [multiplier, comparePhrase] = value > 1 ? [value, 'more'] : [1 / value, 'less'];
                return `${topicPlural} are x${multiplier.toFixed(1)} times ${comparePhrase} likely to be in the target audience than the average consumer`;
        }
    }
}

module.exports = require("angular").module(__filename, [
    require("../../data/audience-insights-service").name,
    require('common/segment-size.drv/segment-size.drv').name,
    require('common/audience-gender.drv/audience-gender.drv').name,
    require('common/am-responsive-bar-chart.drv/am-responsive-bar-chart.drv').name,
    require('common/age-gender-chart.drv/age-gender-chart.drv').name,
    require('common/am-donut-chart.drv/am-donut-chart.drv').name
])
    .directive("audienceDemographicsWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-demographics-widget.html"),
        scope: {},
        controller: AudienceDemographicsWidgetController
    })]);