"use strict";
var common = require("infra/utils/common");

var audienceBuilderModule = angular.module(__filename, [
    require("./modals/lifestyles-modal").name,
    require("./modals/demographics-modal").name,
    require("./modals/interests-modal").name,
    require("./modals/websites-modal").name,
    require("./modals/load-audience/load-audience-modal").name,
    require("../../widgets/audience-preview-widget/audience-preview-widget").name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
    require("data/audience-mgmt").name,
    require("infra/mixpanel/mixpanel-audience").name,
    require("infra/context/filters/levelOfIntent-mold").name // TODO: delete me
]);

var SEGMENT_MAP = {
    lifestyle: { label: "Lifestyles", icon: "icon-lifestyle" },
    demographics: { label: "Demographics", icon: "icon-profile" },
    interests: { label: "Interests", icon: "icon-interests" },
    websites: { label: "Websites", icon: "icon-tv-monitor" }
};

audienceBuilderModule.stateConfig = [{
    name: "audience-builder",
    url: "/audience-builder",
    template: require("./audience-builder.html"),
    display: "Build Audience",
    context: {
        audience_segment: "GeneralMold"
    },
    controller: audienceBuilderController
}];

audienceBuilderController.$inject = ['$scope', 'context', 'audienceMgmt', 'filtersPartition', 'lifestylesModal',
    'demographicsModal', 'interestsModal', 'websitesModal', 'loadAudienceModal', 'notificator', 'confirmAction', 'mixpanelAudience'];

function audienceBuilderController($scope, context, audienceMgmt, filtersPartition, lifestylesModal, demographicsModal,
    interestsModal, websitesModal, loadAudienceModal, notificator, confirmAction, mixpanelAudience) {

    var SEGMENT_MODAL_MAP = { lifestyle: lifestylesModal, demographics: demographicsModal, interests: interestsModal, websites: websitesModal };

    var AGE_TO_ID_MAP = {};
    var AGE_ID_TO_LABEL_ARRAY = [];
    _.each(filtersPartition.age, function (age, id) {
        AGE_TO_ID_MAP[age.label] = id;
        age.label.split('-').forEach(function (age) {
            AGE_ID_TO_LABEL_ARRAY.push(age)
        });
        AGE_TO_ID_MAP["12-17"] = 0;
    });
    AGE_ID_TO_LABEL_ARRAY.push(filtersPartition.age[filtersPartition.age.length - 1].label);

    $scope.SEGMENT_MAP = SEGMENT_MAP;
    $scope.logicalOperands = [{ value: 'and', label: 'ALL of the attributes below' }, { value: 'or', label: 'ANY of the attributes below' }];
    mixpanelAudience.trackPageView('audience builder');
    function loadFromContext() {
        $scope.audience_segment = context.current.audience_segment;
        $scope.audience_name = context.current.audience_name || '';
        $scope.advancedSegmentation = context.current.audience_advancedSegmentation || false;
        $scope.logicalOperand = _.isEmpty(context.current.audience_logicalOperand) ? $scope.logicalOperands[0] : _.find($scope.logicalOperands, { value: context.current.audience_logicalOperand.value });
        if (!$scope.advancedSegmentation) _.each($scope.audience_segment, (s) => s.operand = $scope.logicalOperand);
    }

    loadFromContext();
    $scope.refreshPreviewWidget = 0;
    var originalAudienceName = '';

    $scope.onLogicalOperandChange = function (selectedOperand) {
        $scope.logicalOperand = selectedOperand;
        _.each($scope.audience_segment, (segment) => segment.operand = selectedOperand);

        refreshContext();
        forcePreviewWidgetUpdate();
    };

    $scope.segmentTypes = [
        { value: "demographics", label: "Demographics", image: "./images/pages/audience-builder/images/demographics_img.png" },
        { value: "interests", label: "Interests", image: "./images/pages/audience-builder/images/interests_img.png" },
        { value: "websites", label: "Websites", image: "./images/pages/audience-builder/images/websites_img.png" },
        { value: "lifestyle", label: "Lifestyles", image: "./images/pages/audience-builder/images/lifestyle_img.png" }
    ];

    $scope.onToggleAdvancedSegmentation = function (on) {
        if (!on) {
            var advancedOperands = _($scope.audience_segment).map('operand.value').uniq().value();
            if (advancedOperands.length == 1) {
                var operand = _.find($scope.logicalOperands, { value: advancedOperands[0] || 'and' });
                $scope.logicalOperand = operand ? operand : $scope.logicalOperand;
            }

            _.each($scope.audience_segment, (segment) => segment.operand = $scope.logicalOperand);
            refreshContext();
            forcePreviewWidgetUpdate();
        }
        mixpanelAudience.trackAdvancedSegmentation();
    };

    $scope.loadAudience = function () {
        getConfirmation(true, function () {
            loadAudienceModal.showModal().then(function (modal) {
                modal.close.then(function (value) {
                    // When the user uses browse back btn, there is no "segments" attribute.
                    // We use this property as a validation, i.e. any valid response should include a "value" and segments" attribute.
                    if (value && value.segments) {
                        $scope.audience_id = value.id;
                        $scope.audience_segment = _.cloneDeep(value.segments);
                        $scope.audience_name = value.name;
                        originalAudienceName = value.name;
                        $scope.advancedSegmentation = value.advancedSegmentation;
                        $scope.logicalOperand = !$scope.advancedSegmentation && value.segments[0].operand ? value.segments[0].operand : $scope.logicalOperands[0];
                        notificator.success({ body: `"${$scope.audience_name}" audience loaded successfully` });
                        clearNameErrors();
                        refreshContext();
                    }

                    getProgramAudience();
                });
            });
        });
    };

    function getConfirmation(load, callback) {
        if (!$scope.audienceDirty()) {
            callback();
        } else {
            var confirm = 'Continue without saving';
            var cancel = 'Cancel';
            var title = 'Audience unsaved';
            var msg = `Are you sure you want to ${load ? 'load' : 'create'} a new audience
                       without saving your changes to this audience?`;

            confirmAction.getConfirmation(msg, confirm, cancel, title, 'audience-confirmation').then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation === true) {
                        callback();
                    }
                });
            });
        }
    }

    $scope.audienceDirty = function () {
        var current_audience = _.find(programAudiences, { id: $scope.audience_id }) || { segments: [], name: '', advancedSegmentation: false };
        return angular.toJson(current_audience.segments) != angular.toJson($scope.audience_segment)
            || current_audience.name != $scope.audience_name
            || current_audience.advancedSegmentation != $scope.advancedSegmentation;
    };

    $scope.resetAudience = function () {
        getConfirmation(false, function () {
            $scope.audience_segment = [];
            $scope.audience_name = '';
            originalAudienceName = '';
            $scope.audience_id = null;
            $scope.advancedSegmentation = false;
            $scope.logicalOperand = $scope.logicalOperands[0];
            refreshContext();
            clearNameErrors();
        });
    };

    $scope.deleteSegment = function (index) {
        $scope.audience_segment.splice(index, 1);
        refreshContext();
    };

    $scope.editSegment = function (index) {
        var type = $scope.audience_segment[index].type;
        $scope.segmentType = type;
        var modal = SEGMENT_MODAL_MAP[type];
        var disableGeo = type == "demographics" && _.filter($scope.audience_segment, (seg) => seg.type == "demographics").length > 1;
        modal.showModal($scope.audience_segment[index], false, disableGeo).then(function (modal) {
            modal.close.then(function (value) {
                $scope.segmentType = null;
                // When the user uses browse back btn, there is no "type" attribute.
                // We use this property as a validation, i.e. any valid response should include a "type" attribute.
                if (value && value["type"]) $scope.audience_segment[index] = value;
            });
        })
    };

    $scope.newSegment = function (type) {
        $scope.segmentType = type;
        var modal = SEGMENT_MODAL_MAP[type];
        var prevDefinedGeo = _.filter($scope.audience_segment, (seg) => seg.type == "demographics")[0];
        var disableGeo = type == "demographics" && prevDefinedGeo;
        var initialDefinedProperties = disableGeo ? { geo: prevDefinedGeo.geo, ethnicity: prevDefinedGeo.ethnicity, income: prevDefinedGeo.income } : null;
        modal.showModal(initialDefinedProperties, true, disableGeo).then(function (modal) {
            modal.close.then(function (value) {
                $scope.segmentType = null;
                if (value && value["type"]) {
                    value.operand = $scope.advancedSegmentation ? $scope.filter.logicOperand[0] : $scope.logicalOperand;
                    $scope.audience_segment.push(value);
                }
            });
        });
        mixpanelAudience.trackSegment(type);
    };

    function isInvalidName(isEdit) {
        $scope.name_missing = !$scope.audience_name;
        var audiences = isEdit ? _.filter(programAudiences, a => a.id != $scope.audience_id) : programAudiences;
        var names = _.map(audiences, audience => audience.name.toLowerCase());
        $scope.name_exist_in_program = names.includes($scope.audience_name.toLowerCase());
        return $scope.name_missing || $scope.name_exist_in_program;
    }

    function newAudience(data) {
        return audienceMgmt.create({ data: data });
    }

    function editAudience(data) {
        return audienceMgmt.update($scope.audience_id, { data: data });
    }

    var saveInProcess = false;
    $scope.saveAudience = function () {
        if (saveInProcess) return;
        if (isInvalidName($scope.audience_id)) return;

        saveInProcess = true;
        var data = {
            name: $scope.audience_name,
            segments: _.cloneDeep($scope.audience_segment),
            advancedSegmentation: $scope.advancedSegmentation
        };

        var promise = $scope.audience_id ? editAudience(data) : newAudience(data);
        promise.then((res) => {
            var msg = `The audience "${$scope.audience_name}" was saved successfully`;
            if (originalAudienceName && res.name != originalAudienceName) {
                msg = `The audience "${originalAudienceName}" was renamed to "${$scope.audience_name}" and saved successfully`;
            }

            notificator.success({ body: msg });
            refreshContext();
            clearNameErrors();
            getProgramAudience();
            saveInProcess = false;
        });
    };

    $scope.clearNameErrors = clearNameErrors;

    function clearNameErrors() {
        $scope.name_exist_in_program = false;
        $scope.name_missing = false;
    }

    var programAudiences = [];
    function getProgramAudience() {
        audienceMgmt.list().then(data => {

            programAudiences = data;
            var currentAudience = _.find(programAudiences, { name: $scope.audience_name }) || {};
            $scope.audience_id = currentAudience.id;
            originalAudienceName = currentAudience.name;
            $scope.disableLoadAudience = programAudiences.length == 0;

            // map audience ids to names, for later display
            context.current.audience_ids = data.reduce((obj, aud) => {
                obj[aud.id] = aud.name;
                return obj;
            }, {});
            context.current.audience_id = currentAudience.id;

        });
    }

    getProgramAudience();
    var unbindContextListener = context.onChange(function (newVal, oldVal) {
        if (oldVal && newVal.p_id == oldVal.p_id) return;
        clearNameErrors();
        loadFromContext();
        getProgramAudience();
    });

    $scope.$on('$destroy', unbindContextListener);
    $scope.refreshContext = refreshContext;

    function refreshContext() {
        context.current.audience_segment = $scope.audience_segment;
        context.current.audience_name = $scope.audience_name;
        context.current.audience_advancedSegmentation = $scope.advancedSegmentation;
        context.current.audience_logicalOperand = $scope.logicalOperand;
    }

    var nameInput = angular.element(document.querySelector('.segment-name'));
    var inputFont = nameInput.css('font');
    $scope.rename = function (onBlur) {
        if (saveInProcess) return;
        if ($scope.audience_name == originalAudienceName || $scope.audience_segment.length == 0) return;
        $scope.saveAudience();
        if (!onBlur) nameInput.blur();
    };

    $scope.calcAsteriskLeft = function () {
        return 10 + common.getTextWidth($scope.audience_name, inputFont) + 5;
    };

    function forcePreviewWidgetUpdate() {
        $scope.refreshPreviewWidget++;
    }

    function getAgeValueSummary(age) {
        var ages_groups = [];
        var merged_age_groups = [];
        _.each(age, function (age) {
            var ageId = AGE_TO_ID_MAP[age.label];
            ages_groups.push(ageId * 2);
            ages_groups.push(ageId * 2 + 1)
        });

        var i;
        for (i = 0; i < ages_groups.length; i++) {
            if (i == 0 || i == ages_groups.length - 1 || ages_groups[i] != ages_groups[i - 1] + 1 || ages_groups[i] != ages_groups[i + 1] - 1) {
                merged_age_groups.push(ages_groups[i]);
            }
        }

        var ages_labels = [];
        for (i = 0; i < merged_age_groups.length; i = i + 2) {
            var from = AGE_ID_TO_LABEL_ARRAY[merged_age_groups[i]];
            var to = AGE_ID_TO_LABEL_ARRAY[merged_age_groups[i + 1]];
            ages_labels.push(from != to ? from + "-" + to : from);
        }

        return "<ii>Age between</ii> " + ages_labels.join(' and ');
    }

    function getIncomeValueSummary(income) {
        var has_first_income = income[0].value == filtersPartition.newIncome[0].value;
        var has_last_income = income[income.length - 1].value == filtersPartition.newIncome[filtersPartition.newIncome.length - 1].value;
        var summary = "<ii>Income "
        if (!has_first_income && !has_last_income) {
            summary += "between</ii> " + income[0].label.split('-')[0] + "-" + income[income.length - 1].label.split('-')[1];
        } else if (has_first_income) {
            summary += "below</ii> " + income[income.length - 1].label.split('-')[1];
        } else {
            summary += "over</ii> " + income[0].label.split('-')[0] + "K";
        }

        summary = summary.replace(/\+k/i, '').replace(/99k/i, '100K');
        return summary;
    }

    function getDemographicsValueSummary(demographics) {
        var summary = [];
        if (demographics.gender) summary.push(_.upperFirst(demographics.gender[0].label));
        if (demographics.age) summary.push(getAgeValueSummary(demographics.age));
        if (demographics.income) summary.push(getIncomeValueSummary(demographics.income));
        if (demographics.children) summary.push(_.upperFirst(demographics.children[0].summary));
        if (demographics.geo) summary.push(demographics.geo[0].label);
        if (demographics.ethnicity) summary.push(demographics.ethnicity[0].label);
        return summary.join(', ');
    }

    function getInterestsAndWebsitesValueSummary(interests) {
        var summary = [];
        if (interests.required) summary = summary.concat(_.map(interests.required, 'text').join(' <ii>and</ii> '));
        if (interests.included) summary = summary.concat(_.map(interests.included, 'text').join(' <ii>or</ii> '));
        if (interests.excluded) summary = summary.concat("<ii>without</ii> " + _.map(interests.excluded, 'text').join(' <ii>and</ii> '));
        if (interests.levelOfIntent) summary = summary.concat("<ii>Level of Intent</ii> " + interests.levelOfIntent.label);
        return summary.join(",&nbsp;&nbsp;&nbsp;");
    }

    $scope.getSegmentValuesSummary = function (segment) {
        if (segment.type == "lifestyle" || segment.type == "custom_segment") return segment.label;
        if (segment.type == "demographics") return getDemographicsValueSummary(segment);
        if (segment.type == "interests" || segment.type == "websites") return getInterestsAndWebsitesValueSummary(segment);
    };

    $scope.onLogicOperandChange = function () {
        forcePreviewWidgetUpdate();
        refreshContext();
    }
}

audienceBuilderModule.filter("trusted_html", ['$sce', function ($sce) {
    return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    }
}]);

module.exports = audienceBuilderModule;