"use strict";
var config = require("infra/config"),
    c = require("infra/utils/common");

var REFERRALS_API = config.INSIGHTS_API + '/insights/referrals';
var REFERRAL_THRESHOLD = 0.1;

var labelsMap = {
  source: {
    self: "Organic Reading",
    other: "Other Websites"
  },
  publisher: {
    other: "Other"
  }
};

module.exports = angular.module(__filename, [])
    .service("referralsService", ['baseInsightsService', function(baseInsightsService) {
        var latestData;
        return {
            get: getReferrals,
            getCachedData: getCachedData
        };

        function getCachedData(params) {
            return latestData;
        }

        function getReferrals(params) {
            var termMap = {};
            var requestParams = baseInsightsService.buildParams(termMap, params);
            requestParams.user_1st_party_audience = params.user_1st_party_audience;
            requestParams.source = params.webSource;
            if (_.isEmpty(termMap)) return Promise.reject("No Terms");
            var replaceLabelsMap = labelsMap[requestParams.source];
            return baseInsightsService.postAsyncData(REFERRALS_API, requestParams).then(function(response) {
                var refs = response.data.keys;
                var topData = [];
                var totalMax = -1;
                _.each(refs, function(ref, index) {
                    var chartData = {
                        label: ref,
                        values: [],
                        id: index
                    };
                    replaceLabelAddUrl(chartData, replaceLabelsMap, requestParams.source);
                    var max = -1;
                    _.each(response.data.plot, function(entry, trendName) {
                        var term = termMap[trendName];
                        var entryData = {
                            value: entry[ref] ? entry[ref].normalized_value : 0,
                            id: term.id || term.text,
                            class: term.class
                        };

                        max = Math.max(entryData.value, max);
                        totalMax = Math.max(totalMax, max);
                        chartData.values.push(entryData);
                    });

                    chartData.maxStrength = max;
                    if (max >= REFERRAL_THRESHOLD || params.webSource == 'publisher') {
                        topData.push(chartData);
                    }
                });

                var results = {
                    topData: topData,
                    max: totalMax,
                    sorted: 'maxStrength'
                };

                sortResults(results, replaceLabelsMap);
                return buildFinalResults(results, params.trends);
              }).then(function(results) {
                baseInsightsService.notifyInsufficient(results.insufficientSeeds);
                // return results.insufficientSeeds.length == params.trends.length ? Promise.reject(results) : results;
                return results
              }).catch(baseInsightsService.handleError);
        }

        function replaceLabelAddUrl(data ,map, source) {
            if (map[data.label]) {
                data.label = map[data.label];
            } else if (source === 'publisher' && !map[data.label]) {
                data.url = `http://${data.label}`;
            }
        }

        function sortResults(data, labelsMap) {
            data.topData = _.sortBy(data.topData, 'maxStrength').reverse();

            // Moves other websites to the end of the array
            var index = _.findIndex(data.topData, {
                label: labelsMap['other']
            });
            if (index > -1) {
                data.topData.push(_.pullAt(data.topData, index)[0]);
            }
        }

        function buildFinalResults(result, trends) {
              var emptyTerms = baseInsightsService.getEmptyTrends(result.topData, trends);
              var extendedResults = _.extend(result, {insufficientSeeds: emptyTerms});
              latestData = extendedResults;
              return extendedResults;
        }
    }]);
