"use strict";
module.exports = angular.module(__filename, [])
    .service('errorMgmt', ['notificator', '$q', '$timeout', function (notificator, $q, $timeout) {
        var counter, cartridge;
        counter = 0;
        cartridge = [];

        // API ..
        this.error = handleGeneralError;
        this.httpError = handleGeneralError;
        this.widgetServiceError = handleWidgetError;
        /////////

        function onLine() {
            if (navigator.onLine) {
                return true
            } else {
                notificator.error({body: 'Internet connection lost. Please try again later.'});
                return false
            }
        }

        function updateCartridge(err) {
            var status = err.status || 0;
            var error = {id: counter, status: status, time: new Date().getTime()};

            counter++;
            cartridge.push(error);
            return error
        }

        function handleGeneralError(err) {
            if (!onLine()) return;

            // won't notify on authorization failure
            if (err.status == 401) return;

            var error = updateCartridge(err);
            var notice = messageByStatusCode(err.status);
            if (notice !== '') notifyUser(notice);
        }

        function notifyUser(notice) {
            if (cartridge.length == 0) return;
            $timeout(function () {
                var not205errors = cartridge.filter(function (element) {
                    return element.status != 205;
                });

                if (not205errors.length == 1) {
                    notificator.error({body: notice});
                } else {
                    if (not205errors.length == 0) {
                        return;
                    }
                    notificator.error({body: 'Sorry, something went wrong. Our engineers are working quickly to resolve the issue.'});
                }

                cartridge = [];
            }, 1000);
        }

        function messageByStatusCode(statusCode) {
            var notify_text = '';
            switch (statusCode) {
                case 400:
                case 500:
                    notify_text = 'Sorry, something went wrong. Our engineers are working quickly to resolve the issue.';
                    break;
                case 502:
                case 503:
                    notify_text = 'Internet connection lost. Please try again later.';
                    break;
                case -1:
                case 0:
                case 504:
                    notify_text = 'Oops, something went wrong, please try again.';
                    break;
            }
            return notify_text
        }

        function handleWidgetError(wName, err) {
            if (!onLine()) return;
            var error, notice;
            error = updateCartridge(err);
            notice = 'Sorry, ' + wName + ' is currently unavailable. Please try again later.';
            notifyUser(notice);
        }
    }]
);
