"use strict";
var config = require("infra/config");
var c = require("infra/utils/common");
var CHANNELS_API = config.INSIGHTS_API + '/insights/consumption_channel';
var CHANNELS_NAME_MAPPING = {
  "articles/sim-web": "Web - Desktop",
  "articles/sim-web-mobile": "Web - Mobile",
  "tweets": "Social",
  "videos": "Video"
}
module.exports = angular.module(__filename, [])
    .service("consumptionChannelService", ['baseInsightsService', function(baseInsightsService) {
        var latestData;

        return {
            get: getConsumptionChannel,
            getLatestData: getLatestData
        }

        function getConsumptionChannel(params) {
            var termMap = {};
            var requestParams = baseInsightsService.buildParams(termMap, params);
            requestParams.user_1st_party_audience = params.user_1st_party_audience;
            if (_.isEmpty(termMap)) return Promise.reject("No Terms");

            return baseInsightsService.postAsyncData(CHANNELS_API, requestParams)
              .then(function(response) {
                return buildResults(response.data, termMap);
              }).then(function(data){
                var empty = baseInsightsService.getEmptyTrends(data, params.trends);
                baseInsightsService.notifyInsufficient(empty);
                return empty.length == params.trends.length ? Promise.reject(data) : data;

                // return data
              }).catch(baseInsightsService.handleError);
        }

        function buildResults(results, termsMaps) {
          var finalResults = [];
          _.each(results['all/channels'],  function(channel) {
            var max = 0;
            var resultObject = {
              text: channel,
              values: []
            };

            _.each(termsMaps, function (termObject, termName) {
              var termValue = results[termObject.text][channel] * 100;
              max = Math.max(max, termValue);
              resultObject.values.push(Object.assign({value: termValue}, termObject));
            });

            resultObject.max = max;
            finalResults.push(resultObject);
          });

          mapChannelNames(finalResults);
          latestData = finalResults;
          return finalResults
        }

        function mapChannelNames(res) {
          _.mapValues(res, function(channel) {
            channel.text = CHANNELS_NAME_MAPPING[channel.text] || channel.text;
            return channel
          });
        }

        function getLatestData(){
          return latestData;
        }
    }]);
