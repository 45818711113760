"use strict";
var warVars = require("pages/warroom/warroom-vars"),
    config  = require("infra/config"),
    programTheme = require('pages/programs/program-theme/program-theme'),
    groupsModule = angular.module(__filename, [warVars.name]);
   

groupsModule.stateConfig = {
    name: 'groups',
    url: '/groups',
    needPermission: false,
    display: 'Group Management',
    displayTab: 'War Room',
    template: require('pages/warroom-groups/warroom-groups.html'),
    controller: GroupsCtrl
};


GroupsCtrl.$inject = ["$scope", "$state", "$http", "$timeout", "context", "notificator", "util", "abiPermissions"];

function GroupsCtrl($scope, $state, $http, $timeout, context, notificator, util, abiPermissions) {
    $scope.$root.helpPage = '6_1_Creating_and_Managing_Groups';
    $scope.queries = {};
    $scope.hasPermission = abiPermissions.hasPermission;

    $scope.queries['management'] = {
        title: 'groups',
        columns: [
            {id: 'name', title: 'group', template: 'partials/warroom-group.partial.html'},
            {id: 'phrases', title: 'phrases', flex:3, template: 'partials/warroom-groups-phrases.partial.html'}
        ],
        crud: true,
        crudTemplateHead: 'partials/warroom-groups-watch-head.partial.html',
        crudTemplateBody: 'partials/warroom-groups-watch-body.partial.html',
        scrollTdTemplate: 'partials/warroom-groups-scroll-td.partial.html',
        fixedSize:true,
        order: 'name',
        search: '',
        reverse: false
    };

    $scope.query = $scope.queries['management'];

    $scope.selected = {
        group: null
    };

    $scope.watch = {
        watchChanged: false,
        watchAll: false
    };

    $scope.crud = null; //null/edit/add/delete/edit_theme

    $scope.init = function () {
       $scope.resize();
       $scope.getManagement();
       $scope.programTheme = programTheme;
    };


    //events
    $scope.$on('resize', function (event, mass) {
        $scope.resize();
    });

    $scope.$on('update', function (event, mass) {
        $scope.init();
    });

    $scope.$on('geo', function (event, mass) {
        $scope.init();
    });

    $scope.resize = function () {
        $scope.size = warVars.getSize();
        $(".quick-table-wrap").css("width", $scope.size.wid - 85 + 'px');
        $("quick-table .tbody").css("height", ($scope.size.hi - 284) + 'px');
    };

    $scope.getManagement = function () {
        $scope.query.loading = true;
        $http.get(config.USER_MGMT_API + "/phrase-groups?tag=brand:" + context.program.id).success(
            function (groupsData) {

                if (groupsData.length === 0) {
                    $scope.query.show([]);
                    $timeout(function () {
                        $scope.resize();
                    }, 0);
                    $scope.add();
                    return;
                } else {
                    $scope.selected.group = null;
                    $scope.crud = null;
                }

                //add watched or unwatched
                userGroups().success(function (groups) {
                    var watched = false;
                    var groupIds = groups.map(function(group){return group.id});

                    $.each(groupsData, function (i, group) {
                        group.watched = groupIds.indexOf(group.id) !== -1;
                        if (group.watched) {
                            watched = true;
                        }
                    });

                    $scope.watch.watchAll = watched;

                    if (!watched && groupsData.length) {
                        notificator.notify({body: 'Please click the eye icon to watch at least one group.'});
                    }

                    _.each(groupsData, function (group) {
                        _.each(group.phrases, function (phrase) {
                            phrase.display = util.getTermDisplay(phrase);
                        });
                    });

                    $scope.query.show(groupsData);
                    $timeout(function () {
                        $scope.resize();
                    }, 0);
                }).error(function (e, status) {
                    if (status === 404) { // there are no saved watches for brand
                        $scope.query.show(groupsData);
                        $timeout(function () {
                            $scope.resize();
                        }, 0);
                    }
                });
            }
        ).error(function(e){
            console.log(e);
            $scope.query.show([]);
        });

        function userGroups() {
            return $http.get(config.USER_MGMT_API + "/user-groups/user:" + $scope.$root.user.id + "|brand:" + context.program.id);
        }
    };

    $scope.edit = function (group) {
        //opens edit popup
        $scope.selected.group = group;
        util.upgradePhrasesToTerms($scope.selected.group.phrases);

        $scope.watch.watchChanged = false;
        $scope.selected.group.phrasesOrig = $scope.selected.group.phrases.slice(0);

        $scope.crud = "edit";
        $("#edit_group_title").val(group.name);
    };

    $scope.validate = function () {
        var error = '';

        if (!$scope.selected.group.phrases.length) {
            error = 'Please add at least one phrase to your group.<br><br>';
        }

        var title = $("#edit_group_title").val();
        if (!title) {
            error += 'Please enter the group title.';
        } else if ($scope.crud === "add") {
            var i, len = $scope.query.dataArray.length;
            for (i = 0; i < len; i++) {
                if ($scope.query.dataArray[i].name === title) {
                    error += 'Group called ' + title + ' already exists.';
                    break;
                }
            }
        }

        if (error) {
            notificator.error({body: error});
            $scope.query.loading = false;
        }

        return !error;
    };

    $scope.saveGroup = function () {

        if (!$scope.validate()) {
            return;
        }

        $scope.query.loading = true;

        //hashtags
        $scope.selected.group.phrases.forEach(function(phrase){
            phrase.id = $scope.phraseId(phrase);
        });
        $scope.selected.group.phrases = _.uniqBy($scope.selected.group.phrases, function (e) {
          return e.text;
        });


        var group = {
            name: $("#edit_group_title").val(),
            phrases: $scope.selected.group.phrases,
            tag: 'brand:' + context.program.id
        };

        if ($scope.crud === "edit") {
            group.id = $scope.selected.group.id;
        }

        $http({
            url: config.USER_MGMT_API + "/phrase-groups" +
            ($scope.crud === "edit" ? "/" + $scope.selected.group.id : ""),
            method: $scope.crud === "edit" ? "put" : "post",
            contentType: "application/json",
            data: angular.toJson(group)
        }).success(function (response) {
            $scope.query.loading = false;
            group.watched = $scope.selected.group.watched;

            if ($scope.crud === "edit") {
                var groupIndex = $scope.query.dataArray.map(function (obj, ind) {
                    if (obj.id === group.id) {
                        return ind;
                    }
                }).filter(isFinite);
                $scope.query.dataArray[groupIndex] = group;
            } else {
                response.watched = group.watched;
                $scope.query.dataArray.push(response);
                group.id = response.id;

                $timeout(function () {
                    $scope.resize();
                }, 0);
            }

            _.each($scope.query.dataArray, function (group) {
                _.each(group.phrases, function (phrase) {
                    phrase.display = util.getTermDisplay(phrase);
                });
            });
            $scope.query.show($scope.query.dataArray);

            if ($scope.watch.watchChanged) {
                if (group.watched) {
                    $scope.watchGroup(group);
                } else {
                    $scope.unwatchGroup(group);
                }
            }

            $scope.selected.group = null;
            $scope.crud = null;
        }).error(function (e) {
            $scope.selected.group = null;
            $scope.crud = null;
            $scope.query.loading = false;
            console.log(e);
        });
    };

    $scope.add = function () {
        $scope.selected.group = {watched: true, phrases:[]};
        $scope.watch.watchChanged = true;
        $scope.crud = "add";
        $("#edit_group_title").val('');
        $timeout(function(){$("#edit_group_title").focus();});
    };


    $scope.userSubscribe = function (groupIds, op) {
        $http({
            url: config.USER_MGMT_API + "/" + op,
            method: "put",
            contentType: "application/json",
            data: JSON.stringify({user: "user:" + $scope.$root.user.id + "|brand:" + context.program.id, v: groupIds})
        }).success(function () {
        }).error(function (e) {
            console.log(e + ", error " + op);
        });
    };

    $scope.watchGroup = function (group) {
        group.watched = true;
        $scope.userSubscribe([group.id], "subscribe");
    };

    $scope.unwatchGroup = function (group) {
        group.watched = false;
        $scope.userSubscribe([group.id], "unsubscribe");
    };

    $scope.watchAll = function () {
        $scope.watch.watchAll = true;
        var ids = [];
        $.each($scope.query.dataArray, function (i, group) {
            group.watched = true;
            ids.push(group.id);
        });
        $scope.userSubscribe(ids, "subscribe");
    };

    $scope.unwatchAll = function () {
        $scope.watch.watchAll = false;
        var ids = [];
        $.each($scope.query.dataArray, function (i, group) {
            group.watched = false;
            ids.push(group.id);
        });
        $scope.userSubscribe(ids, "unsubscribe");
    };

    $scope.delete = function (group) {
        $scope.selected.group = group;
        $scope.crud = "delete";
        $("#delete_group_name").html(group.name);
    };

    $scope.deleteCB = function () {
        $scope.query.loading = true;
        $scope.crud = null;
        $http.delete(config.USER_MGMT_API + "/phrase-groups/" + $scope.selected.group.id).success(
            function () {
                $scope.query.loading = false;
                $scope.query.dataArray = $scope.query.dataArray.filter(function (el) {
                    return el.id !== $scope.selected.group.id;
                });
                $scope.query.show($scope.query.dataArray);
            }).error(function (e) {
                console.log(e);
                $scope.query.loading = false;
            }
        );
    };

    $scope.checkEnter = function (e) {
        if (e === 13) {//enter
            if ($scope.crud == "delete") {
                $scope.deleteCB();
            }
        }
    };

    $scope.init();
}

module.exports = groupsModule;

