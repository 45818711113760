"use strict";
var config = require("infra/config"),
    c = require("infra/utils/common");

var TOP_ASSOCIATION_API = config.INSIGHTS_API + '/insights/get_associations',
    USER_ASSOCIATION_API = config.INSIGHTS_API + '/insights/get_associations_by_reference',
    ADD_ASSOCIATION_API = config.INSIGHTS_API + '/keywords/translate';

var DEFAULT_CLASS = 'trending-default';
var NORMAL_TYPE_ID = 'normal';
var MISSING = 'NA';
var MISSING_VALUE = 'NA';
var DEFAULT_SORT_KEY = 'absolute';

module.exports = angular.module(__filename, [])
    .service("associationTrendService", ['$q', '$state', 'context', 'baseInsightsService', 'topicsTree', 'geoService', 'util', 'abiPermissions', 'CHANNEL',
     function ($q, $state, context, baseInsightsService, topicsTree, geoService, util, permissions , CHANNEL) {

        var customReferences = [];
        var latestDataNormal;
        var latestDataSplit;
        function set(entry, name, factor) {
            if (factor != 1) {
                var original = name + '_original';
                if (!c.isNumber(entry[original])) {
                    entry[original] = entry[name];
                }
                entry[name] = entry[original] * factor;
            }
        }

        function normalizeData(type, data, results) {
            if (c.isArray(data)) {
                if ((c.isNumber(results.abs_factor) && results.abs_factor != 1)
                    || (c.isNumber(results.factor) && results.factor != 1)) {
                    _.each(data, function (entry) {
                        if (c.is(entry.values)) {
                            _.each(entry.values, function (item) {
                                if (NORMAL_TYPE_ID == type) {
                                    set(item, 'value', results.factor);
                                    set(item, 'abs_value', results.abs_factor);
                                } else {
                                    set(item, 'left', results.factor);
                                    set(item, 'abs_left', results.abs_factor);
                                    set(item, 'right', results.factor);
                                    set(item, 'abs_right', results.abs_factor);
                                }
                            });
                        }
                    });
                }
            }
        }

        function recalculateMax(data, results, type) {
            if (c.isArray(data)) {
                _.each(data, function (entry) {
                    if (c.isArray(entry.values)) {
                        _.each(entry.values, function (item) {
                            if (NORMAL_TYPE_ID == type) {
                                results.max = Math.max(item['value_original'], results.max);
                                results.abs_max = Math.max(item['abs_value_original'], results.abs_max);
                            } else {
                                results.max = Math.max(item['left_original'], results.max);
                                results.max = Math.max(item['right_original'], results.max);
                                results.abs_max = Math.max(item['abs_left_original'], results.abs_max);
                                results.abs_max = Math.max(item['abs_right_original'], results.abs_max);
                            }
                        });
                    }
                });
            }
        }

        function normalize(type, results, recalculate) {
            if (c.is(results)) {
                if (recalculate) {
                    results.max = 0;
                    results.abs_max = 0;
                    recalculateMax(results.topData, results, type);
                    recalculateMax(results.userData, results, type);
                }
                results.factor = results.max == 0 ? 1 : 100 / results.max;
                results.abs_factor = results.abs_max == 0 ? 1 : 100 / results.abs_max;
                normalizeData(type, results.topData, results);
                normalizeData(type, results.userData, results);
            }
        }

        function getValue(o, k1, k2) {
            return c.isNumber(o[k1]) ? o[k1] : (c.isString(k2) ? (c.isNumber(o[k2]) ? o[k2] : MISSING_VALUE) : MISSING_VALUE);
        }

        function pushAssociationValues(sources, result, association, type, format) {
            if (c.isString(association.label)) {
                if (c.isArray(association.values)) {
                    _.each(association.values, function (value) {
                        var row = [association.label];
                        // TODO Put NA if specific term value is missing
                        row.push(c.isNull(value.id) ? MISSING : (c.isNull(sources[value.id]) ? value.id
                            : (c.isString(sources[value.id].text) ? sources[value.id].text : MISSING)));
                        if (type == NORMAL_TYPE_ID) {
                          row.push(format(getValue(value, 'value_original', 'value'), 'numeric'));
                          row.push(format(getValue(value, 'abs_value', 'abs_value_original'), 'numeric'));
                          if (_.has(value, 'skew')) {
                            row.push(format(value.skew, 'numeric'));
                          }
                          if(_.has(value, 'share')){
                              row.push(format(value.share,'percent'));
                          }
                        } else {
                          row.push(format(getValue(value, 'left_original', 'left'), 'numeric'));
                          row.push(format(getValue(value, 'abs_left', 'abs_left_original'), 'numeric'));
                          row.push(format(getValue(value, 'right_original', 'right'), 'numeric'));
                          row.push(format(getValue(value, 'abs_right', 'abs_right_original'), 'numeric'));
                        }
                        result.push(row);
                    });
                }
            }
        }

        function v(value) {
            return c.isNumber(value) ? value : 0;
        }

        function validateData(type, key, results, map) {
            if (c.isArray(results[key]) && c.is(map)) {
                _.each(results[key], function (entry) {
                    var values = [];
                    entry.maxStrength = 0;
                    entry.maxConsumption = 0;
                    entry.maxSkew = 0;
                    if (c.is(entry.values)) {
                        var total = _.sum(_.map(entry.values, function(item){return v(item.abs_value)}));
                        var has_share = _.size(entry.values) > 1;
                        _.each(entry.values, function (item, termId) {
                            if (c.isString(termId)) {
                                item.id = termId;
                                var term = map[termId];
                                item.class = (c.is(term) && c.isString(term.class)) ? term.class : DEFAULT_CLASS;
                                if (c.is(term) && c.isString(term.text)) {
                                    item.label = term.text;
                                }
                                item.display = (c.is(term) && c.isString(term.display)) ? term.display : "";
                                if (NORMAL_TYPE_ID == type) {
                                    item.value = v(item.value);
                                    results.max = Math.max(item.value, results.max);
                                    entry.maxStrength = Math.max(item.value, entry.maxStrength);
                                    item.abs_value = v(item.abs_value);
                                    if(has_share) {
                                        item.share = total == 0 ? 0 : item.abs_value / total;
                                    }
                                    results.abs_max = Math.max(item.abs_value, results.abs_max);
                                    entry.maxConsumption = Math.max(item.abs_value, entry.maxConsumption);
                                    entry.maxSkew = Math.max(item.skew, entry.maxSkew);
                                } else {
                                    item.left = v(item.left);
                                    results.max = Math.max(item.left, results.max);
                                    item.right = v(item.right);
                                    results.max = Math.max(item.right, results.max);
                                    item.abs_left = v(item.abs_left);
                                    results.abs_max = Math.max(item.abs_left, results.abs_max);
                                    item.abs_right = v(item.abs_right);
                                    results.abs_max = Math.max(item.abs_right, results.abs_max);
                                    entry.maxStrength = Math.max(item.left + item.right, entry.maxStrength);
                                    entry.maxConsumption = Math.max(item.abs_left + item.abs_right, entry.maxConsumption);
                                }
                                values.push(item);
                            }
                        });
                    }
                    entry.values = values;
                });
            } else {
                results[key] = [];
            }
        }

        function getSortKey(measure) {
          var map = {'relative': 'maxStrength',
                     'absolute': 'maxConsumption',
                     'skew':     'maxSkew'};
          return map[measure] || map[DEFAULT_SORT_KEY];
        }

        function sort(measure, results) {
            var sortKey = getSortKey(measure);
            if (!c.isString(results.sorted) || results.sorted != sortKey) {
                c.sortByNumeric(results.userData, sortKey);
                results.sorted = sortKey;
            }
        }

        function validate(type, measure, results, map) {
            validateData(type, 'topData', results, map);
            validateData(type, 'userData', results, map);
            normalize(type, results);
            sort(measure, results);
        }

        function tweakSGTelcoData(channel,timeframe,geo){
            channel = channel || '';
            if(channel.includes("telco")){
                timeframe = c.getTimeframe(timeframe, false, CHANNEL.daysShift(channel));
            }
            else {
                timeframe = c.getTimeframe(timeframe);
            }

            geo = geoService.geosForChannel(geo, $state, context);
            return [timeframe,geo];
        }

       function getTopAssociationParameters(type, timeframe, terms_ids, keyword, geo, topics, kwd_ids, boolean_logics, channel, sub_geos, audience,user_1st_party_audience) {
            var p = {
                keyword: keyword,
                view: type,
                values_type: 'relative',
                "origins": geoService.objToIdArray(geo),
                "sub_geos": sub_geos,
                "topics": topicsTree.getIds(topics),
                sensitive_content: topicsTree.isSensitive,
                timeframe: timeframe.type,
                timeframe_start: timeframe.start,
                timeframe_end: timeframe.end,
                "boolean_terms": boolean_logics,
                "terms_ids": kwd_ids,
                channel: channel,
                user_1st_party_audience:user_1st_party_audience
            };
           if(audience !== undefined && baseInsightsService.shouldSendAudience(channel, permissions)){
               p["audience"] = _.flatMap(util.removeFullySelectedAudienceSegments(audience, true), 'value');
           }
            if (terms_ids.length > 0) {
                p["compare_to"] = terms_ids;
            }
            return p;
        }

        function getUserAssociationParameters(type, timeframe, terms, references, geo, topics, kwd_ids, boolean_logics, channel, sub_geos, audience, user_1st_party_audience) {
            var booleanReferences = util.getTerms(references, true);
            var references_terms_ids = util.getPhrasesToIdMap(references);

            var referenceIds = _.map(c.rejectBooleanLogics(references), c.getTermId);

            var keywordIds = _.map(terms, c.getTermId);
            var params = {
                  "boolean_references": booleanReferences,
                  "references_terms_ids": references_terms_ids,
                  "references": referenceIds,
                  "keywords": keywordIds,
                  "origins": geoService.objToIdArray(geo),
                  "sub_geos": c.isArray(sub_geos) ? sub_geos : [],
                  "topics": topicsTree.getIds(topics),
                  sensitive_content: topicsTree.isSensitive,
                  view: type,
                  values_type: 'relative',
                  timeframe: timeframe.type,
                  timeframe_start: timeframe.start,
                  timeframe_end: timeframe.end,
                  "boolean_terms": boolean_logics,
                  "terms_ids": kwd_ids,
                  channel: channel,
                  user_1st_party_audience:user_1st_party_audience
            };
            if(audience !== undefined && baseInsightsService.shouldSendAudience(channel, permissions)){
                params["audience"] = _.flatMap(util.removeFullySelectedAudienceSegments(audience, true), 'value');
            }
            return params;
        }

        function setCustomReferences(references) {
            customReferences = c.isArray(references) ? references : [];
        }

        function getCustomReferences() {
            return c.isArray(customReferences) ? customReferences : [];
        }

        function clearCustomReferences() {
            customReferences = [];
        }

        function validateResults(result, type, defer) {
            if (type == NORMAL_TYPE_ID) {
                latestDataNormal = result;
            } else {
                latestDataSplit = result;
            }
            defer.resolve(result);
        }

        function getEmptyTrends(graphData, terms) {
          var insufficient = terms;
          var isEmpty = true;
          _.each(graphData, function(data) {
            _.each(data.values, function (value) {
              if ((value.value && value.value != 0) || (value.left && (value.left !=0 && value.right != 0))) {
                insufficient = _.reject(insufficient, {class: value.class});
              }
            })
          });

          return _.map(insufficient, 'text');
        }

        function augmentMissingLabels(userData, references) {
            var labels = {};
            _.each(references, function(ref) { labels[ref["id"]] = ref["display"] });
            _.each(userData, function(ud) {
                if (ud["label"] === null) {
                    ud["label"] = labels[ud["id"]];
                }
            });
        }

        return {
            getSortKey: getSortKey,
            getUserAssociation: function (term) {
                var defer = $q.defer();
                var result = {
                    validated: [],
                    error: null
                };
                if (c.isString(term)) {
                    var parameters = {
                        "keywords[]": term.split(','),
                        source: 'insights'
                    };
                    baseInsightsService.postAsyncData(ADD_ASSOCIATION_API, parameters).then(function (associations) {
                        _.each(associations.data, function (entry, index) {
                            result.validated.push({
                                id: entry,
                                label: index
                            });
                        });
                        defer.resolve(result);
                    }, function (response) {
                        result.error = c.getServerError(response);
                        defer.resolve(result);
                    });
                } else {
                    defer.resolve(result);
                }
                return defer.promise;
            },
            setCustomReferences: setCustomReferences,
            getCustomReferences: getCustomReferences,
            clearCustomReferences: clearCustomReferences,
            recalculate: function (type, results) {
                normalize(type, results, true);
            },
            getValuesByAssociationTable: function (params, type, measure, format) {
                var result = [], sources = {};
                var terms = c.isArray(params.boolean_logics) ? params.terms.concat(params.boolean_logics) : params.terms;
                var raw = (NORMAL_TYPE_ID == type) ? latestDataNormal : latestDataSplit;

                _.each(terms, function (target) {
                    if (c.isNumber(target.id)) {
                        sources[target.id.toString()] = target;
                    }
                });

                if (c.is(raw)) {
                    var sortKey = getSortKey(measure);
                    c.sortByNumeric(raw.userData, sortKey);
                    if (c.isArray(raw.userData)) _.each(raw.userData, function (association) {
                        pushAssociationValues(sources, result, association, type, format);
                    });
                }
                return result;
            },
            getAssociations: function (type, measure, timeframe, terms, references, geo, topics, kwd_ids, boolean_logics, channel, limit, sub_geos, audience,user_1st_party_audience) {
                var defer = $q.defer();
                var termsMap = {},
                    result = {
                        topData: [],
                        userData: [],
                        factor: 1,
                        max: 0,
                        abs_factor: 1,
                        abs_max: 0,
                        error: null
                    };

                var termIds = [], keyword;

                _.each(terms, function (entry, index) {
                    var entryId = c.getTermId(entry);
                    if (index == 0) {
                        keyword = entryId;
                    } else {
                        termIds.push(entryId);
                    }
                    if (c.is(termsMap)) termsMap[entryId.toString()] = entry;
                });

                _.each(boolean_logics, function (entry) {
                    termsMap[entry.text] = entry;
                });

                var dat = tweakSGTelcoData(channel, timeframe, geo);
                var p1 = getTopAssociationParameters(type, dat[0], termIds, keyword, dat[1], topics, kwd_ids, boolean_logics, channel, sub_geos, audience, user_1st_party_audience);
                var p2 = getUserAssociationParameters(type, dat[0], terms, references, dat[1], topics, kwd_ids, boolean_logics, channel, sub_geos, audience, user_1st_party_audience);
                baseInsightsService.postAsyncData(TOP_ASSOCIATION_API, p1).cancellableThen(function(topData) {
                    result.topData = topData.data.slice(0, limit);
                    if (!_.isEmpty(references)) {
                        console.log(p2);
                        baseInsightsService.postAsyncData(USER_ASSOCIATION_API, p2).cancellableThen(function(userData) {
                            setCustomReferences(references);
                            try {
                                result.userData = userData.data.slice(0, limit);
                                augmentMissingLabels(result.userData,references);
                                validate(type, measure, result, termsMap);
                            } catch (e) {
                                return defer.reject(userData);
                            }
                            defer.resolve(result);
                            validateResults(result, type, defer);
                        }, function(response) {
                            clearCustomReferences();
                            defer.reject(response);
                        });
                    } else {
                        clearCustomReferences();
                        validate(type, measure, result, termsMap);
                        validateResults(result, type, defer);
                    }
                }).catch(function(err) {
                  baseInsightsService.handleError(err).catch(reason => {});
                  return defer.reject(err);
                });
              return defer.promise;
            },

            getUserAssociationsByReference: function (type, measure, references, params, limit) {
                var defer = $q.defer();
                var result = {
                        topData: [],
                        userData: [],
                        factor: 1,
                        max: 0,
                        abs_factor: 1,
                        abs_max: 0,
                        error: null
                    };

                var dat = tweakSGTelcoData(params.channel, params.timeframe, params.geo);
                var p = getUserAssociationParameters(type, dat[0], params.terms, references, dat[1], params.topics,
                    params.kwd_ids, params.boolean_logics, params.channel, params.sub_geos, params.audience,params.user_1st_party_audience);

                var basePromise = baseInsightsService.postAsyncData(USER_ASSOCIATION_API, p);
                defer.promise.cancel = basePromise.cancel;
                basePromise.cancellableThen(function(response) {
                    result.userData = response.data.slice(0, limit);
                    augmentMissingLabels(result.userData, references);
                    var map = {};
                    _.each(params.terms, function (entry, index) {
                        var entryId = c.getTermId(entry);
                        if (c.is(map)) map[entryId.toString()] = entry;
                    });

                    _.each(params.boolean_logics, function (entry) {
                        map[entry.text] = entry;
                    });
                    validateData(type, 'userData', result, map);
                    normalize(type, result);
                    sort(measure, result);
                    validateResults(result, type, defer);
                }).cancellableCatch(function(err) {
                    baseInsightsService.handleError(err).catch(reason => {});
                    return defer.reject(err);
                });

                return defer.promise;
            }
        }
    }
]);
