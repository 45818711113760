"use strict";
"use strict";

module.exports = angular.module(__filename,[])
.factory("examplesDataModel",[function(){
    return {
        examples: {
            consumption: [],
            topics: [],
            sentiment: [],
            association: [],
            articles_consumption: [],
            channels: []
        },
        visible_examples: [],
        terms: [],
        filters: [],
        filter_type: '',
        alphabetized: false,
        visible: false,
        open: false,
        icon: '',
        promise: angular.noop
    }
}]);
