"use strict";
var c = require("infra/utils/common");

var getTimeframeLabel = function (entry, field) {
    return c.getTimeframeLabel(entry, field.format);
};

var getBooleansLoginSummary = function(booleans, field) {
  if (_.isEmpty(booleans)) return undefined;
  var key   = field.pkey || 'text';
  var entries = [];
  var boolNames = [];
  var requires = [bold('Always about:')], includes = [bold('Prefer content about:')], excludes = [bold('Never about:')];
  _.each(booleans, function(bool) {
    var boolName = bool[key];
    boolNames.push(boolName);

    var boolSummary = [];
    requires.push(bool.required.join());
    includes.push(bool.included.join());
    excludes.push(bool.excluded.join());
  });

  return {title: boolNames, data: [requires, includes, excludes]};
};

var NOT_AVAILABLE = 'NA';
// boolean_logics is atteched twice, one time just for the boolean name and one time for the breakdown.

var getParameterSummary = function (p, field) {
    var key = field.pkey || 'text';
    var empty = field.empty || 'All';
    var entry = _.isArray(p) ? _.map(p, key).join(', ') : p[key];

    return entry.length > 0 ? entry : empty;
};

var getExportDate = function (date) {
    return (c.isDate(date) ? date : moment()).format('YYYY-DD-MM (HH:mm)');
};

var bold = function (label) {
    return {value: label, metadata: {style: 1}};
};

var getExportFileName = function (title, date) {
    return title + ' ' + getExportDate(date);
};

var getChannelName = function(key){
    var mapping = {
      'articles': 'All',
      'tweets': 'Social',
      'videos': 'Video',
      'sg_telco': 'SG Telco',
      'au_telco': 'AU Telco'
    };

    return mapping[key] || key || 'All';
};


var DEFAULT_SUMMARY_PARAMETERS = [
  {key: 'timeframe',      label: 'Content From:',    value_f: getTimeframeLabel, format: 'YYYY-MM-DD' },
  {key: 'terms',          label: 'Seeds:',           pkey: 'text',  empty: 'None' },
  {key: 'boolean_logics', label: 'Refined Seeds:',   pkey: 'text',  empty: 'None' },
  {key: 'topics',         label: 'Filtered Topics:', pkey: 'name',  empty: 'All'  },
  {key: 'geo',            label: 'Geo:',             pkey: 'label', empty: 'All'  },
  {key: 'channel',        label: 'Channel:',         value_f: getChannelName, empty: 'All'  },
  {key: 'boolean_logics', label: 'Refined Seeds:', value_f: getBooleansLoginSummary, pkey: 'text',  empty: 'None', attached_at_end: true }
];

var AUDIENCE_SUMMARY_FIELD = {key: 'audience', label: 'Audience:', pkey: _.identity};

module.exports = {
    NOT_AVAILABLE: NOT_AVAILABLE,
    DEFAULT_SUMMARY_PARAMETERS: DEFAULT_SUMMARY_PARAMETERS,
    AUDIENCE_SUMMARY_FIELD: AUDIENCE_SUMMARY_FIELD,

    getExportDate: getExportDate,
    getExportFileName: getExportFileName,
    getBooleansLoginSummary: getBooleansLoginSummary,
    getQuerySummary: function (format, parameters, date, fields) {

        var summary = {name: 'Summary', table: [[format('Generated At:', 'bold'), getExportDate(date)]]};
        _.each(c.isArray(fields) ? fields : DEFAULT_SUMMARY_PARAMETERS, function (field) {
            var entry = parameters[field.key];
            if (c.is(entry) || field.value_f) {
                var value_f = field.value_f || getParameterSummary;
                entry = value_f(entry, field);
            } else {
                return;
            }
            if(_.isObject(entry)) {
              summary.table.push([]); // new line
              summary.table.push([format(field.label, 'bold')].concat(entry.title));
              summary.table = summary.table.concat(entry.data);
            } else if (entry) {
              summary.table.push([format(field.label, 'bold'), entry]);
            } else {
              return;
            }
        });
        summary.columns = [{width: 20}, {width: 40}];
        return summary;
    }
};
