"use strict";
var config = require("infra/config");

module.exports = angular.module(__filename, [])
    .service('topicsTree', ['$http', 'CacheFactory', function($http, CacheFactory) {
        var FULL_TOPICS_LIST_LENGTH;
        var TOPICS_STORAGE_KEY = 'topicsList';
        var SENSITIVE_TOPICS_STORAGE_KEY = 'sensitiveTopicsList';

        var TOPICS_URL = config.TRENDLINE_API + '/all_topics';
        var SENSITIVE_URL = config.TRENDLINE_API + '/sensitive_topics';
        var TOPICS_LIST_EXPIRATION = 60 * 24 * 1 * 60 * 1000; // 1 day
        var MATURE_CONTENT_TOPICS = [1385];

        var cache = CacheFactory('topicsTree', {
          storageMode: 'localStorage',
          maxAge: TOPICS_LIST_EXPIRATION
        });

        var _flatten_topics = [];
        var _current_topics = [];
        var _super_topics = [];
        var sensitive = false;

        /** API **/
        this.get = getTopics;
        this.getAllIds = getAllIds;
        this.getIds = getIds;
        this.getTopicsWithSensitive = getTopicsWithSensitive;
        this.isAllTopics = isAllTopicsSelected;

        Object.defineProperties(this, {
            topics: {
                get: function () {
                    return _current_topics;
                }
            },
            superTopics: {
                get: function () {
                    return _super_topics;
                }
            },
            topicsFlatten: {
                get: function () {
                    return _flatten_topics;
                }
            },
            isSensitive: {
                get: function () {
                    return sensitive;
                }
            }
        });

        function getAllIds() {
            return _.flatten(_.map(_current_topics, function (t) {return _.map(t.children, 'id').concat([t.id])} ));
        }

        function getIds(array) {
            return isAllTopicsSelected(array) ? [] : _.map(array, 'id');
        }

        function isAllTopicsSelected(array) {
            return (array || []).length == FULL_TOPICS_LIST_LENGTH;
        }

        function getTopics() {
            return loadTopics(TOPICS_URL, TOPICS_STORAGE_KEY).then(function (topics) {
                _current_topics = topics;
                _super_topics = _.mapPick(_current_topics, ['id', 'name']);
                sensitive = false;
                var flatTopic = flattenTopicTree(topics);
                angular.copy(flatTopic, _flatten_topics);
                FULL_TOPICS_LIST_LENGTH = flatTopic.length;
                return _current_topics;
            });
        }

        function getTopicsWithSensitive() {
            return loadTopics(SENSITIVE_URL, SENSITIVE_TOPICS_STORAGE_KEY).then(function (sensitiveTopics) {
                return getTopics().then(function (topics) {
                    sensitiveTopics = filterMatureContentTopics(sensitiveTopics);
                    _current_topics = topics.concat(sensitiveTopics);
                    _super_topics = _.mapPick(_current_topics, ['id', 'name']);
                    sensitive = true;
                    var flatTopic = flattenTopicTree(_current_topics);
                    angular.copy(flatTopic, _flatten_topics);
                    FULL_TOPICS_LIST_LENGTH = flatTopic.length;
                    return _current_topics;
                });
            })
        }

        function loadTopics(url, storageKey) {
            var localTopics = cache.get(storageKey);
            var promise = Promise.resolve(localTopics);
            if (!localTopics) {
                promise = $http.get(url).then(function(response) {
                    return cache.put(storageKey, response.data);
                });
            }

            return promise;
        }

        function flattenTopicTree(topics) {
            var children = _.map(topics, 'children');
            return topics.concat(_.flatten(children));
        }

        // currently only filter out the topic children if the MATURE_CONTENT_TOPICS array contains one of the id's
        function filterMatureContentTopics(topics) {
            return _.map(topics, function (t) {
                return _.extend(_.omit(t, 'children'), {
                    children: _.reject(t.children, function (c) {
                        return _.includes(MATURE_CONTENT_TOPICS, c.id)
                    })
                });
            });
        }
    }]
);
