"use strict";
var angular = require("angular"),
    config  = require("infra/config"),
    c       = require("infra/utils/common");

var TIMING_API = config.INSIGHTS_API + '/insights/timing';

module.exports = angular.module(__filename, [])
  .service("timingService", ['$q', 'baseInsightsService', function($q, baseInsightsService) {

    var timing = {};
    var NUM_BUCKETS = 7;
    var colors = _.range(1, NUM_BUCKETS + 1);

    return {
      get: getTiming,
      getTimingForSeed: getTimingForSeed
    };

    function getJenksSegments(domain, range) {
      function initializeMatrix(rows, cols, initializeFirstRowVals) {
        var mat = [];
        for (var i = 0; i < rows; i++) {
          mat[i] = new Array(cols).fill(Infinity);
        }
        for (var row = 0; row < mat.length; row++) {
          mat[row][0] = 0;
        }
        mat[0].fill(initializeFirstRowVals);
        mat[0][0] = 0;

        return mat;
      }

      var nbClass = range.length;

      if (domain.length === 0) {
        return function() { return range[0];};
      }

      var dataList = domain.sort(function(a,b) { return a - b; });

      var mat1 = initializeMatrix(dataList.length + 1, nbClass + 1, 1);
      var mat2 = initializeMatrix(dataList.length + 1, nbClass + 1, 0);

      var v = 0.0;

      // and this part - I'm a little clueless on - but it works
      // pretty sure it iterates across the entire dataset and compares each
      // value to
      // one another to and adjust the indices until you meet the rules:
      // minimum deviation
      // within a class and maximum separation between classes
      for ( var l = 2, ll = dataList.length + 1; l < ll; l++) {
        var s1 = 0.0;
        var s2 = 0.0;
        var w = 0.0;
        for ( var m = 1, ml = l + 1; m < ml; m++) {
          var i3 = l - m + 1;
          var val = parseFloat(dataList[i3 - 1]);
          s2 += val * val;
          s1 += val;
          w += 1;
          v = s2 - (s1 * s1) / w;
          var i4 = i3 - 1;
          if (i4 != 0) {
            for ( var p = 2, pl = nbClass + 1; p < pl; p++) {
              if (mat2[l][p] >= (v + mat2[i4][p - 1])) {
                mat1[l][p] = i3;
                mat2[l][p] = v + mat2[i4][p - 1];
              }
            }
          }
        }
        mat1[l][1] = 1;
        mat2[l][1] = v;
      }

      var k = dataList.length;
      var kclass = [];

      // fill the kclass (classification) array with zeros:
      for (var i = 0; i < nbClass; i++) {
        kclass.push(0);
      }

      // this is the last number in the array:
      kclass[nbClass] = dataList[dataList.length - 1];
      // this is the first number - can set to zero, but want to set to lowest
      // to use for legend:
      kclass[0] = dataList[0];
      var countNum = nbClass;
      while (countNum >= 2) {
        var id = (mat1[k][countNum]) - 2;
        kclass[countNum - 1] = dataList[id];
        k = (mat1[k][countNum] - 1);
        // spits out the rank and value of the break values:
        // console.log("id="+id,"rank = " + String(mat1[k][countNum]),"val =
        // " + String(dataList[id]))
        // count down:
        countNum -= 1;
      }
      // check to see if the 0 and 1 in the array are the same - if so, set 0
      // to 0:
      if (kclass[0] == kclass[1]) {
        kclass[0] = 0
      }

      var segments = kclass.slice(1);

      return function(value) {
        var currSeg = 0;
        while (value > segments[currSeg]) {
          currSeg++;
        }

        return range[currSeg];
      };
    }

    function getQuantileScale(domain, range){
      return d3.scale.quantile()
             .domain([d3.min(domain), d3.max(domain)])
             .range(range);
    }

    function getTiming(params) {
      var termMap = {};
      var parameters = baseInsightsService.buildParams(termMap, params, 1);

      delete parameters.audience;
      if (_.isEmpty(termMap)) return Promise.reject("No Terms");

      return baseInsightsService.postAsyncData(TIMING_API, parameters).then(function (response) {
        timing = buildHeatmapResults(response, termMap);
        return timing;
      }).catch(baseInsightsService.handleError);
    }

    function getNormalizedRecord(entry) {
      var sum_all_values = _.reduce(entry['days_hours'], function(memo,rec){ return memo + rec[1];}, 0);
      if(sum_all_values == 0){
          return null;
      }
      var result = {'days_hours' : [], 'day_totals' : [], 'hour_totals': []};
      //Normalizing all values to percentage of all data
      var day_totals_color_scale = angular.noop;
      _.each(['days_hours','day_totals','hour_totals'],function(field){
        result[field] = _.map(entry[field],function(rec){return [rec[0], parseFloat((rec[1] * 100.0 / sum_all_values).toFixed(2))]});
        var values = _.map(result[field], _.last);
        var color_scale = angular.noop;
        if('day_totals' === field){
          color_scale = getQuantileScale(values, colors);
          day_totals_color_scale = color_scale;
        } else {
          color_scale = getJenksSegments(values, colors);
        }
        result[field + '_colors'] = _.map(result[field], function (rec) {
          return [rec[0], color_scale(rec[1])]
        });
      });
      var day_totals = _.map(_.sortBy(result.day_totals, _.first), _.last);
      result.weekday_total = c.average(day_totals.slice(0,5));
      result.weekend_total = c.average(day_totals.slice(5));
      result.weekday_total_color = day_totals_color_scale(result.weekday_total);
      result.weekend_total_color = day_totals_color_scale(result.weekend_total);
      return result;
    }

    function buildHeatmapResults(response, termMap){
      var seed_data = [], foundTerms = {}, result = {};
      _.each(response.data, function (entry, trendName) {
        var term = termMap[trendName] ||
                   _.find(termMap, function(rec){return rec['text'].toLowerCase() == trendName}) ||
                   _.find(termMap, ['id', parseInt(trendName)]);
        if (c.isString(trendName) && c.is(term) && c.isArray(entry['day_totals']) && c.isArray(entry['days_hours']) && c.isArray(entry['hour_totals'])) {
          var record = getNormalizedRecord(entry);
          if(record !== null) {
            foundTerms[term.text] = term;
            record.term = term;
            seed_data.push(record);
          }
        }
      });
      var insufficient = isResponseDataEmpty(seed_data, _.difference(Object.keys(termMap),Object.keys(foundTerms)));
      baseInsightsService.notifyInsufficient(insufficient);
      if(insufficient.length == Object.keys(termMap).length){
        return Promise.reject({});
      }
      result.seeds = seed_data;
      result.average_hours = _.map(_.zip.apply([], _.map(seed_data,function(rec){return rec.hour_totals})), function(values){
        return c.average(_.map(values, _.last))
      });
      result.average_days = _.map(_.zip.apply([], _.map(seed_data,function(rec){return rec.day_totals})), function(values){
        return c.average(_.map(values, _.last))
      });
      result.average_weekday = c.average(_.map(seed_data, function(rec){return rec.weekday_total}));
      result.average_weekend = c.average(_.map(seed_data, function(rec){return rec.weekend_total}));
      var average_hours = [];
      angular.copy(result.average_hours, average_hours);
      var hours_color_scale = getJenksSegments(average_hours, colors);
      result.average_hours_colors = _.map(result.average_hours,function(average){return hours_color_scale(average)});
      var days_color_scale = getQuantileScale(result.average_days, colors);
      result.average_days_colors = _.map(result.average_days,function(average){return days_color_scale(average)});
      result.average_weekday_color = days_color_scale(result.average_weekday);
      result.average_weekend_color = days_color_scale(result.average_weekend);
      return result;
    }

    function isResponseDataEmpty(data, notFound) {
      var insufficient = notFound;
      _.each(data, function(record) {
        if (!record['days_hours'] || !_.some(record['days_hours'],function(el){return el[1] > 0})){
          insufficient.push(record.term.display);
        }
      });

      return insufficient;
    }

    function getTimingForSeed(term){
      return _.find(timing.seeds, function(record){return (term.id == -1 && record.term.text == term.text) || (term.id != -1 && record.term.id == term.id)});
    }
}]);
