"use strict";
"use strict";

module.exports = angular.module(__filename, []).service("BooleanMold", function () {
    function BooleanMold() {
        var me = this;
        me._value = false;
        me.default = false;

        me.replace = function (val) {
            return me._value = !!val || me.default;
        }.bind(this);
    }

    return BooleanMold;
});