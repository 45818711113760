"use strict";
const BaseWidget = require("../base_widget");
const common = require("infra/utils/common");
const mixpanel = require("../../infra/mixpanel/mixpanel-audience");


AudiencePreviewController.$inject = ['audienceInsightsService', 'filtersPartition', 'util', 'context', 'mixpanelAudience', '$timeout'];

function AudiencePreviewController(audienceInsightsService, filtersPartition, util, context, mixpanelAudience, $timeout) {
    this.audienceInsightsService = audienceInsightsService;
    this.infraUtil = util;
    this.mixpanelAudience = mixpanelAudience;
    this.context = context;
    const $scope = this.$scope;

    this.all_ages = filtersPartition.age.map(a => a.summary);
    this.$scope.options = {
        xAxisColor: "#3e4347", barWidth: 13, labelFont: "12px Helvetica", valueFont: "12px Helvetica",
        highlightedBarColor: "white", highlightedLabelColor: "white", highlightedLabelFont: "12px Helvetica", highlightedValueColor: "white",
        highlightedValueFont: "13px Helvetica"
    };
    $scope.debugUser = $scope.$root.user.userType === 'debug';

    $scope.prepExploreAudience = function() {
        context.current.audience_segment_target_ratio = null;
        mixpanelAudience.trackExploreAudience();
    };
    $scope.prepExploreAudienceWithTargetRatio = function(targetRatioPercentage) {
        context.current.audience_segment_target_ratio = targetRatioPercentage / 100.0;
        mixpanelAudience.trackExploreAudience();
    }
}

function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

AudiencePreviewController.prototype._doUpdate = function() {

    if(this.promise) this.promise.cancel();
    const $scope = this.$scope;
    $scope.has_data = false;
    $scope.too_wide = false;
    $scope.too_narrow = false;
    $scope.error = false;
    if(_.isEmpty($scope.audienceSegment)) {
        $scope.summary = "All Population";
        return;
    }
    this.promise = this.audienceInsightsService.getDemographicsDataForPreviewBySegment($scope.audienceSegment);

    this.promise.then(data => {
            if(data) {
                if(this.audienceInsightsService.isRequestCancelled(data)) return false; // cancel
                switch(data.status) {
                    case 'ok':
                        $scope.has_data = true;
                        $scope.age = data.distribution.age;
                        $scope.gender = data.distribution.gender;
                        $scope.population = data.audienceSize.population;
                        break;
                    case 'error':
                        switch(data.error.reason) {
                            case "selectionTooNarrow":
                                const snapshot = angular.copy(this.context.current);
                                this.mixpanelAudience.trackTooNarrow(snapshot);
                                $scope.too_narrow = true;
                                break;
                            case "selectionTooWide":
                                $scope.too_wide = true;
                                break;
                        }
                        break;
                }

            } else { // no data
                $scope.error = true;
            }
        }
    );

    return this.promise;
};

module.exports = angular.module(__filename, [
    require("../../data/audience-insights-service").name,
    mixpanel.name,
]).directive("audiencePreviewWidget", [() => BaseWidget({
    restrict: "E",
    template: require("./audience-preview-widget.html"),
    scope: {
        audienceSegment: "=",
        forceUpdate: "="
    },
    controller: AudiencePreviewController
})])
    .filter("trusted_html", ['$sce', ($sce) => (htmlCode) => $sce.trustAsHtml(htmlCode)]);
