"use strict";
"use strict";
 
dynamicTrendsCtrl.$inject = ['$scope', '$q', '$sce', 'filtersPartition', '$timeout', 'parentScope', 'geoService', 'close'];
function dynamicTrendsCtrl($scope, $q , $sce, filtersPartition, $timeout, parentScope, geoService , close) {

    $scope.editTargetCtrl = parentScope.editTargetCtrl;
    $scope.query = $.extend(true, {}, parentScope.queries.dynamic);

    
    //creatre hidden tree for summary
    var checkedTopics = ($scope.editTargetCtrl.currentTarget.query.topics||[]).slice(0);
    checkedTopics = checkedTopics.map(function(t){return {id: t}});

    $scope.dataTrees = { 
        'topicsSummary': $.extend(true, {} , 
            {children: $scope.$root.topicsFilter.slice(0), 
             checkedArray: checkedTopics,
             allSelectedLabel: "All Topics"
            })
    };

    $scope.treeLoaded = true;

    $scope.init = function () {

        var target = $scope.editTargetCtrl.currentTarget;
        
        $timeout(function(){
            $scope.query.show(parentScope.queries.dynamic.dataArray);
            $scope.dataTrees.topicsSummary.show();
            $scope.targetInfo = {
                seeds: target.seeds.map(function(seed){return seed.text}),
                timeframe: getTimeframe(target.query),
                channel: target.channel,  
                demographics: $sce.trustAsHtml(getAudience(target.query.audience)),
                topics: $scope.dataTrees.topicsSummary.summaryStr,
                geos: geoService.genSummaryByIds(target.query.geos)   
            }

        });
    };

    function getAudience(aud){
        if(!aud || !aud.length){
            return 'all Audience';
        }

        aud = aud.map(function(seg){return getAudienceSegment(seg)});
        return aud.join(" , ");
    }

    function getAudienceSegment(seg){
        var i , j , filter , len;
        for(i in filtersPartition){
            filter = filtersPartition[i], len = filter.length;
            for(j=0;j<len;j++){
                if(filter[j].value==seg){
                    return i+": "+ '<span class="info-sub">'+filter[j].label+'</span>';
                }
            }
        }
        return '';
    }

    function getTimeframe(query){
       
        //timeframe set in targets creation, like "1 day"

        if(query.timeframe && query.timeframe != "custom"){
            return query.timeframe;
        }

        return moment(query.timeframe_start).format("MMM Do, YYYY") + 
            " - " + 
            moment(query.timeframe_end).format("MMM Do, YYYY");
    }

    parentScope.$on('updateDynamicTrends', function (event, obj) {
        $scope.init();
    });

    $scope.close = function () {
        $scope.editTargetCtrl.dynamicTrendsOpened = false;
        close({});
    };

    $scope.init();
}

module.exports = dynamicTrendsCtrl;
 
