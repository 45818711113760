"use strict";

var realtime = require('pages/warroom-realtime/warroom-realtime')
   ,groups = require('pages/warroom-groups/warroom-groups')
   ,programTheme = require('pages/programs/program-theme/program-theme')
   ,trending = require('pages/warroom-trending/warroom-trending')
   ,BaseWidget = require("widgets/base_widget")
   ,warVars = require("pages/warroom/warroom-vars")
   ,mixPanel = require("../../infra/mixpanel/mixpanel-base");

var children = [
    realtime.stateConfig,
    groups.stateConfig,
    trending.stateConfig
  ];


module.exports = angular.module(__filename, [
    realtime.name, mixPanel.name,
  ])
  .directive("warroomWidget", ["$window", function ($window) {
    return BaseWidget({
      restrict: "E",
      template: require('./warroom.html'),
      scope: false,
      controller: warroomController
    });

}])

.filter('unique', function() {
   return function(collection, keyname) {
      var output = [],
          keys = [];

      angular.forEach(collection, function(item) {
          var key = item[keyname];
          if(keys.indexOf(key) === -1) {
              keys.push(key);
              output.push(item);
          }
      });

      return output;
   };

  })

.filter('range', function() {
  return function(input, total) {
    total = parseInt(total);
    for (var i=0; i<total; i++){
      input.push(i);
    }
    return input;
  };
});

module.exports.stateConfig = {
  children: children,
    name:'warroom',
    url:'/warroom',
    display: 'War Room',
    template: '<warroom-widget></warroom-widget>',
    context: {
        warroom_timeframe: "TimeframeMold"
    }
}


warroomController.$inject = ["$scope", "$state", "$http", "context", "util", "user", "geoService", "abiPermissions", "WARROOM_GEOS", "notificator"];

warroomController.prototype._doUpdate = function (newVals, oldVals) { };

function warroomController($scope, $state, $http, context, util, user, geoService, abiPermissions, WARROOM_GEOS, notificator) {

  $scope.context = context;
  $scope.children = children;
  $scope.timeframe = context.current.warroom_timeframe;
  $scope.resizeTimer = null;
  $scope.p_id = context.program.id;

  $scope.openedGroups = {};

  $scope.geos = [{label: 'All geos', id: 'all', cc: 'all'}].concat(geoService.geos);

  if(abiPermissions.hasPermission(['warroom extra geos'])){
    $scope.geos = $scope.geos.concat(WARROOM_GEOS.geos);
  }

  $scope.geoDefault = $scope.geos[0];
  $scope.geo = $scope.geoDefault;
  warVars.geo = $scope.geo.id;

  $scope.init = function(){
    $scope.checkTimeframe();
    $scope.resize();

    var savedGeo = localStorage["warroom_geo"];
    if(savedGeo && savedGeo != null && savedGeo != "null")
    $scope.geo = JSON.parse(savedGeo);

    $scope.setGeo();

    $scope.programTheme = programTheme;
    programTheme.init($scope, $http, user, context, notificator);

    $scope.selectTab($scope.selected);
  }

  $scope.checkTimeframe = function(){

    //no timeframe => use insights
    if(!$scope.timeframe[0] || !$scope.timeframe[1]){
      $scope.timeframe = context.current.insights_timeframe;
    }

    //no valid dates => last 30 minutes
    if(($scope.timeframe[0]+'').indexOf("_")==-1 || ($scope.timeframe[1]+'').indexOf("_")==-1 ||
        $scope.timeframe[0] == $scope.timeframe[1]){

      $scope.timeframe[0] = moment().add(-30, "minutes").format("DD_MM_YY_HH_mm");
      $scope.timeframe[1] = moment().format("DD_MM_YY_HH_mm");

      localStorage["warroom_mode"] = 'realtime';
      localStorage["warroom_realtime"] = 'true';
    }
  }

  $scope.hasTabPermission = function(permissionName, needPermission){
    return needPermission? abiPermissions.hasPermission([permissionName]) : true;
  }

  $scope.setGeo = function(){
    //warroom only supports 1 geo
    localStorage["warroom_geo"] = JSON.stringify($scope.geo);
    warVars.geo = $scope.geo.id;
  }

  $scope.clickGeo = function(){
    var geoChanged = localStorage["warroom_geo"] != JSON.stringify($scope.geo);
    $scope.setGeo();
    if(geoChanged){
      $scope.$broadcast('geo');
    }
  }

  $scope.resetFilters = function(){
    $scope.geo = $scope.geoDefault;
    $scope.clickGeo();
  }

  var changeListener = context.onChange(function(val){
    //dont send 'update' when changing timeframe. apply button handles that.

    var programChange = context.program.id != $scope.p_id;

      var dateChange = (val.warroom_timeframe[0] != $scope.timeframe[0] ||
        val.warroom_timeframe[1] != $scope.timeframe[1]) && !programChange;

    $scope.p_id = context.program.id;
    $scope.timeframe = val.warroom_timeframe;
    $scope.checkTimeframe();

    programTheme.getTheme();

    if(dateChange){
      $scope.$broadcast('date_change');
    }else if(programChange){
      $scope.$broadcast('update');
    }

  });

  this.$scope.$on('$destroy', () => {
    changeListener();
  });

  this.onResize = function (values) {
    clearTimeout($scope.resizeTimer);
    $scope.resizeTimer = setTimeout(function(){
      $scope.resize();
      $scope.$broadcast('resize');
    },5);
  };

  $scope.resize = function(){

    if(warVars.filtersSmall()){
      $("warroom-widget side-filters-menu").css('width', warVars.filtersWidth[0]);
    }else{
      $("warroom-widget side-filters-menu").css('width', warVars.filtersWidth[1]);
    }
  }

  $scope.selectTab = function(tab){

    tab = tab || (localStorage['warroom'] || $scope.children[0].name);

    localStorage['warroom'] = tab;
    $scope.selected = tab;
    $scope.selectedTabName = tab.substring(tab.indexOf(".")+1);
    $state.go(tab);
  }

  $scope.maxMin = function(maxed, $event){

    //to use:
    //1. <a ng-click="resize('box_name', $event)" class="max_min" ng-class="{max : maxed=='box_name'}"></a>
    //2. resize should call maxMin

    $(".max_min").attr("title", "maximize / minimize");

    if(maxed){
      $scope.maxed = $scope.maxed != maxed ? maxed: null;
    }

    return $scope.maxed;
  }

  $scope.clickGroupArrow = function(group, open){
    if(!group.more){
      group.more = {status:false};
    }
    group.more.status=open;
    group.open = open;
    $scope.openedGroups[group.id]=group.more;
  }

    $scope.phraseTooltip = function (phrase) {
        var str = '';
        if (phrase.required) {
            str += "Always about: &nbsp;";
            phrase.required.forEach(function (item, i) {
                item.display = util.getTermDisplay(item);
                str += item.display + ', ';
            });
            str = str.substring(0, str.length - 2);
            str += '<br>';
        }

        if (phrase.included) {
            str += "Prefer content: ";
            phrase.included.forEach(function (item, i) {
                item.display = util.getTermDisplay(item);
                str += item.display + ', ';
            });
            str = str.substring(0, str.length - 2);
            str += '<br>';
        }

        if (phrase.excluded) {
            str += "Never about: &nbsp; &nbsp;";
            phrase.excluded.forEach(function (item, i) {
                item.display = util.getTermDisplay(item);
                str += item.display + ', ';
            });
            str = str.substring(0, str.length - 2);
            str += '<br>';
        }

        return str;
    };

  $scope.phraseId = function(phrase){

    if(phrase.isHashtag || phrase.type=="hashTag"){
      return "__"+phrase.text.replace("#","");
    }
    else{
      return phrase.id;
    }
  }

  $scope.phrasesToBackend = function(phrases){

    var phraseFields = ["text", "name", "id", "class", "invalid", "type", "origin", "display"];

    var phrases = phrases.slice(0).map(function(phrase) {
        for(var i in phrase){
          if(-1 == phraseFields.indexOf(i)){
            delete phrase[i];
          }
        }

        return phrase;
      });

    return phrases;
  }

  $scope.init();
}
