"use strict";
module.exports = angular.module(__filename, []).controller('programInterestsController', ['$scope', 'programBooleanLogicService', 'util', 'defineInterestModal',
    function ($scope, pblService, util, defineInterestModal) {
        $scope.deleteBooleanLogic = deleteBooleanLogic;
        $scope.editBooleanLogic = editBooleanLogic;

        $scope.query = {
            dataArray: [],
            columns: [
                {
                    id: 'display',
                    title: 'interest name',
                    template: 'partials/interest-name.partial.html'
                },
                {
                    id: 'u_name',
                    title: 'creator'
                },
                {
                    id: 'created_at',
                    title: 'creation date',
                    template: 'partials/interest-date.partial.html'
                },
                {
                    id: 'actions',
                    title: '',
                    template: 'partials/interest-actions.partial.html',
                    sort: false
                }
            ],
            fixedSize: true,
            order: '',
            reverse: false
        };

        function populateBooleanLogics(view) {
            if (view && view != 'program interests') return;

            pblService.list($scope.program.id).then(function (data) {
                util.upgradeTerms(data);
                $scope.query.show(data);
                $scope.boolean_logics = data;
            });
        }

        $scope.$watch('selectedView', populateBooleanLogics);

        function deleteBooleanLogic(bl) {
            defineInterestModal.deleteBooleanLogic(bl, $scope.program.name)
            .then(res => populateBooleanLogics())
        }

        function executeDelete(bl) {
            pblService.delete(bl.id, $scope.program.id).then(function () {
                $scope.notificator.success({body: (bl.display || bl.text) + ' was removed from program ' + $scope.program.name});
                populateBooleanLogics();
            });
        }

        function editBooleanLogic(bl) {
            var term = angular.copy(bl);
            term.display = bl.display;
            $scope.$parent.refineInterestOpened = true;
            defineInterestModal.showModal([], term, 'Refine interest', false, $scope.program).then(function (modal) {
                modal.close.then(function (interest) {
                    $scope.$parent.refineInterestOpened = false;
                    if (interest) {
                        populateBooleanLogics();
                    }
                });
            });
        }
    }
]);
