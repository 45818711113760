"use strict";
/* jquery sparkline directive 
 to use:
 1. scope needs row with stats [{total:x},{total:y}]
 2. and clickSparkline (func)
 3. <jq-sparkline ng-model="row.totals" values="{{row.stats}}"></jq-sparkline>
 */

module.exports = angular.module(__filename, []).directive("jqSparkline", [function () {
    return {
        restrict: 'E',
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
            var timeout = null, vals, opts;

            function drawSparkline() {
                if (attrs.values && attrs.opts) {
                    vals = JSON.parse(attrs.values).map(function (cell) {
                        return cell.total;
                    });

                    opts = attrs.opts ? JSON.parse(attrs.opts) : {};
                    opts.chartRangeMin = opts.chartRangeMin || 0;

                    opts.tooltipFormatter = scope.tooltipFormatter; // does nothing if 'tooltipFormatter' function not exists in scope

                    setTimeout(function () {
                        $(elem).unbind('sparklineClick').empty().sparkline(vals, opts).bind('sparklineClick', function (ev) {
                            if (timeout !== null) {
                                return;
                            }

                            timeout = setTimeout(function () {
                                //sparkline fire multi clicks, so we only send one
                                var sparkline = ev.sparklines[0],
                                    region = sparkline.getCurrentRegionFields();
                                if (0 == region.y) {
                                    scope.error({body: 'no tweets for this point'});
                                } else {
                                    var index = Math.round(region.x / vals.length * ngModel.$viewValue.stats.length);
                                    scope.clickSparkline(ngModel.$viewValue, index, region.y);
                                }
                                timeout = null;
                            }, 100);
                        });
                    }, 0);
                }
            }

            attrs.$observe("values", drawSparkline);
            attrs.$observe("opts", drawSparkline);
        }
    }
}]);
