"use strict";

module.exports = {

	setFromTo: function(scope, showGroups){

      scope.checkTimeframe();
      scope.time.from = this.timeToServer(scope, scope.timeframe[0]);
      scope.time.to   = this.timeToServer(scope, scope.timeframe[1]);
    
      if(showGroups && !this.validDate(scope)){
        return;
      }

      scope.time.minutes = (new Date(scope.time.to)-new Date(scope.time.from))/1000/60;
      scope.selected.date = null;

      if(showGroups){
        scope.getGroups({keep:true, clicked:true});
      }

    },

    validDate: function(scope){

      var from  = Date.parse(scope.time.from),
          to    = Date.parse(scope.time.to), 
          error = '';

          var diff = to-from, day = 24*60*60*1000;

          if(diff<=0){
          	error = "'date-from' should be larger than 'date-to'";
          }
          else if(diff > 7*day){
            	error = 'maximum date span is one week';
          }

          if(error){
            scope.error( { body: error } );
          }

          return error == '';   
    },


    timeToServer: function(scope, time){
      //27_11_15_12_34 => 2015-11-27T12:34 + timezone

      var minutes = - 1*scope.timezone / 60000;
      return moment(time,"DD_MM_YY_HH_mm").add(minutes, "minutes").format("YYYY-MM-DDTHH:mm");
    }

}