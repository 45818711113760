"use strict";
const topbarTmpl = require("./topbar.html"),
    timeFrameFilter = require("./timeframe/timeframe"),
    contextModule = require("infra/context/context"),
    mixpanel = require("infra/mixpanel/mixpanel-base"),
    uiRouter = require("angular-ui-router");

module.exports = angular.module(__filename, [
    timeFrameFilter.name,
    contextModule.name,
    uiRouter.name,
    require("data/discovery-service").name
]).directive("topbar", ["context", "$state", '$rootScope', 'abiPermissions', 'mixpanelService',
    function(context, $state, $rootScope, abiPermissions, mixpanelService) {
        return {
            restrict: "E",
            template: topbarTmpl,
            link: function(scope) {
                scope.context = context;
                scope.$state = $state;

                //scope.trackExport = mixpanelService.trackExport;

                scope.hasActiveFilter = function() {
                    const state = $state.current;
                    const display = state.display.toLowerCase();
                    return (['dashboard', 'discovery', 'insights'].includes(display) || state.name.startsWith('warroom')) && $rootScope.hasActiveFilter(state.name);
                };

                scope.checkChangeProgram = function(e) {
                    //block update when warroom loads
                    if(scope.isWarroomRealtime() && $rootScope.warroomRealtime.loading) {
                        e.stopPropagation();
                        e.preventDefault();
                        e.stopImmediatePropagation();
                    }
                };

                scope.clickApply = function() {
                    scope.$broadcast('click_apply');
                };

                scope.toggleTimeMode = function() {
                    scope.$broadcast('toggle_time_mode');
                };

                scope.setTimeframe = function() {
                    scope.$broadcast('set_time_frame');
                };

                scope.setTimezone = function() {
                    scope.$broadcast('set_time_zone');
                };

                scope.isWarroomRealtime = function() {
                    return $state.current.name.toLowerCase().includes('warroom.realtime');
                };

                scope.trackHelpCenter = function() {
                    mixpanelService.trackHelpCenter($rootScope.helpPage);
                };

                scope.hasPermission = abiPermissions.hasPermission;

                scope.hideFiltersMenu = function() {
                    return $state.current.name.startsWith('audience-');
                };

                scope.hideHelp = function() {
                    const state = $state.current;
                    return !scope.hasPermission(['help center'])
                           || state.name.startsWith('audience-')
                           || (state.name.startsWith('settings.') && !$state.is('settings.programs'))
                           || $state.is('insights.landscape')
                           || $state.is('insights.channels');
                };

                scope.hideExport = function() {
                    const state = $state.current;
                    return state.display.toLowerCase() === 'dashboard'
                           || (state.name.startsWith('settings.') && state.name !== 'settings.users' && state.name !== 'settings.targets')
                           || state.name.indexOf('warroom') != -1
                           || state.name === 'audience-builder'
                           || (!scope.hasPermission(['export']) && (state.name != 'insights.consumption' && state.name != 'discovery.bubbles'));
                };

                scope.hideTimeframe = function() {
                    const state = $state.current;
                    return state.display.toLowerCase() === 'dashboard'
                           || state.name.startsWith('audience-')
                           || (state.name.toLowerCase() === 'warroom.realtime' && $rootScope.warroomRealtime.mode == 'realtime');
                };

                scope.showDatePicker = function() {
                    const state = $state.current;
                    return (state.display.toLowerCase() === 'discovery' && scope.hasPermission(['discovery custom timeframe']))
                           || state.display.toLowerCase() === 'insights'
                           || state.display.toLowerCase() === 'real time'
                           || state.name.toLowerCase() === 'warroom.realtime';
                };
            }
        }
    }
]);
