"use strict";
module.exports = angular.module(__filename, [])
    .service('audienceTableStructure', [function () {
        let tableStructure = {};
        const base = {
            dataArray: [],
            columns: [
                {
                    id: 'phrase',
                    title: 'interest',
                    alwaysShown: true
                },
                {
                    id: 'interest-portion',
                    title: 'consumption in audience',
                    template: 'partials/interest-portion.partial.html',
                    tooltip: 'How interested is the audience in the phrase'
                },
                {
                    id: 'segment-portion',
                    title: 'audience portion in consumption',
                    template: 'partials/segment-portion.partial.html',
                    tooltip: 'How unique is this interest to the audience',
                    flex: '1-5'
                },
                {
                    id: 'uniqueness-index',
                    title: 'audience skew',
                    template: 'partials/composition-index.partial.html',
                    tooltip: 'The extent to which the interest is over-indexed within the audience',
                    flex: '0-7'
                },
                {
                    id: 'score',
                    title: 'significance score',
                    template: 'partials/segnificance-score.partial.html',
                    tooltip: 'The extent to which the result is unlikely to be random',
                    flex: '0-7'
                },
                {
                    id: 'topic',
                    title: 'topic',
                    flex: '0-7'
                }
            ],
            order: 'interest-portion',
            reverse: true
        };

        tableStructure.web = _.cloneDeep(base);
        tableStructure.web.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the phrase';
        tableStructure.web.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the phrase';

        tableStructure.domain = _.cloneDeep(base);
        tableStructure.domain.columns[0].title = 'website';
        tableStructure.domain.columns[0].template = 'partials/audience-domain.partial.html';
        tableStructure.domain.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience visits the website';
        tableStructure.domain.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience visits the website';

        tableStructure.searches = _.cloneDeep(base);
        tableStructure.searches.columns[0].title = 'search term';
        tableStructure.searches.columns[1].tooltip = 'How popular the interest is among the audience. 100% means everyone in the audience consumes the phrase';
        tableStructure.searches.columns[2].tooltip = 'How unique the interest is to the audience. 100% means only this audience consumes the phrase';

        tableStructure.sports = _.cloneDeep(base);
        tableStructure.keywords = _.cloneDeep(base);

        tableStructure.interests = _.cloneDeep(base);
        tableStructure.interests.order = 'interest-portion';

        tableStructure.tv = _.cloneDeep(base);
        tableStructure.tv.columns[0] = { id: 'title', title: 'TV Shows', ellipsis: 'true', flex: '0-8'};
        tableStructure.tv.columns[1].tooltip = 'How interested is the audience in the TV show';
        tableStructure.tv.columns[2].tooltip = 'How unique is this TV show to the audience';
        tableStructure.tv.columns[2].flex = '1-2';
        tableStructure.tv.columns[3].tooltip = 'The extent to which the TV show is over-indexed within the audience';
        tableStructure.tv.columns[3].flex = '0-7';
        tableStructure.tv.columns[4].flex = '0-7';
        tableStructure.tv.columns[5] = { id: 'genre', title: 'Genre', flex: '0-3', ellipsis: 'true', tooltip: ''};
        tableStructure.tv.columns[6] = { id: 'network', title: 'Network', flex: '0-4', ellipsis: 'true', tooltip: ''};

        this.tableStructure = tableStructure;
        this.base = base;
    }]
);
