"use strict";
"use strict";

module.exports = angular.module(__filename, [
    require('./selected-column.js').name,
    require('./removed-column.js').name,
    require('./am-table.col-desc.js').name,
    require('../../data/grid-data-layer.js').name
]).directive('amTable', ['$filter', '$timeout', '$q', 'GridDataLayer', 'Grid',
    function ($filter, $timeout, $q, GridDataLayer, Grid) {
        var filter = $filter('filter'),
            orderBy = $filter('orderBy');
        return {
            restrict: 'A',
            template: require('./am-table.html'),
            replace: true,
            transclude: true,
            controller: require('./am-table-controller.js'),
            controllerAs: 'amTableCtrl',
            scope: {
                columns: '=',
                source: '=',
                onSelected: '&',
                onRemove: '&',
                selectedModel: '=',
                tableAs: '=',
                name: '@',
                sourceAs: '@'
            },
               
            compile: function (tElement, tAttrs) {
                return {
                    pre: function (scope, element, attrs, tableCtrl) {
                        tableCtrl.extraColumns = scope.columns;
                    },
                    post: function postLink(scope, element, attrs, tableCtrl, transclude) {
                        var $tableContent = element.find('.tableContent'),
                            $heightCatch = element.find('.heightCatch'),
                            $content = element.find('.content'),
                            $rowsContent = element.find('.rows-content');
                        attrs.tableAs && (scope.tableAs = tableCtrl);

                        scope.dataToView = dataToView;

                        $rowsContent.replaceWith(transclude());

                        var order = [];
                        var originalData = [];
                        var $outerScope = scope.$parent;
                        var dataAs = scope.sourceAs || '$data';
                        /************************************
                         * extend columns from amTable Config
                         ************************************/
                        scope.$watch('columns', function (columns) {
                            _.forEach(columns, function (col, i) {
                                tableCtrl.columns[i] = _.extend(tableCtrl.columns[i] || {}, col);

                                tableCtrl.noHiddebleColumn = _.some(tableCtrl.columns, function (col) {
                                    return col.hiddenble;
                                });
                            });
                        });

                        /**********************************
                         * Update inner `data` from outside
                         **********************************/

                        // hideColumns will run only once, after that the real killPromiseWatch will be full
                        var killPromiseWatch = hideColumns;
                        scope.$watch('source', function (source) {
                            if (!source) return;

                            killPromiseWatch();
                            if (source instanceof Function) {
                                source = source();
                            }

                            //convert it to $q if it not already
                            scope.sourcePromise = $q.when(source);
                            killPromiseWatch = scope.$watch('sourcePromise.$$state.value', function (data, oldData) {
                                if (!data) return;
                                originalData = data || [];

                                if (scope.sourcePromise.$$state.status == 2) {
                                    console.error(data);
                                } else {
                                    dataToView(true);
                                }
                            });
                        }, true);

                        //guyB:
                        function arrToLowerCase(arr) {
                            return _.map(arr, function (item, index) {
                                return item.toLowerCase();
                            });
                        }

                        function hideColumn(col) {
                            col.hidden = true; // disable it in the edit menu
                            scope.toggleColumnShow(col);
                        }

                        function hideColumns() {
                            if (!Grid.config.channels) {
                                return;
                            }

                            var localColumnsArr = GridDataLayer.queries[Grid.config.channels.value][Grid.config.information].hiddenColumns;
                            var hiddenColumnsArr = arrToLowerCase(GridDataLayer.hiddenColumns);

                            // hides all the default columns
                            tableCtrl.columns.forEach(function (col) {
                                // hides the global columns
                                if (hiddenColumnsArr.indexOf(col.title.toLowerCase()) > -1) {
                                    hideColumn(col)
                                }

                                // hides the specific columns for this tab
                                if (localColumnsArr) {
                                    localColumnsArr = arrToLowerCase(localColumnsArr);
                                    if (localColumnsArr.indexOf(col.title.toLowerCase()) > -1) {
                                        hideColumn(col)
                                    }
                                }
                            });
                        }

                        /**
                         * sort data, add index to any object, calculate maxIndex that we can scroll to,
                         * prepare $data with the right slice of the whale data,
                         *
                         * @param reset
                         */
                        var i = 0, c = 35, maxindx, sortedData = [], contentheight = 0;

                        function dataToView(reset) {
                            if (reset) {
                                i = 0;
                                tt = 0;
                                $content.scrollTop(0);
                            }
                            if (order.length) {
                                sortedData = orderBy(originalData, order);
                            } else {
                                sortedData = originalData;
                            }
                            angular.forEach(sortedData, function (datum, index) {
                                datum.index = index;
                            });
                            tableCtrl.sortedData = sortedData;
                            maxindx = sortedData.length - c;

                            $outerScope[dataAs] = sortedData.slice(i, i + c);
                            hts = [];
                            // calculate estimation of size of the $heightCatch for proper scroll
                            $timeout(function () {
                                var heightFactor = sortedData.length / c;
                                contentheight = heightFactor * $tableContent[0].offsetHeight;
                                $heightCatch.height(contentheight);
                            });
                        }

                        /**
                         * INFINITE SCROLL
                         **/
                        var scrollTop = 0, hts = [], tt = 0;
                        $content.on('scroll', function () {
                            scrollTop = this.scrollTop;

                            var $trs = $tableContent.find('tr');
                            var trsLen = $trs.length;
                            var j = 0;
                            if (scrollTop > tt) {
                                do {
                                    hts[i] = hts[i] || $trs[j % trsLen].offsetHeight;
                                    j++;
                                    tt += hts[i];
                                    i++
                                } while (i <= maxindx && tt < scrollTop);
                                i--;
                                tt -= hts[i];
                            } else {
                                while (i > 0 && tt > scrollTop) {
                                    hts[i] = hts[i] || $trs[j % trsLen].offsetHeight;
                                    j++;
                                    tt -= hts[i];
                                    i--
                                }
                            }
                            if (i == 0) {
                                tt = 0;
                            } else if (i >= maxindx) {
                                $heightCatch.height(tt + $tableContent[0].offsetHeight);
                            } else {
                                $heightCatch.height($content[0].scrollHeight);
                            }
                            $tableContent.css('top', tt);
                            $outerScope[dataAs] = sortedData.slice(i, i + c);
                            scope.$parent.$digest();
                        });

                        /***
                         * Relative Reference to Data on outer scope
                         ***/
                        var columnsKeys = _.map(tableCtrl.columns, 'dataKey');
                        _.forEach(columnsKeys, function (key, i) {
                            $outerScope['col' + i] = key;
                        });

                        scope.sortBy = function sortBy(key, col) {
                            var v = 'sorted' in col ? col.sorted : -1;
                            scope.selectedColumn = col;
                            col.sorted = (v + 1) % 3; // 3 state

                            // adding the '!' and '!!' is for all 'undefined' values, to be ordered like minimum values
                            // https://stackoverflow.com/questions/18604374/angularjs-orderby-with-empty-field/30028814#33286216

                            switch (col.sorted) {
                                case 0:
                                    order.unshift('-' + key);
                                    if (col.dataType != "numeric") {
                                        order.unshift('!' + key);
                                    }
                                    break;
                                case 1:
                                    order.unshift(key);
                                    if (col.dataType != "numeric") {
                                        order.unshift('!!' + key);
                                    }
                                    break;
                                case 2:
                                    order = [];
                                    scope.selectedColumn = null;
                                    break;
                            }
                            dataToView();
                        };

                        scope.$on('$destroy', function () {
                        });
                    }
                }
            }
        }
    }
]);
