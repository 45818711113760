"use strict";
var c = require("infra/utils/common"),
    config = require('infra/config'),
    mixpanel = require("infra/mixpanel/mixpanel-discovery"),
    audienceSegmentBuilderHelper = require('data/audience-segment-builder-helper');

require("pages/targets/targets-common");
require("data/audience-skew-service");

gridController.$inject = ["$scope", "$http", 'audienceMgmt', 'filtersPartition', 'TargetsCommon', 'context', 'Grid', 'GRID_QUERIES', 'CHANNEL', 'SENTIMENT', 'alertsService', '$state',
    '$rootScope', '$filter', '$timeout', 'Excel', 'ModalService', 'sentimentsPopupService', 'notificator', 'util', 'geoService', 'mixpanelDiscovery', 'abiPermissions', 'audienceSkewService'];
function gridController(scope, $http, audienceMgmt, filtersPartition, TargetsCommon , context, Grid, GRID_QUERIES, CHANNEL, SENTIMENT, alertsService, $state, $rootScope, $filter, $timeout,
                        Excel, ModalService, sentimentsPopupService, notificator, util, geoService, mixpanelDiscovery, permissions, audienceSkewService) {

    $rootScope.helpPage = 'Channel_Insights';

    scope.sentimentsPopupService = sentimentsPopupService;
    scope.$state = $state;
    scope.Math = window.Math;
    scope.prediction = {};
    scope.channelName = CHANNEL.name;
    scope.tab = null;
    scope.tabs = [];
    scope.query = null;
    scope.Grid = Grid;
    scope.currentTarget = {};
    scope.targets = [];
    scope.contentDriversTargets = true;

    var NA = 'N/A';

    scope.sentimentsPopupService.reset($(".main-content-page quick-table"));

    //audience
    scope.customSegments = [];
    scope.lifestyles = audienceSkewService.getAudienceLifeStyle(filtersPartition, scope.test_mode);
    scope.lifestyle = null;

    scope.setQuery = function () {
        mixpanelDiscovery.trackPageView('grid');
        //reset query params when switching tabs
        var channel = CHANNEL.name(context.current.gridChannels),
            tab = $state.current.name.replace("discovery.grid.", "").split(".")[0];

        if (tab == scope.tab && scope.oldChannel == channel) { //same tab
            return;
        }

        scope.oldChannel = channel;

        if (!GRID_QUERIES.QUERIES[channel][tab]) {
            //use 1st tab
            var obj = GRID_QUERIES.QUERIES[channel];
            tab = Object.keys(obj)[0];
        }

        var newQuery = GRID_QUERIES.QUERIES[channel][tab];

        localStorage["grid_tab_" + channel] = tab;
        scope.tab = tab;
        scope.tabs = Object.keys(GRID_QUERIES.QUERIES[channel]);

        if (null == scope.query) {
            scope.query = $.extend(true, {}, newQuery);
        } else {
            for (var i in newQuery) {
                scope.query[i] = newQuery[i];
            }
        }

        if (undefined != scope.query.reset) {
            scope.query.reset();
        }

        scope.toggleSelected = function (row, $event) {
            TargetsCommon.toggleSelected(scope, row, $event);
        };

    };

    //events

    scope.$watch('context.current.gridChannels', function (channel, oldChannel) {
        var tab = localStorage['grid_tab_' + CHANNEL.name(channel)] || 'phrases',
            newState = 'discovery.grid.' + tab + '.' + CHANNEL.name(channel);

        scope.tabs = Object.keys(GRID_QUERIES.QUERIES[CHANNEL.name(channel)]);

        try {
            $state.go(newState);
        } catch (er) {
            //some tabs are like hashtags, not... hashtags.social
            newState = newState.substring(0, newState.lastIndexOf("."));
            $state.go(newState);
        }

        updateTargetsList();
    });

    var updateTargetsHandlerWatch = scope.$root.$on('updateTargets', function (event, obj) {
        TargetsCommon.updateTargetsHandler(obj, scope);
    });

    scope.$on('$destroy', function () { //exit grid
        Grid.clearCache();
        updateTargetsHandlerWatch(); //clears watch
    });

    scope.$watch('context.program', function (program) {
        if (!program) return;

        audienceMgmt.list().then(data => {
            scope.customSegments = audienceSkewService.getAudienceCustomSegments(data, scope.lifestyles);
        });

        updateTargetsList();
    });

    var refreshViewListener = $rootScope.$on('refresh-view', function () {
        scope.refreshGrid();
    });

    scope.$on('mailExport', function () {
        notificator.notify('extracting all audiences, will send you a download link shortly.');
        //scope.sendAnotherRequest("justExport");
    });



    //audience skew

    scope.showAudienceToggle = function(){
        return c.getChannels($state, context)[0] == 'articles';
    }

    scope.getAudienceSkewCol = function(){
        if(scope.query && scope.query.columns){
            let cols = scope.query.columns.filter((col)=> {return col.id == 'uniqueness-index'});

            if(cols.length){
                return cols[0];
            }
        }

        return null;
    }

    scope.$on('column-selection-changed', function (event, col) {

        if(col.id=='uniqueness-index' && col.hidden){
            scope.lifestyle = [];
            scope.postProcessQuery(scope.query.dataArray);
        }
    });

    scope.$watch('lifestyle', function (newValue, oldValue) {
        let show = newValue && newValue.length,
            col = scope.getAudienceSkewCol();

        if(col){
            col.hidden = !show;
        }

        if(scope.query){
            scope.postProcessQuery(scope.query.dataArray);
        }
  
    });

    scope.getSkew = function(arr){

        if(!scope.lifestyle.length || !arr.length){
            return;
        }
        let reqData = audienceSkewService.DEFAULTS,
            segment = audienceSegmentBuilderHelper.convertAudienceSegmentToLogicStatement(scope.lifestyle[0].segment);

        reqData['filter1'] = segment; 
        reqData['pre-chosen-words'] = _.map(arr, function(o) { return o.id; });

        scope.query.loading = true;

        //clear skew until it loads
        arr.forEach((row) =>{row['uniqueness-index'] = '';});   

        let promise = $http({
                url: audienceSkewService.AUDIENCE_INSIGHTS_URL,
                method: 'POST',
                cache: true,
                data: reqData
        }).then(function(res) {
              
            if(!res.data.words){
                notificator.notify({body: 'Sorry, insufficient data for this audience'});
            }

            let skews = (res.data.words||[]).reduce(function(result, item) {
              result[item['phrase-id']] = item['uniqueness-index']; 
              return result;
            }, {})

            var skew,
                skewColId = scope.query.columns[0].id,
                audienceLabel = scope.lifestyle[0].label;

            scope.query.dataArray.forEach(setSkew);


            function setSkew(row){
                let skew = skews[row.id];

                row['uniqueness-index'] = (skew || 0).toFixed(2);
                row['display-uniqueness-index'] = skew == undefined ? NA : row['uniqueness-index'];
                
                row['title-uniqueness-index'] = 'Consumers of '+row[skewColId]+ ' are ';

                if(skew==undefined){
                    row['title-uniqueness-index'] = 
                    'There is not enough data regarding '+audienceLabel+'\'s skew towards '+row[skewColId];
                
                }else if(skew==0){
                    row['title-uniqueness-index'] += 'unlikely to be '+audienceLabel;
                
                }else if(skew<1){
                    row['title-uniqueness-index'] += 'x' +(1/row['uniqueness-index']).toFixed(2)+ ' times less likely to be '+
                        audienceLabel+' than the average consumer';

                }else if(skew==1){
                    row['title-uniqueness-index'] += 'as likely to be '+audienceLabel+' the same as any other consumer';

                }else if(skew>1){
                    row['title-uniqueness-index'] += 'x' +row['uniqueness-index']+ ' times more likely to be '+
                        audienceLabel+' than the average consumer';
                }

            }

            scope.query.loading = false;
        });
    }

    scope.postProcessQuery = function(arr){
        if(!arr || !arr.length){
            return;
        }

        //audience skew
        if(scope.showAudienceToggle()){

            if(scope.lifestyle.length){
                scope.getSkew(arr);      
            }else{
                
                function emptySkew(row){
                    row['uniqueness-index'] = NA;
                    row['display-uniqueness-index'] = NA;
                    row['title-uniqueness-index'] = NA;

                }
                arr.forEach(emptySkew);
            }
        }
    }


    // Targets
    function updateTargetsList() {
        TargetsCommon.getTargets({scope:scope, $state: $state});
    }

    scope.openTargets = function(){
        TargetsCommon.openTargets(scope);
    }

    scope.createTargetWindow = function(phrases){

        var channel   = CHANNEL.name(context.current.gridChannels),
            timeframe = context.current._discovery_timeframe_mold._value.slice(0);

        //send: 1 day | 3 days | ... | custom
        if(isNaN(timeframe[0])){
            timeframe = "custom";
        }else{
            if(1 != timeframe[0]){
                timeframe[1] += "s";
            }
            timeframe = timeframe.join(" ");
        }

        Grid.query_params.timeframe = timeframe;

        let results = {[scope.tab]: phrases};

        TargetsCommon.createTargetWindow({
            target_type: 'trends',
            name: '',
            channel: channel,
            u_id: scope.$root.user.id,
            seeds: Grid.config.terms,
            query: Grid.query_params,
            results: results
        });
    };

    scope.addToCurrentTarget = function(){
        TargetsCommon.addToCurrentTarget(scope);
    }

    scope.editTarget = function () {  
        if(scope.currentTarget){
            TargetsCommon.edit(scope.currentTarget, scope.tab);
        }
    };

    /******************************************************************/

    scope.refreshGrid = function () {
        if (!scope.query.loading) {
            Grid.clearCache();
            scope.sendAnotherRequest();
        }
    };

    scope.$watchCollection(function () {
        return getRelevantConfig();
    }, function (newConfig, oldConfig) {
        /****************************************************
         * sent one time when coming into the page
         * and in every change of the context's value
         ****************************************************/
        scope.sendAnotherRequest();
    });

    // customFilter = true needs deep watch
    var i, filters = getRelevantConfig();

    for (i in filters) {
        if (filters[i] && filters[i].customFilter) {
            scope.$watch('context.current.' + i, function (newVal, oldVal) {
                scope.sendAnotherRequest();
            }, true);
        }
    }

    scope.sendAnotherRequest = function (justExport) {
        if (scope.$root) scope.$root.topicsDistribution = null;

        scope.setQuery();

        scope.query.rowSelected = null;
        scope.query.loading = true;

        context.done.then(function () {
            Grid.config = getRelevantConfig(true);
            Grid.config.geos = geoService.geosForChannel(Grid.config.geos, $state, context);
            Grid.config.sub_geos = geoService.subGeosForChannel(Grid.config.sub_geos, $state, context);
            if ((context.current.gridChannels.value == 'sg_telco') && !permissions.hasPermission('sg telco audience filters')) {
              Grid.config.audience = [];
            }

            scope.query.selected = [];

            scope.ts = (new Date().getTime());

            window.gridKdebugData = {docsUsed1: 0, docsUsed2: 0, elapsed: 0};

            var resultsPromise = Grid.list(scope.ts).then(function (data) {

                if (!scope.$root) return; // request is irrelevant - user switched tab
                var channel = context.current.gridChannels.value;
                var social_terms = [];
                if (channel != "tweets" && channel != "facebook") {
                    social_terms = _(context.current.terms).filter(function (term) {
                        return _.includes(['post', 'hashTag', 'mention'], term.type) || util.isSocialBooleanLogic(term);
                    }).map('text').value();
                    if (social_terms.length) {
                        notificator.notify('Sorry, @mentions, @posts and #hashtags are supported only for Twitter channel ('
                            + _(social_terms).map(util.getTermDisplayShort).join() + ')');
                    }
                }

                if (!data['data']) data = {data: [], topicsDistribution: {}, ts: 0};
                var returned_phrases = _(data.data).map('seed').value();
                var insufficient = _(context.current.terms).filter(function (term) {
                    return returned_phrases.indexOf(term.text) === -1 && social_terms.indexOf(term.text) === -1;
                }).map('display').value();
                if (!_.isEmpty(insufficient)) {
                    notificator.notify('Sorry, insufficient content about ' + insufficient.join(', ') + '. Please try more general interests or filters');
                }
                scope.$root.topicsDistribution = data.topicsDistribution;

                //show only current query
                if (scope.ts == data.ts && scope.query.show) {
                    scope.postProcessQuery(data['data']);
                    scope.query.show(data['data']);
                }

                var data = window.gridKdebugData || {
                        docsUsed1: 0,
                        docsUsed2: 0,
                        elsapsed: 0
                    };

                scope.gridKdebugData = {
                    docsUsed1: data.docsUsed1,
                    docsUsed2: data.docsUsed2,
                    elapsed: data.elapsed + "s"
                };

                $timeout(function () {
                    delete window.gridKdebugData;
                });

                return data['data'];
            });
        });
    };

    function getRelevantConfig(removeFullySelectedAudience) {
        var ctx = context.current;
        var ctx_audience = c.getAudience(ctx, ctx.gridChannels.value);
        var audience = removeFullySelectedAudience ? util.removeFullySelectedAudienceSegments(ctx_audience, true) : ctx_audience;

        //geos & sub_geos are changed outside to not trigger a watch
        return {
            language: ctx._language_mold.getLanguage($state, context).value,
            terms: ctx.terms,
            topics: ctx.topics,
            audience: audience,
            geos: ctx.geo,
            sub_geos: ctx.discoverySubGeos,
            isKeyTrends: ctx.keyTrends,
            clustering: false,
            subGeosExportTitle: scope.subGeosExportTitle,
            channels: ctx.gridChannels,
            grid_tab: localStorage["grid_tab_" + CHANNEL.name(ctx.gridChannels)] || 'phrases',
            program: context.program,
            timeframe: ctx.discovery_timeframe,
            gridAdvanced: ctx.gridAdvanced || {customFilter: true}
        }
    }

    scope.showSentimentsPopup = function (row, event) {
        var data = SENTIMENT.setRowData(row);
        scope.sentimentsPopupService.activate($(event.currentTarget), data, {});
    };

    scope.net_sent_display = function (seed) {
        return Math.min(100, Math.pow(seed.net_sent, 2)) / 2;
    };

    scope.sentiment_bar = function (seed) {
        return seed.net_sent > 0 ? 'positive' : seed.net_sent < 0 ? 'negative' : '';
    };

    scope.openContentDrivers = function (row, event, toggle) {
        scope.query.rowSelected = row;
        scope.$broadcast('openContentDrivers', "content_drivers");
    };

    scope.noTerms = function () {
        return context.current.terms.length == 0 && !context.current.keyTrends;
    };

    scope.predictionOpen = function () {
        if (!scope.query.selected.length) {
            return;
        }

        scope.prediction.phrases = scope.query.selected.map(function (phrase) {
            return {
                class: phrase.term.class,
                id: phrase.id,
                text: phrase.phrase,
                display: phrase.phrase,
                origin: phrase.seed,
                invalid: false,
                type: 'term'
            };
        });
        scope.prediction.channel = scope.context.current.gridChannels.value;
        scope.prediction.open({phrases: scope.query.selected});
    };

    function currentTab() {
        return $state.current.url.substring(1);
    }

    scope.updateKeyTrends = function () {
        context.current.keyTrends = !context.current.keyTrends;
    };

    /*****************************
     * Alerts section
     *****************************/

    function createAlertObj() {
        //preparing the alert object data
        var selected = $.extend(true, [], scope.query.selected);
        var display_interests = [];

        $.each(selected, function (i, sel) {
            //for other tabs if needed
            var text = "";
            switch (currentTab()) {
                case 'web':
                case 'social':
                    text = sel.phrase;
                    break;
                case 'hashtags':
                    text = sel.hashtag;
                    break;
                case 'influencers':
                case 'audience':
                    text = sel.handle;
                    break;
                case 'emojis':
                    //TBD
                    break;
            }
            var obj = {
                active: false,
                class: sel.term.class,
                display: sel.phrase,
                id: sel.id,
                origin: sel.seed,
                text: text,
                type: sel.term.type == 'programBL' || sel.term.type == 'booleanLogic' ?
                    'term' : sel.term.type //doesnt support non-terms for now
            };

            display_interests.push(obj);
        });

        scope.alert = {
            predefined: true,
            display_interests: display_interests
        };
    }

    function getCurrentAlerts() {
        var currentAlertsNames = [];
        alertsService.thinList().then(function (data) {
            if (data || data.length > 0) {
                data.forEach(function (alert) {
                    currentAlertsNames.push(alert.name.toLowerCase());
                });
            }

            alertsService.getSettings().then(function (data) {
                scope.settingsMissing = !data;
                openAlertDialog(currentAlertsNames);
            });
        });
    }

    function openAlertDialog(currentAlertsNames) {
        ModalService.showModal({
            template: require('../alerts/alert-dialog/alert-dialog.html'),
            inputs: {alert: angular.copy(scope.alert), currentAlertsNames: currentAlertsNames, settingsMissing: scope.settingsMissing},
            controller: 'alertDialogController'
        }).then(function (modal) {
            modal.close.then(function () {
            });
        });
    }

    scope.alertOpen = function () {
        if (!scope.query.selected.length) {
            return;
        }
        createAlertObj();
        getCurrentAlerts();
    };

    scope.isMailExport = function () {
        if (!scope.gridKdebugData) {
            return false;
        }
        var ratio = scope.gridKdebugData.docsUsed1 / scope.gridKdebugData.docsUsed2;
        return ratio <= 0.9 && scope.tab == 'audience';
    };

    scope.$root.createExcelWorkbook = function () {
        if (scope.isMailExport()) { //backend will handle export
            return {isMailExport: true}
        }

        var workbook = Excel.builder.createWorkbook();
        workbook.fileName = 'Grid ' + scope.query.title + ' ' + moment().format('YYYY-MM-DD HH:mm') + '.xlsx';

        Grid.generateSummarySheet(workbook);

        var desc = {Seed: {key: 'term.text', width: 16}};

        scope.query.columns.forEach(function (col) {
            if (!col.hidden || col.export) {
                desc[col.title] = {key: col.displayValue || col.id, width: 16};
            }
        });

        Excel.addTableSheet(workbook, scope.query.viewArray, desc, {name: util.capitalFirst(scope.query.title)});

        workbook.loading = scope.query.loading;
        mixpanelDiscovery.trackExport();
        return workbook;
    };
}

function correctState(channel) {
    switch (channel) {
        case 'articles':
            return 'discovery.grid.phrases.web';
        case 'sg_telco':
            return 'discovery.grid.phrases.sg_telco';
        case 'tweets':
            return 'discovery.grid.phrases.social';
        case 'facebook':
            return 'discovery.grid.interests.facebook';
    }
}

/** Pages **/
module.exports.stateConfig = {
    name: "grid",
    url: "/grid",
    reload: false,
    template: require("./grid.discovery.html"),
    display: "Discovery",
    data: {
        permissions: ['channel insights']
    },
    controller: gridController,
    redirectTo: ['context', function (context) {
        var channel = context.current.gridChannels.value;
        return {state: correctState(channel)};
    }],
    children: [
        {
            stateConfig: {
                name: "phrases",
                url: "/phrases",
                display: "Discovery",
                context: {},
                children: [
                    {
                        stateConfig: {
                            name: "social",
                            url: "/social",
                            display: "Discovery",
                            context: {}
                        }
                    },
                    {
                        stateConfig: {
                            name: "web",
                            url: "/web",
                            display: "Discovery",
                            context: {}
                        }
                    },
                    {
                        stateConfig: {
                            name: "sg_telco",
                            url: "/sg_telco",
                            display: "Discovery",
                            context: {}
                        }
                    }
                ]
            }
        },
        {
            stateConfig: {
                name: "hashtags",
                url: "/hashtags",
                display: "Discovery",
                context: {}
            }
        },
        {
            stateConfig: {
                name: "influencers",
                url: "/influencers",
                display: "Discovery",
                context: {}
            }
        },
        {
            stateConfig: {
                name: "audience",
                url: "/audience",
                display: "Discovery",
                context: {}
            }
        },
        {
            stateConfig: {
                name: "emojis",
                url: "/emojis",
                display: "Discovery",
                context: {}
            }
        },
        {
            stateConfig: {
                name: "pages",
                url: "/pages",
                display: "Discovery",
                context: {},
                children: [
                    {
                        stateConfig: {
                            name: "facebook",
                            url: "/facebook",
                            display: "Discovery",
                            context: {}
                        }
                    }

                ]
            }
        },
        {
            stateConfig: {
                name: "interests",
                url: "/interests",
                display: "Discovery",
                context: {},
                children: [
                    {
                        stateConfig: {
                            name: "facebook",
                            url: "/facebook",
                            display: "Discovery",
                            context: {}
                        }
                    }
                ]
            }
        }
    ],

    context: {}
};
