"use strict";
module.exports = angular.module(__filename, [])
    .service('userErrorNotifications', ['errorMgmt', '$q', function (errorMgmt, $q) {
        function shouldNotify(rejection) {
            if (rejection.config.url.match('snapshot|suggest|session|disable_notification')) return false;
            if (rejection.config.timeout && rejection.config.timeout.$$state && rejection.config.timeout.$$state.value === 'AMOBEE_CANCEL_OK') return false;
            return true;
        }

        return {
            'responseError': function (rejection) {
                if (shouldNotify(rejection)) {
                  console.log("HTTP interceptor, with rejection\n", rejection);
                  errorMgmt.httpError(rejection);
                }
                return $q.reject(rejection)
            }
        }
    }])
    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('userErrorNotifications')
    }]
);
