"use strict";
"use strict";

module.exports = angular.module(__filename, []).service('Excel', ['topicsTree', function (topicsTree) {
    var EB = require('../../3rdparty/excelbuilder/excel-builder.js');
    var Workbook = require('../../3rdparty/excelbuilder/Excel/Workbook');
    this.builder = EB;
    this.formater = formatter; //(workbook)
    this.formatter = formatter; //(workbook) // fix typo
    this.columnName = columnName;
    this.addTableSheet = addTableSheet;
    this.insertDataAtColumn = insertDataAtColumn;

    /*------------implementation---------------------*/

    function columnName(columnNumber) {
        var dividend = columnNumber;
        var columnName = "";
        var modulo;

        while (dividend > 0) {
            modulo = (dividend - 1) % 26;
            columnName = String.fromCharCode(65 + modulo) + columnName;
            dividend = Math.floor(((dividend - modulo) / 26));
        }
        return columnName;
    }

    function formatter(workbook) {
        var styleSheet = workbook.getStyleSheet();
        var style = {
            bold: {
                metadata: {
                    style: styleSheet.createFormat({font: {bold: true}}).id
                }
            },
            italic: {
              metadata: {
                  style: styleSheet.createFormat({font: {italic: true}}).id
              }
            },
            title: {
                metadata: {
                    style: styleSheet.createFormat({font: {bold: true}}).id
                }
            },
            percent: {
                metadata: {
                    style: styleSheet.createFormat({format: '0.00%'}).id
                }
            },
            percent2: {
                metadata: {
                    /** in case data in already represent percent and we just adding '%' */
                    style: styleSheet.createFormat({format: '0.0%'}).id
                }
            },
            int_percent: {
                metadata: {
                    style: styleSheet.createFormat({format: '0%'}).id
                }
            },
            two_decimal_percent: {
                metadata: {
                    style: styleSheet.createFormat({format: '0.00%'}).id
                }
            },
            numeric: {
                metadata: {
                    style: styleSheet.createFormat({format: '0.00'}).id
                }
            },
            integer: {
                metadata: {
                    style: styleSheet.createFormat({format: '0'}).id
                }
            },
            date: {
                metadata: {
                    style: styleSheet.createFormat({
                        format: 'mm/dd/yyyy', alignment: {
                            horizontal: "left"
                        }
                    }).id
                }
            },
            fullDate: {
                metadata: {
                    style: styleSheet.createFormat({
                        format: 'mm/dd/yyyy hh:mm:ss', alignment: {
                            horizontal: "left"
                        }
                    }).id
                }
            },
            string: {}
        };

        var transform = {
            bold: angular.identity,
            percent: angular.identity,
            percent2: division(100),
            int_percent: angular.identity,
            two_decimal_percent: angular.identity,
            numeric: angular.identity,
            date: stringToExcelDate,
            fullDate: stringToExcelDateTime,
            topic: topicIdToString,
            mention: addMention,
            string: angular.identity
        };
        return function (value, keys, type) {
            if (angular.isArray(value)) {
                value = (keys === 'simple_array' ? value : (_.map(value, keys))).join(', ')
            } else {
                type = keys;
            }
            type = type || 'string';
            var transformer = transform[type] || angular.identity;
            var styeler = style[type] || {};
            return angular.extend({value: transformer(value)}, styeler);
        };
    }

    /*todo: why that code here in general service and not in the consumer service*/
    var topics = {},
        treeWalk = function (node) {
            topics[node.id] = {'id': node.id, 'name': node.name};
            if (node.children) {
                node.children.forEach(treeWalk);
            }
        };
    topicsTree.topics.forEach(treeWalk);

    function insertDataAtColumn(data, dataToInsert, colNum){
        var mergedData = [];
        var mergedRowsLength = _.max([data.length, dataToInsert.length])
        for (var i = 0; i < mergedRowsLength; i++){
            var mergedRow = [];
            if (data[i]) mergedRow = mergedRow.concat(data[i])
            if (dataToInsert[i]) {
                mergedRow = mergedRow.concat(new Array(colNum - mergedRow.length - 1));
                mergedRow = mergedRow.concat(dataToInsert[i])
            }
            mergedData.push(mergedRow)
        }

        return mergedData;
    }

    function addTableSheet(workbook, data, columnsDesc, options) {
        /**
         * predefine style
         * cell type = "number" | "date" | "formula" | "text"
         * columnsDesc = {
         *    "video URL":    {key:'url',width:20,type:'text'},
         *    "Title":        {key:'title',width:20,type:'text'},
         *    "Description":  {key:'desc',width:20,type:'text'},
         *    "Domain":       {key:'domain',width:20,type:'text'},
         * };
         *       or
         * columnsDesc{
         *     "video URL":    'url',
         *     "Title":        'title',
         *     "Description":  'desc',
         *     "Domain":       'domain',
         * };
         **/
        _.defaults(options, {
            name: 'worksheet'
        });
        var t = formatter(workbook);

        var workSheet = workbook.createWorksheet(options);

        var titleLine = _.map(_.keys(columnsDesc), function (text) {
            return t(text, 'title');
        });
        var pickedColumns = _.values(columnsDesc);
        _.transform(pickedColumns, function (result, obj, i) {
            /**normalize all to  objects layout**/
            result[i] = _.isObject(obj) ? obj : {key: obj};
        });

        var pickedKeys = _.map(pickedColumns, 'key');
        var pickedOpt = _.mapPick(pickedColumns, ['type', 'def']);
        /*make the date grid*/
        var grid = _.mapAt(data, pickedKeys);
        grid = _.map(grid, function (row) {
            return _.zipWith(row, pickedOpt, function (datum, opt) {
                return t(_.or(datum, opt.def, ''), opt.type);
            })
        });

        /*add title line*/
        grid.unshift(titleLine);
        /*pivot grid if asqed*/
        options.pivot && (grid = _.unzip(grid));
        /*add the data grid to worksheet*/
        workSheet.setData(grid);
        var columnsWith = _.mapPick(pickedColumns, ['width']);
        workSheet.setColumns(columnsWith);
        workbook.addWorksheet(workSheet);
        return workSheet;
    }

    function division(num) {
        return function (value) {
            return value / num;
        }
    }

    function stringToExcelDate(value) {
        //return (moment(value).unix()/ 86400) + 25569+1; //return floating point
        var dayWrapper = moment(value).hours('12').minutes('00').second('00');

        return (moment(dayWrapper).unix() / 86400) + 25569;
        //return 42152.177083333328; //return floating point
    }

    function stringToExcelDateTime(value) {
        return (moment(value).unix() / 86400) + 25569; //return floating point
        //return 42152.177083333328; //return floating point
    }

    function topicIdToString(value) {
        return topics[value] ? topics[value].name : '';
    }

    function addMention(value) {
        return '@' + value;
    }
}]).directive('downloadExcel', ['Excel', '$q', function (Excel, $q) {
    var Workbook = require('../../3rdparty/excelbuilder/Excel/Workbook');
    var dataProtocol = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    var objectURL;
    var options = {
        base64: false,
        type: 'blob',
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    var lastPromise = null; // for tracking on the last process to prevent double create file
    function makeExcelFile(excel) {
        if (excel instanceof Workbook) {
            var blob = Excel.builder.createFile(excel, options);
            URL.revokeObjectURL(objectURL);
            this.href = objectURL = URL.createObjectURL(blob);
        }
    }

    return {
        restrict: 'A',
        scope: {
            downloadExcel: '=',
            fileName: '='
        },
        compile: function (tElement, tAttrs) {
            if (tElement[0].tagName != 'A') return;
            return function link(scope, element, attr, ctrl) {

                var that;
                element.on('click', function (evt) {
                    that = this;
                    clickExcel(evt);
                });

                function clickExcel(evt){

                    evt.originalEvent.preventDefault();

                    if (lastPromise && lastPromise.$$state.status == 0) {
                        /*we still waiting previous promise to end*/
                        return null;
                    }
                    if (!scope.downloadExcel) {
                        return null;
                    }
                    var excel = scope.downloadExcel;
                    if (excel instanceof Function) {
                        excel = excel();
                    }
                    that.classList.add('loading-spinner');
                    lastPromise = $q.when(excel).then(function (excel) {

                        if(excel && excel.loading){
                            setTimeout(function(){
                               clickExcel(evt);
                            },500);
                            return;
                        }

                        that.classList.remove('loading-spinner');

                        if(excel && excel.isMailExport){
                            scope.$root.$broadcast('mailExport');

                        }else if (excel instanceof Workbook) {
                            URL.revokeObjectURL(objectURL);
                            var blob = Excel.builder.createFile(excel, options);
                            objectURL = URL.createObjectURL(blob);
                            var linkEl = document.createElement('a');
                            linkEl.href = objectURL;
                            linkEl.setAttribute('download', scope.fileName || excel.fileName || 'excel.xlsx');
                            linkEl.click();
                        }
                    }, function () {
                        that.classList.remove('loading-spinner');
                    });
                }
            }
        }
    }
}]);
