"use strict";
class HeatMapComponent {
    constructor($element) {
      this.$element = $element;
      this.blockWidth = 50;
      this.blockHeight = 10;
      this.duration = 1000;
      this.legendSpace = 25;
    }

    $onInit() {
      this.element = d3.select(this.$element.find('.heatmap-component')[0])
      this.numberOfDomains = this.numberOfDomains || 7;
    };

    $onChanges(changesObj) {
      this.element = this.element || d3.select(this.$element.find('.heatmap-component')[0]);
      if(this.element) {
        if (changesObj.visiable && this.options) {
          this.draw();
        }
      }
    };

    draw() {
      this.width = this.blockWidth * this.numberOfDomains;
      const title = this.visiable ? [this.options.title] : [];
      const scale = d3.scale.threshold().range( ["heat-map-block-1","heat-map-block-2","heat-map-block-3","heat-map-block-4","heat-map-block-5","heat-map-block-6","heat-map-block-7"] )
      const colors = this.visiable ? _.flatten(_.map(this.options.blocksColors, this._getColors.bind(this))) : []
      const domain = this._getDomains(scale);

      const scldom = scale.domain()
      const numbers = this.visiable ? scldom : [];
      if (this.visiable) {
        this.element.selectAll("rect").data([]).exit().remove()
        this.element.selectAll(".legend-number").data([]).exit().remove()
        this.element.selectAll(".legend-title").data([]).exit().remove()

      } else {
        this.element.selectAll("rect").data(colors).enter().append("rect")
        this.element.selectAll(".legend-number").data(numbers).enter().insert("text", ".legend-block")
        this.element.selectAll(".legend-title").data(title).enter().insert("text", ".legend-title")
      }

      const blocks = this.element.selectAll("rect").data(colors)
      blocks.enter()
        .append("rect")
        .style("fill", d => d)
        .attr("height", this.blockHeight )
        .attr("y", (d, i) => {
          const y = i < this.numberOfDomains ? this.legendSpace : this.legendSpace - ((this.blockHeight + 1) * Math.floor(i / this.numberOfDomains));

          return y + (this.blockHeight * (Math.floor(colors.length / this.numberOfDomains) - 1));
        })
        .attr("x", 0 )
        .attr("width", 0)
        .transition().duration(this.duration)
        .attr("width", this.blockWidth - 1)
        .attr("x", (d,i)=> {
          return this.blockWidth * (i % this.numberOfDomains)
        })

      blocks.exit()
        .transition().delay(this.duration).duration(this.duration)
        .attr("x", 0 )
        .attr("width", 0)
        .remove()

      const legends = this.element.selectAll(".legend-number").data( numbers )

      legends.enter()
             .insert("text", ".legend-block")
             .attr("text-anchor", "middle")
             .attr("class", "legend-number")
             .attr("y", this.legendSpace )
             .attr("x", 0 )
             .attr("width", 0)
             .attr("dy", this.blockHeight )
             .transition().duration(this.duration)
             .attr("width", this.blockWidth - 1)
             .attr("x", (d,i)=> this.blockWidth * i + this.blockWidth/2 )
             .transition().duration(this.duration)
             .attr("dy", this.legendSpace + (this.blockHeight * (Math.floor(colors.length / this.numberOfDomains) - 1)))

      legends.exit()
           .transition().duration(this.duration)
           .attr("dy", this.blockHeight )
           .remove()

      legends.text( (d) => this.options.labelFormat(d))

      const the_title = this.element.selectAll(".legend-title").data(title)

      the_title.enter()
             .insert("text", ".legend-title")
             .text((d)=>d)
             .attr("text-anchor", "start")
             .attr("class", "legend-title")
             .attr("y", this.legendSpace )
             .attr("x", 0 )
             .style("opacity", 0)
             .attr("dy", this.blockHeight )
             .transition().delay(this.duration).duration(this.duration)
             .attr("dy", -this.blockHeight )
             .style("opacity", 1)

      the_title.exit()
           .transition().duration(this.duration)
           .attr("dy", this.blockHeight )
           .style("opacity", 0)
           .remove()
    }

    _getDomains(scale) {
      const {min, max, midValue} = this.options;
      return scale.domain(this._getValuesInRange(max, min, midValue));
    }

    _getColors(sourceColor) {
      const {minColor, maxColor, midValue} = this.options;
      const opacities = this._getValuesInRange(maxColor, minColor, midValue);
      return _.map(opacities, opacity => {
        if (opacity > 1) {
          var [r, g, b] = _.map(sourceColor, color => parseInt((2 - opacity) * color));
        } else {
          var [r, g, b] = _.map(sourceColor, color => parseInt(((1 - opacity) * 255) + (opacity * color)));
        }

        return `rgb(${r}, ${g}, ${b})`;
      });
    }

    _getValuesInRange(max, min, midValue) {
      let divider = this.numberOfDomains;
      let domains;
      if (midValue) {
        divider = Math.floor(divider / 2);
        domains = [min]
                  .concat(_.times(divider - 1, (i) => this._calcValueFunction(midValue, min, divider, i)))
                  .concat([midValue])
                  .concat(_.times(divider - 1, (i) => this._calcValueFunction(max, midValue, divider, i)))
                  .concat([max])
      } else {
        domains = [min]
                  .concat(_.times(divider - 2, (i) => this._calcValueFunction(max, min, divider, i)))
                  .concat([max])
      }
      return domains;
    }

    _calcValueFunction(max, min, divider, i) {
      return ((max - min) / divider) * (i + 1) + min;
    }
}

HeatMapComponent.$inject = ["$element"];
module.exports = angular.module(__filename, [
]).component('heatMapComponent', {
    template: require('./heat-map.html'),
    bindings: {
      visiable: '<',
      options: '<',
      numberOfDomains: '<'
    },
    controller: HeatMapComponent,
})
