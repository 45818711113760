"use strict";
module.exports = angular.module(__filename, []).directive('emailFormValidator', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ctrl) {
            var EMAIL_REGEXP = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

            function emailValidator(ngModelValue) {
                if (!ngModelValue || EMAIL_REGEXP.test(ngModelValue)) {
                    ctrl.$setValidity('invalid', true);
                } else {
                    ctrl.$setValidity('invalid', false);
                }
                return ngModelValue;
            }

            ctrl.$parsers.push(emailValidator);
        }
    };
});
