"use strict";
module.exports = angular.module(__filename, []).service('audienceMgmt', ['mgmtServiceFactory', function (mgmtServiceFactory) {
    var service = mgmtServiceFactory.createService('audience');

    return {
        list: service.list,
        create: service.create,
        update: service.update,
        delete: service.delete
    };
}]);
