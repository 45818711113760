"use strict";
const angular = require("angular"),
    BaseWidget = require("../base_widget"),
    dotdotdot = require("jquery.dotdotdot");

AudienceTvWidgetController.$inject = ['audienceInsightsService', 'context',
    'filtersPartition', 'audienceTableStructure', 'util', 'tvExportService',
    'confirmAction', '$state'
];

function AudienceTvWidgetController(audienceInsightsService, context, filtersPartition, audienceTableStructure, util, exportService, confirmAction, $state) {
    let $scope = this.$scope;
    this.segment = context.current.audience_segment;

    const audienceTables = audienceTableStructure.tableStructure;
    const demographicsPromise = audienceInsightsService.getFullDemographicsDataWithGenderAgeBySegment(this.segment);
    const tooWideMsg = 'The audience you have selected is too wide for TV shows analysis. Please refine your audience criteria.';
    const tooNarrowMsg = 'The audience you have selected is too narrow for TV shows analysis. Please refine your audience criteria.';
    const outputTypes = [
        {
            value: 'bars',
            icon: 'icon-bar-chart',
            label: ''
        },
        {
            value: 'table',
            icon: 'icon-table',
            label: ''
        }
    ];
    const sortByTypes = [
        {
            value: 'interest-portion',
            label: 'Consumption',
            tooltip: {
                text: 'Sort by how popular the TV show is among the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        },
        {
            value: 'skew-score',
            label: 'Skew',
            tooltip: {
                text: 'Sort by the extent to which the TV show is over-indexed within the audience',
                amToolTip: 'top center to bottom right',
                classes: 'no-width-limit-tooltip'
            }
        }
    ];

    $scope.outputTypes = outputTypes;
    $scope.sortByTypes = sortByTypes;
    $scope.sortBy = 'interest-portion';
    $scope.scoreView = 'interest-portion';
    $scope.selectedView = 'bars';
    $scope.query = audienceTables['tv'];

    $scope.onSortChange = (type) => {
        if(!$scope.tvShows) return;
        $scope.scoreView = type;
        $scope.tvShows = _.orderBy($scope.tvShows, type, 'desc');
    };

    $scope.onOutputViewsChange = (type) => {
        if(type === 'bars') refreshTvShowImageView()
    };

    $scope.export = () => {
        return Promise.all([$scope.loadingPromise, demographicsPromise]).then(([shows, demographicsData]) => {
            let excel = exportService.exportToExcel(shows, demographicsData, this.segment);
            return exportService.downloadExcel(excel);
        });
    };
    $scope.$root.createExcelWorkbook = $scope.export;

    let tvPromise = audienceInsightsService.getTvInfo(this.segment).then(data => {
        if(!data) throw 'noData';
        if(data.status === 'noData') throw 'noData';
        if(data.status === 'segment too wide') throw 'tooWide';
        if(data.status === 'insufficient data') throw 'tooNarrow';

        return {shows: data}
    });
    $scope.loadingPromise = tvPromise.then(handleResponseData).catch(handleMissingData);

    function handleResponseData(data) {
        // Add a normalized skew field
        data.shows.forEach(show => {
            show['skew-score'] = show['uniqueness-index'];
            show['phrase'] = show['title']; // For correct tooltip lookup
        });
        util.normalize(data.shows, 'uniqueness-index', 'skew-score');
        util.normalize(data.shows, 'interest-portion', 'interest-portion-normalized');

        $scope.query.show(data.shows);
        $scope.tvShows = data.shows;
        $scope.onSortChange($scope.sortBy);
        return $scope.tvShows;
    }

    function openNavBackModal(msg, title) {
        $scope.showNoDataBG = true;
        confirmAction.getConfirmation(msg, 'Refine Audience', 'Back', title, 'audience-nav-modal').then(modal => {
            modal.close.then(refine => {
                refine ? $state.go('audience-builder') : $scope.navToPrevTab();
            });
        });
    }

    function handleMissingData(reason) {
        switch(reason) {
            case 'tooNarrow':
                openNavBackModal(tooNarrowMsg, 'Audience is too narrow');
                break;
            case 'noData':
            case 'tooWide':
                openNavBackModal(tooWideMsg, 'Audience is too wide');
                break;
        }
    }

    function refreshTvShowImageView() {
        angular.element(document).find('tv-show-info .dotdotdot').each(() => {
            $(this).dotdotdot();
        })
    }
}

module.exports = require("angular").module(__filename, [
    require("data/audience-insights-service").name,
    require('data/tv-export-service').name,
    require("common/tv-show-info.drv/tv-show-info.drv").name,
    require('common/modals/confirmation/confirm-action.modal.service').name,
])
    .directive("audienceTvWidget", [() => BaseWidget({
        restrict: "E",
        template: require("./audience-tv-widget.html"),
        scope: {
            "navToPrevTab": "&"
        },
        controller: AudienceTvWidgetController
    })]);
