"use strict";
module.exports = angular.module(__filename, []).directive('amDatePicker', [function () {
    var enumDay = 'su,mo,tu,we,th,fr,sa'.split(',');
    var viewDateFormat = 'MMM D, YYYY';
    var viewTimeFormat = 'HH:mm';

    var timeOptions = [];
    /*create array.length == 24*/
    for (var i = 0; i < 24; i++) {
        var hh = (i < 10 ? '0' : '') + i;
        timeOptions.push({
            label: hh + ':00', hour: i, minute: 0
        }, {
            label: hh + ':30', hour: i, minute: 30
        });
    }

    return {
        restrict: 'E',
        require: 'ngModel',
        template: require('./datePicker.drv.html'),
        scope: {
            onSelect: '&',
            minDate: '=',
            maxDate: '=',
            rangeStart: '=',
            rangeEnd: '=',
            format: '=',
            withTimePicker: "=",
            returnMoment: '='
        },
        link: function (scope, element, attr, ctrl) {
            scope.enumDay = enumDay;
            scope.nextMonth = nextMonth;
            scope.prevMonth = prevMonth;
            scope.selectDate = selectDate;
            scope.datePickerOpen = false;
            scope.toggleDatePicker = toggleDatePicker;
            scope.closeDatePicker = closeDatePicker;
            scope.updateTime = updateTime;
            scope.timeOptions = timeOptions;
            scope.selectedTime = timeOptions[0];

            var returnMoment = false;
            var now = moment().add(2, 'month');
            var $calender = element.find('.calender');

            /* view -> model */
            ctrl.$parsers.push(function (val) {
                val = returnMoment ? val : val.format(val._f || scope.format);
                ctrl.$render();
                return val;
            });

            /*model -> view*/
            ctrl.$formatters.push(function (val) {
                if (!val) {
                    returnMoment = scope.returnMoment;
                    now = moment();
                } else {
                    returnMoment = moment.isMoment(val);
                    now = moment(val, scope.format);
                    scope.selectedTime = {label: now.format('HH:mm'), hour: now.hour(), minute: now.minute()};
                }

                initMonth(now);
                return now;
            });

            ctrl.$render = function () {
                scope.valueDateText = ctrl.$viewValue.format(viewDateFormat);
                scope.valueTextTime = ctrl.$viewValue.format(viewTimeFormat);
            };

            function toggleDatePicker($event) {
                !scope.datePickerOpen ? openDatePicker($event) : closeDatePicker($event);
            }

            function openDatePicker($event) {
                scope.datePickerOpen = true;
                initMonth(ctrl.$viewValue);
                document.addEventListener('click', closeEventCallback, true);
            }

            function closeEventCallback($event) {
                if ($.contains($calender[0], $event.target)) return;
                closeDatePicker($event);
                document.removeEventListener('click', closeEventCallback, true);
                scope.$digest();
            }

            function closeDatePicker($event) {
                scope.datePickerOpen = false;
            }

            function nextMonth($event) {
                $event.stopPropagation();
                now.add(1, 'month');
                initMonth(now);
            }

            function prevMonth($event) {
                $event.stopPropagation();
                now.subtract(1, 'month');
                initMonth(now);
            }

            function selectDate(day, $event) {
                var monthDiff =
                    (day.daysBefore && -1) ||
                    (day.daysAfter && 1) || 0;
                $event.stopPropagation();
                now.add(monthDiff, 'month').date(day.i);
                if (!day.isOverLimit) {
                    commit($event);
                }
            }

            function updateTime(selectedTime, $event) {
                now.hours(selectedTime.hour);
                now.minutes(selectedTime.minute);
                commit($event);
            }

            function commit($event) {
                ctrl.$setViewValue(moment(now));
                attr.onSelect && scope.onSelect({
                    $date: now,
                    $value: ctrl.$modelValue
                });
                closeDatePicker($event);
            }

            function initMonth(now) {
                scope.thisMonthTitle = now.format('MMMM YYYY');

                var thisMonth = moment(now).startOf('month'),
                    start = moment(thisMonth).startOf('month').startOf('week'),
                    end = moment(thisMonth).endOf('month').endOf('week');

                var allDaysRange = [].concat(
                    _.range(
                        start.date(),
                        start.date() + thisMonth.diff(start, 'days')
                    ).map(_convertToDayObject({daysBefore: true})),

                    _.range(
                        thisMonth.date(),
                        thisMonth.daysInMonth() + 1
                    ).map(_convertToDayObject({'daysIn': true})),

                    _.range(
                        1,
                        1 + end.diff(thisMonth.endOf('month'), 'days')
                    ).map(_convertToDayObject({daysAfter: true}))
                );

                var minDateLimit = moment(scope.minDate, scope.format),
                    maxDateLimit = moment(scope.maxDate, scope.format),
                    minSelectedRange = moment(scope.rangeStart, scope.format),
                    maxSelectedRange = moment(scope.rangeEnd, scope.format);
                /* that implementation is protected against negative value */

                allDaysRange.slice(
                    Math.max(minDateLimit.diff(start, 'days'), 0),
                    Math.max(1 + maxDateLimit.diff(start, 'days'), 0)
                ).forEach(function setInLimit(day) {
                        delete day.isOverLimit;
                    });
                allDaysRange.slice(
                    Math.max(minSelectedRange.diff(start, 'days'), 0),
                    Math.max(1 + maxSelectedRange.diff(start, 'day'), 0)
                ).forEach(function setInSelection(day) {
                        day.isInRange = true
                    });

                scope.days = allDaysRange;
            }

            function _convertToDayObject(extra) {
                return function (value) {
                    return _.extend({i: value, isOverLimit: true}, extra)
                }
            }
        }
    }
}]);
