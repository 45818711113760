"use strict";
var warVars   = require("pages/warroom/warroom-vars");
var config    = require("infra/config");

module.exports = {

	tweetsNeeded: 0,
	tweets: [],
	date: '',
	recentDate: true,
	queryURL: '',

   	get: function(scope){

    	var that = this;

    	try{
    		//translate date
    		this.date = scope.selected.date ? scope.selected.date : scope.selected.group.stats[scope.selected.group.stats.length-1].ts;
    	}catch(er){
    		return;
    	}

    	if(!scope.selected.phraseOrGroup){
    		return;
    	}

		var id = scope.selected.phraseOrGroup.type === 'group' ?
			scope.selected.phraseOrGroup.id : scope.selected.group.id;
		var params = {};
		params.phrase_group_id = id;
		params.count = 30;

		if (scope.selected.date && scope.selected.date_end) {
			params.end_time = scope.selected.date_end.substring(0, scope.selected.date_end.lastIndexOf(':'));
      		params.start_time = scope.selected.date.substring(0, scope.selected.date_end.lastIndexOf(':'));
		} else if (scope.time.mode == 'custom') {
			params.end_time = scope.time.to;
			params.start_time = scope.time.from;
		} else {
			params.end_time = 'now';
			params.start_time = '-' + scope.time.minutes + 'm';
		}

		if (warVars.geo != "all") {
			params.country = warVars.geo;
		}

		if(scope.selected.tweetsSentiment !== "all"){
			params.sentiment = scope.selected.tweetsSentiment;
		}

		if(scope.selected.phrase){
			params.phrase_id = scope.selected.phrase.id;
		}

		if(scope.selected.association){
			params.association_id = scope.selected.association.id;
		}

		//see getTweetFromURL
		this.recentDate = scope.time.mode == "realtime" ||
			moment().valueOf() - moment(scope.time.from, 'YYYY-MM-DDTHH:mm').valueOf() <= 36 * 24 * 60 * 60 * 1000;

		$("#tweets_examples_tweets").html(
			'<div class="loading-img"></div>');
		scope.selected.tweets = true;

		if(scope.selected.association && scope.selected.association.total === 0 && scope.time.mode==='realtime'){
			this.getCB(scope, []);
			return;
		}

		this.queryURL = JSON.stringify(params);
		var queryURL = this.queryURL;

		this.$http({method: 'POST', url: config.REALTIME_API + '/phrases_examples', data: $.param(params), headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
			.success(function(data){
			that.getCB(scope, data, queryURL);
		}).error(function(e){
			console.log(e);
		});
	},

	getCB: function(scope, data, queryURL){
		var that = this;
		this.tweetsNeeded = data.length;
		this.tweets = [];

		if(queryURL != this.queryURL){//a more recent query returned
			return;
		}else if(data.length === 0){
			this.showTweets(); //no tweets message
		}else{
			$.each(data, function(i, url){
				that.getTweetFromURL(url, scope, queryURL); //when done, will call showTweets
			});
		}

		var entityMain = scope.selected.phraseOrGroup,
		    entityAss  = scope.selected.association;

		if (entityMain) {
			var title = (entityMain.display || entityMain.name||entityMain.text);
			if(entityAss){
				var ass = entityAss.text;
				if(entityAss.isHashtag){
					ass = "#" + ass;
				}
				title = title+' <i class="icon-Triangle_Right"></i> '+ass;
			}

			$("#tweets_examples_title_crums").html(title);
			this.setDisplayDate(scope, this.date);
		}
	},

	setDisplayDate: function(scope, date){
		$("#tweets_examples_date").html(scope.timeDisplay(date, "day"));

		//if tweets exist, change their display dates too
		$.each($("#tweets_examples .tweet_date") , function(i, div){
			$(div).html(
				scope.timeDisplay( $(div).attr("date") , "year")
				);
		});
	},

	getTweetFromURL: function(url, scope, queryURL){

		//recent tweets (max 36 days) are fetched from elastic
		//older tweets, from twitter API but with less data => less styling

		if(queryURL != this.queryURL){//a more recent query returned
			return;
		}

		var that = this,
			frags =  url.link.split("/"),
			status = frags[frags.length-1],
			url =  this.recentDate ?
				warVars.ELASTIC_API+'?ids='+status :
				warVars.TWITTER_PUB_API+"/oembed?url=https%3A%2F%2Ftwitter.com%2Fjack%2Fstatus%2F"+status+
				"&callback=JSON_CALLBACK&hide_media=true&omit_script=true";


		var ops  = {
			url: url,
			timeout: 30000,
		}

		if(this.recentDate){
			this.$http(ops).success(function(tweet) {
				tweetSucess(tweet, queryURL);
			}).error(function(){
				tweetError(queryURL);
			});
		}else{
			delete ops.url;
			ops.dataType = 'jsonp';
			
			this.$http.jsonp(url, ops).success(function(tweet) {
	        	tweetSucess(tweet, queryURL);
	        }).error(function(){
	        	tweetError(queryURL);
	        });
		}


        function tweetSucess(tweet, queryURL){
        	if(queryURL != that.queryURL){//a more recent query returned
				return;
			}

        	if(that.recentDate){
        		that.tweets.push(tweet[0] || null);
        	}else{
        		that.tweets.push(tweet);
        	}

        	if(that.tweets.length>=that.tweetsNeeded){ //all ajax calls returned
        		that.showTweets(scope);
        	}
        }

 		function tweetError(queryURL){

 			if(queryURL != that.queryURL){//a more recent query returned
				return;
			}

 			that.tweetsNeeded--;
        	$("#tweets_examples_tweets").html('');
        	if(that.tweets.length>=that.tweetsNeeded){ //all ajax calls returned
        		that.showTweets(scope);
        	}
        }



	},

	showTweets: function(scope){
		var foundTweet = false, str = '', that = this;

		$.each(this.tweets, function(i, tweet){
			if(tweet){
				foundTweet = true;

				if(that.recentDate){

					var tweetStr = '';
					var date = tweet.published_date.indexOf("+") != -1 ?
								tweet.published_date.substring(0, tweet.published_date.indexOf("+")):
								tweet.published_date.substring(0, tweet.published_date.indexOf("Z"));
					var body = tweet.body;
					tweet.body = tweet.body.replace("http://", "");
					tweet.body = tweet.body.replace("https://", "");
					tweet.body = tweet.body.replace("twitter.com/", "");

					tweetStr =
					'<table><tr>'+
						'<td><img class="tweet_image" src="'+tweet.user.image.replace("normal", "bigger")+'" alt="Image not found" onError="this.src=\'images/widgets/twitter.png\';"></td>'+
						'<td>'+
							'<div class="tweet_text">"'+body+'"</div>'+
							'<div class="tweet_footer">'+
								'<i class="img_8 icon-Twitter"> @ '+tweet.user.display_name+'</i><br/>'+
								'<span class="tweet_date" date="'+date+'">'+scope.timeDisplay(date, "year")+'</span> &nbsp;&nbsp; <i class="icon-Retweet_new">&nbsp;</i>'+tweet.rt_count+
							'</div>'+
						'</td>'+
					'</tr></table>';

					str += '<a class="tweets_examples_tweet" target="_blank" '+
					'href="https://twitter.com/'+tweet.user.preferred_username+'/status/'+tweet._id+'">'+tweetStr+'</a>';

				}else{
					//display short tweet summary

					tweet.url = tweet.url.replace("http://", "");
					tweet.url = tweet.url.replace("https://", "");
					tweet.url = tweet.url.replace("twitter.com/", "");

					var tweetStr =

					'<table><tr>'+
						'<td><i class="tweet_image_empty icon-Twitter"></i></td>'+
						'<td>'+
							'<div class="tweet_text">'+tweet.html.replace("blockquote","p")+'</div>'+
						'</td>'+
					'</tr></table>';

					str += '<a class="tweets_examples_tweet" target="_blank" '+
					'href="https://twitter.com/'+tweet.url+'">'+tweetStr+'</a>';
				}
			}
		});

		if(!foundTweet){
			str = '<h4>No tweets for this data.</h4>';
			$("#export_tweets").addClass("fadeout");
		}else{
			$("#export_tweets").removeClass("fadeout");
		}

		var overflow = $("body").css("overflow");
		$("body").css("overflow","hidden");
		$("#tweets_examples_tweets").html(str);

		setTimeout(function(){ //fix sudden scroll bug
			$("body").css("overflow",overflow);
		},1500);
	},


	export: function(scope, Excel){

		var workbook = Excel.builder.createWorkbook();
		workbook.fileName = warVars.encodeCSV($('#tweets_examples_title_crums').html())+' tweet urls.xlsx';

		scope.getSummarySheet(workbook);
      
      	var arr = [];
		var desc = {
			'URL' : {key: 'url', width: 16},
		};

	    this.tweets.forEach(function(tweet){
	      
	    	if(tweet){
				if(!tweet.url){
					tweet.url = 'https://twitter.com/'+tweet.user.preferred_username+'/status/'+tweet._id;
				}
				tweet.url = warVars.encodeCSV(tweet.url);
	     	}

	      	arr.push(tweet);
	    });

    	Excel.addTableSheet(workbook, arr, desc, {name: 'urls'});

      	return workbook;

	}

};
