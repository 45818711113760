"use strict";
var mixPanel = require('./mixpanel-base');

module.exports = angular.module(__filename, [
    mixPanel.name
]).service('mixpanelAlerts', ['$state', '$window', 'context', 'mixpanelService', function ($state, $window, context, mixpanelService) {

    var appName = 'Alerts';
    var widgetName = 'Alerts';

    var ALERT_MAPPINGS = {
        channel: {articles: 'all', videos: 'video', tweets: 'social'},
        sentiment: {net: 'net sentiment', pos: 'positive sentiment', neg: 'negative sentiment'},
        timeframe: {last_month: '1M', last_quarter: '3M', last_6_months: '6M', last_year: '1Y'}
    };

    function isUsingCustomParams(triggers, metadata) {
        return metadata.timeframe != 'last_month' || triggers.compare_to_previous.selected
            || !triggers.compare_to_avg.selected || triggers.compare_to_avg.stdev != 2;
    }

    function buildAlert(alert) {
        var triggers = alert.triggers;
        var metadata = alert.metadata;
        var metric, direction, channel;
        if (metadata.type == 'sentiment') {
            metric = ALERT_MAPPINGS.sentiment[metadata.sentiment];
            direction = metadata.change == 'dec' ? 'decreases' : 'increases';
            channel = 'NA';
        } else {
            metric = 'consumption';
            direction = 'NA';
            channel = ALERT_MAPPINGS.channel[metadata.channel]
        }

        return {
            'Alert ID': alert.id,
            'Alert name': alert.name,
            'Alert type': metadata.type,
            'Tracked interests': metadata['phrases[]'],
            'Interests refinement': _.mapPick(metadata['boolean_logics[]'], ['required', 'included', 'excluded']),
            'Tracked metric': metric,
            'Program name': context.program.name,
            'Using custom params?': isUsingCustomParams(triggers, metadata),
            Channel: channel,
            'Compared to average': triggers.compare_to_avg.selected,
            'Compared to previous day': triggers.compare_to_previous.selected,
            'Average #stdev change': triggers.compare_to_avg.stdev || 0,
            'Average % change': triggers.compare_to_avg.percentage || 0,
            'Previous day #stdev change': triggers.compare_to_previous.stdev || 0,
            'Previous day % change': triggers.compare_to_previous.percentage || 0,
            'Tracked direction': direction,
            'Benchmark timeframe': ALERT_MAPPINGS.timeframe[alert.benchmark_timeframe],
            'Tracked geo': metadata.geo.length != 1 ? 'All' : _.first(metadata.geo).label
        };
    }

    function buildAlertSettings(settings) {
        var interval = settings.interval;
        var hour, day;
        switch (interval.type) {
            case 'Weekly':
                day = moment().day(interval.day_of_week).format('dddd');
            // FALL THROUGH
            case 'Daily':
                hour = _.padStart(interval.hour_of_day, 2, '0') + ':00';
                break;
            default: // DO NOTHING
        }

        return {
            'Program name': context.program.name,
            'How often': interval.type,
            Hour: hour || 'NA',
            'Include weekends?': interval.include_weekends,
            Day: day || 'NA',
            'Send alerts to': settings.emails
        };
    }

    function trackPageView() {
        mixpanelService.trackPageView(appName, widgetName);
    }

    function trackAlertsSettings(settings) {
        mixpanelService.sendToMixpanel('Alerts - Email settings updated', buildAlertSettings(settings));
    }

    function trackAlertCreated(alert) {
        mixpanelService.sendToMixpanel('Alerts - Alert created', buildAlert(alert));
    }

    function trackAlertUpdated(alert) {
        mixpanelService.sendToMixpanel('Alerts - Alert updated', buildAlert(alert));
    }

    function trackAlertDeleted(alert) {
        mixpanelService.sendToMixpanel('Alerts - Alert deleted', buildAlert(alert));
    }

    function trackExport() {
        mixpanelService.trackExport(appName, widgetName);
    }

    return {
        trackPageView: trackPageView,
        trackExport: trackExport,
        trackAlertsSettings: trackAlertsSettings,
        trackAlertCreated: trackAlertCreated,
        trackAlertUpdated: trackAlertUpdated,
        trackAlertDeleted: trackAlertDeleted
    }
}]);
