"use strict";
"use strict";

module.exports = angular.module(__filename, [
    require('data/filters-partition.srv').name
]).service("filterMoldService", ["$q", 'filtersPartition', function ($q, filtersPartition) {
    this.getDefaultFilterMoldFunction = defaultMoldFunction;

    function defaultMoldFunction(items, isNotArray) {
        var me = this;
        me._value = me._value || [];

        this.replace = function (val) {
            if (_.isEmpty(val)) {
                me._value = me.default || me._value;
            } else {
                me._value = val;
            }

            return me._value;
        }.bind(this);
    }
}]);
