"use strict";
var mixPanel = require('analytics.mixpanel');

angular.module('analytics.mixpanel').config(['$mixpanelProvider', function ($mixpanelProvider) {
    $mixpanelProvider.apiKey('3b9c0e987bfe8dbfb18467ca8adad53b');
}]);

module.exports = angular.module(__filename, [
    mixPanel.name
]).config(["authenticationConfig", function (authenticationConfig) {
    var PRODUCTION_HOST = 'intelligence.amobee.com';

    function disableMixpanel(host) {
        return host != PRODUCTION_HOST;
    }

    authenticationConfig.doAfterAuthentication.push(['authInfo', '$mixpanel', '$location', function (authInfo, $mixpanel, $location) {
        var userEmail = authInfo.email;
        if (disableMixpanel($location.host())) {
            console.log('@@@ disabling mixpanel tracking @@@');
            $mixpanel.disable();
            return;
        }

        //$mixpanel.set_config({debug: true});
        $mixpanel.register({user: userEmail});
        $mixpanel.identify(userEmail);
        $mixpanel.people.set({
            '$email': userEmail,
            'Username': authInfo.name,
            'Organization': authInfo.account.name
        });
    }]);
}]).service('mixpanelService', ['$mixpanel', '$state', '$window', 'context', function ($mixpanel, $state, $window, context) {

    function sendToMixpanel(eventName, props) {
        $mixpanel.track(eventName, props);
    }

    function extractWidget(state) {
        var a = state.name.split('.');
        a.shift();
        return a.join(' ');
    }

    function buildTimeframe(timeframe, format) {
        if (isCustomTimeframe(timeframe[1])) {
            return buildCustomTimeframe(timeframe, format);
        } else {
            return timeframe[0] + ' ' + timeframe[1];
        }
    }

    function isCustomTimeframe(timeframe) {
        // match insights & real time view custom timeframe: dd_MM_YY_HH_mm
        return timeframe.match(/\d{2}_\d{2}_\d{2}_\d{2}_\d{2}/);
    }

    function buildCustomTimeframe(timeframe, format) {
        var start = moment(timeframe[0], 'DD/MM/YY HH:mm').format(format);
        var end = moment(timeframe[1], 'DD/MM/YY HH:mm').format(format);
        return start + ' to ' + end;
    }

    function buildProgram() {
        var currentProgram = context.program;
        return {
            programName: currentProgram.name
        };
    }

    function booleanLogicTerm(term) {
        var result = {};
        result.required = _.map(term.required, 'text');
        if (term.included && term.included.length > 0) result.included = _.map(term.included, 'text');
        if (term.excluded && term.excluded.length > 0) result.excluded = _.map(term.excluded, 'text');

        return result;
    }

    function buildTerms(terms) {
        var result = {
            terms: [],
            bl_terms: []
        };

        _.each(terms, (term) => {
            if (term.type == 'booleanLogic' || term.type == 'programBL') {
                result.bl_terms.push(booleanLogicTerm(term));
            } else {
                result.terms.push(term.text);
            }
        });

        return result;
    }

    //track page view
    var lastApp = 'start session';
    var lastWidget = 'start session';

    function trackPageView(currentApp, currentWidget) {
        if (angular.equals(currentApp, lastApp) && angular.equals(currentWidget, lastWidget)) return;
        var eventName = 'Overview - Page View';
        var props = {
            appName: currentApp,
            widget: currentWidget,
            lastApp: lastApp,
            lastWidget: lastWidget
        };
        sendToMixpanel(eventName, props);
        lastApp = currentApp;
        lastWidget = currentWidget;
    }

    function trackSearch(currentApp, currentWidget, terms, refinedTerms) {
        var eventName = 'Overview - Seed Searches';
        var props = {
            appName: currentApp,
            widget: currentWidget,
            terms: terms,
            refinedTerms: refinedTerms
        };
        sendToMixpanel(eventName, props);
    }

    function trackExport(currentApp, widgetName) {
        var eventName = 'Export';
        var props = {
            appName: currentApp,
            widget: widgetName
        };
        sendToMixpanel(eventName, props);
    }

    function trackHelpCenter(helpPageName) {
        var eventName = 'Overview - Help Center';
        var props = {
            helpPage: helpPageName
        };
        sendToMixpanel(eventName, props);
    }

    return {
        sendToMixpanel: sendToMixpanel,
        extractWidget: extractWidget,
        trackExport: trackExport,
        buildTimeframe: buildTimeframe,
        buildProgram: buildProgram,
        buildTerms: buildTerms,
        trackHelpCenter: trackHelpCenter,
        trackPageView: trackPageView,
        trackSearch: trackSearch
    }
}]);
