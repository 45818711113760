"use strict";
module.exports = angular.module(__filename, []).directive('errSrc', function () {
    return {
        priority: -1,
        link: function (scope, element, attrs) {
            function handleError() {
                if (attrs.src != attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                    if (attrs.errClass) element.addClass(attrs.errClass)
                }
            }

            if (attrs.ngSrc == '') {
                attrs.$set('src', attrs.errSrc);
            } else {
                element.bind('error', function () {
                    handleError();
                });
                element.bind('load', function () {
                    if (element.context.naturalHeight < 10) {
                        handleError();
                    }
                });
            }
        }
    }
});