"use strict";
angular.module(__filename, [
    require('common/modals/confirmation/confirm-action.modal.service.js').name,
    require('pages/settings/common/permissions-panel.drv/permissions-panel.drv.js').name,
    require("angular-modal-service").name,
    require("./organization-edit").name,
    require("../../infra/mixpanel/mixpanel-settings").name

]).controller('OrganizationsController', ['$timeout', '$scope', '$rootScope', 'mgmtServiceFactory', 'ModalService', 'confirmAction', 'notificator', '$q','geoService', 'mixpanelSettings',
    function ($timeout, $scope, $root, mgmtServiceFactory, ModalService, confirmAction, notificator, $q, geoService, mixpanelSettings) {
        var service = mgmtServiceFactory.createService('organization');
        populate();
        $scope.editOrganization = editOrganization;
        $scope.deleteOrganization = deleteOrganization;
        $scope.canDeleteOrg = canDeleteOrg;
        $scope.animateSize = animateSize;
        mixpanelSettings.trackPageView('organizations');
        function populate() {
            $scope.loading = true;
            var promises = [];
            var listRequest = service.list().then(function (data) {
              $scope.organizations = data;
            });
            var optionsRequest = service.customRequest('GET', '/options').then(function (data) {
              $scope.generalPermissions = data.permissions;
              $scope.generalCountries = data.countries;
            });
            $q.all([listRequest, optionsRequest]).then(function() {
              $scope.gotData = true;
            }).finally(function(){
              $scope.loading = false;
            });
        }

        function formatPermissions(org) {
          var gPermissions = angular.copy($scope.generalPermissions);
          if (org == undefined) {
            return gPermissions.map( function(p) {
              p.on = p.default_on;
              return p;
            });
          } else {
            return gPermissions.map( function(p) {
              p.on = _.includes(_.map(org.permissions, 'id'), p.id);
              return p;
            });
          }
        }

        function formatCountries(org) {
          var gCountries = angular.copy($scope.generalCountries);
          if (org == undefined) {
            return gCountries.map( function(c) {
              c.selected = false;
              return c;
            });
          } else {
            return gCountries.map( function(c) {
              c.selected = _.includes(_.map(org.countries, 'id'), c.id);
              return c;
            });
          }
        };

        function canDeleteOrg(org) {
            return (org.users_count + org.admins_count) === 0;
        }

        function deleteOrganization(id) {
            var idMap = $scope.organizations.map(function (org) {
                return org.id;
            });
            var orgInx = idMap.indexOf(id);
            var confirmationMsg = "Deleted organization cannot be retrieved, do you wish to proceed?";
            confirmAction.getConfirmation(confirmationMsg).then(function (modal) {
                modal.close.then(function (confirmation) {
                    if (confirmation) executeDelete(id);
                });
            });

            function executeDelete(id) {
                service.delete(id).then(function () {
                    $scope.organizations.splice(orgInx, 1);
                });
            }
        }

        function editOrganization(org) {
            if (org == undefined) {
                var organization = {};
                openEditModal(organization);
            } else {
                service.get(org.id)
                  .then(function(organization) {
                    openEditModal(organization);
                  });
            }
        }

        function animateSize(e) {
            $(e.toElement).addClass('animate-bloat');
            $timeout(function () {
                $(e.toElement).removeClass('animate-bloat')
            }, 500);
        }

        function openEditModal(org) {
            ModalService.showModal({
                template: require('./organization-dialog.html'),
                inputs: {
                    organization: org,
                    service: service,
                    permissions: formatPermissions(org),
                    countries: formatCountries(org)
                },
                controller: 'organizationEditController'
            }).then(function (modal) {
                modal.close.then(function (result) {
                    if (result != undefined && result.reload) populate();
                });
            });
        }
    }
]).stateConfig = {
    name: 'organizations',
    url: '/organizations',
    template: require('./organizations.html'),
    controller: 'OrganizationsController',
    display: 'Settings'
};

module.exports = angular.module(__filename);
