"use strict";
var loginModule = angular.module(__filename, []);

function LoginController($scope, $stateParams, authentication, forgotPasswordModal, abiNotificationModal, notificator, amDeviceDetector) {
    $scope.user = {};
    $scope.rememberMe = true;
    $scope.serverError = false;
    $scope.showLogin = false;
    $scope.serverErrorText = '';
    $scope.login = login;
    $scope.openForgotPwdModal = forgotPwd;
    $scope.loginDisabled = false;
    $scope.isBusy = false;
    $scope.isSupportedDevice = amDeviceDetector.isSupportedDevice();

    var message;
    if ($stateParams.msg === 'timeout') {
        message = 'Session timeout. Please relogin';
        notificator.error({body: message, timeout: 10000});
        $scope.serverError = true;
        $scope.serverErrorText = message;
    }

    if ($stateParams.msg === 'version') {
        message = 'New version is available, please relogin';
        notificator.notify({body: message, timeout: 10000});
        $scope.serverError = true;
        $scope.serverErrorText = message;
    }

    function login() {
        $scope.isBusy = true;
        $scope.loginDisabled = true;
        $scope.serverError = false;
        authentication.login($scope.user.username, $scope.user.password, $scope.rememberMe).catch(function (res) {
            $scope.serverError = true;
            $scope.serverErrorText = "Unknown user name / password";
            $scope.loginDisabled = false;
        }).finally(function() {$scope.isBusy = false;} );
    }

    function forgotPwd() {
        $scope.showLogin = false;
        $scope.showReset = true;
        forgotPasswordModal.show().then(function (modal) {
            modal.close.then(function (success) {
                if (success) {
                    abiNotificationModal.show('good', 'Reset Password', 'A password reset link has been sent to your Email').
                        then(function (modal) {
                            modal.close.then(function () {
                                $scope.showReset = false;
                            });
                        });
                } else {
                    $scope.showReset = false;
                }
            })
        });
    }
}

LoginController.$inject = ['$scope', '$stateParams', 'authentication', 'forgotPasswordModal', 'abiNotificationModal', 'notificator', 'amDeviceDetector'];
loginModule.stateConfig = {
    name: "login",
    url: "/login",
    display: "login",
    params: {msg: null, help: null, next: null},
    template: require("./login.html"),
    controller: LoginController
};

module.exports = loginModule;
