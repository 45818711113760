"use strict";
/**
 * Created by Pery on 26/01/2015.
 */
module.exports = function postLinkOneFromOne(scope, element, attr, ctrls) {
    if (!ctrls || !ctrls[0]) return;
    var ctrl = ctrls[0];

    var inputCtrl = scope.inputCtrl;

    var PARTIAL = 2;
    var trueValue = attr.ngTrueValue || 'true';
    var falseValue = attr.ngFalseValue || 'false';
    var partialValue = attr.partialValue || '"partial"';

    scope.attr = attr;
    if (attr.textBefore) {
        scope.$watch('attr.textBefore', function (nv) {
            scope.textBefore = nv;
        });
    }
    if (attr.textAfter) {
        scope.$watch('attr.textAfter', function (nv) {
            scope.textAfter = nv;
        });
    }

    //if(scope.returingArray == void 0)
    //    inputCtrl.returingArray = false;

    element.click(function (event) {
        if (attr.disabled) return;
        inputCtrl.$event = event;
        toggleChecked();
    });

    function render(value) {
        element.removeAttr('checked');
        element.removeAttr('indeterminate');
        if (value) {
            element.attr('checked', 'checked');
            (value == PARTIAL) && element.attr('indeterminate', 'indeterminate');

            element.addClass('On')
                .removeClass('Off');
        } else {
            element.addClass('Off')
                .removeClass('On');
        }
    }

    ctrl.render = function () {
        render(ctrl.$viewValue);
    };

    var initViewValue = attr.checked ? attr.indeterminate ? PARTIAL : true : false;
    //todo: something not work here when modelValue init before directive build
    //ctrl.$setViewValue( ctrl.$viewValue || initViewValue );
    ctrl.render(ctrl.$viewValue);

    function toggleChecked() {
        ctrl.$setViewValue(!ctrl.$modelValue);
        ctrl.render();
    }

    ctrl.$formatters.push(function (value) {
        switch (value) {
            case scope.$parent.$eval(trueValue):
                value = true;
                break;
            case scope.$parent.$eval(falseValue):
                value = false;
                break;
            case scope.$parent.$eval(partialValue):
                value = PARTIAL;
                break;
        }
        render(value);
        return value;
    });
    ctrl.$parsers.unshift(function (value) {
        value = value ? (value == PARTIAL) ? partialValue : trueValue : falseValue;
        value = scope.$parent.$eval(value);
        ctrl.render(value);
        return value;
    });
};
