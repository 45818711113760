"use strict";
var config = require("infra/config");

module.exports = angular.module(__filename, []).service('emailService', ['$http', 'notificator', function ($http, notificator) {
    var HOST = config.USER_MGMT_API;
    var URL = HOST + '/email';
    var suffix = '?disable_notification=1';
    var TRACK_PHRASE_SUCCESS = 'Thank you, phrase will be available within 48 hours';
    var CONTACT_US_SUCCESS = 'Thanks for your input! We will review it and get back to you shortly';

    return {
        trackPhrase: sendTrackingEmail,
        contactUs: sendContactUsEmail
    };

    ////////////////////////////////
    function sendTrackingEmail(uId, phrase, language) {
        var url = URL + '/track_phrase' + suffix;
        var params = {
            id: uId,
            phrase: phrase,
            language: language
        };

        return $http.post(url, params).then(
            function (response) {
                return success(response, TRACK_PHRASE_SUCCESS);
            },
            error
        );
    }

    function sendContactUsEmail(uId, subject, message, attachments) {
        var url = URL + '/contact_us';
        var fd = new FormData();
        fd.append('id', uId);
        fd.append('subject', subject);
        fd.append('message', message);
        attachments.forEach(function (attachment) {
            fd.append('files[]', attachment);
        });

        return $http.post(url, fd, {headers: {'Content-Type': undefined}}).then(
            function (response) {
                return success(response, CONTACT_US_SUCCESS);
            },
            error
        );
    }

    function success(response, notice) {
        notificator.success({body: notice});
        return response;
    }

    function error(error) {
        var notice = "Sorry, an error has occurred while processing your request";
        if (error.status == 404) notice = "Sorry, service is unavailable please try again later";
        if (error.status >= 500) notice = "Oops, something went wrong our engineers are working on it";
        notificator.error({body: notice});
        return Promise.reject(error);
    }
}]);
