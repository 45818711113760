"use strict";
var d3 = require("d3"),
    c = require("infra/utils/common");

module.exports = require("angular").module(__filename, [])
  .directive("amHourlyHeatmapChart", [function() {
    return {
      restrict: 'E',
      scope: {
        data: '<',
        chartHeight: '<',
        chartWidth: '<'
      },
      template: require('./hourly-heatmap-chart.html'),
      link: function ($scope, $element, $attrs, $ctrl) {
          var MARGIN = { height: 65, width: 175 },
              GRID_SPACE = 1,
              AVG_SPACE = 12,
              PANEL_HEIGHT = 60,
              ADDED_MARGIN = 60,
              MIN_CARD_SIZE = 25;

          var hours = c.getHeatmapHours(),
              hourNumbers = c.getHeatmapHourNumbers(),
              denoms = Array(12).fill('am').concat(Array(12).fill('pm'));

        $scope.$watch('[chartWidth,chartHeight]', function () {
          $scope.update();
        });

        $scope.$watchCollection(function(){
          if(!c.is($scope.data)) return false;
          return $scope.data.labels;
        }, function () {
          $scope.update();
        });

        $scope.$watchCollection(function(){
          if(!c.is($scope.data) || !c.isArray($scope.data.seeds)) return false;
          return _.flatten(_.map($scope.data.seeds, function(record){return _.map(_.sortBy(record.hour_totals, _.first), _.last)}));
        }, function () {
          $scope.update();
        });

        $scope.update = function () {
          if ($scope.chartWidth === undefined || !c.is($scope.data) || !$scope.data.hasOwnProperty('seeds') || !c.isArray($scope.data.seeds)) {
            return;
          }
          var isSmallResolution = $scope.chartWidth < 1100 || $scope.chartHeight < 480;
          $scope.heatmapData = [];
          $scope.heatmapColors = [];
          var num_seeds = $scope.data.seeds.length;
          _.each($scope.data.seeds,function(record){
            $scope.heatmapData = $scope.heatmapData.concat(_.map(_.sortBy(record.hour_totals, _.first), _.last));
            $scope.heatmapColors = $scope.heatmapColors.concat(_.map(_.sortBy(record.hour_totals_colors, _.first), _.last));
          });
          $scope.seedNames = _.map($scope.data.labels, function(label){
            var result = {label: c.cutText(label,13)};
            if(result.label != label){
              result.tooltip = label;
            }
            return result;
          });
          $scope.heatmapAverages = $scope.data.average_hours;
          $scope.heatmapAveragesColors = $scope.data.average_hours_colors;
          var cardSizeByWidth = Math.floor(($scope.chartWidth - MARGIN.width - (23 * GRID_SPACE)) / 24);
          var cardSizeByHeight = Math.floor(($scope.chartHeight - MARGIN.height - ((num_seeds - 1) * GRID_SPACE) - (2 * AVG_SPACE) - PANEL_HEIGHT - ADDED_MARGIN) / num_seeds);
          $scope.cardSize = Math.max(MIN_CARD_SIZE, Math.min(cardSizeByWidth, cardSizeByHeight));
          $scope.hourLabels = isSmallResolution ? denoms : hours;
          $scope.addedHourLabels = isSmallResolution ? hourNumbers : null;
          $scope.posTop = isSmallResolution ? 50 : 35;
          $scope.posLeft = 145;
          if($scope.cardSize < cardSizeByWidth){
            $scope.posLeft += 12 * (cardSizeByWidth - $scope.cardSize - GRID_SPACE); //Bringing heatmap to center of widget
          }
          $scope.titleMargin = $scope.posLeft - 107;
        };

        $scope.update();
      }
    }
  }]);
