"use strict";
DiscoveryViewSwitcherController.$inject = ["$scope", '$state', 'abiPermissions'];

function DiscoveryViewSwitcherController($scope, $state, abiPermissions) {
  $scope.$state = $state

  $scope.moreThanOneButton = function() {
    var self = this;
    var getPermissions = _.property("data.permissions")
    var subApps = _(_.map(self.$state.get("discovery").children));
    var activeButtons = subApps.map(function(s) { return getPermissions(self.$state.get(s)); })
    .map(function(p) { return +abiPermissions.hasPermission(p); }).sum();
    return activeButtons > 1;
  };
}

module.exports = angular.module(__filename, []).directive('amDiscoveryViewSwitcher', ['context', 'abiPermissions', function (context, abiPermissions) {
  return {
    restrict:'E',
    template:require('./discoveryViewSwitcher.drv.html'),
    controller: DiscoveryViewSwitcherController,
    link:function(scope, element, attr, ctrl){
      scope.context = context;
      scope.hasPermission = abiPermissions.hasPermission;
    }
  }
}]);
