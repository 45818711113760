"use strict";
module.exports = require("angular").module(__filename, [
    require("../am-gauge-bar.drv/am-gauge-bar.drv").name
])
    .directive('segmentSize', [() => ({
        restrict: 'E',
        template: require('./segment-size.drv.html'),
        scope: {
            "segmentSize": "=",
            "population": "="
        },
        link: function($scope) {
            $scope.$watch("population", function() {
                $scope.percents = Math.min($scope.segmentSize, 100);
            })
        }
    })]);
