"use strict";
module.exports = angular.module(__filename, [
    require('common/am-range-slider.drv/am-range-slider.drv').name
]).service('demographicsModal', ['ModalService', function (ModalService) {
    this.showModal = showModal;

    function showModal(demographics, isNewSegment, disableGeo) {
        return ModalService.showModal({
            template: require('./demographics-modal.html'),
            inputs: {demographics: demographics, isNewSegment: isNewSegment, disableGeo: disableGeo},
            controller: ['$scope', 'close', 'filtersPartition', 'demographics', 'isNewSegment', 'disableGeo', DemographicsModalCtrl]
        });
    }

    function DemographicsModalCtrl($scope, close, filtersPartition, demographics, isNewSegment, disableGeo) {
        const US = _.filter($scope.Geos.geos, (geo) => geo.id == 840)[0];
        const ALL_GEOS = {label: 'All Geos', value: 'all', summary: 'All Geos', id: -1};
        const ALL_ETHNICITIES = filtersPartition.racesWithAll[0];

        $scope.isNewSegment = isNewSegment;
        $scope.demographics = _.extend({gender: null, age: null, income: null, children: null, geo: ALL_GEOS, ethnicity: ALL_ETHNICITIES}, demographics);
        $scope.demographics.geo = $scope.demographics.geo && $scope.demographics.geo.length ? $scope.demographics.geo[0] : ALL_GEOS;
        $scope.demographics.ethnicity = $scope.demographics.ethnicity && $scope.demographics.ethnicity.length ? $scope.demographics.ethnicity[0] : ALL_ETHNICITIES;
        $scope.disableGeo = disableGeo;
        $scope.close = close;
        $scope.geos_with_all = [ALL_GEOS].concat($scope.Geos.geos);
        $scope.shouldResetIncome = false;

        $scope.all_incomes = ["$0K", "$25K", "$50K", "$75K", "$100K", "$150K", "$200K", "Over $200K"]
        $scope.income_from = ($scope.demographics.income || []).length ? convert_income_to_index($scope.demographics.income[0]) : 0;
        $scope.income_to = ($scope.demographics.income || []).length ? convert_income_to_index($scope.demographics.income[$scope.demographics.income.length - 1]) + 1 : $scope.all_incomes.length - 1;
        $scope.demographics.income = filtersPartition.newIncome.slice($scope.income_from, $scope.income_to);

        $scope.on_income_selected = function(data) {
            $scope.demographics.income = filtersPartition.newIncome.slice(data.from, data.to);
            $scope.$apply();
        };

        $scope.$watch("demographics.ethnicity", function (a, b) {
            if ($scope.demographics.ethnicity.value != ALL_ETHNICITIES.value && $scope.demographics.geo.id == ALL_GEOS.id) $scope.demographics.geo = US;
        });

        $scope.$watch("demographics.income", function (a, b) {
            if ($scope.demographics.income.length != filtersPartition.newIncome.length && $scope.demographics.geo.id == ALL_GEOS.id) $scope.demographics.geo = US;
        });

        $scope.$watch("demographics.geo", function (a, b) {
            if ($scope.demographics.geo.id != "840") {
              $scope.demographics.ethnicity = ALL_ETHNICITIES;
              $scope.shouldResetIncome = !$scope.shouldResetIncome;
              $scope.demographics.income = filtersPartition.newIncome.slice(0,filtersPartition.newIncome.length);
            }
        });

        function convert_income_to_index(income){
            return _.map(filtersPartition.newIncome, 'value').indexOf(income.value)
        }

        $scope.iconToLabelMap = {};
        filtersPartition.genders.forEach(g => { $scope.iconToLabelMap[g["icon"]] = _.capitalize(g["audienceLabel"]) });

        $scope.isUsSelected = function () {
            return this.demographics.geo.id == "840" || this.demographics.geo.id == ALL_GEOS.id;
        };

        $scope.isValid = function () {
            return !_.isEmpty(removeEmptyFilters(this.demographics))
        };

        function removeEmptyFilters(demographics) {
            let results = {};
            if ((demographics.gender || []).length > 0) results.gender = demographics.gender;
            if ((demographics.age || []).length > 0 && (demographics.age || []).length < filtersPartition.age.length) results.age = demographics.age;
            if ((demographics.income || []).length < $scope.all_incomes.length - 1) results.income = demographics.income;
            if ((demographics.children || []).length > 0) results.children = demographics.children;
            if (demographics.geo.id != ALL_GEOS.id) results.geo = [demographics.geo];
            if (demographics.ethnicity.value != ALL_ETHNICITIES.value) results.ethnicity = [demographics.ethnicity];
            return results;
        }

        $scope.save = function () {
            close(_.extend(removeEmptyFilters(this.demographics), {type: "demographics", operand: this.demographics.operand}));
        }
    }
}]);
