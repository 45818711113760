"use strict";
module.exports = angular.module(__filename, [require('./program-service').name]).
    service('userMgmt', ['programService', 'mgmtServiceFactory', function (programService, mgmtServiceFactory) {
        var service = mgmtServiceFactory.createService('user');

        return {
            options: getUserOptions,
            list: service.list,
            user: service.get,
            add: addUser,
            update: updateUser,
            bulkEdit: bulkEditUsers,
            deleteUser: service.delete,
            resetPassword: resetUserPassword,
            resetPasswordRequest: resetPasswordRequest,
            buildUser: buildUser
        };

        ///////////////////////////
        function buildUser(user) {
            var built = {
                id: user.id,
                name: user.username,
                email: user.email,
                userType: user.user_type.name,
                account: user.account,
                programs: user.programs.map(function (obj) {
                    return programService.buildProgram(obj);
                }),
                permissions: user.permissions
            };
            built.programs = _.sortBy(built.programs, [function(p) { return p.name != "my interests"; }, function(p) { return p.name.toLowerCase(); }]);
            return built;
        }

        function getUserOptions(id) {
            return service.customRequest('GET', '/' + id + '/options');
        }

        function addUser(user) {
            return service.create(user).then(buildUser);
        }

        function updateUser(id, params) {
            return service.update(id, params).then(buildUser);
        }

        function bulkEditUsers(bulkRequest) {
          return service.customRequest('PUT', '/bulk', bulkRequest);
        }

        function resetUserPassword(id, password) {
            return service.customRequest('POST', '/' + id + '/reset_password', password);
        }

        function resetPasswordRequest(email) {
            return service.customRequest('POST', '/reset_password', {email: email});
        }
    }]
);
