"use strict";
module.exports = require("angular").module(__filename, [])
    .directive("audienceFilter", [function() {
        return {
            restrict: 'E',
            scope: {
                context: '=',
                debounceGroup: '<',
                audienceSummaryOrder: '<',
                filter: '<',
                channels: '<'
            },
            template: require('./audience-filter.html'),
            link: function ($scope, $element, $attrs, $ctrl) {

                $scope.showFirstPartyAudienceForArticles = true;
                $scope.refreshAudienceSummary = 0;
                // temp fix for old snapshots, after changing label from 12-17 to 13-17
                $scope.context.current.audience.forEach(function (a) { if (a.summary == "12-17") a.summary ="13-17"});

                var refreshAudienceSummary = function () {
                    $scope.refreshAudienceSummary++;
                };

                $scope.determineMold = function () {
                    if(_.isEqual($scope.channels, ['sg_telco'])){
                        $scope.mold = $scope.context.current._sgTelcoAudience_mold;
                    } else {
                        $scope.mold = $scope.context.current._audience_mold;
                    }
                };

                $scope.hideOrShowEthnicity = function () {
                    $scope.showEthnicityForArticles = (!$scope.channels || _.isEmpty(_.difference($scope.channels, ['articles', 'videos']))) && $scope.isGeoUsSelected();
                };

                $scope.onChannelsUpdate = function () {
                    $scope.channels = _.castArray($scope.channels);
                    $scope.determineMold();
                    refreshAudienceSummary();
                    $scope.hideOrShowEthnicity();
                    $scope.showChildren = $scope.showIncome = _.isEmpty(_.difference($scope.channels, ['articles', 'videos']));
                    $scope.showSgTelcoEthnicitiesAndNationalities = _.isEqual($scope.channels, ['sg_telco']);
                };

                $scope.isGeoUsSelected = function () {
                    return ($scope.context.current.geo.length == 0 || _.some($scope.context.current.geo, {cc: "US"}))
                };

                $scope.onChannelsUpdate();

                $scope.$watch('context.current.geo', $scope.hideOrShowEthnicity);
                $scope.$watch('channels', $scope.onChannelsUpdate);
                $scope.$watch('context.program', $scope.onChannelsUpdate, true);
                $scope.$watch('context.current.audience', refreshAudienceSummary);
                $scope.$watch('context.current.sgTelcoAudience', refreshAudienceSummary);
                $scope.$watch('context.current.userFirstPartyAudience',refreshAudienceSummary);
            }
        };
    }]);
