"use strict";
module.exports = angular.module(__filename, []).directive('favIconDisplayer', [function () {
    var GOOGLE_FAV_ICON_API = "https://www.google.com/s2/favicons?domain=";
    return {
        restrict: 'E',
        template: require('./fav-icon-displayer.drv.html'),
        link: function ($scope, $element, $attr) {
            $scope.favicon_width = $attr.width || 16;
            $scope.favicon_height = $attr.height || 16;
            $scope.favicon_url = getFavIconUrl($attr.url, $attr.domain);
            /*
             Other methods of retrieving favicons:
             $scope.favicon_url = $attr.domain + '/favicon.ico';
             get multiple domains together (need to separate to different requests if the num of domains too big, to not exceed URI max length):
             http://jsfiddle.net/95A4h/ or http://jsfiddle.net/tx2z/WTAr9/225/
             $scope.favicon_url = "https://icons.better-idea.org/icon?size=32&fallback_icon_url=/images/common/no-available-image.svg&url=" + $attr.domain
             */

            function getFavIconUrl(url, domain) {
                return domain.startsWith('google.') ? getGoogleFavIcon(url) : GOOGLE_FAV_ICON_API + $attr.domain
            }

            function getGoogleFavIcon(url) {
                if (url.match(/^https?:\/\/www.google\.[a-z]{1,3}(\.[a-z]{1,2})?\/maps/)) return GOOGLE_FAV_ICON_API + "https://www.google.com/maps/";
                var subdomain = url.split(/[\.//]/)[2];
                if (['play','chrome','calendar','classroom','adwords','inbox','business','news','tagmanager','appengine','aboutme','sites','cse','fi','enterprise'].indexOf(subdomain) > -1) return GOOGLE_FAV_ICON_API + subdomain + ".google.com";
                if (subdomain == 'productforums') return GOOGLE_FAV_ICON_API + "https://productforums.google.com";
                if (subdomain == 'analytics') return GOOGLE_FAV_ICON_API + "https://www.google.com/intl/en/analytics";
                if (subdomain == 'keep') return GOOGLE_FAV_ICON_API + "https://www.google.com/keep";
                // could not retrieve right icon for inbox,classroom,calendar,adwords,tagmanager,appengine         
                return url.split('.')[0] + ".google.com/favicon.ico";
            }
        }
    }
}]);