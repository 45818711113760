"use strict";
var tmpl = require("./quick-refine-seed.html");

var popupSettings = {
    position: {
        adjust: {method: 'shift'},
        my: 'top center',
        at: 'bottom center'
    },
    hide: {
        target: $(tmpl),
        event: 'click unfocus',
        fixed: true
    },
    show: {ready: true, solo: false, event: 'click'},
    style: {classes: 'example-popup-refine popup-window', tip: {width: 20, height: 10}}
};

module.exports = angular.module(__filename, [
    require('../popup/popup-service').name
]).service("quickRefinePopupService", ['popupService', function (popupService) {
    return {
        activate: function (elem, data, bindings) {
            var extendedData = {};
            angular.extend(extendedData, data);
            popupService.pop(elem, extendedData, popupSettings, tmpl, bindings);
        }
    }
}]);
