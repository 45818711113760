"use strict";
module.exports = require("angular").module(__filename, [
    require("../../common/element-selector.drv/element-selector.drv").name
]).directive('topicSelector', ['topicsTree', function(topicsTree) {
        return {
            restrict: 'E',
            template: require('./topic-selector.drv.html'),
            scope: {
                "topics": "=",
                "onSelect": "&"
            },
            compile: function() {
                return {
                    pre: function($scope, $element) {
                        $scope.$watch('topics', function() {
                            $scope.data = $scope.topics;
                            if($scope.data && $scope.data.length) {
                                if($scope.data[0].id != 'All Interests') $scope.data.unshift({id: 'All Interests'});
                                $scope.data.forEach(function(topic) {
                                    topic.title = topic.id;
                                    topic.image = `./images/images/topics/${topic.id.trim().toLowerCase().split(' ').join('_')}.png`;
                                })
                            }
                        });

                        $scope.onSelect = $scope.onSelect() ? $scope.onSelect() : null;
                    }
                }
            }
        }
    }]
);
