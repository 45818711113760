"use strict";

////marketListHelper

var config    = require("infra/config");
var marketListHelperScope = null;
var gotMarkets = false;
var ACTIVATE_TO_ALLMARKETS = "Activate to all markets";

module.exports = {

    init: function(scope, http, context){

        this.context = context;
        marketListHelperScope = scope;
        this.scope = scope;
        this.http = http;

        this.showMarketDirective = false;
        this.marketErr = "";

        if(!gotMarkets){
            this.savedMarket = "";
            this.marketsResponse = [];
            this.marketList = [];
            this.getMarkets();
        }

        this.checkAllMarkets();
        this.getSavedMarket();     
    },

    setCurrentTargetsMarkets: function(me){

        //change current target by market selection
        me.isActivated = false;
        if (me.currentTarget.activated_to && me.currentTarget.activated_to.length) { //set options select by activated_to
            var i, option, len = me.activateOptions.length;

            for (i = 0; i < len; i++) {
                option = me.activateOptions[i];
                option.selected = me.currentTarget.activated_to.indexOf(option.id) != -1;

                if (option.selected) {
                    me.isActivated = true;
                    me.activatedOption = option;//single platform
                    this.showMarketDirective = (option.id === "turn");
                }
            }
        }
    },


    getMarkets: function(){
       var that = this;
       that.http.get(config.USER_MGMT_API + '/dsp/markets').
          success(function(markets) {
             that.marketsResponse = markets;
             that.formatMarketData(markets);
             gotMarkets = true;
          });
    },

    checkAllMarkets: function(){
        var that = this;

        var isAllMarkets = that.marketList.find(item => item.market === ACTIVATE_TO_ALLMARKETS);
        var perm = that.scope.hasPermission('activate to all markets');

        //will show the customed first underlined "Activate to all markets" as the top option
        if(perm && !isAllMarkets){
            var allMarketsStr = {"market" : ACTIVATE_TO_ALLMARKETS, "id": 0, "specialTitle":true};
            that.marketList.unshift(allMarketsStr);
        }else if(!perm && isAllMarkets){
            that.marketList = that.marketList.slice(1);
        }
    },

    formatMarketData: function(marketsResponse){
        var that = this;
        var fieldToSort="marketId"; //or "title" or "name"
        const sortedArray = _.chain(marketsResponse).pickBy(_.identity).sortBy(o => parseInt(o[fieldToSort])).value();
        that.marketList =  _.map(sortedArray, item => ({
          market: `${item.marketId} - ${item.name}`,
          id: item.marketId
        }));

        $.each(sortedArray, function (i, cell) {
            var str = {"market" : cell.marketId + " - " + cell.name, "id": cell.marketId};
            that.marketList.push(str);
        });

        that.getSavedMarket();
    },

    getSavedMarket: function(marketsResponse){
       var that = this;

       //handles the special custom "Activate to all markets"
       if(that.scope.editTargetCtrl.currentTarget.market_id === 0){
            that.savedMarket =  ACTIVATE_TO_ALLMARKETS;
       }else if(that.marketsResponse){
           //the autocomplete (market) saved value to display
            var savedMarket = that.marketsResponse.find(item => item.marketId === that.scope.editTargetCtrl.currentTarget.market_id);
            that.savedMarket = (savedMarket && savedMarket.marketId)? savedMarket.marketId + " - " + savedMarket.name : "";
       }
    },

    toggleMarket: function(state){
        var that = this;

        if(state == "turn" || state === true){
            that.showMarketDirective = true;
            that.scope.editTargetCtrl.currentTarget.market_id = "";
        }else{
           that.showMarketDirective = false;
           that.marketErr = "";
           that.scope.editTargetCtrl.updateTarget();
        }
    },



    //callback from the directive when a market is selected
    onMarketSelect: function(selected){
        if(selected == null){return;}
        //var that = this;
        var that = marketListHelperScope.marketListHelper;

        that.scope.editTargetCtrl.currentTarget.market_id = selected.id;
        that.marketErr = "";
        that.scope.editTargetCtrl.updateTarget();

    },


    onMarketErr: function(msg){
        var that = this;
        that.marketErr = "error";
    },

};
