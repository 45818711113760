"use strict";
"use strict";
/**
 * Created by pery on 13/02/2015.
 */
/**
 * ALERT: this is a expert infrastructure code, Hard to read Hard to Change Easy to use.
 * so if you plan reading, take your time.
 * if you plan changing, know what you do. not changed as 'by the way'
 * if you plan to use, read the comments or find example
 */
module.exports = angular.module(__filename, [])
    .service('directiveBuildHelper', ['$parse', function ($parse) {
        /** API */
        this.breaksNaturalSentence = getSemiNaturalSentenceBreaker;
        this.compile = compile;

        /**
         * ((@modelMapper as)? (@itemLabel for)? (_instance_ in @source@) (branch by @branch)? (index by @indexBy)?
         *
         * take sentence like above and convert it to regex that can take sentence and break then to part
         * so it can break sentence like:
         * "{name:topic.name,id:topic.id} as topic.name for topic in topicsFilter branch by topic.children index by topic.id"
         **/
        function getSemiNaturalSentenceBreaker(describesPattern) {
            var FIND_GROUP = /(\([\s\S]+?\)\??)/g;
            var groups = describesPattern.match(FIND_GROUP);
            var HARD_WHITE_SPACE = '\\s+';
            var SOFT_WHITE_SPACE = '\\s*';
            var SUPER_HARD_WHITE_SPACE = '\\s';
            var EXPIRATION_WITH_SPACES = '([\\s\\S]+?)';
            var EXPIRATION_WITHOUT_SPACES = '([\\S]+?)';
            var keywords = [];
            var alias = [];
            var sources = [];
            var expressions = [];

            function replaceAndCollect(replacerExpression, i, collectionIndices) {
                return function (match, p1, offset, string) {
                    //added to keyword collection for save consist index reference
                    keywords.push(p1);

                    collectionIndices && collectionIndices.push(keywords.length - 1);

                    return replacerExpression
                }
            }

            groups = groups.map(function (group, i) {
                group = group
                    //replace _str_ with exiration represent instance name
                    .replace(/_([\w]+)_/g, replaceAndCollect(EXPIRATION_WITHOUT_SPACES, i, alias))
                    //replace @exp@ with exiration represent source array
                    .replace(/@([\w]+)@/g, replaceAndCollect(EXPIRATION_WITHOUT_SPACES, i, sources))
                    //replace !@ with expiration finder(not return word after space)
                    .replace(/!@([\w]+)/g, replaceAndCollect(EXPIRATION_WITHOUT_SPACES, i, expressions))
                    //replace @ with expiration finder(can take white space, stop just if find next group match)
                    .replace(/@([\w]+)/g, replaceAndCollect(EXPIRATION_WITH_SPACES, i, expressions))
                    //replace white space with regex
                    .replace(/\s+/g, HARD_WHITE_SPACE)
                    //repace _ with just one white space
                    .replace(/_/g, SUPER_HARD_WHITE_SPACE);

                return group[0] + '?:' + group.slice(1);
            });

            var sentence = '^' + SOFT_WHITE_SPACE + groups.join(SOFT_WHITE_SPACE) + SOFT_WHITE_SPACE + '$';
            var regex = new RegExp(sentence);
            regex.keywords = keywords;
            regex.alias = alias;
            regex.sources = sources;
            regex.expressions = expressions;
            return regex
        }

        //'(@modelMapper as)? (@itemLabel for)? (@instance in @source) (branch by @branch)? (index by @indexBy)?'
        function compile(describesPattern) {
            var regexParser = getSemiNaturalSentenceBreaker(describesPattern),
                keywords = regexParser.keywords,
                alias = regexParser.alias,
                expressions = regexParser.expressions,
                sources = regexParser.sources;

            expressions.push(alias[0]); // identity expiration

            return function parser(expression, scope) {
                var matches = expression.match(regexParser);
                if (!matches) {
                    console.error('iexp', "Expected expression in form of '" + describesPattern + "' but got '{0}'.Element: {1}");
                }
                matches.shift(); //first match not needed so it equal to all sentence.
                var s = {}; // little scope
                function getter(i) {
                    var m = matches[i];
                    if (!m)return void 0;
                    var getterFn = $parse(m);
                    return function (item) {
                        s[matches[alias[0]]] = item;
                        return getterFn(scope, s);
                    };
                }

                var expressionsGetters = _.transform(expressions, function (result, iFn, i, obj) {
                    result[keywords[iFn]] = getter(iFn);
                }, {});

                function converter(item) {
                    var handler = {};
                    _.forEach(expressionsGetters, function (exp, key) {
                        /*more simple for most of cases*/
                        handler[key] = exp && exp(item);
                    });
                    //todo:add a way to get original item
                    handler.original = item;
                    return handler;
                }

                var source;

                /*how to `forEach` every item in the array*/
                var wrapperCollection = {
                    array /**default*/: function (items, handler) {
                        return angular.isArray(items) ? _.map(items, handler) : items;
                    },
                    tree: function (items, converter) {
                        return items && items.map(function (item) {
                                item = converter(item);
                                /*branch should mandatory in tree?*/
                                if (item.branch) {
                                    item.branch = _.map(item.branch, function (item) {
                                        return converter(item)
                                    });
                                }
                                return item;
                            })
                    }
                };

                var wrapper = wrapperCollection.array;
                var onSourceUpdate = [];

                /*maybe is a good idea to expose watch*/
                function refreshSource(items) {
                    source = wrapper(items, converter) || items;
                    if (!source) return;
                    onSourceUpdate.forEach(function (fn) {
                        fn()
                    });
                }

                scope.$watch(matches[sources [0]], refreshSource, true);

                return {
                    raw: {
                        source: getter(sources[0], scope),
                        getters: expressionsGetters,
                        alias: alias,
                        keywords: keywords
                    },
                    converter: converter,
                    setWrapper: function (fn) {
                        wrapper = fn;
                    },
                    useArrayWrapper: function () {
                        wrapper = wrapperCollection.array;
                    },
                    useTreeWrapper: function () {
                        wrapper = wrapperCollection.tree;
                    },
                    onSourceUpdate: function (fn) {
                        onSourceUpdate.push(fn);
                    },
                    source: function () {
                        return source;
                    }
                }
            }
        }
    }]);
