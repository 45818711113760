"use strict";
'use strict';

const queries = {
    web: {

        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 'specifity', title: 'specificity score', hidden: true},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'score', title: 'total score'},
                {id: 'uniqueness-index', title: 'Audience Skew', template: 'partials/audience-skew.partial.html'}
                ]

        }
    },

    sg_telco: {

        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'specifity', title: 'specificity score', hidden: true}
            ]

        }
    },

    au_telco: {

        phrases: {
            columns: [
                {id: 'phrase', title: 'phrase', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'reach', title: 'Estimated Volume'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 't', title: 'topic', ellipsis: true},
                {id: 'specifity', title: 'specificity score', hidden: true}
            ]
        }
    },

    social: {

        phrases: {
            columns: [
                {id: 'phrase', title: 'Phrase', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'Total Score', flex: '0-7'},
                {id: 'relevancy', title: 'Relevancy Score'},
                {id: 'mentions', title: 'Mutual Mentions'},
                {id: 'rt_count', title: 'Retweets', hidden: true, flex: '0-7'},
                {id: 'mentions_per_day', title: 'Estimated Volume'},
                {id: 'velocity', title: 'Velocity', sym: '%', flex: '0-7'},
                {id: 'specificity', title: 'Specificity score', hidden: true},
                {id: 'top_topic', title: 'Topic', ellipsis: true},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},

                {id: 'pos', title: 'Positive', export: true, hidden: true, alwaysHidden: true},
                {id: 'neu', title: 'Neutral', export: true, hidden: true, alwaysHidden: true},
                {id: 'neg', title: 'Negative', export: true, hidden: true, alwaysHidden: true}
            ]
        },

        hashtags: {
            columns: [
                {id: 'hashtag', title: 'hashtag', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score', flex: '0-7'},
                {id: 'mentions', title: 'Mutual Mentions'},
                {id: 'rt_count', title: 'Retweets', flex: '0-7'},
                {id: 'velocity', title: 'velocity', sym: '%', flex: '0-7'},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},

                {id: 'pos', title: 'Positive', export: true, hidden: true, alwaysHidden: true},
                {id: 'neu', title: 'Neutral', export: true, hidden: true, alwaysHidden: true},
                {id: 'neg', title: 'Negative', export: true, hidden: true, alwaysHidden: true}
            ]
        },

        influencers: {
            columns: [
                {id: 'handle', title: 'handle', moreInfo: 'bio', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score', flex: '0-7'},
                {id: 'mentions', title: 'mutual mentions'},
                {id: 'retweets', title: 'retweets', flex: '0-7'},
                {id: 'followers', title: 'followers'},
                {id: 'klout_score', title: 'klout score', hidden: true},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},

                {id: 'pos', title: 'Positive', export: true, hidden: true, alwaysHidden: true},
                {id: 'neu', title: 'Neutral', export: true, hidden: true, alwaysHidden: true},
                {id: 'neg', title: 'Negative', export: true, hidden: true, alwaysHidden: true},

                {id: 'verified', title: 'Verified user', hidden: true, alwaysHidden: true}
            ]
        },

        audience: {
            columns: [
                {id: 'handle', title: 'handle', moreInfo: 'bio', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score', flex: '0-7'},
                {id: 'mentions', title: 'mutual mentions'},
                {id: 'retweets', title: 'retweets', flex: '0-7'},
                {id: 'followers', title: 'followers'},
                {id: 'klout_score', title: 'klout score', hidden: true},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},

                {id: 'pos', title: 'Positive', export: true, hidden: true, alwaysHidden: true},
                {id: 'neu', title: 'Neutral', export: true, hidden: true, alwaysHidden: true},
                {id: 'neg', title: 'Negative', export: true, hidden: true, alwaysHidden: true},

                {id: 'tail', title: 'Retweeter', hidden: true, alwaysHidden: true}
            ]
        },

        emojis: {
            columns: [
                {id: 'id', title: 'emoji', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'eid', search: true, title: 'name', template: 'partials/emoji-name.partial.html'},
                {id: 'score', title: 'total score', flex: '0-7'},
                {id: 'mentions', title: 'mutual mentions'},
                {id: 'retweets', title: 'retweets', flex: '0-7'},
                {id: 'net_sent', title: 'Net Sentiment', template: 'partials/net-sentiment.partial.html'},

                {id: 'pos', title: 'Positive', export: true, hidden: true, alwaysHidden: true},
                {id: 'neu', title: 'Neutral', export: true, hidden: true, alwaysHidden: true},
                {id: 'neg', title: 'Negative', export: true, hidden: true, alwaysHidden: true}
            ]
        }
    },

    facebook: {

        interests: {
            columns: [
                {id: 'id', title: 'interest', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'relevancy', title: 'relevancy score'},
                {id: 'estimated_reach', title: 'Estimated reach', template: 'partials/facebook-estimated-reach.partial.html'},
                {id: 'velocity', title: 'velocity', sym: '%'},
                {id: 'facebook_category', title: 'Facebook category', template: 'partials/facebook-category.partial.html'}
            ]
        },

        pages: {
            columns: [
                {id: 'name', title: 'name', flex: '1-5', alwaysShown: true, template: 'partials/phrase-select.partial.html'},
                {id: 'score', title: 'total score'},
                {id: 'shares', title: 'shares'},
                {id: 'likes', title: 'likes'},
                {id: 'comments', title: 'comments'},
                {id: 'top_topic', title: 'topic'},
                {id: 'ID', hidden: true, alwaysHidden: true}
            ]
        }

    }
};

const tooltips = {

    score: {
        all: 'Final recommendation score based on Volume, Relevancy & Velocity',
        influencers: "Final recommendation score based on the influencer's popularity and relevancy",
        audience: "Final recommendation score based on the relevancy and the volume of the user's twitter activity"
    },

    relevancy: 'A measure indicating the tendency of a phrase to be mentioned in the context of the seed. 0 score means the phrase is never mentioned in the context of the seed, while 100 means it is always mentioned in the seed’s context.',
    reach: 'Estimated average daily phrase volume in US-based web consumption',

    mentions: {
        all: 'Total mentions of the phrase with the seed in tweets & retweets during the selected time frame',
        handles: 'Total mentions of the handle with the seed in tweets & retweets during the selected time frame',
        emojis: 'Total mentions of the emoji with the seed in tweets & retweets during the selected time frame'
    },

    rt_count: {
        all: 'Total number of retweets that contained the phrase and the seed',
        hashtags: 'Total number of that contained the hashtag and the seed'
    },
    retweets: {
        all: 'Total number of retweets that contained the handle and the seed',
        emojis: 'Total number of retweets that contained the emoji and the seed'
    },

    mentions_per_day: 'Average daily mentions of the phrase in tweets & retweets',
    velocity: 'A measure of how much the phrase has changed in popularity over a given period of time, compared to the previous period of time.',

    id: {
        interests: 'Facebook-targetable interests'
    },

    specifity: 'A measure of how specific the phrase is. Low values refer to phrases that appear in many topics, high values - few topics',
    specificity: 'A measure of how specific the phrase is. Low values refer to phrases that appear in many topics, high values - few topics',
    estimated_reach: 'Estimated target audience size',
    facebook_category: 'Topic category on Facebook',

    net_sent: 'A measure indicating the overall sentiment expressed in content that mentions the phrase and the seed. The scale ranges from mostly negative to mostly positive.',
    shares: 'A measure of how many shares this page has',
    likes: 'A measure of how many likes this page has',
    comments: 'A measure of how many comments this page has',

    t: 'The most common topic of the phrase in web consumption',
    top_topic: 'The most common topic of the phrase in web consumption',

    followers: {
        all: "User's number of followers",
        influencers: "Influencer's number of followers"
    },

    klout_score: {
        all: "User's rank according to Klout",
        influencers: "Influencer's rank according to Klout"
    },

    'uniqueness-index': 'Audience Skew score measures the extent of which a phrase is likely to be consumed by an audience, compared to the average consumer'
};

//add to query values like tooltip
for(let i in queries) {
    let channel = queries[i];

    for(let j in channel) {
        let query = channel[j];
        query.title = j;

        //tooltips
        query.columns.forEach(function(col) {
            if(tooltips[col.id]) {
                let tip = tooltips[col.id];
                if(typeof tip == 'string') {
                    col.tooltip = tip;
                } else {
                    if(tip[j]) {
                        col.tooltip = tip[j];
                    } else {
                        col.tooltip = tip.all;
                    }
                }
            }
        });

        //sort by score
        query.order = 'score';
        query.reverse = true;
        query.click = 0;
    }
}


module.exports = angular.module(__filename, []).constant('GRID_QUERIES', {QUERIES: queries});
