"use strict";
var tmpl = require("./news-widget.html"),
    BaseWidget = require("../base_widget"),
    NewsModule = require("data/news"),
    c = require("infra/utils/common"),
    dotdotdot = require("jquery.dotdotdot");

function NewsWidget(newsService, $timeout, $scope, $element, util) {
    this.newsService = newsService;
    this.$timeout = $timeout;
    this.util = util;
    var self = this;
    var totalStrides = 3;
    $scope.stride = 3; // three articles per "page"
    $scope.startIndex = 0;

    $scope.paginate = function (step) {
        var newStartIndex = $scope.startIndex + step;
        if (newStartIndex == totalStrides * $scope.stride || newStartIndex < 0) {
            if (newStartIndex == totalStrides * $scope.stride) {
                $scope.startIndex = 0;
            } else {
                $scope.startIndex = $scope.stride * 2;
            }
        } else {
            $scope.startIndex = newStartIndex;
        }
        // update text length ...
        self.$timeout(function () {
            self.$element.find(".article-text").dotdotdot();
        });
    };

    $scope.openBrowserTab = function (url) {
        self.$window.open(url);
    };
    $scope.toDate = function (dateString) {
        return new Date(dateString);
    };
    $scope.getUrlDomain = function (url) {
        var matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        return matches && matches[1];  // will return null if no match is found
    };
}

NewsWidget.prototype._doUpdate = function (values) {
    var selectedTerms = _.filter(values.terms, 'text', this.$scope.brandName);
    var kwd_ids = this.util.getPhrasesToIdMap(selectedTerms);
    var terms = _.map(selectedTerms, 'text');
    if (_.isEmpty(terms)) {
      return Promise.resolve();
    }
    const params = {
      terms,
      results_per_term: 9,
      geos: this.$scope.geos,
      kwd_ids,
      timeframe: c.getTimeframe(30, false),
      brand_name: this.$scope.brandName
    };

    return this.newsService.get(params).then(result => {
            this.$scope.result = result;
            this.$scope.startIndex = 0;
            this.$timeout(() => this.$element.find(".article-text").dotdotdot());
        }
    );
};

NewsWidget.prototype.onResize = function () {
    var self = this;
    this.$timeout(function () {
        self.$element.find(".article-text").dotdotdot();
    });
};

NewsWidget.$inject = ["newsService", "$timeout", "$scope", "$element", "util"];
module.exports = angular.module(__filename, [
    NewsModule.name
]).directive("newsWidget", [function () {
    return BaseWidget({
        restrict: "E",
        template: tmpl,
        scope: {
            brandName: "=brandName",
            geos: "=geos",
            terms: "=terms"
        },
        controller: NewsWidget
    });
}]).filter('slice', function () {
    return function (arr, start, end) {
        return (arr || []).slice(start, end);
    };
});
