"use strict";
'use strict';

module.exports = angular.module(__filename, []).service('alertsExportService', ['Excel', 'context', 'alertsService',
    function (Excel, context, alertsService) {
        var objectURL;
        var options = {
            base64: false,
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        };

        var timeframes = {
            last_month: '1M',
            last_quarter: '3M',
            last_6_months: '6M',
            last_year: '1Y'
        };

        function summaryWorksheet(workbook, formatter, settings, alert) {
            var name = alert ? alert.name + ' Summary' : 'Summary';
            var worksheet = workbook.createWorksheet({name: name});
            var p_name, emails, mode;
            if (!settings) {
                p_name = context.program.name;
                emails = 'N/A';
                mode = 'N/A';
            }

            var data = [
                [formatter('Generated At:', 'bold'), moment().format('YYYY-MM-DD (HH:mm)')],
                [formatter('Program', 'bold'), p_name || settings.p_name],
                [formatter('Recipients', 'bold'), emails || settings.emails.join('; ')],
                [formatter('Alert mode', 'bold'), mode || settings.interval.type]
            ];

            if (alert) {
                data.splice(3, 0, [formatter('Alert name', 'bold'), alert.name]);
                data.splice(4, 0, [formatter('Alert type', 'bold'), alert.metadata['type']]);
            }

            worksheet.setData(data);
            worksheet.setColumns([{width: 15}, {width: 30}]);
            workbook.addWorksheet(worksheet)
        }

        function detailedReportWorksheet(workbook, formatter, data, alert) {
            var detailedReportWorksheet = workbook.createWorksheet({name: alert.name + ' Triggers'});
            var titleLine = ['Trigger datetime', 'Interest', 'Interest refinement', '% Change from previous day',
                '% Change from average', 'pp. Change from previous day', 'pp. Change from average', 'Status', 'Alert mode', 'Content driver 1', 'Content driver 2',
                'Association 1', ' Association 2', 'Association 3', 'Association 4', 'Association 5', 'Trigger vs. Avg', 'Thresh vs. Avg value',
                'stdev / %', 'Trigger vs. prev day', 'Thresh vs. prev day value', 'stdev / %', 'Benchmark', 'Tracking', 'Direction',
                'Geo', 'Channel', 'Value of tracked metric', 'Threshold 1 value', 'Threshold 2 value', 'Benchmark Average'
            ];

            var boldTitles = _.map(titleLine, function (title) {
                return formatter(title, 'bold');
            });

            var dateFormattedData = _.map(data, function (dataRow) {
                dataRow[0] = dateFormat(dataRow[0]);
                return dataRow;
            });

            dateFormattedData.unshift(boldTitles);
            detailedReportWorksheet.setData(dateFormattedData);
            detailedReportWorksheet.setColumns([{width: 20}, {width: 20}, {width: 20}, {width: 24}, {width: 24}, {width: 25}, {width: 19}, {width: 7}, {width: 20},
                {width: 20}, {width: 20}, {width: 20}, {width: 20}, {width: 20}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15},
                {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}, {width: 15}]);
            workbook.addWorksheet(detailedReportWorksheet);
        }

        function dateFormat(dateObj) {
            return moment(dateObj).format('M/D/YY HH:mm a')
        }

        function getAlertLine(alert) {
            var res = [alert.name, !alert.paused ? "True" : "False"];
            res.push(_.map(alert.display_interests, 'text').join(', '));
            res.push(alert.active ? 'Active' : 'Inactive');

            var activeInterests = _.map(_.filter(alert.display_interests, 'active'), 'text');
            res.push(activeInterests.length > 0 ? activeInterests.join(', ') : 'N/A');

            var value;
            var triggers = alert.triggers;
            if (triggers.compare_to_avg.selected) {
                value = triggers.compare_to_avg.percentage ? triggers.compare_to_avg.percentage + '%' : triggers.compare_to_avg.stdev + ' stdev';
                res.push('Yes', value);
            } else {
                res.push('No', 'N/A');
            }
            if (triggers.compare_to_previous.selected) {
                value = triggers.compare_to_previous.percentage ? triggers.compare_to_previous.percentage + '%' : triggers.compare_to_previous.stdev + ' stdev';
                res.push('Yes', value);
            } else {
                res.push('No', 'N/A');
            }

            res.push(timeframes[alert.benchmark_timeframe]);

            var tracking = getTracking(alert);
            res.push(tracking);

            var direction = (_.find(alertsService.alertChangeDirection, {value: alert.metadata.change}) || {}).label;
            res.push(direction);
            return res;
        }

        function groupSettingsWorksheet(workbook, formatter, alerts) {
            var groupSettingsWorksheet = workbook.createWorksheet({name: 'Group Settings'});
            var titleLine = [
                formatter('Group', 'bold'),
                formatter('Enabled', 'bold'),
                formatter('Interests', 'bold'),
                formatter('Group status', 'bold'),
                formatter('Active interests', 'bold'),
                formatter('Trigger vs. Avg', 'bold'),
                formatter('Thresh vs. Avg', 'bold'),
                formatter('Trigger vs. prev day', 'bold'),
                formatter('Thresh vs. prev day', 'bold'),
                formatter('Benchmark', 'bold'),
                formatter('Tracking', 'bold'),
                formatter('Direction', 'bold')
            ];

            var data = _.map(alerts, getAlertLine);

            data.unshift(titleLine);
            groupSettingsWorksheet.setData(data);
            groupSettingsWorksheet.setColumns([
                {width: 30}, {width: 30}, {width: 12}, {width: 25}, {width: 15},
                {width: 15}, {width: 18}, {width: 18}, {width: 11}
            ]);
            workbook.addWorksheet(groupSettingsWorksheet);
        }

        function interestsWorksheet(workbook, formatter, interests) {
            var interestsWorksheet = workbook.createWorksheet({name: 'Interests'});
            var titleLine = [
                formatter('Interest', 'bold'),
                formatter('Tracking', 'bold'),
                formatter('Interest status', 'bold'),
                formatter('% Change vs. previous day', 'bold'),
                formatter('% Change vs. average', 'bold'),
                formatter('pp. Change vs. previous day', 'bold'),
                formatter('pp. Change vs. average', 'bold'),
                formatter('Stdev change vs. previous day', 'bold'),
                formatter('Stdev change vs. average', 'bold'),
                formatter('Current consumption', 'bold'),
                formatter('Previous day', 'bold'),
                formatter('Average', 'bold'),
                formatter('Stdev', 'bold')
            ];

            var data = _.map(interests, function (interest) {
                var interestChanges = calcInterestChanges(interest);
                return [
                    interest.phrase,
                    interest.tracking,
                    interest.active ? 'Active' : 'Inactive',
                    interestChanges.prevPercentageChange,
                    interestChanges.AvgPercentageChange,
                    interestChanges.pointsPrevChange,
                    interestChanges.pointsAvgChange,

                    interestChanges.prevStdChange,
                    interestChanges.avgStdChange,
                    interestChanges.currentConsumption,
                    interestChanges.prevConsumption,
                    interestChanges.currentAvg,
                    interestChanges.currentStd
                ];
            });

            data.unshift(titleLine);
            interestsWorksheet.setData(data);
            interestsWorksheet.setColumns([
                {width: 15}, {width: 15}, {width: 24}, {width: 21}, {width: 28},
                {width: 25}, {width: 20}, {width: 12}, {width: 10}, {width: 10}
            ]);
            workbook.addWorksheet(interestsWorksheet);
        }

        function buildInterests(alerts, values) {
            var interests = [];
            alerts.forEach(function (alert) {
                var alertValues = values[alert.id];
                alert.display_interests.forEach(function (interest) {
                    var value = alertValues[interest.text] || {phrase: interest.text, previous: {}, average: {}};
                    interests.push(Object.assign(value, {tracking: getTracking(alert)}));
                });
            });

            return interests;
        }

        function getTracking(alert) {
            return alert.metadata.type == 'sentiment' ? _.find(alertsService.sentimentDetection, {id: alert.metadata.sentiment}).label : alert.metadata.type
        }

        function calcInterestChanges(interest) {
            var isNetSentiment = interest.tracking == _.find(alertsService.sentimentDetection, {id: 'net'}).label;
            var getValue = (key, suffix = '') => {
                var value = _.get(interest, key);
                return _.isNumber(value) ? `${value.toFixed(2)}${suffix}` : 'N/A'
            };

            var calcValue = (x, y) => _.isNumber(x) && _.isNumber(y) ? (x - y).toFixed(2) : 'N/A';
            return {
                prevPercentageChange: isNetSentiment ? 'N/A' : getValue("previous.percentage_change", "%"),
                AvgPercentageChange: isNetSentiment ? 'N/A' : getValue("average.percentage_change", "%"),
                pointsPrevChange: calcValue(interest.current_consumption, interest.previous_day_consumption) + "%",
                pointsAvgChange: calcValue(interest.current_consumption, interest.current_average) + "%",
                prevStdChange: getValue("previous.std_change"),
                avgStdChange: getValue("average.std_change"),
                currentConsumption: getValue("current_consumption"),
                prevConsumption: getValue("previous_day_consumption"),
                currentAvg: getValue("current_average"),
                currentStd: getValue("current_stdev")
            }
        }

        return {
            exportToExcel: function (alerts, settings, values) {
                var workbook = Excel.builder.createWorkbook();
                var formatter = Excel.formater(workbook);

                summaryWorksheet(workbook, formatter, settings);
                groupSettingsWorksheet(workbook, formatter, alerts);
                var interests = buildInterests(alerts, values);
                interestsWorksheet(workbook, formatter, interests);

                workbook.fileName = 'Alerts ' + moment().format('YYYY-MM-DD HH-mm') + '.xlsx';
                return workbook;
            },
            downloadExcel: function (excel) {
                URL.revokeObjectURL(objectURL);
                var blob = Excel.builder.createFile(excel, options);
                objectURL = URL.createObjectURL(blob);
                var linkEl = document.createElement('a');
                linkEl.href = objectURL;
                linkEl.setAttribute('download', excel.fileName || ('Alerts ' + moment().format('YYYY-MM-DD HH-mm') + '.xlsx'));
                linkEl.click();
            },
            exportAlertToExcel: function (alert, settings, data) {
                var workbook = Excel.builder.createWorkbook();
                var formatter = Excel.formater(workbook);
                console.log(alert, settings);
                summaryWorksheet(workbook, formatter, settings, alert[0]);
                detailedReportWorksheet(workbook, formatter, data, alert[0]);
                workbook.fileName = alert[0]['name'] + moment().format('YYYY-MM-DD HH-mm') + '.xlsx';
                return workbook;
            }
        }
    }
]);
