"use strict";
function ConsumptionController($rootScope) {
}

ConsumptionController.$inject = ["$rootScope"];

module.exports.stateConfig = {
    name: "consumption",
    url: "/consumption",
    template: require("./consumption.insights.html"),
    display: "Insights",
    data: {
      permissions: ['consumption trends']
    },
    controller: ConsumptionController,
    context: {}
};
