"use strict";
/*
 non array side menu filter. to use:

 1. place it on context.current
 2. each of its objects's values needs a <value>_def too
    context.current.filterName = {
        obj1{value1: x, value1_def = y},
        obj2{value1: x, value1_def = y},
    }
 3. specific values like titles. see "//specific filters"
 */

module.exports = {
    reset: function (fill) {
        for (var name in fill) {
            if (typeof fill[name] != "string") {
                var obj = fill[name];
                for (var prop in obj) {
                    if (prop.indexOf("_def") != prop.length - 4) {
                        obj[prop] = obj[prop + "_def"];
                    }
                }
            }
        }
    },
    summary: function (name, filter) {
        var i, sum = [];
        for (i in filter) {
            if (i.indexOf("_def") == -1) {
                sum.push({title: this[name].titles[i], value: this[name].values(i, filter[i])});
            }
        }

        return sum;
    },
    objWithoutDef: function (obj) {
        var obj2 = {};
        for (var i in obj) {
            if (i.indexOf("_def") != i.length - 4) {
                obj2[i] = obj[i];
            }
        }
        return obj2;
    },

    //SPECIFIC FILTERS
    gridAdvanced: {
        titles: {
            min_rt_count: 'Minimum Re-Tweets',
            min_followers: 'Minimum Followers',
            targeting_mode: 'Relevancy Vs Scale'
        },
        values: function (name, value) {
            if (name == 'targeting_mode') {
                var values = ["High Relevancy", "Relevancy", "Regular", "Scale", "High Scale"];
                return values[value / 25];
            } else {
                return value;
            }
        }
    }
};
