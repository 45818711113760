"use strict";
var config = require('infra/config');
module.exports = angular.module(__filename, [
    require('angular-cookies').name
]).service("authentication", ['$rootScope', '$injector', '$http', '$q', '$auth', '$state', '$window', '$location', '$cookies', 'userMgmt', 'authenticationConfig',
    function ($rootScope, $injector, $http, $q, $auth, $state, $window, $location, $cookies, userMgmt, authenticationConfig) {
        this.login = login;
        this.logout = logout;
        this.authenticate = autoAuthenticate;
        this.silentReAuthentication = silentReAuthentication;

        var APP_TO_PAGE_MAP = {
            "trending interests": "discovery.bubbles",
            "content streams": "discovery.streams",
            "channel insights": "discovery.grid.phrases.web",
            "consumption trends": "insights.consumption",
            topics: "insights.topic",
            sentiment: "insights.sentiment",
            associations: "insights.association",
            channels: "insights.channels",
            timing: "insights.timing",
            landscape: "insights.landscape",
            "war room": "warroom.groups",
            audience: "audience-builder",
            alerts: "alerts"
        };

        var reAuthTs = 0;
        var MAX_MILLSEC_BETWEEN_AUTHS = 5000;
        var reAuthPromise = null;
        var reAuthURLs = {};
        var reauthListener = angular.noop;
        var PAGE_REDIRECTIONS = {"audience-explorer": "audience-builder"};

        function autoAuthenticate() {
            if ($window.location.hash.indexOf('login') != -1) return $q.reject('Already in login');

            if ($window.location.hash.indexOf('reset_password') != -1) {
                return $state.go('reset_password', {t: $location.search().t});
            }
            if (localStorage.rememberMe == 'false' && !$cookies.get('abi-session')) {
                return $state.go('login', {next: $window.location.hash.substring(2).replace(/\//g, '.')});
            }

            if ($auth.isAuthenticated()) {
                return userMgmt.user($auth.getToken()).then(function (response) {
                    return onLoginSuccess({user: response, token: $auth.getToken()});
                });
            } else {
                return refreshAccessToken().then(function (tokens) {
                    return $q.all([userMgmt.user($auth.getToken()), tokens.data])
                }).then(function (data) {
                    return onLoginSuccess(angular.extend({}, {user: data[0]}, data[1]));
                }).catch(function (err) {
                    $state.go('login', {next: $window.location.hash.substring(2).replace(/\//g, '.')});
                });
            }
        }

        function silentReAuthentication(url) {
            if (!reAuthPromise || new Date().getTime() - reAuthTs > MAX_MILLSEC_BETWEEN_AUTHS) {
                reAuthPromise = refreshAccessToken();
                reAuthURLs = {};
                reAuthURLs[url] = true;
            } else {
                if (reAuthURLs[url]) {
                    return Promise.reject('url ' + url + ' already asked for reAuthentication!');
                }

                reAuthURLs[url] = true;
            }

            reAuthTs = new Date().getTime();
            return reAuthPromise;
        }

        function login(username, password, rememberMe) {
            // In case user doesn't want to be remembered
            localStorage.setItem('rememberMe', rememberMe);
            $cookies.put('abi-session', true);

            var userCredentials = {username: username, password: password, rememberMe: rememberMe};
            return $auth.login(userCredentials).then(function (response) {
                return onLoginSuccess(response.data)
            }).catch(function (err) {
                return $q.reject(err);
            });
        }

        function logout() {
            $http.post(config.AUTHENTICATION_API + "/logout", {token: localStorage.refresh_token}).finally(function (response) {
                $auth.logout();
                localStorage.removeItem("refresh_token");
                return $state.go('login');
            }).then(function () {
                // Waiting for the state transition to end,
                // and after that prevent state changes to other states in the application
                reauthListener = $rootScope.$on('$stateChangeStart', function (event, nextState) {
                    if (nextState.name != 'reset_password') {
                        event.preventDefault();
                    }
                });
            });
        }

        function refreshAccessToken() {
            return $auth.login({refresh_token: localStorage.refresh_token}).then(function (response) {
                localStorage.setItem("refresh_token", response.data.refresh_token);
                return response;
            });
        }

        function onLoginSuccess(data) {
            // Will destroy the reauth listener if exists.
            reauthListener();

            if (data.refresh_token) {
                localStorage.setItem("refresh_token", data.refresh_token);
            }

            $cookies.put('Authorization_help', data.token, {domain: '.intelligence.amobee.com', expires: moment().add(1, 'days').toDate()});
            return postAuthentication(userMgmt.buildUser(data.user));
        }

        function postAuthentication(user) {
            return $q.all(startListeners(user)).then(function () {
                if ($state.params.help) {
                    return $window.location.href = 'http://help.intelligence.amobee.com';
                }
                var permissions = user.permissions.map(function (permission) {
                    return permission.name;
                });

                // If no dashboard for user navigate to the first application in map
                var allowedApps = _.intersection(Object.keys(APP_TO_PAGE_MAP), permissions);
                var appStates = allowedApps.map(function (app) {
                    return APP_TO_PAGE_MAP[app].split('.')[0];
                }).concat('settings');

                var nextState = permissions.indexOf('dashboard') == -1 ? APP_TO_PAGE_MAP[allowedApps[0]] : 'dashboard';
                if (!$state.includes('login')) {
                    var wantedState = $window.location.hash.substring(2).replace(/\//g, '.').replace(/#.*/g, '');
                    if (PAGE_REDIRECTIONS[wantedState]) wantedState = PAGE_REDIRECTIONS[wantedState];
                    nextState = appStates.includes(wantedState.split('.')[0]) ? wantedState : nextState;
                } else {
                    if ($state.params.next) {
                        nextState = $state.params.next;
                    }
                }

                $state.go(nextState, {reload: true}).then(function () {
                }).catch(function (err) {
                    console.log(err)
                });
                return user;
            });
        }

        function startListeners(authInfo) {
            return authenticationConfig.doAfterAuthentication.map(function (cb) {
                return $injector.invoke(cb, null, {authInfo: authInfo});
            });
        }
    }
]);
