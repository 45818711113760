"use strict";
module.exports = require("angular").module(__filename, [])
    .directive('ageGenderChart', ["$window", function($window) {
            return {
                restrict: 'E',
                template: require('./age-gender-chart.drv.html'),
                scope: {
                    data: "=",
                    indexLine: "="
                },
                link: function($scope, $element) {
                    $scope.$watch('data', () => {
                        if($scope.data) {
                            const maleValues = $scope.data.map(o => o.male.value),
                                maleMax = Math.max(...maleValues), maleMin = Math.min(...maleValues);
                            if(maleMax !== maleMin)
                                $scope.data.forEach(element => { if(maleMax === element.male.value) element.male.highlight = true });

                            const femaleValues = $scope.data.map(o => o.female.value),
                                femaleMax = Math.max(...femaleValues), femaleMin = Math.min(...femaleValues);
                            if(femaleMax !== femaleMin)
                                $scope.data.forEach(element => { if(femaleMax === element.female.value) element.female.highlight = true });

                            $scope.barUnit = 1 / Math.max(maleMax, femaleMax);
                        }
                    });
                }
            }
        }]
    );
